import React from "react";
import PropTypes from "prop-types";

import PhoneNumber from "../common/PhoneNumber";

import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { DropdownButton, MenuItem } from "react-bootstrap";
import CustomLoadable from "../common/Loadable";

const AccountLocationList = ({ account, onClickDelete, onClickEdit }) => {
  let accountLocationsView = <CustomLoadable />;

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Address",
      accessor: "address.addr1",
    },
    {
      Header: "City",
      accessor: "address.city",
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: (row) => <PhoneNumber number={row.value} />,
    },
    {
      Header: "Fax",
      accessor: "fax",
      Cell: (row) => <PhoneNumber number={row.value} />,
    },
    {
      Header: "",
      accessor: "_id",
      Cell: (row) => (
        <div className="text-center actions">
          <DropdownButton
            title="Actions"
            id={"dropdown"}
            onClick={(e) => e.stopPropagation()}
          >
            <MenuItem onClick={() => onClickEdit(row.value)}>
              <FontAwesomeIcon icon={faFolder} />
              View
            </MenuItem>
            <MenuItem onClick={() => onClickDelete(row.value)}>
              <FontAwesomeIcon icon={faTrashAlt} className="brand-color" />
              Delete
            </MenuItem>
          </DropdownButton>
        </div>
      ),
    },
  ];

  if (account) {
    accountLocationsView = (
      <ReactTable
        className="-highlight"
        data={account.locations}
        loading={false}
        columns={columns}
        defaultPageSize={10}
      />
    );
  }

  return <div>{accountLocationsView}</div>;
};

AccountLocationList.propTypes = {
  account: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export default AccountLocationList;
