import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as companyActions from "../../actions/companyActions";
import * as modalActions from "../../actions/modalActions";

import * as labelActions from "../../actions/labelActions";
import { Row, Col, Collapse } from "react-bootstrap";
import CompanyList from "./CompanyList";
import CompanyLocationList from "./CompanyLocationList";
import Modal from "../common/Modal";
import DynamicForm from "../common/DynamicForm";
import ConfirmModal from "../common/ConfirmModal";

import find from "lodash/find";

class CompanyLocationPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { adding: false };

    autoBind(this);

    props.actions.loadCompanies();
  }

  handleCancel() {
    this.setState({ add: false });
  }

  handleSave(location) {
    let company = this.props.companies[0];
    this.setState({ adding: false });
    this.props.actions.createLocation(company, location);
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={3}>
            <h3 style={{ lineHeight: "50px" }}>Locations</h3>
          </Col>
          <Col md={3} mdOffset={6}>
            <button
              className="btn btn-sm right btn-add"
              onClick={() => this.setState({ add: !this.state.add })}
            >
              {this.state.add ? "HIDE" : "ADD"}
            </button>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="settings-add">
            <Collapse in={this.state.add}>
              <div>
                <Row>
                  <h4>Add Location</h4>
                  <DynamicForm
                    ref="form"
                    fieldData={find(this.props.labels, {
                      entity: "Location",
                    })}
                    onCancel={this.handleCancel}
                    onSave={this.handleSave}
                    entity={"Location"}
                    saving={this.props.savingLocation}
                    editing={true}
                    adding={true}
                  />
                </Row>
              </div>
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <CompanyLocationList
              companies={this.props.companies}
              handleSave={this.handleSave}
              handleAdd={this.handleAdd}
              adding={this.state.add}
              savingCompany={this.props.savingCompany}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

CompanyLocationPage.propTypes = {
  actions: PropTypes.object,

  labels: PropTypes.array,
  savingLocation: PropTypes.bool,
  savingCompany: PropTypes.bool,
  companies: PropTypes.array.isRequired,
  company: PropTypes.object.isRequired,
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,

    labels: state.reducers.labels.labels,
    savingLocation: state.reducers.savingLocation,
    companies: state.reducers.companies.companies,
    company: state.reducers.company,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...companyActions, ...labelActions },
      dispatch
    ),
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(CompanyLocationPage);
