import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { Input, Textarea, Select } from "formsy-react-components";
import autoBind from "react-autobind";
import uniqBy from "lodash/uniqBy";

class ZipForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
    };

    autoBind(this);
  }

  enableButton() {
    this.setState({
      canSubmit: true,
    });
  }

  disableButton() {
    this.setState({
      canSubmit: false,
    });
  }

  submit(model) {
    this.props.onSave(model);
    this.refs.form.reset();
  }

  resetForm() {
    this.refs.form.reset();
  }

  render() {
    return (
      <div>
        <Formsy
          ref="form"
          className="vertical form"
          onValidSubmit={this.submit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
        >
          <Col md={3}>
            <Input
              formNoValidate
              required
              name="zip"
              label="Zip"
              placeholder="Zip"
              value={this.props.zip.zip || ""}
            />
          </Col>
          <Col md={3}>
            <Input
              formNoValidate
              required
              name="city"
              label="City"
              placeholder="City"
              value={this.props.zip.city || ""}
            />
          </Col>
          <Col md={3}>
            <Input
              formNoValidate
              required
              name="county"
              label="County"
              placeholder="County"
              value={this.props.zip.county || ""}
            />
          </Col>
          <Col md={3}>
            <Select
              formNoValidate
              required
              name="region"
              label="Region"
              placeholder="Region"
              options={[
                {
                  value: "",
                  label: "Please select...",
                },
                ...uniqBy(this.props.zips, "region").map((region) => {
                  console.log(region);
                  return {
                    value: region.region,
                    label: region.region,
                  };
                }),
              ]}
              value={this.props.zip.region || ""}
            />
          </Col>
          <Col md={12}>
            <Link
              style={
                this.props.zip.id != 0
                  ? { display: "inline" }
                  : { display: "none" }
              }
              className="btn btn-danger"
              to="/app/settings/zips"
            >
              Cancel
            </Link>
            <button
              className="btn btn-warning"
              type="button"
              onClick={this.resetForm}
            >
              Reset
            </button>
            <input
              className="btn btn-success"
              type="submit"
              disabled={!this.state.canSubmit}
              value={this.props.saving ? "Saving... " : "Save"}
            />
          </Col>
        </Formsy>
      </div>
    );
  }
}

ZipForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  zip: PropTypes.object.isRequired,
  zips: PropTypes.array.isRequired,
};

export default ZipForm;
