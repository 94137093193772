import React from "react";
import PropTypes from "prop-types";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFolder,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import uniqBy from "lodash/uniqBy";
import CustomLoadable from "../common/Loadable";

const ProcedureList = ({ items, onClickDelete }) => {
  let itemsView = <CustomLoadable />;

  let chargeTypeFilterOptions = uniqBy(
    items
      .filter((item) => item.charge_type && item.charge_type._id)
      .map((item) => ({
        label: item.charge_type.type,
        value: item.charge_type._id,
      })),
    "value"
  );

  const columns = [
    {
      Header: "Description",
      accessor: "description",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["description"] }),
      filterAll: true,
    },
    {
      Header: "License Required",
      accessor: "license_required",
      Cell: (row) => (
        <div className="text-center">
          {row.value == true ? <FontAwesomeIcon icon={faCheck} /> : ""}
        </div>
      ),
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "true") {
          return row[filter.id] === true;
        }
        if (filter.value === "false") {
          return row[filter.id] !== true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      ),
    },
    {
      Header: "OT Eligible",
      accessor: "ot_eligible",
      Cell: (row) => (
        <div className="text-center">
          {row.value == true ? <FontAwesomeIcon icon={faCheck} /> : ""}
        </div>
      ),
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "true") {
          return row[filter.id] === true;
        }
        if (filter.value === "false") {
          return row[filter.id] !== true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      ),
    },
    {
      Header: "Charge Type",
      accessor: "charge_type.type",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else {
          return row[filter.id] == filter.value;
        }
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            {chargeTypeFilterOptions.map((option) => {
              return (
                <option key={option.value} value={option.label}>
                  {option.label}
                </option>
              );
            })}
          </select>
        );
      },
    },
    {
      Header: "Product Category",
      accessor: "product_category.label",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["description"] }),
      filterAll: true,
    },
    {
      Header: "",
      accessor: "_id",
      filterable: false,
      Cell: (row) => (
        <div className="text-center actions">
          <DropdownButton
            title="Actions"
            id={"dropdown"}
            onClick={(e) => e.stopPropagation()}
          >
            <LinkContainer to={`/app/settings/procedures/${row.value}`}>
              <MenuItem>
                <FontAwesomeIcon icon={faFolder} />
                View
              </MenuItem>
            </LinkContainer>
            <MenuItem onClick={() => onClickDelete(row.value)}>
              <FontAwesomeIcon icon={faTrashAlt} className="brand-color" />
              Delete
            </MenuItem>
          </DropdownButton>
        </div>
      ),
    },
  ];

  if (items.length) {
    itemsView = (
      <ReactTable
        filterable
        className="-highlight"
        data={items}
        loading={false}
        columns={columns}
        defaultPageSize={10}
        defaultSorted={[
          {
            id: "description",
            asc: true,
          },
        ]}
      />
    );
  }
  return <div>{itemsView}</div>;
};

ProcedureList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  filter: PropTypes.object,
  onChange: PropTypes.func,
};

export default ProcedureList;
