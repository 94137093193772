import React from "react";
import PropTypes from "prop-types";

import { Col, Row } from "react-bootstrap";

export default function FormBox({
  children,
  containerClassNames,
  footerClassNames,
  showDeleteButton,
  onDeleteClick,
  onCancelClick,
  onSubmit,
  isSubmitDisabled,
  saving,
}) {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form className={containerClassNames} onSubmit={handleSubmit}>
      <Row>{children}</Row>
      <Row>
        <Col md={12} xs={12} className={footerClassNames}>
          {showDeleteButton && (
            <button
              className="btn btn-danger"
              type="button"
              onClick={onDeleteClick}
            >
              Delete
            </button>
          )}
          <button
            className="btn btn-danger"
            type="button"
            onClick={onCancelClick}
          >
            Cancel
          </button>
          <input
            className="btn btn-success"
            type="submit"
            disabled={isSubmitDisabled}
            value={saving ? "Saving... " : "Save"}
          />
        </Col>
      </Row>
    </form>
  );
}

FormBox.propTypes = {
  children: PropTypes.object,
  containerClassNames: PropTypes.string,
  footerClassNames: PropTypes.string,
  showDeleteButton: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  saving: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

FormBox.defaultProps = {
  containerClassNames: "vertical form",
  footerClassNames: "action-footer",
};
