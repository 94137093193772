import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as itemActions from "../../actions/itemActions";
import * as codeActions from "../../actions/codeActions";
import * as chargetypeActions from "../../actions/chargetypeActions";

import ProcedureForm from "./ProcedureForm";
import { Row, Col } from "react-bootstrap";

import isEmpty from "lodash/isEmpty";

class ProcedureEditPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      editing: false,
    };

    props.actions.getItem(props.match.params.id);
    autoBind(this);
  }

  componentDidMount() {
    let { chargetypes, actions } = this.props;
    if (isEmpty(chargetypes)) actions.loadChargetypes();
  }

  handleSave(item) {
    let data = {
      id: this.props.item.id,
      ...item,
    };

    this.props.actions.updateItem(data);
  }

  handleEdit() {
    this.setState({
      editing: !this.state.editing,
    });
  }

  handleCancel() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div className="content-wrapper">
        <Row>
          <Col md={12}>
            <h1>{this.props.item.description}</h1>
          </Col>
          <Col md={9}>
            <h3>Procedure Info</h3>
          </Col>
          <Col md={3}>
            <button
              className="btn btn-warning btn-sm right"
              onClick={this.handleEdit}
            >
              EDIT
            </button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ProcedureForm
              item={this.props.item}
              chargetypes={this.props.chargetypes}
              codes={this.props.codes}
              onSave={this.handleSave}
              editing={this.state.editing}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ProcedureEditPage.propTypes = {
  actions: PropTypes.object.isRequired,

  savingItem: PropTypes.bool,
  chargetypes: PropTypes.array.isRequired,
  item: PropTypes.object,
  params: PropTypes.object,
  labels: PropTypes.array,
  codes: PropTypes.array,
};

ProcedureEditPage.contextTypes = {
  router: PropTypes.object,
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,

    savingItem: state.reducers.savingItem,
    chargetypes: state.reducers.chargetypes,
    codes: state.reducers.codes,
    item: state.reducers.item,
    labels: state.reducers.labels.labels,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...itemActions, ...chargetypeActions, ...codeActions },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(ProcedureEditPage);
