import React, { useMemo } from "react";
import PropTypes from "prop-types";

import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
} from "react-bootstrap";
import { isEmpty, isNil } from "lodash";

export default function FieldGroup({
  controlId,
  bsSize,
  bsClass,
  label,
  type,
  value,
  placeholder,
  onChange,
  componentClass,
  required,
  disabled,
  readOnly,
  formNoValidate,
  name,
  step,
  onFocus,
  validations,
  rows,
  classNames,
}) {
  const validationState = useMemo(() => {
    if (isEmpty(validations) || isNil(value) || value === "") {
      return { variant: null, helpText: "" };
    }
    return (
      Object.keys(validations)
        .map((key) => {
          if (!validations[key].validator(value)) {
            return validations[key];
          }
        })
        .filter(Boolean)[0] || { variant: null, helpText: "" }
    );
  }, [validations, value]);

  return (
    <FormGroup
      controlId={controlId}
      validationState={validationState.variant}
      bsSize={bsSize}
      bsClass={bsClass}
      className={classNames}
    >
      {label && <ControlLabel>{label}</ControlLabel>}
      <FormControl
        componentClass={componentClass}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={({ target: { name, value } }) => onChange(name, value)}
        readOnly={readOnly}
        required={required}
        disabled={disabled}
        formNoValidate={formNoValidate}
        name={name}
        step={step}
        onFocus={onFocus}
        rows={rows}
      />
      {validationState.helpText && (
        <HelpBlock>{validationState.helpText}</HelpBlock>
      )}
    </FormGroup>
  );
}

FieldGroup.propTypes = {
  controlId: PropTypes.string,
  bsSize: PropTypes.string,
  bsClass: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  componentClass: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  formNoValidate: PropTypes.bool,
  name: PropTypes.string,
  step: PropTypes.number,
  onFocus: PropTypes.func,
  validations: PropTypes.object,
  rows: PropTypes.number,
  classNames: PropTypes.string,
};

FieldGroup.defaultProps = {
  type: "text",
  bsSize: "small",
  componentClass: "input",
  formNoValidate: true,
  onChange: () => null,
  onFocus: () => null,
  validations: {},
  classNames: "row",
};
