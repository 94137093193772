import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const accounts = (state = initialState.accounts, action) => {
  switch (action.type) {
    case types.LOAD_ACCOUNT_SUCCESS:
      return action.accounts;

    case types.CREATE_ACCOUNT_SUCCESS:
      return [...state, Object.assign({}, action.account)];

    case types.UPDATE_ACCOUNT_SUCCESS:
      return [
        ...state.filter((accounts) => accounts.id !== action.account.id),
        Object.assign({}, action.account),
      ];

    case types.DELETE_ACCOUNT_SUCCESS:
      return [...state.filter((account) => account.id !== action.accountId)];

    default:
      return state;
  }
};

export const account = (state = initialState.account, action) => {
  switch (action.type) {
    case types.GET_ACCOUNT_SUCCESS:
      return action.account;

    default:
      return state;
  }
};

export const savingAccount = (state = initialState.savingAccount, action) => {
  switch (action.type) {
    case types.SAVING_ACCOUNT:
      return action.savingAccount;

    default:
      return state;
  }
};

export const accountToDelete = (
  state = initialState.accountToDelete,
  action
) => {
  switch (action.type) {
    case types.REQUEST_ACCOUNT_ID:
      return action.accountToDelete;

    default:
      return state;
  }
};

export const acctLocationToDelete = (
  state = initialState.acctLocationToDelete,
  action
) => {
  switch (action.type) {
    case types.REQUEST_LOCATION_ID:
      return action.acctLocationToDelete;

    default:
      return state;
  }
};
