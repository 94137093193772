import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Col } from "react-bootstrap";
import { connect } from "react-redux";

import DropdownSearch from "../../../../common/DropdownSearchWithoutFormsy";
import FieldGroup from "../../../../common/FieldGroup";

function CompletedStageForm({ editing, inputs, auth, onChange, users }) {
  const completedByOptions = useMemo(() => {
    return users.map((user) => {
      let isDisabled = false;
      const reasons = [];
      if (user.cori_exp_date && new Date(user.cori_exp_date) < new Date()) {
        isDisabled = true;
        reasons.push("CORI");
      }
      if (
        user.physical_exp_date &&
        new Date(user.physical_exp_date) < new Date()
      ) {
        isDisabled = true;
        reasons.push("Physical");
      }
      if (user.tbtest_exp_date && new Date(user.tbtest_exp_date) < new Date()) {
        isDisabled = true;
        reasons.push("TB Test");
      }
      return {
        value: user._id,
        label: isDisabled
          ? `${user.last_name} ${user.first_name} (${reasons.join(
              ", "
            )} Expired)`
          : `${user.full_name}`,
      };
    });
  }, [users]);

  return (
    <React.Fragment>
      <Col md={12} xs={12}>
        <DropdownSearch
          name="completed_by"
          label="Completed By"
          value={
            inputs.completed_by?._id || inputs.completed_by || auth.user._id
          }
          onChange={(name, { value }) => onChange(name, value)}
          disabled={!editing}
          data={completedByOptions}
        />
      </Col>
      <Col md={6} style={{ display: "none" }} xs={12}>
        <FieldGroup
          controlId="completed_date"
          label="Completed Date"
          onChange={onChange}
          name="completed_date"
          type="date"
        />
      </Col>
    </React.Fragment>
  );
}

CompletedStageForm.propTypes = {
  editing: PropTypes.bool,
  inputs: PropTypes.object,
  auth: PropTypes.object,
  onChange: PropTypes.func,
  users: PropTypes.array,
};

CompletedStageForm.defaultProps = {
  inputs: {},
  auth: {},
  users: [],
};

function mapStatesToProps(state) {
  return {
    auth: state.reducers.auth,
  };
}

export default connect(mapStatesToProps, null)(CompletedStageForm);
