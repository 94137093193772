import React from "react";

import ReportsList from "./ReportsList";
import BreadCrumbs from "../common/BreadCrumbs";
import { Row, Col } from "react-bootstrap";

import reports from "./reports";

const ReportsPage = () => (
  <div className="content-wrapper">
    <Row>
      <BreadCrumbs breadcrumbs={[{ label: "Reports" }]} />
    </Row>
    <Row>
      <Col md={12}>
        <strong style={{ fontSize: "1.5em" }}>
          *Secure reports run only on ESMA VPN
        </strong>
        <ReportsList reports={reports} />
      </Col>
    </Row>
  </div>
);
export default ReportsPage;
