import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { Row, Col, FormControl } from "react-bootstrap";

import DropdownSearch from "../../common/DropdownSearchWithoutFormsy";

import orderBy from "lodash/orderBy";

const VeteranInfoFormSection = ({
  inputs,
  editing,
  onChange,
  codes,
  contacts,
}) => {
  const generateCodeOptions = useCallback(
    (entity, field) => {
      const codeOptions = codes
        .filter((code) => code.entity == entity && code.field == field)
        .map((code) => ({
          value: code._id,
          label: code.label,
        }));
      return orderBy(codeOptions, "label");
    },
    [codes]
  );

  const generateLeadContactOptions = useCallback(() => {
    const contactOptions = contacts.map((contact) => ({
      value: contact._id,
      label: `${contact.last_name}, ${contact.first_name}`,
    }));
    return orderBy(contactOptions, "label");
  }, [contacts]);

  const veteranStatusCodes = useMemo(
    () => [...generateCodeOptions("Client", "VeteranStatus")],
    [generateCodeOptions]
  );
  const contactTypeCodes = useMemo(
    () => [...generateCodeOptions("Client", "ContactType")],
    [generateCodeOptions]
  );
  const militaryAffiliationCodes = useMemo(
    () => [...generateCodeOptions("Client", "MilitaryAffiliation")],
    [generateCodeOptions]
  );
  const serviceBranchCodes = useMemo(
    () => [...generateCodeOptions("Client", "ServiceBranch")],
    [generateCodeOptions]
  );
  const dischargeNatureCodes = useMemo(
    () => [...generateCodeOptions("Client", "DischargeNature")],
    [generateCodeOptions]
  );
  const disabilityRatingCodes = useMemo(
    () => [...generateCodeOptions("Client", "DisabilityRating")],
    [generateCodeOptions]
  );
  const programCodes = useMemo(
    () => [...generateCodeOptions("Client", "Program")],
    [generateCodeOptions]
  );
  const housingStatusCodes = useMemo(
    () => [...generateCodeOptions("Client", "HousingStatus")],
    [generateCodeOptions]
  );
  const currentBenefitsCodes = useMemo(
    () => [...generateCodeOptions("Client", "CurrentBenefits")],
    [generateCodeOptions]
  );
  const leadContactOptions = useMemo(() => {
    return generateLeadContactOptions();
  }, [generateLeadContactOptions]);

  return (
    <>
      <Row>
        <fieldset>
          <Col md={3} xs={12}>
            <DropdownSearch
              data={veteranStatusCodes}
              disabled={!editing}
              label="Veteran Status"
              name="veteran_status"
              value={inputs.veteran_status}
              onChange={(name, { value }) => onChange(name, value)}
            />
          </Col>
          <Col md={3} xs={12}>
            <DropdownSearch
              isMulti
              data={leadContactOptions}
              disabled={!editing}
              name="lead_contacts"
              label="Lead Contacts"
              value={inputs.lead_contacts}
              onChange={(name, value) => onChange(name, value)}
            />
          </Col>
          <Col md={2} xs={12}>
            <DropdownSearch
              isMulti
              data={contactTypeCodes}
              disabled={!editing}
              label="Contact Type"
              name="contact_type"
              value={inputs.contact_type}
              onChange={(name, value) => onChange(name, value)}
            />
          </Col>
          <Col md={2} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="organization"
                  style={{ textTransform: "uppercase" }}
                >
                  Organization
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  disabled={!editing}
                  name="organization"
                  label="Organization"
                  placeholder="Organization"
                  value={inputs.organization || ""}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="title"
                  style={{ textTransform: "uppercase" }}
                >
                  Title
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  disabled={!editing}
                  label="Title"
                  name="title"
                  placeholder="Title"
                  value={inputs.title || ""}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
        </fieldset>
      </Row>
      <Row>
        <fieldset>
          <Col md={2} xs={12}>
            <DropdownSearch
              isMulti
              data={militaryAffiliationCodes}
              disabled={!editing}
              label="Military Affiliation"
              name="military_affiliation"
              value={inputs.military_affiliation}
              onChange={(name, value) => onChange(name, value)}
            />
          </Col>
          <Col md={2} xs={12}>
            <DropdownSearch
              isMulti
              data={serviceBranchCodes}
              disabled={!editing}
              label="Branch of Service"
              name="service_branch"
              value={inputs.service_branch}
              onChange={(name, value) => onChange(name, value)}
            />
          </Col>
          <Col md={2} xs={12}>
            <DropdownSearch
              isMulti
              data={dischargeNatureCodes}
              disabled={!editing}
              label="Nature of Discharge"
              name="discharge_nature"
              value={inputs.discharge_nature}
              onChange={(name, value) => onChange(name, value)}
            />
          </Col>
          <Col md={2} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="discharge_year"
                  style={{ textTransform: "uppercase" }}
                >
                  Discharge Year
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  disabled={!editing}
                  name="discharge_year"
                  label="Discharge Year"
                  placeholder="Discharge Year"
                  type="number"
                  value={inputs.discharge_year || ""}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={12}>
            <DropdownSearch
              data={disabilityRatingCodes}
              disabled={!editing}
              label="VA Disability Rating"
              name="disability_rating"
              value={inputs.disability_rating?.value}
              onChange={(name, value) => onChange(name, value)}
            />
          </Col>
          <Col md={2} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="disability_rating_text"
                  style={{ textTransform: "uppercase" }}
                >
                  Disability Rating Reason
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  disabled={
                    !editing || inputs.disability_rating?.label !== "None"
                  }
                  label="Disability Rating Reason"
                  name="disability_rating_text"
                  placeholder="Disability Rating Reason"
                  value={inputs.disability_rating_text || ""}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
        </fieldset>
      </Row>
      <Row>
        <fieldset>
          <Col md={2} xs={12}>
            <DropdownSearch
              isMulti
              data={programCodes}
              disabled={!editing}
              label="Program"
              name="program"
              value={inputs.program}
              onChange={(name, value) => onChange(name, value)}
            />
          </Col>
          <Col md={2} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="household_size"
                  style={{ textTransform: "uppercase" }}
                >
                  Household Size
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  disabled={!editing}
                  label="Household Size"
                  name="household_size"
                  placeholder="Household Size"
                  value={inputs.household_size || ""}
                  type="number"
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="monthly_income"
                  style={{ textTransform: "uppercase" }}
                >
                  Monthly Household Income
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  disabled={!editing}
                  label="Monthly Household Income"
                  name="monthly_income"
                  placeholder="Monthly Household Income"
                  value={inputs.monthly_income || ""}
                  type="number"
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={12}>
            <DropdownSearch
              data={housingStatusCodes}
              disabled={!editing}
              label="Housing Status"
              name="housing_status"
              value={inputs.housing_status?.value}
              onChange={(name, value) => onChange(name, value)}
            />
          </Col>
          <Col md={2} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="housing_status_text"
                  style={{ textTransform: "uppercase" }}
                >
                  Other Housing Concerns
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  disabled={
                    !editing ||
                    inputs.housing_status?.label !== "Other Housing Concerns"
                  }
                  label="Other Housing Concerns"
                  name="housing_status_text"
                  placeholder="Other Housing Concerns"
                  value={inputs.housing_status_text || ""}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={12}>
            <DropdownSearch
              isMulti
              data={currentBenefitsCodes}
              disabled={!editing}
              label="Current Benefits"
              name="current_benefits"
              value={inputs.current_benefits}
              onChange={(name, value) => onChange(name, value)}
            />
          </Col>
        </fieldset>
      </Row>
    </>
  );
};

VeteranInfoFormSection.propTypes = {
  codes: PropTypes.array,
  onCancel: PropTypes.func,
  adding: PropTypes.bool,
  editing: PropTypes.bool,
  saving: PropTypes.bool,
  inputs: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  clients: PropTypes.array,
  client: PropTypes.object,
  contacts: PropTypes.array,
  handleCheckUniqueName: PropTypes.func,
  zips: PropTypes.array,
  onMultipleChange: PropTypes.func,
  onReferredByChange: PropTypes.func,
};

VeteranInfoFormSection.defaultProps = {
  codes: [],
  onCancel: () => null,
  adding: true,
  editing: true,
  saving: false,
  onSubmit: () => null,
  clients: [],
  client: null,
  contacts: [],
  handleCheckUniqueName: () => null,
  zips: [],
  onMultipleChange: () => null,
  onReferredByChange: () => null,
};

export default VeteranInfoFormSection;
