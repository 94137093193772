import { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";

import { Nav, NavItem } from "react-bootstrap";
import { LinkContainer, IndexLinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faCalendarCheck,
  faFileExport,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import {
  faBuildingUser,
  faFileInvoiceDollar,
  faAddressBook,
  faFileContract,
  faStopwatch,
  faPlus,
  faFileAlt,
  faFileCircleCheck,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { config } from "../../../config";

class MainMenu extends Component {
  constructor(props, context) {
    super(props, context);
    autoBind(this);
  }

  render() {
    let { auth, isMobile } = this.props;
    let userId = auth.user._id;
    const param = "MongoDB+Reports";
    // process.env.NODE_ENV == "test" ? "Test+Reports" : "MongoDB+Reports";
    let url = `https://reports.eastersealsma.org/ReportServer?/${param}/Staff%20Call%20Detail%20List&Employee=${userId}`;
    return (
      <Nav className="sidebar sidebar-nav">
        {isMobile && (
          <LinkContainer to="/app/calls/add">
            <NavItem>
              <FontAwesomeIcon icon={faPlus} />
              New Call
            </NavItem>
          </LinkContainer>
        )}
        {!isMobile && (
          <IndexLinkContainer to="/app">
            <NavItem>
              <FontAwesomeIcon icon={faHouse} />
              Dashboard
            </NavItem>
          </IndexLinkContainer>
        )}
        <LinkContainer exact to="/app/calls">
          <NavItem>
            <FontAwesomeIcon icon={faStopwatch} />
            Time Entries
          </NavItem>
        </LinkContainer>
        {!isMobile && auth.maxRole >= 6000 && (
          <LinkContainer to="/app/call-approval">
            <NavItem>
              <FontAwesomeIcon icon={faCalendarCheck} />
              Time Approval
            </NavItem>
          </LinkContainer>
        )}
        {!isMobile && auth.maxRole == 9000 && (
          <LinkContainer to="/app/invoices">
            <NavItem eventKey={5}>
              <FontAwesomeIcon icon={faFileInvoiceDollar} />
              Invoices
            </NavItem>
          </LinkContainer>
        )}
        {!isMobile && auth.maxRole == 9000 && (
          <LinkContainer to="/app/invoice-approval">
            <NavItem eventKey={6}>
              <FontAwesomeIcon icon={faFileCircleCheck} />
              Invoice Approval
            </NavItem>
          </LinkContainer>
        )}
        {auth.maxRole > 4000 && (
          <LinkContainer to="/app/contracts" eventKey={1}>
            <NavItem>
              <FontAwesomeIcon icon={faFileContract} />
              Contracts
            </NavItem>
          </LinkContainer>
        )}
        {auth.maxRole > 4000 && (
          <LinkContainer to="/app/accounts">
            <NavItem eventKey={7}>
              <FontAwesomeIcon icon={faBuildingUser} />
              Accounts
            </NavItem>
          </LinkContainer>
        )}
        <LinkContainer to="/app/clients">
          <NavItem>
            <FontAwesomeIcon icon={faUsers} />
            Clients
          </NavItem>
        </LinkContainer>
        {auth.maxRole > 4000 && (
          <LinkContainer to="/app/contacts">
            <NavItem eventKey={8}>
              <FontAwesomeIcon icon={faAddressBook} />
              Contacts
            </NavItem>
          </LinkContainer>
        )}
        {auth.maxRole > 4000 && (
          <LinkContainer to="/app/users">
            <NavItem eventKey={9}>
              <FontAwesomeIcon icon={faAddressCard} />
              Employees
            </NavItem>
          </LinkContainer>
        )}
        {!isMobile && auth.maxRole > 4000 && (
          <LinkContainer to="/app/reports">
            <NavItem eventKey={10}>
              <FontAwesomeIcon icon={faFileAlt} />
              Reports
            </NavItem>
          </LinkContainer>
        )}
        {!isMobile && (
          <NavItem eventKey={11} href={url} target="_blank">
            <FontAwesomeIcon
              icon={faFileExport}
              style={{ marginRight: "5px" }}
            />
            CALL DETAIL REPORT
          </NavItem>
        )}
        <div className="footer-tag">
          <p>
            ECLIPS {config.VERSION}
            <br /> © 2019 GraVoc Associates, Inc.
          </p>
        </div>
      </Nav>
    );
  }
}

MainMenu.propTypes = {
  auth: PropTypes.object,
  labels: PropTypes.array,
};

export default MainMenu;
