import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as divisionActions from "../../actions/divisionActions";

import DivisionForm from "./DivisionForm";

class DivisionAddPage extends Component {
  constructor(props, context) {
    super(props, context);

    autoBind(this);
  }

  handleSave(division) {
    this.props.actions.createDivision(division);
  }

  render() {
    return (
      <div className="col-md-4">
        <h1>Add Division</h1>
        <DivisionForm
          onSave={this.handleSave}
          saving={this.props.savingDivision}
          division={this.props.division}
        />
      </div>
    );
  }
}

DivisionAddPage.propTypes = {
  actions: PropTypes.object.isRequired,

  savingDivision: PropTypes.bool,
  division: PropTypes.object,
};

function mapStatesToProps(state, ownProps) {
  let division = {
    id: 0,
    name: "",
  };

  return {
    state: state.reducers,

    savingDivision: state.reducers.savingDivision,
    division: division,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...divisionActions }, dispatch),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(DivisionAddPage);
