export default {
  alert: {
    message: {
      content: "",
      type: "",
    },
    show: false,
  },
  modal: {
    id: "",
    show: false,
  },
  savingUser: false,
  loadingUser: false,
  userToDelete: "",
  users: [],
  user: {},
  auth: {
    username: "",
    user: {},
    roles: null,
    maxRole: null,
    isAuth: false,
    isAdmin: false,
    isMobile: false,
    isBrowser: false,
    resetSent: false,
  },
  accounts: [],
  account: {},
  savingAccount: false,
  accountToDelete: "",
  contacts: [],
  contact: {},
  savingContact: false,
  contactToDelete: "",
  labels: [],
  label: {},
  savingLabel: false,
  labelToDelete: "",
  licenses: [],
  license: {},
  savingLicense: false,
  licenseToDelete: "",
  divisions: [],
  division: {},
  savingDivision: false,
  divisionToDelete: "",
  departmentToDelete: "",
  roles: [],
  role: {},
  savingRole: false,
  roleToDelete: "",
  locations: [],
  location: {},
  savingLocation: false,
  acctLocationToDelete: "",
  glaccounts: [],
  glaccount: {},
  savingGlaccount: false,
  glaccountToDelete: "",
  zips: [],
  zip: {},
  savingZip: false,
  zipToDelete: "",
  items: [],
  item: {},
  savingItem: false,
  itemToDelete: "",
  codes: [],
  code: {},
  savingCode: false,
  codeToDelete: "",
  companies: [],
  company: {},
  savingCompany: false,
  companyToDelete: "",
  chargetypes: [],
  chargetype: {},
  savingChargetype: false,
  chargetypeToDelete: "",
  contracts: [],
  contract: {},
  savingContract: false,
  contractToDelete: "",
  clientToDelete: "",
  calls: [],
  callsByStage: [],
  call: {},
  loadingCall: false,
  savingCall: false,
  callToDelete: "",
  callsToApprove: [],
  callsToInvoice: [],
  invoices: [],
  invoice: {},
  savingInvoice: false,
  loadingInvoice: false,
  invoiceToVoid: "",
  invoicesToApprove: [],
  invoicesToComplete: [],
  invoicesToPrint: [],
  client: null,
  clients: [],
  episodeToDelete: "",
  clientContracts: [],
};
