import React from "react";
import PropTypes from "prop-types";

import { isEmpty } from "lodash";
import { Col } from "react-bootstrap";

import DropdownSearch from "../../common/DropdownSearchWithoutFormsy";
import DateTimePicker from "../../common/DateTimePickerWithoutFormsy";
import FieldGroup from "../../common/FieldGroup";
import FormBox from "../../common/FormBox";
import DivisionSelect from "../../division/DivisionSelect";

import CallProcedureList from "../CallProcedureList";

import {
  ApprovedStageForm,
  CancelledStageForm,
  CompletedStageForm,
  InvoicedStageForm,
} from "./components";

const CallForm = ({
  users,
  onCancel,
  onDelete,
  editing,
  onEnterTime,
  clients,
  saving,
  inputs,
  showDeleteButton,
  handleSubmit,
  requireProcedureChange,
  handleParentAccountChange,
  handleContractChange,
  contractOptions,
  handleChange,
  prodCatOptions,
  assignedToOptions,
  handleStageChange,
  stageOptions,
  accountOptions,
  showDuration,
  showBillingQuantity,
  durationFieldValidations,
  durationDisabled,
  billingQuantityDisabled,
  isSubmitDisabled,
  startTimeValidations,
  endTimeValidations,
  isValidDate,
}) => {
  return (
    <React.Fragment>
      <FormBox
        isSubmitDisabled={isSubmitDisabled}
        onCancelClick={onCancel}
        onDeleteClick={onDelete}
        saving={saving}
        showDeleteButton={showDeleteButton}
        onSubmit={handleSubmit}
      >
        <Col md={3} xs={12}>
          <DropdownSearch
            required
            name="parent_account"
            label="Parent Account"
            onChange={(_, { value }) => handleParentAccountChange(_, value)}
            disabled={!editing}
            value={inputs.parent_account}
            data={accountOptions}
          />
        </Col>
        <Col md={3} xs={12}>
          <DropdownSearch
            required
            name="contract"
            label="Contract"
            onChange={(_, { value }) => handleContractChange(value)}
            disabled={!editing}
            value={inputs.contract?._id}
            data={contractOptions}
          />
        </Col>
        <Col md={3} xs={12}>
          <DivisionSelect
            required
            name="division"
            label="Division"
            value={inputs.division}
            disabled={!editing}
            onChange={handleChange}
          />
        </Col>
        <Col md={3} xs={12}>
          <DropdownSearch
            required
            name="product_category"
            label="Discipline"
            disabled={!editing}
            data={prodCatOptions}
            onChange={(name, { value }) => handleChange(name, value)}
            value={inputs.product_category}
          />
        </Col>
        <Col md={6} xs={12}>
          <DropdownSearch
            required
            name="assigned_to"
            label="Assigned To"
            onChange={(name, { value }) => handleChange(name, value)}
            disabled={!editing}
            data={assignedToOptions}
            value={inputs.assigned_to}
          />
        </Col>
        <Col md={6} xs={12}>
          <DropdownSearch
            required
            name="stage"
            label="Stage"
            disabled={!editing}
            onChange={(_, { value }) => handleStageChange(value)}
            data={stageOptions}
            value={inputs.stage?._id || ""}
          />
        </Col>
        <Col md={inputs.contract && inputs.contract.is_tif ? 3 : 6} xs={12}>
          <DateTimePicker
            required
            name="start_time"
            label="Scheduled Start Time"
            disabled={!editing}
            value={inputs.start_time}
            onChange={handleChange}
            validations={startTimeValidations}
          />
        </Col>
        <Col md={inputs.contract && inputs.contract.is_tif ? 3 : 6} xs={12}>
          <DateTimePicker
            required
            name="end_time"
            label="Scheduled End Time"
            disabled={!editing}
            value={inputs.end_time}
            onChange={(name, value) => {
              // Don't allow user to change end date if quantity is already defined
              if (!inputs.quantity) {
                handleChange(name, value);
              }
            }}
            minDate={new Date(inputs.start_time)}
            valid={isValidDate}
            validations={endTimeValidations}
          />
        </Col>
        {/* If contract requires TIF, show TIF */}
        {inputs.contract && inputs.contract.is_tif && (
          <React.Fragment>
            <Col md={3} xs={12}>
              <DateTimePicker
                name="tif_in"
                label="Time in Facility Start Time"
                disabled={!editing}
                interval={1}
                onChange={handleChange}
                value={inputs.tif_in}
              />
            </Col>
            <Col md={3} xs={12}>
              <DateTimePicker
                name="tif_out"
                label="Time in Facility End Time"
                disabled={!editing}
                interval={1}
                onChange={handleChange}
                value={inputs.tif_out}
              />
            </Col>
          </React.Fragment>
        )}
        {showDuration && (
          <Col xs={12} md={showBillingQuantity ? 6 : 12}>
            <FieldGroup
              required
              formNoValidate
              type="number"
              step={0.01}
              name="quantity"
              label="Duration"
              value={inputs.quantity}
              onFocus={onEnterTime}
              disabled={durationDisabled}
              validations={durationFieldValidations}
              onChange={handleChange}
            />
          </Col>
        )}
        {showBillingQuantity && (
          <Col xs={12} md={6}>
            <FieldGroup
              required
              formNoValidate
              type="number"
              step={0.1}
              name="billing_quantity"
              label="Billing Quantity"
              value={inputs.billing_quantity}
              onFocus={onEnterTime}
              disabled={billingQuantityDisabled}
            />
          </Col>
        )}
        {!isEmpty(inputs.entries) && (
          <CallProcedureList
            entries={inputs.entries}
            contract={inputs.contract}
            editing={editing}
            clients={clients}
            requireProcedureChange={requireProcedureChange}
          />
        )}
        {inputs.stage.order >= 2 && inputs.stage.order <= 4 && (
          <CompletedStageForm
            inputs={inputs}
            editing={editing}
            onChange={handleChange}
            users={users}
          />
        )}
        {inputs.stage.order >= 3 && inputs.stage.order <= 4 && (
          <ApprovedStageForm
            inputs={inputs}
            editing={editing}
            users={users}
            onChange={handleChange}
          />
        )}
        {inputs.stage.order === 4 && (
          <InvoicedStageForm
            inputs={inputs}
            editing={editing}
            users={users}
            onChange={handleChange}
          />
        )}
        {inputs.stage.order === 5 && (
          <CancelledStageForm
            inputs={inputs}
            editing={editing}
            onChange={handleChange}
            users={users}
          />
        )}
        <Col md={12} xs={12}>
          <FieldGroup
            componentClass="textarea"
            rows={3}
            type="text"
            name="notes"
            label="Notes"
            value={inputs.notes}
            onChange={handleChange}
            disabled={!editing}
          />
        </Col>
      </FormBox>
    </React.Fragment>
  );
};

CallForm.propTypes = {
  saving: PropTypes.bool,
  users: PropTypes.array,
  onCancel: PropTypes.func,
  onEnterTime: PropTypes.func,
  editing: PropTypes.bool,
  clients: PropTypes.array,
  onDelete: PropTypes.func,
  inputs: PropTypes.object,
  showDeleteButton: PropTypes.bool,
  handleSubmit: PropTypes.func,
  requireProcedureChange: PropTypes.bool,
  handleParentAccountChange: PropTypes.func,
  handleContractChange: PropTypes.func,
  contractOptions: PropTypes.array,
  handleChange: PropTypes.func,
  prodCatOptions: PropTypes.array,
  assignedToOptions: PropTypes.array,
  handleStageChange: PropTypes.func,
  stageOptions: PropTypes.array,
  showDuration: PropTypes.bool,
  showBillingQuantity: PropTypes.bool,
  durationFieldValidations: PropTypes.object,
  durationDisabled: PropTypes.bool,
  billingQuantityDisabled: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  startTimeValidations: PropTypes.object,
  endTimeValidations: PropTypes.object,
  isValidDate: PropTypes.bool,
  accountOptions: PropTypes.array,
};

export default CallForm;
