import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as glaccountActions from "../../actions/glaccountActions";

import GlaccountForm from "./GlaccountForm";

class GlaccountAddPage extends Component {
  constructor(props, context) {
    super(props, context);

    autoBind(this);
  }

  handleSave(glaccount) {
    this.props.actions.createGlaccount(glaccount);
  }

  render() {
    return (
      <div className="col-md-4">
        <h1>Add Glaccount</h1>
        <GlaccountForm
          onSave={this.handleSave}
          saving={this.props.savingGlaccount}
          glaccount={this.props.glaccount}
        />
      </div>
    );
  }
}

GlaccountAddPage.propTypes = {
  actions: PropTypes.object.isRequired,
  savingGlaccount: PropTypes.bool,
  glaccount: PropTypes.object,
};

function mapStatesToProps(state, ownProps) {
  let glaccount = {
    id: 0,
    name: "",
  };

  return {
    state: state.reducers,
    savingGlaccount: state.reducers.savingGlaccount,
    glaccount: glaccount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...glaccountActions }, dispatch),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(GlaccountAddPage);
