import { find } from "lodash";

export default function checkDuration(users, inputs) {
  // Show duration if contract is Assistive Technology, Easter Seals, or Senior Service
  const user = find(users, { id: inputs.assigned_to });
  const isAT = user && user.division && user.division.code === "AT";
  return !!(
    isAT ||
    (inputs.contract &&
      (inputs.contract.name === "Easter Seals" ||
        (inputs.contract.invoice_format &&
          inputs.contract.invoice_format.label === "Senior Service"))) ||
    (inputs.stage &&
      inputs.stage.label !== "Scheduled" &&
      inputs.stage.label !== "Cancelled")
  );
}
