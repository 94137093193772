import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { Row, Col, FormControl } from "react-bootstrap";
import find from "lodash/find";

import DropdownSearch from "../../common/DropdownSearchWithoutFormsy";
import PhoneNumberInput from "../../common/PhoneNumberInputWithoutFormsy";

import orderBy from "lodash/orderBy";

const ContactInfoFormSection = ({
  inputs,
  editing,
  onChange,
  codes,
  clients,
  client,
}) => {
  const generateCodeOptions = useCallback(
    (entity, field) => {
      const codeOptions = codes
        .filter((code) => code.entity == entity && code.field == field)
        .map((code) => ({
          value: code._id,
          label: code.label,
        }));
      return orderBy(codeOptions, "label");
    },
    [codes]
  );

  const phoneTypeCodes = useMemo(
    () => [...generateCodeOptions("*", "PhoneType")],
    [generateCodeOptions]
  );
  const preferredContactMethodCodes = useMemo(
    () => [...generateCodeOptions("Client", "PeferredContactMethod")],
    [generateCodeOptions]
  );

  return (
    <>
      <Row>
        <fieldset>
          <Col md={3} style={{ padding: 0 }}>
            <Col md={6} xs={12}>
              <PhoneNumberInput
                disabled={!editing}
                name="primary_phone"
                label="Primary Phone"
                placeholder="Primary Phone"
                value={inputs.primary_phone}
                validate
                onChange={(name, value) => onChange(name, value)}
              />
            </Col>
            <Col md={6} xs={12}>
              <DropdownSearch
                data={phoneTypeCodes}
                disabled={!editing}
                label="Phone Type"
                name="primary_phone_type"
                value={inputs.primary_phone_type}
                onChange={(name, { value }) => onChange(name, value)}
              />
            </Col>
          </Col>
          <Col md={3} xs={12}>
            {editing ? (
              <Row className="form-group">
                <Col sm={12} xs={12}>
                  <label
                    className="control-label"
                    htmlFor="email"
                    style={{ textTransform: "uppercase" }}
                  >
                    Email
                  </label>
                </Col>
                <Col sm={12} xs={12}>
                  <FormControl
                    disabled={!editing}
                    name="email"
                    label="Email"
                    type="email"
                    value={inputs.email || ""}
                    validations={{
                      isExists: (values, value) => {
                        if (value) {
                          let foundClient = find(clients, { email: value });
                          let alreadyExists =
                            foundClient &&
                            client &&
                            foundClient._id !== client._id;
                          return !alreadyExists;
                        } else return true;
                      },
                    }}
                    validationErrors={{
                      isExists: "There is already a client with this email.",
                    }}
                    onChange={({ target: { name, value } }) =>
                      onChange(name, value)
                    }
                  />
                </Col>
              </Row>
            ) : (
              <div className="form-group row">
                <label className="control-label col-sm-3">Email</label>
                <div className="col-sm-9 email-link-container">
                  <a href={`mailto: ${inputs.email}`}>{inputs.email}</a>
                </div>
              </div>
            )}
          </Col>
          <Col md={2} xs={12}>
            <PhoneNumberInput
              disabled={!editing}
              name="secondary_phone"
              label="Secondary Phone"
              placeholder="Secondary Phone"
              value={inputs.secondary_phone}
              onChange={(name, value) => onChange(name, value)}
            />
          </Col>
          <Col md={2} xs={12}>
            <DropdownSearch
              data={phoneTypeCodes}
              disabled={!editing}
              label="Phone Type"
              name="secondary_phone_type"
              value={inputs.secondary_phone_type}
              onChange={(name, { value }) => onChange(name, value)}
            />
          </Col>
          <Col md={2} xs={12}>
            <DropdownSearch
              data={preferredContactMethodCodes}
              disabled={!editing}
              label="Peferred Contact Method"
              name="preferred_contact_method"
              value={inputs.preferred_contact_method}
              onChange={(name, { value }) => onChange(name, value)}
            />
          </Col>
        </fieldset>
      </Row>
      <Row>
        <fieldset>
          <Col md={3} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="emergency_contact_name"
                  style={{ textTransform: "uppercase" }}
                >
                  Emergency Contact Name
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  disabled={!editing}
                  name="emergency_contact_name"
                  label="Emergency Contact Name"
                  placeholder="Emergency Contact Name"
                  value={inputs.emergency_contact_name || ""}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={3} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="emergency_contact_relationship"
                  style={{ textTransform: "uppercase" }}
                >
                  Relationship
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  disabled={!editing}
                  name="emergency_contact_relationship"
                  label="Relationship"
                  placeholder="Relationship"
                  value={inputs.emergency_contact_relationship || ""}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={3} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="emergency_contact_phone"
                  style={{ textTransform: "uppercase" }}
                >
                  Emergency Contact Phone
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  disabled={!editing}
                  name="emergency_contact_phone"
                  label="Emergency Contact Phone"
                  placeholder="Emergency Contact Phone"
                  value={inputs.emergency_contact_phone || ""}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={3} xs={12}>
            {editing ? (
              <Row className="form-group">
                <Col sm={12} xs={12}>
                  <label
                    className="control-label"
                    htmlFor="emergency_contact_email"
                    style={{ textTransform: "uppercase" }}
                  >
                    Emergency Contact Email
                  </label>
                </Col>
                <Col sm={12} xs={12}>
                  <FormControl
                    disabled={!editing}
                    name="emergency_contact_email"
                    label="Emergency Contact Email"
                    placeholder="Emergency Contact Email"
                    value={inputs.emergency_contact_email || ""}
                    onChange={({ target: { name, value } }) =>
                      onChange(name, value)
                    }
                  />
                </Col>
              </Row>
            ) : (
              <div className="form-group row">
                <label className="control-label col-sm-3">
                  Emergency Contact Email
                </label>
                <div className="col-sm-9 email-link-container">
                  <a href={`mailto: ${inputs.emergency_contact_email}`}>
                    {inputs.emergency_contact_email}
                  </a>
                </div>
              </div>
            )}
          </Col>
        </fieldset>
      </Row>
      <Row>
        <fieldset>
          <Col md={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="accommodation_needs"
                  style={{ textTransform: "uppercase" }}
                >
                  Accommodation Needs
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  as="textarea"
                  disabled={!editing}
                  name="accommodation_needs"
                  label="Accommodation Needs"
                  placeholder="Accommodation Needs"
                  value={inputs.accommodation_needs || ""}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                  rows={2}
                />
              </Col>
            </Row>
          </Col>
        </fieldset>
      </Row>
    </>
  );
};

ContactInfoFormSection.propTypes = {
  codes: PropTypes.array,
  onCancel: PropTypes.func,
  adding: PropTypes.bool,
  editing: PropTypes.bool,
  saving: PropTypes.bool,
  inputs: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  clients: PropTypes.array,
  client: PropTypes.object,
  contacts: PropTypes.array,
  handleCheckUniqueName: PropTypes.func,
  zips: PropTypes.array,
  onMultipleChange: PropTypes.func,
  onReferredByChange: PropTypes.func,
};

ContactInfoFormSection.defaultProps = {
  codes: [],
  onCancel: () => null,
  adding: true,
  editing: true,
  saving: false,
  onSubmit: () => null,
  clients: [],
  client: null,
  contacts: [],
  handleCheckUniqueName: () => null,
  zips: [],
  onMultipleChange: () => null,
  onReferredByChange: () => null,
};

export default ContactInfoFormSection;
