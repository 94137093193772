import moment from "moment";

const validation = (startTime) => ({
  invalidDate: {
    validator: (value) =>
      value && startTime && moment(value).isBefore(startTime) ? false : true,
    helpText: "End time should be after start time",
  },
  invalidDuration: {
    validator: (value) => {
      if (value && startTime) {
        const duration = moment.duration(moment(value).diff(moment(startTime)));
        const hours = duration.asHours();
        return hours < 24;
      }
    },
    helpText: "Call cannot extend over 24 hours",
  },
});

export default validation;
