import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Division from "./Division";

import ReactTable from "react-table";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import _ from "lodash";
import CustomLoadable from "../common/Loadable";

const DivisionList = ({
  divisions,
  onClickEdit,
  onClickDelete,
  onClickDeleteDept,
}) => {
  let divisionsView = <CustomLoadable />;

  const columns = [
    { expander: true, show: true },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Code",
      accessor: "code",
    },
    {
      Header: "",
      accessor: "_id",
      Cell: (row) => {
        return (
          <div className="text-center actions">
            <DropdownButton
              title="Actions"
              id={"dropdown"}
              onClick={(e) => e.stopPropagation()}
            >
              <LinkContainer to={`/app/settings/divisions/${row.value}`}>
                <MenuItem>
                  <FontAwesomeIcon icon={faFolder} />
                  View
                </MenuItem>
              </LinkContainer>
              <MenuItem onClick={() => onClickDelete(row.value)}>
                <FontAwesomeIcon icon={faTrashAlt} /> Delete
              </MenuItem>
            </DropdownButton>
          </div>
        );
      },
    },
  ];

  let divisionId = [];

  const subColumns = [
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Department Id",
      accessor: "dept_id",
    },
    {
      Header: "",
      accessor: "_id",
      Cell: (row) => (
        <div className="text-center actions">
          <DropdownButton
            title="Actions"
            id={"dropdown"}
            onClick={(e) => e.stopPropagation()}
          >
            {/* <LinkContainer
              to={`/app/settings/divisions/${divisionId}/departments/${
                row.value
              }`}
            >
              <MenuItem>
                <FontAwesomeIcon icon={faFolder} />
                View
              </MenuItem>
            </LinkContainer> */}
            <MenuItem onClick={() => onClickDeleteDept(row.original)}>
              <FontAwesomeIcon icon={faTrashAlt} /> Delete
            </MenuItem>
          </DropdownButton>
        </div>
      ),
    },
  ];

  if (divisions.length > 0) {
    divisionsView = (
      <ReactTable
        className="-highlight"
        data={divisions}
        loading={false}
        columns={columns}
        pageSize={divisions.length}
        showPagination={false}
        defaultSorted={[
          {
            id: "description",
            asc: true,
          },
        ]}
        SubComponent={(row) => {
          divisionId.push(row.original._id);
          return (
            <div
              style={{
                padding: "20px",
              }}
            >
              {row.original.departments.length > 0 ? (
                <div>
                  <h3>Departments List</h3>
                  <ReactTable
                    className="-highlight"
                    data={row.original.departments}
                    columns={subColumns}
                    showPagination={false}
                    defaultPageSize={row.original.departments.length}
                    defaultSorted={[
                      {
                        id: "description",
                        asc: true,
                      },
                    ]}
                  />
                </div>
              ) : (
                <h5 style={{ textAlign: "center", color: "#999" }}>
                  No Departments
                </h5>
              )}
            </div>
          );
        }}
      />
    );
  }

  return <div>{divisionsView}</div>;
};

DivisionList.propTypes = {
  divisions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      departments: PropTypes.arrayOf(
        PropTypes.shape({
          dept_id: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired
  ).isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickDeleteDept: PropTypes.func.isRequired,
};

export default DivisionList;
