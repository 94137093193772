import { push } from "react-router-redux";
import queryString from "query-string";

import * as types from "./actionTypes";
import { showModalSuccess, hideModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import clientService from "../services/clientService";

export function loadClientSuccess(clients) {
  return {
    type: types.LOAD_CLIENT_SUCCESS,
    clients,
    meta: {
      throttle: true,
    },
  };
}

export function getClientSuccess(client) {
  return {
    type: types.GET_CLIENT_SUCCESS,
    client,
  };
}

export function savingClient(status = true) {
  return {
    type: types.SAVING_CLIENT,
    savingClient: status,
  };
}

export function createClientSuccess(client) {
  return {
    type: types.CREATE_CLIENT_SUCCESS,
    client,
  };
}

export function updateClientSuccess(client) {
  return {
    type: types.UPDATE_CLIENT_SUCCESS,
    client,
  };
}

export function deleteClientSuccess(clientId) {
  return {
    type: types.DELETE_CLIENT_SUCCESS,
    clientId,
  };
}

export function getContractsSuccess(contracts) {
  return {
    type: types.GET_CONTRACTS_BY_CLIENT_SUCCESS,
    contracts,
  };
}

export function loadClients() {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return clientService
      .loadClients()
      .then((data) => dispatch(loadClientSuccess(data)))
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function loadPaginatedClients(query = {}) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return clientService
      .loadPaginatedClients(
        queryString.stringify(query, {
          arrayFormat: "bracket",
        })
      )
      .then((data) => data)
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function getClient(id, showDetails = null) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return clientService
      .getClient(id)
      .then((client) => {
        dispatch(getClientSuccess(client));
        if (showDetails) {
          dispatch(showModalSuccess(showDetails));
        }
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function createClient(client) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingClient());
    return clientService
      .createClient(client)
      .then((createdClient) => {
        dispatch(createClientSuccess(createdClient));
        dispatch(savingClient(false));
        dispatch(showAlertSuccess("Client created successfully", "success"));
        dispatch(push(`/app/clients/${createdClient._id}`));
      })
      .catch((error) => {
        dispatch(savingClient(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateClient(client, updatedFields) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingClient());
    return clientService
      .updateClient(client, updatedFields)
      .then((updatedClient) => {
        dispatch(updateClientSuccess(updatedClient));
        dispatch(savingClient(false));
        dispatch(showAlertSuccess("Client updated successfully", "success"));
      })
      .catch((error) => {
        dispatch(savingClient(false));
        dispatch(
          showAlertSuccess(
            error.description || "There is already a client with this number",
            "error"
          )
        );
      });
  };
}

export function deleteClient(id) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return clientService
      .deleteClient(id)
      .then(() => {
        dispatch(deleteClientSuccess(id));
        dispatch(showAlertSuccess("Client removed", "success"));
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function addNote(client, note, showAlert = true, attachments) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingClient());
    return clientService
      .createNote(client, note, attachments)
      .then((updatedClient) => {
        dispatch(updateClientSuccess(updatedClient));
        dispatch(savingClient(false));
        dispatch(hideModalSuccess("clientAddNoteModal"));
        if (showAlert) {
          dispatch(showAlertSuccess("Note added successfully", "success"));
        }
      })
      .catch((error) => {
        dispatch(savingClient(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateNote(clientId, note) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingClient());
    return clientService
      .updateNote(clientId, note)
      .then((updatedClient) => {
        dispatch(updateClientSuccess(updatedClient));
        dispatch(savingClient(false));
        dispatch(showAlertSuccess("Note updated successfully", "success"));
      })
      .catch((error) => {
        dispatch(savingClient(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteNote(noteId) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingClient());
    return clientService
      .deleteNote(noteId)
      .then((updatedClient) => {
        dispatch(updateClientSuccess(updatedClient));
        dispatch(savingClient(false));
        dispatch(showAlertSuccess("Note deleted successfully", "success"));
      })
      .catch((error) => {
        dispatch(savingClient(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function addEpisode(client, episode) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingClient());
    return clientService
      .createEpisode(client, episode)
      .then((updatedClient) => {
        dispatch(updateClientSuccess(updatedClient));
        dispatch(savingClient(false));
        dispatch(hideModalSuccess("episodesDetailsModal"));
        dispatch(showAlertSuccess("Episode added successfully", "success"));
      })
      .catch((error) => {
        dispatch(savingClient(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateEpisode(clientId, episode) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingClient());
    return clientService
      .updateEpisode(clientId, episode)
      .then((updatedClient) => {
        dispatch(updateClientSuccess(updatedClient));
        dispatch(savingClient(false));
        dispatch(hideModalSuccess("episodesDetailsModal"));
        dispatch(showAlertSuccess("Episode updated successfully", "success"));
      })
      .catch((error) => {
        dispatch(savingClient(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteEpisode(episodeId) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingClient());
    return clientService
      .deleteEpisode(episodeId)
      .then((updatedClient) => {
        dispatch(updateClientSuccess(updatedClient));
        dispatch(savingClient(false));
        dispatch(showAlertSuccess("Episode deleted successfully", "success"));
      })
      .catch((error) => {
        dispatch(savingClient(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function requestClientId(clientId) {
  return (dispatch) => {
    dispatch({ type: types.REQUEST_CLIENT_ID, clientToDelete: clientId });
    dispatch(showModalSuccess("clientDeleteModal"));
  };
}

export function requestEpisodeId(episodeId) {
  return (dispatch) => {
    dispatch({ type: types.REQUEST_EPISODE_ID, episodeToDelete: episodeId });
    dispatch(showModalSuccess("episodeDeleteModal"));
  };
}

export function getClientContracts(id) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return clientService
      .getContractsByClient(id)
      .then((contracts) => {
        dispatch(getContractsSuccess(contracts));
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function addNoteAttachment(client, note, files) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingClient());
    if (files.length == 1) {
      return clientService
        .uploadNoteAttachment(client, note, files[0])
        .then((updatedClient) => {
          dispatch(updateClientSuccess(updatedClient));
          dispatch(savingClient(false));
          dispatch(
            showAlertSuccess("Attachment added successfully", "success")
          );
          dispatch(hideModalSuccess("clientNoteAttachmentsModal"));
        })
        .catch((error) => {
          dispatch(savingClient(false));
          dispatch(showAlertSuccess(error.description, "error"));
        });
    } else {
      dispatch(savingClient(false));
      dispatch(showAlertSuccess("Upload 1 File at a Time", "error"));
    }
  };
}

export function downloadNoteAttachment(note, attachment) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return clientService
      .downloadNoteAttachment(note, attachment)
      .then(() => {
        dispatch(
          showAlertSuccess("Attachment downloaded successfully", "success")
        );
      })
      .catch((error) => {
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function addNoteAttachmentToNewNote(files) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    if (files.length == 1) {
      return clientService
        .uploadNoteAttachmentToNewNote(files[0])
        .then((data) => {
          return data;
        })
        .catch((error) => {
          dispatch(savingClient(false));
          dispatch(showAlertSuccess(error.description, "error"));
        });
    } else {
      dispatch(savingClient(false));
      dispatch(showAlertSuccess("Upload 1 File at a Time", "error"));
    }
  };
}

export function deleteAttachmentNote(client, note, attachment) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingClient());

    return clientService
      .deleteNoteAttachment(client, note, attachment)
      .then((updatedClient) => {
        dispatch(updateClientSuccess(updatedClient));
        dispatch(savingClient(false));
        dispatch(
          showAlertSuccess("Attachment deleted successfully", "success")
        );
        dispatch(hideModalSuccess("editClientNoteModal"));
      })
      .catch((error) => {
        dispatch(savingClient(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}
