import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import { withFormsy } from "formsy-react";
import CreatableSelect from "react-select/lib/Creatable";

import find from "lodash/find";

import { Row, Col } from "react-bootstrap";

class DropdownSearch extends Component {
  constructor(props) {
    super(props);

    this.state = { value: props.value };

    autoBind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      let obj = find(nextProps.data, { value: nextProps.value });
      this.setState({
        value: obj || { value: nextProps.value, label: nextProps.value },
      });
    }
  }

  handleChange(name, selected) {
    this.props.setValue(selected.value);
    if (this.props.onChange) {
      this.props.onChange(
        name,
        selected,
        this.props.data.find((item) => item.value === selected.value)
      );
    }
  }

  render() {
    let { name, label, data, required, disabled, isMulti } = this.props;
    let fieldLabel = required ? (label += " *") : label;

    const { value } = this.state;

    return (
      <Row className="form-group">
        <Col sm={12}>
          <label
            className="control-label"
            htmlFor={name}
            style={{ textTransform: "uppercase" }}
          >
            {fieldLabel}
          </label>
        </Col>
        <Col sm={12}>
          <CreatableSelect
            required={required}
            name={name}
            onChange={(value) => this.handleChange(name, value)}
            options={data}
            value={value || ""}
            defaultValue={data}
            placeholder="Please Select..."
            validations="isEmptyString"
            isMulti={isMulti}
            isDisabled={disabled}
          />
        </Col>
      </Row>
    );
  }
}

DropdownSearch.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  data: PropTypes.array,
  required: PropTypes.bool,
  setValue: PropTypes.func,
  getValue: PropTypes.func,
  label: PropTypes.string,
  isMulti: PropTypes.bool,
};

export default withFormsy(DropdownSearch);
