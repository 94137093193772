import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Nav, NavItem } from "react-bootstrap";
import { LinkContainer, IndexLinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faFileAlt,
  faStickyNote,
  faListUl,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import find from "lodash/find";
import { config } from "../../../config";

class ClientMenu extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    let { auth, location } = this.props;

    const id = location.pathname.split("/")[3];

    return (
      <Nav className="sidebar sidebar-nav">
        <IndexLinkContainer to="/app/clients">
          <NavItem eventKey={1}>
            <FontAwesomeIcon icon={faChevronLeft} />
            All Clients
          </NavItem>
        </IndexLinkContainer>
        {auth.isAuth && (
          <React.Fragment>
            <LinkContainer exact to={`/app/clients/${id}`}>
              <NavItem eventKey={2}>
                <FontAwesomeIcon icon={faUser} />
                Client Info
              </NavItem>
            </LinkContainer>
            <LinkContainer exact to={`/app/clients/${id}/episodes`}>
              <NavItem eventKey={2}>
                <FontAwesomeIcon icon={faListUl} />
                Episodes
              </NavItem>
            </LinkContainer>
            <LinkContainer to={`/app/clients/${id}/notes`}>
              <NavItem eventKey={3}>
                <FontAwesomeIcon icon={faStickyNote} />
                Notes
              </NavItem>
            </LinkContainer>
            <LinkContainer exact to={`/app/clients/${id}/contracts`}>
              <NavItem eventKey={2}>
                <FontAwesomeIcon icon={faFileAlt} />
                Contracts
              </NavItem>
            </LinkContainer>
          </React.Fragment>
        )}
        <div className="footer-tag">
          <p>
            ECLIPS {config.VERSION}
            <br /> © 2019 GraVoc Associates, Inc.
          </p>
        </div>
      </Nav>
    );
  }
}

ClientMenu.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  client: PropTypes.object,
  codes: PropTypes.array,
};

function mapStatesToProps(state) {
  return {
    state: state,
    auth: state.reducers.auth,
    client: state.reducers.client,
    codes: state.reducers.codes,
  };
}

export default connect(mapStatesToProps)(ClientMenu);
