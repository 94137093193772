import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import "./ClientNotes.scss";

const ClientNoteAttachments = ({
  client,
  codes,
  note,
  onCancel,
  onDownload,
}) => {
  const handleClick = (attachment) => {
    onDownload(note, attachment);
  };

  return (
    <div className="flex file-container">
      {note.attachments.map((attachment) => (
        <div key={attachment._id} className="file-display">
          <span>{attachment.path}</span>
          <span>
            Created Date:&nbsp;
            {moment(attachment.created_on).format("MM/DD/YYYY")}
          </span>
          <input
            className="btn btn-success"
            type="button"
            value="Download"
            onClick={() => handleClick(attachment)}
          />
        </div>
      ))}
    </div>
  );
};
ClientNoteAttachments.propTypes = {
  client: PropTypes.object,
  codes: PropTypes.array,
  note: PropTypes.object,
  onCancel: PropTypes.func,
  onDownload: PropTypes.func,
};
ClientNoteAttachments.defaultProps = {
  onCancel: () => null,
  onDownload: () => null,
};

export default ClientNoteAttachments;
