import React, { Component } from "react";
import PropTypes from "prop-types";

import Formsy from "formsy-react";
import { Input, Checkbox } from "formsy-react-components";
import { Row, Col } from "react-bootstrap";

import DropdownSearch from "../../common/DropdownSearch";
import CurrencyInput from "../../common/CurrencyInput";

import find from "lodash/find";
import orderBy from "lodash/orderBy";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

class ContractLineForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { canSubmit: false };
  }

  componentDidMount = () => {
    if (!this.props.adding) {
      this.setState({
        division: this.props.line.division
          ? this.props.line.division._id
          : null,
        // chargetype: this.props.line.item
        //   ? this.props.line.item.charge_type
        //   : null
      });
    }
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  add = (model) => {
    this.props.onAdd(this.props.contract, model);
  };

  cancel = () => {
    this.refs.form.reset();
    this.props.onCancel();
  };

  submit = (model) => {
    model.id = this.props.line._id;
    this.props.onSave(model);
  };

  generateLocationOptions = () => {
    const locationOptions = [];

    if (!isEmpty(this.props.accounts)) {
      if (this.props.contract.parent_account) {
        let parentAccount = find(this.props.accounts, {
          _id: this.props.contract.parent_account,
        });
        parentAccount.locations.map((location) => {
          let state = find(this.props.codes, { _id: location.address.state });
          locationOptions.push({
            value: location._id,
            label: `${location.name} (${location.address.addr1}. ${location.address.city}, ${state.label})`,
          });
        });
      }
    }

    return locationOptions;
  };

  generateDepartmentOptions = () => {
    const departmentOptions = [];
    let parentDivision;
    if (!isEmpty(this.props.divisions)) {
      if (this.state.division) {
        parentDivision = this.props.divisions.find(
          (division) => division._id == this.state.division
        );
      } else if (this.props.contract.division) {
        parentDivision = this.props.divisions.find(
          (division) => division._id == this.props.contract.division
        );
      }

      if (parentDivision) {
        parentDivision.departments.map((department) =>
          departmentOptions.push({
            value: department._id,
            label: department.description,
          })
        );
      }
    }

    return orderBy(departmentOptions, "label");
  };

  generateItemOptions = () => {
    const itemOptions = this.props.items
      .filter((item) => {
        if (
          item.product_category &&
          (this.state.product_category || this.props.line.product_category)
        ) {
          return (
            item.product_category._id ==
              (this.state.product_category ||
                this.props.line.product_category._id) || item.is_global
          );
        } else {
          return item;
        }
      })
      .map((item) => ({
        value: item._id,
        label: item.description,
      }));

    return orderBy(itemOptions, "label");
  };

  generateCodeOptions = (entity, field) => {
    const codeOptions = this.props.codes
      .filter((codes) => codes.entity == entity && codes.field == field)
      .map((code) => ({
        value: code._id,
        label: code.label,
      }));

    return orderBy(codeOptions, "label");
  };

  onChange = (field, selected) => {
    this.setState(
      {
        [field]: selected.value,
      },
      () => console.log("location", this.state.location)
    );

    if (field == "item") {
      let item = find(this.props.items, { _id: selected.value });
      let charge_type = item.charge_type._id;
      this.setState({ charge_type });
    }
  };

  render() {
    let glRevCode = find(this.props.glaccounts, {
      id: this.state.gl_rev_code || this.props.line.gl_rev_code,
    });
    let isDirectInvoice = glRevCode && glRevCode.code == 4110 ? true : false;
    let productCategory;
    let service;
    let uom;
    if (this.props.line.product_category) {
      productCategory = this.props.line.product_category._id;
    }
    if (this.props.line.service) {
      service = this.props.line.service._id;
    }
    if (this.props.line.uom) {
      uom = this.props.line.uom._id;
    }
    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.props.adding ? this.add : this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={4} xs={12}>
            <DropdownSearch
              required
              name="division"
              label="Division"
              onChange={this.onChange}
              value={this.state.division || this.props.line.division}
              disabled={!this.props.editing}
              data={orderBy(
                [
                  ...this.props.divisions.map((division) => ({
                    value: division._id,
                    label: division.description,
                  })),
                ],
                "label"
              )}
            />
          </Col>
          <Col md={4} xs={12}>
            <DropdownSearch
              required
              name="department"
              label="Department"
              onChange={this.onChange}
              value={this.state.department || this.props.line.department}
              disabled={!this.props.editing}
              data={[...this.generateDepartmentOptions()]}
            />
          </Col>
          <Col md={4} xs={12}>
            <DropdownSearch
              required
              name="location"
              label="Location"
              onChange={this.onChange}
              value={this.state.location || this.props.line.location}
              disabled={!this.props.editing}
              data={[...this.generateLocationOptions()]}
            />
          </Col>
          <Col md={6} xs={12}>
            <Input
              formNoValidate
              required
              type="date"
              name="start_date"
              label="Start Date"
              onChange={debounce(this.onChange, 500)}
              validations={{
                isValidDate: function (values, value) {
                  return value <= values["end_date"];
                },
                isValidYear: (values, value) => value >= "1753-01-01",
              }}
              validationErrors={{
                isValidDate: "Start date should be before end date",
                isValidYear: "Please enter a valid Year",
              }}
              value={moment
                .utc(this.state.start_date || this.props.line.start_date)
                .format("YYYY-MM-DD")}
              disabled={!this.props.editing}
            />
          </Col>
          <Col md={6} xs={12}>
            <Input
              formNoValidate
              required
              type="date"
              name="end_date"
              label="End Date"
              value={moment
                .utc(this.state.end_date || this.props.line.end_date)
                .format("YYYY-MM-DD")}
              disabled={!this.props.editing}
              onChange={debounce(this.onChange, 500)}
              validations={{
                isValidDate: function (values, value) {
                  return value >= values["start_date"];
                },
                isValidYear: (values, value) => value <= "9999-12-31",
              }}
              validationErrors={{
                isValidDate: "End date should be after start date",
                isValidYear: "Please enter a valid Year",
              }}
            />
          </Col>
          <Col md={4} xs={12}>
            <DropdownSearch
              name="product_category"
              label="Product Category"
              value={this.state.product_category || productCategory}
              onChange={this.onChange}
              disabled={!this.props.editing}
              data={orderBy(
                [
                  { value: null, label: "--" },
                  ...this.props.codes
                    .filter(
                      (codes) =>
                        codes.entity == "Item" &&
                        codes.field == "product_category"
                    )
                    .map((code) => ({
                      value: code._id,
                      label: code.label,
                    })),
                ],
                "label"
              )}
            />
          </Col>
          <Col md={4} xs={12}>
            <DropdownSearch
              required
              name="item"
              label="Item"
              onChange={this.onChange}
              value={
                this.state.item ||
                (this.props.line.item && this.props.line.item._id)
              }
              disabled={!this.props.editing}
              data={[...this.generateItemOptions()]}
            />
          </Col>
          <Col md={4} xs={12}>
            <DropdownSearch
              required
              name="service"
              label="Program"
              onChange={this.onChange}
              value={this.state.service || service}
              disabled={!this.props.editing}
              data={orderBy(
                [
                  ...this.props.codes
                    .filter(
                      (codes) =>
                        codes.entity == "Contract Line" &&
                        codes.field == "service"
                    )
                    .map((code) => ({
                      value: code._id,
                      label: code.label,
                    })),
                ],
                "label"
              )}
            />
          </Col>
          <Col md={4} xs={12}>
            <DropdownSearch
              required
              name="uom"
              label="UOM"
              onChange={this.onChange}
              value={this.state.uom || uom}
              disabled={!this.props.editing}
              data={[...this.generateCodeOptions("Contract Line", "uom")]}
            />
          </Col>
          <Col md={4} xs={12}>
            <Input
              formNoValidate
              required
              step={0.01}
              type="number"
              name="rate"
              label="Rate"
              placeholder="Rate"
              disabled={!this.props.editing}
              value={this.props.line.rate}
            />
          </Col>
          <Col md={4} xs={12}>
            <DropdownSearch
              required
              name="charge_type"
              label="Charge Type"
              onChange={this.onChange}
              value={this.state.charge_type || this.props.line.charge_type}
              disabled={!this.props.editing}
              data={[
                ...this.props.chargetypes.map((chargetype) => {
                  return { value: chargetype._id, label: chargetype.type };
                }),
              ]}
            />
          </Col>
          <Col md={4} xs={12}>
            <CurrencyInput
              type="number"
              name="max_val"
              label="Max Value"
              placeholder="Max Value"
              disabled={!this.props.editing}
              value={this.props.line.max_val}
            />
          </Col>
          <Col md={4} xs={12}>
            <Input
              formNoValidate
              type="number"
              name="max_hours"
              label="Max Hours"
              placeholder="Max Hours"
              disabled={!this.props.editing}
              value={this.props.line.max_hours}
            />
          </Col>
          <Col md={4} xs={12}>
            <Input
              formNoValidate
              type="number"
              name="max_visits"
              label="Max Visits"
              placeholder="Max Visits"
              disabled={!this.props.editing}
              value={this.props.line.max_visits}
            />
          </Col>
          {/* <Col md={6} xs={12} style={{ display: "none" }}>
            <DropdownSearch
              name="charge_type"
              label="Charge Type"
              value={this.state.chargetype || ""}
              disabled={!this.props.editing}
              data={orderBy(
                [
                  ...this.props.chargetypes.map(chargetype => ({
                    value: chargetype._id,
                    label: chargetype.type
                  }))
                ],
                "label"
              )}
            />
          </Col> */}
          <Col md={isDirectInvoice ? 6 : 12} xs={12}>
            <DropdownSearch
              required
              name="gl_rev_code"
              label="Revenue Code"
              onChange={this.onChange}
              value={this.state.gl_rev_code || this.props.line.gl_rev_code}
              disabled={!this.props.editing}
              data={orderBy(
                [
                  ...this.props.glaccounts
                    .filter((glaccount) => glaccount.type.value == "R")
                    .map((glaccount) => ({
                      value: glaccount._id,
                      label: `${glaccount.code} - ${glaccount.description}`,
                    })),
                ],
                "label"
              )}
            />
          </Col>
          {isDirectInvoice && (
            <Col md={6} xs={12}>
              <DropdownSearch
                name={"procedure_type"}
                label="Procedure Type"
                value={
                  this.state.procedure_type || this.props.line.procedure_type
                }
                onChange={this.onChange}
                data={[
                  ...this.props.codes
                    .filter(
                      (code) =>
                        code.entity == "Call" && code.field == "procedure_type"
                    )
                    .map((type) => {
                      return {
                        value: type._id,
                        label: type.label,
                      };
                    }),
                ]}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <Checkbox
              name="status"
              label="Contract Completed"
              disabled={!this.props.editing}
              value={this.props.line.status}
            />
          </Col>
          <Col md={12} xs={12} className="action-footer">
            <button
              className="btn btn-danger"
              type="button"
              onClick={this.cancel}
            >
              Cancel
            </button>
            {!this.props.adding && (
              <input
                className="btn btn-success"
                type="submit"
                disabled={!this.state.canSubmit}
                value={this.props.saving ? "Saving... " : "Save"}
              />
            )}
            {this.props.adding && (
              <input
                className="btn btn-success"
                type="submit"
                disabled={!this.state.canSubmit}
                value={this.props.saving ? "Adding... " : "Add"}
              />
            )}
          </Col>
        </Row>
      </Formsy>
    );
  }
}

ContractLineForm.propTypes = {
  saving: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  codes: PropTypes.array.isRequired,
  contract: PropTypes.object.isRequired,
  line: PropTypes.object.isRequired,
  divisions: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  chargetypes: PropTypes.array.isRequired,
  glaccounts: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  editing: PropTypes.bool,
  adding: PropTypes.bool,
  onAdd: PropTypes.func,
};

export default ContractLineForm;
