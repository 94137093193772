/* eslint-disable react-refresh/only-export-components */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { bindActionCreators } from "redux";

import * as clientActions from "../../actions/clientActions";

import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";

import ClientList from "./components/ClientList";

const ClientPage = ({ actions, history }) => {
  const [filters, setFilters] = useState({});

  const [pagination, setPagigation] = useState({
    page: 0,
    pageSize: 10,
    sorted: [
      {
        id: "last_name",
        desc: false,
      },
      {
        id: "first_name",
        desc: false,
      },
      {
        id: "city",
        desc: false,
      },
    ],
  });

  const [clients, setClients] = useState({ result: [], count: 0 });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const query = { ...filters, ...pagination };
      setLoading(true);
      if (pagination.sorted?.length) {
        delete query.sorted;
        query.sort = pagination.sorted.map(
          (x) => `${[x.id]}: ${x.desc ? -1 : 1}`
        );
      }
      const result = await actions.loadPaginatedClients(query);
      setClients(result);
      setLoading(false);
    })();
  }, [actions, filters, pagination]);

  const handleFiltersChange = (newFilters) => {
    const updates = newFilters.reduce((obj, item) => {
      obj[item.id] = item.value;
      return obj;
    }, {});
    setClients({});
    setFilters(updates);
  };

  const handlePageChange = (page) => {
    setClients({});
    setPagigation((prev) => ({ ...prev, page }));
  };

  const handlePageSizeChange = (pageSize) => {
    setClients({});
    setPagigation((prev) => ({ ...prev, pageSize }));
  };

  const handleSortedChange = (newSort) => {
    setClients({});
    setPagigation((prev) => ({ ...prev, sorted: newSort }));
  };

  return (
    <div className="content-wrapper">
      <Row>
        <BreadCrumbs breadcrumbs={[{ label: "Clients" }]} />
        <Col md={5} className="text-right">
          <AddButton entity="clients" />
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <ClientList
            clients={clients}
            pagination={pagination}
            loading={loading}
            onClickView={(id) => history.push(`/app/clients/${id}`)}
            onFiltersChange={handleFiltersChange}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onSortedChange={handleSortedChange}
          />
        </Col>
      </Row>
    </div>
  );
};

ClientPage.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...clientActions,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(ClientPage);
