import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as invoiceActions from "../../actions/callActions";

import ReactTable from "react-table";
import Loading from "../common/Loading";

import matchSorter from "match-sorter";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

class GpList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      approvedInvoices: [],
    };

    autoBind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.invoices !== nextProps.invoices) {
      let approvedInvoices = nextProps.invoices.filter(
        (invoice) =>
          invoice.stage.label === "Approved" || invoice.stage.label === "SFI"
      );
      this.setState({ approvedInvoices });
    }
  }

  render() {
    const { approvedInvoices } = this.state;
    const { selectAll, loadingInvoice } = this.props;

    const isSelected = (key) => {
      return this.props.invoicesToComplete.includes(key);
    };

    const toggleSelection = (key) => {
      this.props.onToggle(key);
    };

    const toggleAll = () => {
      if (!isEmpty(approvedInvoices)) {
        let selected = approvedInvoices.map((invoice) => invoice._id);
        this.props.toggleAll(selected);
      }
    };

    const formatMDY = (date) => {
      let fixedDate = moment.utc(date);
      const dateObj = new Date(fixedDate);
      const dateString = `${
        dateObj.getMonth() + 1
      }/${dateObj.getDate()}/${dateObj.getFullYear()}`;
      return dateString;
    };

    const columns = [
      {
        accessor: "id",
        Header: () => (
          <input
            type="checkbox"
            style={{ margin: "3px auto" }}
            onClick={(e) => toggleAll()}
            checked={selectAll}
          />
        ),
        Cell: (row) => (
          <input
            type="checkbox"
            onClick={(e) => {
              toggleSelection(row.original._id);
            }}
            checked={isSelected(row.original._id)}
          />
        ),
        sortable: false,
        filterable: false,
        maxWidth: 80,
        style: {
          textAlign: "center",
        },
      },
      {
        Header: "Number",
        id: "number",
        accessor: (d) => d.number,
      },
      {
        Header: "Date",
        accessor: "document_date",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [(item) => formatMDY(item.start_date)],
          }),
        filterAll: true,
        Cell: (row) => (
          <span>{moment.utc(row.value).format("MM/DD/YYYY")}</span>
        ),
      },
      {
        Header: "Status",
        id: "stage",
        accessor: (s) => s.stage.value,
      },
      {
        Header: "Division",
        id: "division",
        accessor: (d) => d.division.description,
      },
      {
        Header: "Format",
        accessor: "format.label",
      },
    ];

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
      getTrGroupProps: (s, r) => {
        if (s && r && r.original) {
          const selected = isSelected(r.original._id);
          return {
            style: {
              backgroundColor: selected ? "lightgreen" : "inherit",
            },
          };
        } else {
          return { style: {} };
        }
      },
    };

    return (
      <ReactTable
        className={"-striped -highlight"}
        data={approvedInvoices}
        LoadingComponent={() => <Loading active={loadingInvoice} />}
        columns={columns}
        defaultPageSize={15}
        toggleAll={toggleAll}
        {...checkboxProps}
        defaultSorted={[
          {
            id: "number",
            asc: true,
          },
        ]}
      />
    );
  }
}

GpList.propTypes = {
  invoices: PropTypes.array,
  invoicesToComplete: PropTypes.array,
  onToggle: PropTypes.func,
  toggleAll: PropTypes.func,
  filter: PropTypes.object,
  auth: PropTypes.object,
  codes: PropTypes.array,
};

function mapStatesToProps(state, ownProps) {
  return {
    invoices: state.reducers.invoices,
    invoicesToComplete: state.reducers.invoicesToComplete,
    loadingInvoice: state.reducers.loadingInvoice,
    codes: state.reducers.codes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...invoiceActions }, dispatch),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(GpList);
