import React from "react";

import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const EditButton = ({ onClickEdit, auth, disabled }) => {
  let hideButton = auth && auth.maxRole < 6000;
  return (
    <Col md={5} xs={12}>
      {!hideButton && (
        <button
          className="btn btn-warning right btn-edit"
          onClick={onClickEdit}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
          Edit
        </button>
      )}
    </Col>
  );
};

export default EditButton;
