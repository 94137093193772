import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { find } from "lodash";

import * as modalActions from "../../../actions/modalActions";

import ConfirmModal from "../../common/ConfirmModal";

import { CallFormEnterTime } from "./components";

import {
  checkDuration,
  checkProceduresDate,
  clearEntries,
  generateAssignedToOptions,
  generateContractOptions,
  generateProdCatOptions,
  generateStageOptions,
  isDateWithin24Hours,
} from "./utils";

import { useCallChangeHandler } from "./hooks";

import CallForm from "./CallForm";

import {
  durationValidation,
  endTimeValidations,
  startTimeValidation,
} from "./validations";

const requiredFields = [
  "start_time",
  "end_time",
  "assigned_to",
  "contract",
  "product_category",
  "division",
  "parent_account",
];

const CallFormWrapper = ({
  adding,
  call,
  codes,
  auth,
  onAdd,
  users,
  onSave,
  onCancel,
  onDelete,
  contracts,
  onConfirmChange,
  actions,
  editing,
  onEnterTime,
  clients,
  modal,
  saving,
  readOnly,
  deleting,
  onSaveCompletedCall,
  handleDelete,
  accounts,
}) => {
  const [requireProcedureChange, setRequireProcedureChange] = useState(false);
  const [isValidDate, setIsValidDate] = useState(true);

  const { inputs, handleChange, handleMultipleChanges } = useCallChangeHandler(
    call,
    auth,
    codes
  );

  const showBillingQuantity = useMemo(
    () =>
      !!inputs.entries.find((entry) =>
        ["MIN", "MILE", "STUDENTS"].includes(entry.uom)
      ),
    [inputs.entries]
  );

  const showDuration = useMemo(
    () => checkDuration(users, inputs),
    [inputs, users]
  );

  const showDeleteButton = useMemo(
    () =>
      !adding &&
      inputs.stage &&
      inputs.stage.label !== "Invoiced" &&
      (auth.maxRole === 9000 ||
        (auth.maxRole > 4000 &&
          auth.maxRole <= 8000 &&
          inputs.stage.label !== "Approved") ||
        (auth.maxRole === 4000 && inputs.stage.label === "Scheduled")),
    [adding, auth, inputs.stage]
  );

  const totalQuantities = useMemo(
    () => inputs.entries.reduce((acc, curr) => acc + curr.quantity, 0),
    [inputs.entries]
  );

  const prodCatOptions = useMemo(
    () => generateProdCatOptions(contracts, inputs.contract, inputs.division),
    [contracts, inputs.contract, inputs.division]
  );

  const assignedToOptions = useMemo(
    () => generateAssignedToOptions(users, auth),
    [auth, users]
  );

  const stageOptions = useMemo(
    () =>
      generateStageOptions(
        codes,
        auth.maxRole,
        editing,
        inputs.invoiced_by,
        inputs.contract
      ),
    [inputs.contract, inputs.invoiced_by, auth, codes, editing]
  );

  const contractOptions = useMemo(
    () => generateContractOptions(contracts, inputs.parent_account),
    [contracts, inputs.parent_account]
  );

  const accountOptions = useMemo(
    () =>
      accounts.map((account) => ({
        value: account._id,
        label: account.name,
      })),
    [accounts]
  );

  const durationFieldValidations = useMemo(
    () => durationValidation(totalQuantities),
    [totalQuantities]
  );

  const startTimeFieldValidations = useMemo(
    () => startTimeValidation(inputs.contract, inputs.start_time),
    [inputs.contract, inputs.start_time]
  );

  const endTimeFieldValidations = useMemo(
    () => endTimeValidations(inputs.start_time),
    [inputs.start_time]
  );

  const isDurationDisabled =
    inputs.start_time && adding
      ? !inputs.product_category
      : editing
        ? !call.product_category
        : true;

  const isBillingQuantityDisabled = adding
    ? !inputs.product_category
    : editing
      ? !call.product_category
      : true;

  const isSubmitDisabled =
    requiredFields.some((field) => !inputs[field]) ||
    (!inputs.entries.length && showDuration) ||
    !isValidDate ||
    readOnly ||
    requireProcedureChange;

  useEffect(() => {
    const incorrectProcedure = inputs.entries.find(
      (entry) =>
        !inputs.contract?.lines.find((line) => line._id === entry.procedure)
    );
    setRequireProcedureChange(incorrectProcedure);
  }, [inputs.entries, inputs.contract]);

  useEffect(() => {
    setIsValidDate(isDateWithin24Hours(inputs.start_time, inputs.end_time));
  }, [inputs.end_time, inputs.start_time]);

  useEffect(() => {
    if (
      !checkProceduresDate(inputs.contract, inputs.entries, inputs.start_time)
    ) {
      onConfirmChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.contract, inputs.entries, inputs.start_time]);

  const handleClearEntries = () => {
    handleMultipleChanges(
      clearEntries(inputs.entries, inputs.contract, inputs.start_time)
    );
  };

  const handleCancelClearEntries = () => {
    handleMultipleChanges({
      start_time: call.start_time,
      end_time: call.end_time,
    });
    actions.hideModal();
  };

  const handleParentAccountChange = (_, value) => {
    handleMultipleChanges({
      parent_account: value,
      contract: null,
      entries: [],
      product_category: null,
      quantity: "",
    });
  };

  const handleContractChange = (value) => {
    handleChange("contract", find(contracts, { _id: value }));
  };

  const handleStageChange = (value) => {
    handleChange("stage", find(codes, { _id: value }));
  };

  const handleSubmit = () => {
    const data = { ...inputs };
    const assignedTo = find(users, { id: inputs.assigned_to });

    data.cost = assignedTo.reg_pay_rate;
    data.contract = inputs.contract._id;
    data.stage = inputs.stage._id;

    delete data.isValidDate;

    if (!data.invoiced_by) {
      delete data.invoiced_by;
    }

    if (adding) {
      onAdd(data);
    } else {
      onSave(data);
    }
  };

  return (
    <React.Fragment>
      <CallForm
        assignedToOptions={assignedToOptions}
        billingQuantityDisabled={isBillingQuantityDisabled}
        clients={clients}
        contractOptions={contractOptions}
        durationDisabled={isDurationDisabled}
        editing={editing}
        handleChange={handleChange}
        handleContractChange={handleContractChange}
        handleParentAccountChange={handleParentAccountChange}
        handleStageChange={handleStageChange}
        handleSubmit={handleSubmit}
        inputs={inputs}
        isSubmitDisabled={isSubmitDisabled}
        onCancel={onCancel}
        onDelete={onDelete}
        onEnterTime={onEnterTime}
        prodCatOptions={prodCatOptions}
        requireProcedureChange={requireProcedureChange}
        saving={saving}
        showBillingQuantity={showBillingQuantity}
        showDeleteButton={showDeleteButton}
        showDuration={showDuration}
        stageOptions={stageOptions}
        durationFieldValidations={durationFieldValidations}
        users={users}
        startTimeValidations={startTimeFieldValidations}
        endTimeValidations={endTimeFieldValidations}
        isValidDate={isValidDate}
        accountOptions={accountOptions}
      />
      <CallFormEnterTime
        clients={clients}
        inputs={inputs}
        users={users}
        onChange={handleMultipleChanges}
      />
      <ConfirmModal
        id="callConfirmModal"
        title="Date Confirmation"
        body="There are procedures that are not valid for your selected date range. Please review and update the procedures for this call."
        modal={modal}
        cancel={handleCancelClearEntries}
        close={actions.hideModal}
        confirm={handleClearEntries}
      />
      <ConfirmModal
        id="callDeleteModal"
        title={!deleting ? "Save Call" : "Delete Call"}
        body={
          !deleting
            ? "Are you sure you want to save this call? Once it is completed you will not be able to make edits."
            : "Are you sure you want to delete this call?"
        }
        modal={modal}
        close={actions.hideModal}
        confirm={() =>
          !deleting ? onSaveCompletedCall(inputs) : handleDelete()
        }
      />
    </React.Fragment>
  );
};

CallFormWrapper.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  saving: PropTypes.bool,
  onSave: PropTypes.func,
  call: PropTypes.object,
  auth: PropTypes.object,
  users: PropTypes.array,
  contracts: PropTypes.array,
  codes: PropTypes.array,
  onAdd: PropTypes.func,
  onCancel: PropTypes.func,
  onEnterTime: PropTypes.func,
  editing: PropTypes.bool,
  adding: PropTypes.bool,
  clients: PropTypes.array,
  readOnly: PropTypes.bool,
  onDelete: PropTypes.func,
  onConfirmChange: PropTypes.func,
  deleting: PropTypes.bool,
  onSaveCompletedCall: PropTypes.func,
  handleDelete: PropTypes.func,
  accounts: PropTypes.array,
};

function mapStatesToProps(state) {
  return {
    modal: state.reducers.modal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions }, dispatch),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(CallFormWrapper);
