import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withFormsy } from "formsy-react";

import Select from "react-select";
import { Row, Col } from "react-bootstrap";

const DropdownSearch = ({
  name,
  label,
  data,
  placeholder,
  required,
  validations,
  validationErrors,
  disabled,
  isMulti,
  menuIsOpen,
  value: initialValue,
  onChange,
}) => {
  const [state, setState] = useState(isMulti ? { value: [] } : { value: "" });

  useEffect(() => {
    if (initialValue && !isMulti) {
      const obj = data.find((d) => d.value === initialValue);
      setState(obj);
    } else if (initialValue && isMulti && Array.isArray(initialValue)) {
      const obj = initialValue.map((val) =>
        data.find((d) => d.value === val.value)
      );
      setState(obj);
    } else if (!initialValue) {
      setState({ value: "" });
    }
  }, [data, initialValue]);

  const isFalsy = isMulti
    ? state.length == 0
    : typeof state == "null" || typeof state == "undefined" || state == "";

  const fieldLabel = required ? (label += " *") : label;

  return (
    <Row className="form-group">
      <Col sm={12} xs={12}>
        <label
          className="control-label"
          htmlFor={name}
          style={{ textTransform: "uppercase" }}
        >
          {fieldLabel}
        </label>
      </Col>
      <Col sm={12} xs={12}>
        <Select
          formNoValidate
          required={required}
          name={name}
          autosize={false}
          value={!isFalsy ? state : ""}
          onChange={(val) => onChange(name, val)}
          options={data}
          placeholder={placeholder ? placeholder : "Please Select..."}
          validations={validations || "isEmptyString"}
          validationErrors={validationErrors}
          isSearchable
          isMulti={isMulti}
          isClearable={false}
          isDisabled={disabled}
          menuIsOpen={menuIsOpen}
        />
      </Col>
    </Row>
  );
};

DropdownSearch.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  data: PropTypes.array,
  required: PropTypes.bool,
  setValue: PropTypes.func,
  getValue: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isMult: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  validations: PropTypes.bool,
  validationErrors: PropTypes.bool,
  isMulti: PropTypes.bool,
};

export default withFormsy(DropdownSearch);
