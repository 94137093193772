import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { Input, Select, Textarea, Checkbox } from "formsy-react-components";

import DropdownSearch from "../common/DropdownSearch";
import PhoneNumberInput from "../common/PhoneNumberInput";

import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";

class AccountLocationForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
    };
  }

  componentDidMount = () => {
    this.setState({ is_primary: this.props.location.is_primary });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  onChange = (field, selected) => {
    this.setState({
      [field]: selected.value,
    });
  };

  add = (model) => {
    this.props.onAdd(this.props.account, model);
  };

  cancel = () => {
    this.props.onCancel();
  };

  submit = (model) => {
    model.id = this.props.location.id;
    model.is_primary = this.state.is_primary;

    let alreadyExists;
    this.props.account.locations.map((location) => {
      alreadyExists = location.is_primary ? true : false;
    });

    this.props.onSave(this.props.account, model);
  };

  render() {
    this.props.location.address = this.props.location.address || {};
    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={
          !isEmpty(this.props.location.address) ? this.submit : this.add
        }
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={6}>
            <Input
              formNoValidate
              required
              name="name"
              label="Name"
              placeholder="Name"
              disabled={!this.props.editing}
              value={this.props.location.name}
            />
          </Col>
          <Col md={6}>
            <DropdownSearch
              name="location_type"
              label="Type"
              value={
                this.state.location_type || this.props.location.location_type
              }
              disabled={!this.props.editing}
              onChange={this.onChange}
              data={orderBy(
                [
                  ...this.props.codes
                    .filter(
                      (codes) =>
                        codes.entity == "Location" &&
                        codes.field == "location_type"
                    )
                    .map((code) => ({
                      value: code._id,
                      label: code.label,
                    })),
                ],
                "label"
              )}
            />
          </Col>
          {/* <fieldset>
            <legend>
              Address
              <span>
                <input
                  type="checkbox"
                  onChange={() => {
                    this.setState({ is_primary: !this.state.is_primary });
                  }}
                  checked={this.state.is_primary}
                />
                Use as primary address (for both billing and shipping)
              </span>
            </legend> */}
           </Row>
           <Row>
          <Col md={6}>
            <PhoneNumberInput
              name="phone"
              label="Phone"
              placeholder="Phone"
              value={this.props.location.phone}
              disabled={!this.props.editing}
            />
          </Col>
          <Col md={6}>
            <PhoneNumberInput
              name="fax"
              label="Fax"
              placeholder="Fax"
              value={this.props.location.fax}
              disabled={!this.props.editing}
            />
          </Col>
          <Col md={4}>
            <Input
              formNoValidate
              required
              name="address.addr1"
              label="Address 1"
              placeholder="Address 1"
              value={this.props.location.address.addr1}
              disabled={!this.props.editing}
            />
          </Col>
          <Col md={4}>
            <Input
              formNoValidate
              name="address.addr2"
              label="Address 2"
              placeholder="Address 2"
              value={this.props.location.address.addr2}
              disabled={!this.props.editing}
            />
          </Col>
          <Col md={4}>
            <Input
              formNoValidate
              required
              name="address.city"
              label="City"
              placeholder="City"
              value={this.props.location.address.city}
              disabled={!this.props.editing}
            />
          </Col>
          <Col md={4}>
            <DropdownSearch
              required
              name="address.state"
              label="State"
              value={
                this.state["address.state"] || this.props.location.address.state
              }
              disabled={!this.props.editing}
              onChange={this.onChange}
              data={orderBy(
                [
                  ...this.props.codes
                    .filter(
                      (codes) => codes.entity == "*" && codes.field == "state"
                    )
                    .map((code) => ({
                      value: code._id,
                      label: code.label,
                    })),
                ],
                "label"
              )}
            />
          </Col>
          <Col md={4}>
            <Input
              formNoValidate
              required
              name="address.zip"
              label="Zip"
              placeholder="Zip"
              value={this.props.location.address.zip}
              disabled={!this.props.editing}
            />
          </Col>
          <Col md={4}>
            <DropdownSearch
              required
              name="address.country"
              label="Country"
              value={
                this.state["address.country"] ||
                this.props.location.address.country
              }
              onChange={this.onChange}
              disabled={!this.props.editing}
              data={orderBy(
                [
                  ...this.props.codes
                    .filter(
                      (codes) => codes.entity == "*" && codes.field == "country"
                    )
                    .map((code) => ({
                      value: code._id,
                      label: code.label,
                    })),
                ],
                "label"
              )}
            />
          </Col>
          {/* </fieldset> */}
          <Col md={12} className="action-footer">
            <button
              className="btn btn-danger"
              type="button"
              onClick={this.cancel}
            >
              Cancel
            </button>
            {!this.props.adding && (
              <input
                className="btn btn-success"
                type="submit"
                disabled={!this.state.canSubmit}
                value={this.props.saving ? "Saving... " : "Save"}
              />
            )}
            {this.props.adding && (
              <input
                className="btn btn-success"
                type="submit"
                disabled={!this.state.canSubmit}
                value={this.props.saving ? "Adding... " : "Add"}
              />
            )}
          </Col>
        </Row>
      </Formsy>
    );
  }
}

AccountLocationForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  codes: PropTypes.array,
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  adding: PropTypes.bool.isRequired,
  account: PropTypes.object.isRequired,
  location: PropTypes.object,
};

AccountLocationForm.defaultProps = {
  location: {
    name: "",
    fax: "",
    phone: "",
    address: {
      addr1: "",
      addr2: "",
      state: "",
      city: "",
      zip: "",
      country: "",
    },
  },
};

export default AccountLocationForm;
