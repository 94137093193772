import React from "react";
import PropTypes from "prop-types";

import PhoneNumber from "../common/PhoneNumber";
import Loading from "../common/Loading";
import ReactTable from "react-table";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faFolder } from "@fortawesome/free-solid-svg-icons";

import matchSorter from "match-sorter";

const ContactList = ({ contacts, auth, onClickView, onClickDelete }) => {
  const columns = [
    {
      Header: "First",
      accessor: "first_name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["first_name"] }),
      filterAll: true,
    },
    {
      Header: "Last",
      accessor: "last_name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["last_name"] }),
      filterAll: true,
    },
    {
      Header: "Account",
      accessor: "account",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["account.name"] }),
      filterAll: true,
      Cell: (row) => (row.value ? row.value.name : ""),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (row) => (row.value ? row.value.toLowerCase() : ""),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["email"] }),
      filterAll: true,
    },
    {
      Header: "Phone",
      accessor: "business_phone",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["business_phone"] }),
      filterAll: true,
      Cell: (row) => (
        <div>
          <PhoneNumber number={row.value} />
        </div>
      ),
    },
    {
      Header: "Ext.",
      accessor: "business_phone_ext",
      maxWidth: 67,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["business_phone_ext"] }),
      filterAll: true,
    },
    {
      Header: "",
      accessor: "_id",
      filterable: false,
      sortable: false,
      Cell: (row) => (
        <div className="text-center actions">
          <DropdownButton
            title="Actions"
            id={"dropdown"}
            onClick={(e) => e.stopPropagation()}
          >
            <LinkContainer to={`/app/contacts/${row.value}`}>
              <MenuItem>
                <FontAwesomeIcon icon={faFolder} />
                View
              </MenuItem>
            </LinkContainer>
            {auth.maxRole == 9000 && (
              <MenuItem onClick={() => onClickDelete(row.value)}>
                <FontAwesomeIcon icon={faTrashAlt} className="brand-color" />
                Delete
              </MenuItem>
            )}
          </DropdownButton>
        </div>
      ),
    },
  ];

  const props = {
    getTdProps: (state, rowInfo, column) => {
      return {
        onClick: () => {
          if (column.id !== "_id") {
            onClickView(rowInfo.original._id);
          }
        },
      };
    },
  };

  return (
    <ReactTable
      className="-highlight"
      filterable
      data={contacts}
      LoadingComponent={() => <Loading active={!contacts.length} />}
      columns={columns}
      {...props}
      defaultPageSize={10}
      defaultSorted={[
        {
          id: "first_name",
          asc: true,
        },
      ]}
    />
  );
};

ContactList.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onClickView: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  filter: PropTypes.object,
  onChange: PropTypes.func,
};

export default ContactList;
