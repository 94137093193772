import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as divisionActions from "../../actions/divisionActions";

import * as labelActions from "../../actions/labelActions";
import { Row, Col } from "react-bootstrap";

import DynamicHeader from "../common/DynamicHeader";
import DivisionForm from "./DivisionForm";
class DivisionEditPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      editing: false,
    };

    autoBind(this);

    props.actions.getDivision(props.match.params.id);
  }

  handleSave(division) {
    let data = {
      id: this.props.division.id,
      ...division,
    };
    this.props.actions.updateDivision(data);
  }

  handleEdit() {
    this.setState({
      editing: !this.state.editing,
    });
  }

  handleCancel() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div className="content-wrapper">
        <Row>
          <Col md={12}>
            <h1>{this.props.division.description}</h1>
          </Col>
          <Col md={9}>
            <h3>
              <DynamicHeader entity="Division" /> Info
            </h3>
          </Col>
          <Col md={3}>
            <button
              className="btn btn-warning btn-sm right"
              onClick={this.handleEdit}
            >
              EDIT
            </button>
          </Col>
        </Row>
        <Row className="mt">
          <Col md={12}>
            <DivisionForm
              onSave={this.handleSave}
              onCancel={this.handleCancel}
              saving={this.props.savingDivision}
              division={this.props.division}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

DivisionEditPage.propTypes = {
  actions: PropTypes.object.isRequired,
  savingDivision: PropTypes.bool,
  division: PropTypes.object,
  params: PropTypes.object,
  labels: PropTypes.array,
};

DivisionEditPage.contextTypes = {
  router: PropTypes.object,
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    savingDivision: state.reducers.savingDivision,
    division: state.reducers.division,
    labels: state.reducers.labels.labels,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...divisionActions, ...labelActions },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(DivisionEditPage);
