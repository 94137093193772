import React, { Component } from "react";
import PropTypes from "prop-types";

class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="vertical-center error-handler">
          <h4>
            An error has occurred, please contact the ESMA helpdesk at{" "}
            <a href="mailto:helpdesk@eastersealsma.org">
              helpdesk@eastersealsma.org
            </a>
            .
          </h4>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorHandler.propTypes = {
  children: PropTypes.object,
};

export default ErrorHandler;
