import moment from "moment";

export default function clearEntries(entries = [], contract, startTime) {
  const filteredEntries = entries.filter((entry) => {
    const procedure = find(contract?.lines, { _id: entry.procedure });
    const format = (date) => moment(date).startOf("day").format("YYYY-MM-DD");
    const callStart = format(startTime);
    const lineStart = format(procedure.start_date);
    const lineEnd = format(procedure.end_date);

    // [] indicates inclusion of value
    return moment(callStart).isBetween(lineStart, lineEnd, null, []);
  });

  return {
    entries: filteredEntries,
    quantity: filteredEntries.reduce((total, e) => total + e.quantity, 0) || "",
  };
}
