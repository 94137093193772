import * as endpoints from "./apiEndpoints";
import axios from "axios";

class DivisionService {
  static loadDivisions() {
    const url = `${endpoints.BASE_URL}${endpoints.GET_DIVISIONS}`;

    return axios.get(url).then((response) => response.data);
  }

  static getDivision(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_DIVISION}/${id}`;

    return axios.get(url).then((response) => response.data);
  }

  static createDivision(division) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_DIVISION}`;
    const body = {
      division: division,
    };

    return axios.post(url, body).then((response) => {
      console.log(response.data);
      return response.data;
    });
  }

  static createDepartment(division, department) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_DIVISION}/${division.id}/department`;

    const body = {
      department: department,
    };

    return axios.post(url, body).then((response) => response.data);
  }

  static updateDepartment(division, department) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_DIVISION}/${division.id}/department`;

    const body = {
      department: department,
    };

    return axios.put(url, body).then((response) => response.data);
  }

  static deleteDepartment(division, department) {
    console.log("div", division);
    console.log("dept", department);
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_DIVISION}/${division.id}/department/delete`;

    const body = {
      department: department,
    };

    return axios
      .post(url, body)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  static updateDivision(division) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_DIVISION}/${division.id}`;
    const body = {
      division: division,
    };

    return axios.put(url, body).then((response) => response.data);
  }

  static deleteDivision(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_DIVISION}/${id}`;
    return axios.delete(url).then((response) => response.data);
  }
}

export default DivisionService;
