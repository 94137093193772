import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Formsy from "formsy-react";
import { Input } from "formsy-react-components";
import { Row, Col } from "react-bootstrap";

class LoginForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
    };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  submit = (model) => {
    this.props.onSave(model);
  };

  render() {
    return (
      <Formsy
        ref="form"
        className="vertical login-form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Input
          required
          name="email"
          placeholder="Email"
          addonBefore={<span className="glyphicon glyphicon-envelope" />}
        />
        <Input
          required
          type="password"
          name="password"
          placeholder="Password"
          addonBefore={<span className="glyphicon glyphicon-lock" />}
        />
        <Row className="text-center">
          <Col md={12}>
            <input
              className="btn"
              type="submit"
              disabled={!this.state.canSubmit}
              value={this.props.saving ? "Logging In... " : "Log In"}
            />
          </Col>
        </Row>
        <Row className="text-center">
          <Link to="/forgot">Forgot Your Password?</Link>
        </Row>
      </Formsy>
    );
  }
}

LoginForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default LoginForm;
