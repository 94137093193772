import { push } from "react-router-redux";
import * as types from "./actionTypes";
import { showModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import codeService from "../services/codeService";

export function loadCodeSuccess(codes) {
  return {
    type: types.LOAD_CODE_SUCCESS,
    codes,
    meta: {
      throttle: true,
    },
  };
}

export function getCodeSuccess(code) {
  return {
    type: types.GET_CODE_SUCCESS,
    code,
  };
}

export function savingCode(status = true) {
  return {
    type: types.SAVING_CODE,
    savingCode: status,
  };
}

export function createCodeSuccess(code) {
  return {
    type: types.CREATE_CODE_SUCCESS,
    code,
  };
}

export function updateCodeSuccess(code) {
  return {
    type: types.UPDATE_CODE_SUCCESS,
    code,
  };
}

export function requestCodeId(codeId) {
  return (dispatch) => {
    dispatch({
      type: types.REQUEST_CODE_ID,
      codeToDelete: codeId,
    });
    dispatch(showModalSuccess("codeDeleteModal"));
  };
}

export function deleteCodeSuccess(codeId) {
  return {
    type: types.DELETE_CODE_SUCCESS,
    codeId,
  };
}

export function loadCodes() {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return codeService
      .loadCodes()
      .then((data) => dispatch(loadCodeSuccess(data)))
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function getCode(id, showCODEDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return codeService
      .getCode(id)
      .then((code) => {
        dispatch(getCodeSuccess(code));
        if (showCODEDetails) {
          dispatch(showModalSuccess("codeDetailsModal"));
        }
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function createCode(code) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingCode());
    return codeService
      .createCode(code)
      .then((createdCode) => {
        dispatch(createCodeSuccess(createdCode));
        dispatch(savingCode(false));
        dispatch(showAlertSuccess("Code created successfully", "success"));
        dispatch(push("/app/settings/codes"));
      })
      .catch((error) => {
        dispatch(savingCode(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateCode(code) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingCode());
    return codeService
      .updateCode(code)
      .then((updatedCode) => {
        dispatch(updateCodeSuccess(updatedCode));
        dispatch(savingCode(false));
        dispatch(showAlertSuccess("Code updated successfully", "success"));
        dispatch(push("/app/settings/codes"));
      })
      .catch((error) => {
        dispatch(savingCode(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteCode(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return codeService
      .deleteCode(id)
      .then(() => {
        dispatch(deleteCodeSuccess(id));
        dispatch(showAlertSuccess("Code removed", "success"));
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}
