import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Panel } from "react-bootstrap";
import Loading from "../../common/Loading";

import orderBy from "lodash/orderBy";
import find from "lodash/find";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const ContractNoteList = ({ contract, users, onClickDelete, onClickEdit }) => {
  let contractNotesView = <Loading active />;

  if (contract && users.length) {
    contractNotesView = (
      <Row>
        {contract.notes && contract.notes.length ? (
          orderBy(contract.notes, "modified_on", "desc").map((note) => {
            let timestamp = moment(note.modified_on).format("MM/DD/YYYY hh:mm");

            let author = find(users, { email: note.created_by });

            return (
              <Panel bsStyle="info" key={note.id} className="note-panel">
                <Panel.Heading>
                  <Row>
                    <Col md={10}>
                      <h4>{note.title}</h4>
                    </Col>
                    <Col md={2}>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="brand-color contractNoteListBtn"
                        onClick={() => onClickDelete(contract.id, note.id)}
                      />
                      <FontAwesomeIcon
                        icon={faPencilAlt}
                        className="brand-color contractNoteListBtn"
                        onClick={() => onClickEdit(contract, note)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <h5>{author.full_name}</h5>
                    </Col>
                    <Col md={6}>
                      <p>
                        <em>{timestamp}</em>
                      </p>
                    </Col>
                  </Row>
                </Panel.Heading>
                <Panel.Body>
                  <blockquote>
                    <p>{note.note}</p>
                  </blockquote>
                </Panel.Body>
              </Panel>
            );
          })
        ) : (
          <h3 className="vertical-center">No notes available</h3>
        )}
      </Row>
    );
  }

  return <div>{contractNotesView}</div>;
};

ContractNoteList.propTypes = {
  contract: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
};

export default ContractNoteList;
