export const SHOW_ALERT_SUCCESS = "SHOW_ALERT_SUCCESS";
export const HIDE_ALERT_SUCCESS = "HIDE_ALERT_SUCCESS";
export const SHOW_MODAL_SUCCESS = "SHOW_MODAL_SUCCESS";
export const HIDE_MODAL_SUCCESS = "HIDE_MODAL_SUCCESS";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const CHECK_AUTH_SUCCESS = "CHECK_AUTH_SUCCESS";
export const RESET_PASS_SENT_SUCCESS = "RESET_PASS_SENT_SUCCESS";
export const RESET_PASS_SUCCESS = "RESET_PASS_SUCCESS";

export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const SAVING_USER = "SAVING_USER";
export const LOADING_USER = "LOADING_USER";
export const REQUEST_USER_ID = "REQUEST_USER_ID";

export const LOAD_ACCOUNT_SUCCESS = "LOAD_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const SAVING_ACCOUNT = "SAVING_ACCOUNT";
export const REQUEST_ACCOUNT_ID = "REQUEST_ACCOUNT_ID";
export const REQUEST_LOCATION_ID = "REQUEST_LOCATION_ID";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";

export const LOAD_CONTACT_SUCCESS = "LOAD_CONTACT_SUCCESS";
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const SAVING_CONTACT = "SAVING_CONTACT";
export const REQUEST_CONTACT_ID = "REQUEST_CONTACT_ID";

export const LOAD_LABEL_SUCCESS = "LOAD_LABEL_SUCCESS";
export const GET_LABEL_SUCCESS = "GET_LABEL_SUCCESS";
export const CREATE_LABEL_SUCCESS = "CREATE_LABEL_SUCCESS";
export const UPDATE_LABEL_SUCCESS = "UPDATE_LABEL_SUCCESS";
export const DELETE_LABEL_SUCCESS = "DELETE_LABEL_SUCCESS";
export const SAVING_LABEL = "SAVING_LABEL";
export const REQUEST_LABEL_ID = "REQUEST_LABEL_ID";

export const LOAD_SYSTEM_SUCCESS = "LOAD_SYSTEM_SUCCESS";
export const GET_SYSTEM_SUCCESS = "GET_SYSTEM_SUCCESS";
export const CREATE_SYSTEM_SUCCESS = "CREATE_SYSTEM_SUCCESS";
export const UPDATE_SYSTEM_SUCCESS = "UPDATE_SYSTEM_SUCCESS";
export const DELETE_SYSTEM_SUCCESS = "DELETE_SYSTEM_SUCCESS";
export const SAVING_SYSTEM = "SAVING_SYSTEM";
export const REQUEST_SYSTEM_ID = "REQUEST_SYSTEM_ID";

export const LOAD_LICENSE_SUCCESS = "LOAD_LICENSE_SUCCESS";
export const GET_LICENSE_SUCCESS = "GET_LICENSE_SUCCESS";
export const CREATE_LICENSE_SUCCESS = "CREATE_LICENSE_SUCCESS";
export const UPDATE_LICENSE_SUCCESS = "UPDATE_LICENSE_SUCCESS";
export const DELETE_LICENSE_SUCCESS = "DELETE_LICENSE_SUCCESS";
export const SAVING_LICENSE = "SAVING_LICENSE";
export const REQUEST_LICENSE_ID = "REQUEST_LICENSE_ID";

export const LOAD_DIVISION_SUCCESS = "LOAD_DIVISION_SUCCESS";
export const GET_DIVISION_SUCCESS = "GET_DIVISION_SUCCESS";
export const CREATE_DIVISION_SUCCESS = "CREATE_DIVISION_SUCCESS";
export const UPDATE_DIVISION_SUCCESS = "UPDATE_DIVISION_SUCCESS";
export const DELETE_DIVISION_SUCCESS = "DELETE_DIVISION_SUCCESS";
export const SAVING_DIVISION = "SAVING_DIVISION";
export const REQUEST_DIVISION_ID = "REQUEST_DIVISION_ID";
export const REQUEST_DEPARTMENT_ID = "REQUEST_DEPARTMENT_ID";

export const LOAD_ROLE_SUCCESS = "LOAD_ROLE_SUCCESS";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const SAVING_ROLE = "SAVING_ROLE";
export const REQUEST_ROLE_ID = "REQUEST_ROLE_ID";

export const LOAD_GLACCOUNT_SUCCESS = "LOAD_GLACCOUNT_SUCCESS";
export const GET_GLACCOUNT_SUCCESS = "GET_GLACCOUNT_SUCCESS";
export const CREATE_GLACCOUNT_SUCCESS = "CREATE_GLACCOUNT_SUCCESS";
export const UPDATE_GLACCOUNT_SUCCESS = "UPDATE_GLACCOUNT_SUCCESS";
export const DELETE_GLACCOUNT_SUCCESS = "DELETE_GLACCOUNT_SUCCESS";
export const SAVING_GLACCOUNT = "SAVING_GLACCOUNT";
export const REQUEST_GLACCOUNT_ID = "REQUEST_GLACCOUNT_ID";

export const LOAD_ZIP_SUCCESS = "LOAD_ZIP_SUCCESS";
export const GET_ZIP_SUCCESS = "GET_ZIP_SUCCESS";
export const CREATE_ZIP_SUCCESS = "CREATE_ZIP_SUCCESS";
export const UPDATE_ZIP_SUCCESS = "UPDATE_ZIP_SUCCESS";
export const DELETE_ZIP_SUCCESS = "DELETE_ZIP_SUCCESS";
export const SAVING_ZIP = "SAVING_ZIP";
export const REQUEST_ZIP_ID = "REQUEST_ZIP_ID";

export const LOAD_PRODCAT_SUCCESS = "LOAD_PRODCAT_SUCCESS";
export const GET_PRODCAT_SUCCESS = "GET_PRODCAT_SUCCESS";
export const CREATE_PRODCAT_SUCCESS = "CREATE_PRODCAT_SUCCESS";
export const UPDATE_PRODCAT_SUCCESS = "UPDATE_PRODCAT_SUCCESS";
export const DELETE_PRODCAT_SUCCESS = "DELETE_PRODCAT_SUCCESS";
export const SAVING_PRODCAT = "SAVING_PRODCAT";
export const REQUEST_PRODCAT_ID = "REQUEST_PRODCAT_ID";

export const LOAD_ITEM_SUCCESS = "LOAD_ITEM_SUCCESS";
export const GET_ITEM_SUCCESS = "GET_ITEM_SUCCESS";
export const CREATE_ITEM_SUCCESS = "CREATE_ITEM_SUCCESS";
export const UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const SAVING_ITEM = "SAVING_ITEM";
export const REQUEST_ITEM_ID = "REQUEST_ITEM_ID";

export const LOAD_CODE_SUCCESS = "LOAD_CODE_SUCCESS";
export const GET_CODE_SUCCESS = "GET_CODE_SUCCESS";
export const CREATE_CODE_SUCCESS = "CREATE_CODE_SUCCESS";
export const UPDATE_CODE_SUCCESS = "UPDATE_CODE_SUCCESS";
export const DELETE_CODE_SUCCESS = "DELETE_CODE_SUCCESS";
export const SAVING_CODE = "SAVING_CODE";
export const REQUEST_CODE_ID = "REQUEST_CODE_ID";

export const LOAD_COMPANY_SUCCESS = "LOAD_COMPANY_SUCCESS";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const SAVING_COMPANY = "SAVING_COMPANY";
export const REQUEST_COMPANY_ID = "REQUEST_COMPANY_ID";

export const LOAD_CHARGETYPE_SUCCESS = "LOAD_CHARGETYPE_SUCCESS";
export const GET_CHARGETYPE_SUCCESS = "GET_CHARGETYPE_SUCCESS";
export const CREATE_CHARGETYPE_SUCCESS = "CREATE_CHARGETYPE_SUCCESS";
export const UPDATE_CHARGETYPE_SUCCESS = "UPDATE_CHARGETYPE_SUCCESS";
export const DELETE_CHARGETYPE_SUCCESS = "DELETE_CHARGETYPE_SUCCESS";
export const SAVING_CHARGETYPE = "SAVING_CHARGETYPE";
export const REQUEST_CHARGETYPE_ID = "REQUEST_CHARGETYPE_ID";

export const LOAD_CONTRACT_SUCCESS = "LOAD_CONTRACT_SUCCESS";
export const GET_CONTRACT_SUCCESS = "GET_CONTRACT_SUCCESS";
export const CREATE_CONTRACT_SUCCESS = "CREATE_CONTRACT_SUCCESS";
export const UPDATE_CONTRACT_SUCCESS = "UPDATE_CONTRACT_SUCCESS";
export const DELETE_CONTRACT_SUCCESS = "DELETE_CONTRACT_SUCCESS";
export const SAVING_CONTRACT = "SAVING_CONTRACT";
export const REQUEST_CONTRACT_ID = "REQUEST_CONTRACT_ID";
export const REQUEST_CLIENT_ID = "REQUEST_CLIENT_ID";

export const LOAD_CALL_SUCCESS = "LOAD_CALL_SUCCESS";
export const LOAD_CALL_BY_STAGE_SUCCESS = "LOAD_CALL_BY_STAGE_SUCCESS";
export const GET_CALL_SUCCESS = "GET_CALL_SUCCESS";
export const TOGGLE_CALL = "TOGGLE_CALL";
export const TOGGLE_ALL_CALLS = "TOGGLE_ALL_CALLS";
export const TOGGLE_INVOICE_CALL = "TOGGLE_INVOICE_CALL";
export const TOGGLE_ALL_INVOICE_CALLS = "TOGGLE_ALL_INVOICE_CALLS";
export const DESELECT_ALL_CALLS = "DESELECT_ALL_CALLS";
export const BULK_APPROVE_SUCCESS = "BULK_APPROVE_SUCCESS";
export const BULK_COMPLETE_SUCCESS = "BULK_COMPLETE_SUCCESS";
export const CREATE_CALL_SUCCESS = "CREATE_CALL_SUCCESS";
export const UPDATE_CALL_SUCCESS = "UPDATE_CALL_SUCCESS";
export const UPDATE_CALLS_SUCCESS = "UPDATE_CALLS_SUCCESS";
export const DELETE_CALL_SUCCESS = "DELETE_CALL_SUCCESS";
export const LOADING_CALL = "LOADING_CALL";
export const SAVING_CALL = "SAVING_CALL";
export const REQUEST_CALL_ID = "REQUEST_CALL_ID";

export const LOAD_INVOICE_SUCCESS = "LOAD_INVOICE_SUCCESS";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const BULK_UPDATE_INVOICES_SUCCESS = "BULK_UPDATE_INVOICES_SUCCESS";
export const SAVING_INVOICE = "SAVING_INVOICE";
export const REQUEST_INVOICE_ID = "REQUEST_INVOICE_ID";
export const TOGGLE_INVOICE = "TOGGLE_INVOICE";
export const TOGGLE_INVOICES_TO_APPROVE = "TOGGLE_INVOICES_TO_APPROVE";
export const TOGGLE_INVOICES = "TOGGLE_INVOICES";
export const TOGGLE_INVOICE_PRINT = "TOGGLE_INVOICE_PRINT";
export const TOGGLE_GP_INVOICE = "TOGGLE_GP_INVOICE";
export const TOGGLE_ALL_GP_INVOICES = "TOGGLE_ALL_GP_INVOICES";
export const DESELECT_ALL_INVOICES = "DESELECT_ALL_INVOICES";
export const LOADING_INVOICE = "LOADING_INVOICE";

// ADP
export const SYNC_ADP_WORKER_OBJECT_SUCCESS = "SYNC_ADP_WORKER_OBJECT_SUCCESS";
export const SYNC_ADP_USER_CERT_SUCCESS = "SYNC_ADP_USER_CERT_SUCCESS";
export const ADP_BULK_UPDATE_CERT_SUCCESS = "ADP_BULK_UPDATE_CERT_SUCCESS";

export const LOAD_CLIENT_SUCCESS = "LOAD_CLIENT_SUCCESS";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const SAVING_CLIENT = "SAVING_CLIENT";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const REQUEST_EPISODE_ID = "REQUEST_EPISODE_ID";
export const GET_CONTRACTS_BY_CLIENT_SUCCESS =
  "GET_CONTRACTS_BY_CLIENT_SUCCESS";
