import React from "react";
import { Grid } from "react-bootstrap";

import PrivateRoute from "./PrivateRoute";
import { Redirect, Switch } from "react-router-dom";

import Header from "../common/Header";
import Dashboard from "../dashboard/Dashboard";
import ContractPage from "../contract/ContractPage";
import ContractAddPage from "../contract/ContractAddPage";
import ContractEditPage from "../contract/ContractEditPage";
import ContractLinePage from "../contract/contractLines/ContractLinePage";
import ContractNotePage from "../contract/contractNotes/ContractNotePage";
import ContractClientPage from "../contract/contractClients/ContractClientPage";
import AccountPage from "../account/AccountPage";
import AccountAddPage from "../account/AccountAddPage";
import AccountEditPage from "../account/AccountEditPage";
import AccountContactsPage from "../account/AccountContactsPage";
import AccountLocationsPage from "../account/AccountLocationsPage";
import AccountNotesPage from "../account/AccountNotesPage";
import ContactPage from "../contact/ContactPage";
import ContactAddPage from "../contact/ContactAddPage";
import ContactEditPage from "../contact/ContactEditPage";
import UsersPage from "../users/UsersPage";
import UserAddPage from "../users/UserAddPage";
import UserEditPage from "../users/UserEditPage";
import InvoicePage from "../invoice/InvoicePage";
import InvoiceAddPage from "../invoice/InvoiceAddPage";
import InvoiceApprovalPage from "../invoice/InvoiceApprovalPage";
import CallPage from "../call/CallPage";
import CallAddPage from "../call/CallAddPage";
import CallEditPage from "../call/CallEditPage";
import CallApprovalPage from "../call/CallApprovalPage";
import ChargetypeEditPage from "../chargetype/ChargetypeEditPage";
import ChargetypeAddPage from "../chargetype/ChargetypeAddPage";
import ChargetypePage from "../chargetype/ChargetypePage";
import CompanyPage from "../company/CompanyPage";
import AccountSettings from "../general/AccountSettings";
import CodePage from "../code/CodePage";
import CodeAddPage from "../code/CodeAddPage";
import CodeEditPage from "../code/CodeEditPage";
import DivisionPage from "../division/DivisionPage";
import DivisionAddPage from "../division/DivisionAddPage";
import DivisionEditPage from "../division/DivisionEditPage";
import DepartmentEditPage from "../division/DepartmentEditPage";
import GlaccountPage from "../glaccount/GlaccountPage";
import GlaccountAddPage from "../glaccount/GlaccountAddPage";
import GlaccountEditPage from "../glaccount/GlaccountEditPage";
import ProcedureEditPage from "../procedure/ProcedureEditPage";
import ProcedureAddPage from "../procedure/ProcedureAddPage";
import ProcedurePage from "../procedure/ProcedurePage";
import ZipEditPage from "../zip/ZipEditPage";
import ZipAddPage from "../zip/ZipAddPage";
import ZipPage from "../zip/ZipPage";
import RoleEditPage from "../role/RoleEditPage";
import RoleAddPage from "../role/RoleAddPage";
import RolePage from "../role/RolePage";
import NotFound from "../not_found/NotFound";
import GpPage from "../gp/GpPage";
import AdpPage from "../adp/AdpPage";
import ReportsPage from "../reports/ReportsPage";
import ClientPage from "../client/ClientPage";
import ClientAddPage from "../client/ClientAddPage";
import ClientEditPage from "../client/ClientEditPage";
import ClientNotePage from "../client/clientNotes/ClientNotePage";
import ClientEpisodesPage from "../client/clientEpisodes";
import ClientContractsPage from "../client/clientContracts";

const UserRoute = ({ auth, isMobile, location }) => {
  const isAdmin = auth.maxRole == 9000;

  return (
    <>
      <Header auth={auth} isMobile={isMobile} location={location} />
      <Grid fluid={true}>
        <Switch>
          <Redirect exact from="/app" to="/app/dashboard" />
          <PrivateRoute
            exact
            path="/app/dashboard"
            component={Dashboard}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/accounts"
            component={AccountPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/accounts/add"
            component={AccountAddPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/accounts/:id"
            component={AccountEditPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/accounts/:id/locations"
            component={AccountLocationsPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/accounts/:id/contacts"
            component={AccountContactsPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/accounts/:id/notes"
            component={AccountNotesPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/contacts"
            component={ContactPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/contacts/add"
            component={ContactAddPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/contacts/:id"
            component={ContactEditPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/contracts"
            component={ContractPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/contracts/add"
            component={ContractAddPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/contracts/:id"
            component={ContractEditPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/contracts/:id/lines"
            component={ContractLinePage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/contracts/:id/notes"
            component={ContractNotePage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/contracts/:id/clients"
            component={ContractClientPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/settings/glaccounts"
            component={GlaccountPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/settings/glaccounts/add"
            component={GlaccountAddPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/settings/glaccounts/:id"
            component={GlaccountEditPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/calls"
            component={CallPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/calls/add"
            component={CallAddPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/calls/:id"
            component={CallEditPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/users"
            component={UsersPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/users/add"
            component={UserAddPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            path="/app/users/:id"
            component={UserEditPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/settings/account"
            component={AccountSettings}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/clients"
            component={ClientPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/clients/add"
            component={ClientAddPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/clients/:id"
            component={ClientEditPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/clients/:id/notes"
            component={ClientNotePage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/clients/:id/episodes"
            component={ClientEpisodesPage}
            auth={auth}
            isMobile={isMobile}
          />
          <PrivateRoute
            exact
            path="/app/clients/:id/contracts"
            component={ClientContractsPage}
            auth={auth}
            isMobile={isMobile}
          />
          {!isMobile ? (
            <PrivateRoute
              exact
              path="/app/call-approval"
              component={CallApprovalPage}
              auth={auth}
            />
          ) : (
            <Redirect to="/app/dashboard" />
          )}
          {!isMobile && auth.maxRole > 4000 ? (
            <PrivateRoute
              exact
              path="/app/reports"
              component={ReportsPage}
              auth={auth}
            />
          ) : (
            <Redirect to="/app/dashboard" />
          )}
          {isAdmin && (
            <Switch>
              <PrivateRoute
                exact
                path="/app/settings/divisions"
                component={DivisionPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/divisions/add"
                component={DivisionAddPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/divisions/:divId/departments/:deptId"
                component={DepartmentEditPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/divisions/:id"
                component={DivisionEditPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/roles"
                component={RolePage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/roles/add"
                component={RoleAddPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/roles/:id"
                component={RoleEditPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/zips"
                component={ZipPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/zips/add"
                component={ZipAddPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/zips/:id"
                component={ZipEditPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/procedures"
                component={ProcedurePage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/procedures/add"
                component={ProcedureAddPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/procedures/:id"
                component={ProcedureEditPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/roles"
                component={RolePage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/codes"
                component={CodePage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/codes/add"
                component={CodeAddPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/codes/:id"
                component={CodeEditPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/company"
                component={CompanyPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/chargetypes"
                component={ChargetypePage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/chargetypes/add"
                component={ChargetypeAddPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/chargetypes/:id"
                component={ChargetypeEditPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/gp"
                component={GpPage}
                auth={auth}
                isMobile={isMobile}
              />
              <PrivateRoute
                exact
                path="/app/settings/adp"
                component={AdpPage}
                auth={auth}
                isMobile={isMobile}
              />
              {!isMobile ? (
                <PrivateRoute
                  exact
                  path="/app/invoices"
                  component={InvoicePage}
                  auth={auth}
                />
              ) : (
                <Redirect to="/app/dashboard" />
              )}
              {!isMobile ? (
                <PrivateRoute
                  exact
                  path="/app/invoices/add"
                  component={InvoiceAddPage}
                  auth={auth}
                />
              ) : (
                <Redirect to="/app/dashboard" />
              )}
              {!isMobile ? (
                <PrivateRoute
                  exact
                  path="/app/invoice-approval"
                  component={InvoiceApprovalPage}
                  auth={auth}
                />
              ) : (
                <Redirect to="/app/dashboard" />
              )}
            </Switch>
          )}
          {!isAdmin && <Redirect from="/app/settings" to="/app/dashboard" />}
          <PrivateRoute
            exact
            path="*"
            component={NotFound}
            auth={auth}
            isMobile={isMobile}
          />
        </Switch>
      </Grid>
    </>
  );
};

export default UserRoute;
