import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { bindActionCreators } from "redux";
import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import ClientForm from "./ClientForm";
import * as clientActions from "../../actions/clientActions";
import * as contactActions from "../../actions/contactActions";
import * as modalActions from "../../actions/modalActions";
import * as zipActions from "../../actions/zipActions";
import ClientService from "../../services/clientService";
import Modal from "../common/Modal";
import DuplicateClientNameModal from "./components/DuplicateClientNameModal";

const INITIAL_STATE = {
  status: "true",
  first_name: "",
  last_name: "",
  birth_date: "",
  est_birth_date: "",
  ethnicity: null,
  gender: null,
  email: "",
  primary_phone: "",
  primary_phone_type: null,
  secondary_phone: "",
  secondary_phone_type: null,
  organization: "",
  title: "",
  lead_contacts: [],
  street_address: "",
  street_address2: "",
  city: "",
  county: "",
  state: "",
  zip: "",
  veteran_status: null,
  episodes: [],
  mrc_activity: null,
  mrc_at_activity: null,
  emergency_contact_name: "",
  emergency_contact_relationship: "",
  emergency_contact_phone: "",
  emergency_contact_email: "",
  tap_activity: null,
  shirt_size: null,
  preferred_contact_method: null,
  disability: [],
  referred_by: null,
  contact_type: [],
  military_affiliation: [],
  service_branch: [],
  discharge_nature: [],
  disability_rating: null,
  disability_rating_text: "",
  program: [],
  household_size: null,
  monthly_income: null,
  housing_status: null,
  housing_status_text: "",
  current_benefits: [],
  accommodation_needs: "",
  optional_referred_by: "",
};

const ClientAddPage = ({
  actions,
  codes,
  history,
  clients,
  contacts,
  modal,
  zips,
}) => {
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [duplicateClients, setDuplicateClients] = useState([]);

  useEffect(() => {
    if (!clients || (clients && !clients.length)) {
      actions.loadClients();
    }
  }, [clients]);

  useEffect(() => {
    if (!contacts || (contacts && !contacts.length)) {
      actions.loadContacts();
    }
  }, [contacts]);

  useEffect(() => {
    if (!zips || (zips && !zips.length)) {
      actions.loadZips();
    }
  }, [zips]);

  useEffect(() => {
    if (codes.length && !inputs.state) {
      const defaultState = codes.find(
        (code) =>
          code.entity == "*" && code.field == "state" && code.value == "MA"
      );
      setInputs((prev) => ({
        ...prev,
        state: defaultState._id,
      }));
    }
  }, [codes, inputs.state]);

  const handleChange = (name, value, entity = null) => {
    if (entity) {
      setInputs((prev) => ({
        ...prev,
        [entity]: {
          ...prev[entity],
          [name]: value,
        },
      }));
    } else {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleReferredByChange = (name, value, foundObject) => {
    if (!foundObject) {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
        optional_referred_by: value,
      }));
    } else {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
        optional_referred_by: "",
      }));
    }
  };

  const handleMultipleChange = (newValues) => {
    setInputs((prev) => ({
      ...prev,
      ...newValues,
    }));
  };

  const handleCheckUniqueName = async () => {
    if (inputs.first_name && inputs.last_name) {
      try {
        const result = await ClientService.findClientsByName(
          inputs.first_name,
          inputs.last_name
        );
        setDuplicateClients(result ? result : []);
        if (result) {
          actions.showModalSuccess("duplicateClientNameModal");
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const handleSave = async () => {
    const model = { ...inputs };
    await actions.createClient({
      ...model,
      primary_phone: model.primary_phone.replace(/\s/g, ""),
      secondary_phone: model.secondary_phone.replace(/\s/g, ""),
      lead_contacts:
        Array.isArray(model.lead_contacts) && model.lead_contacts.length > 0
          ? model.lead_contacts.map(({ value }) => value)
          : [],
      current_benefits:
        Array.isArray(model.current_benefits) &&
        model.current_benefits.length > 0
          ? model.current_benefits.map(({ value }) => value)
          : [],
      program:
        Array.isArray(model.program) && model.program.length > 0
          ? model.program.map(({ value }) => value)
          : [],
      discharge_nature:
        Array.isArray(model.discharge_nature) &&
        model.discharge_nature.length > 0
          ? model.discharge_nature.map(({ value }) => value)
          : [],
      service_branch:
        Array.isArray(model.service_branch) && model.service_branch.length > 0
          ? model.service_branch.map(({ value }) => value)
          : [],
      military_affiliation:
        Array.isArray(model.military_affiliation) &&
        model.military_affiliation.length > 0
          ? model.military_affiliation.map(({ value }) => value)
          : [],
      contact_type:
        Array.isArray(model.contact_type) && model.contact_type.length > 0
          ? model.contact_type.map(({ value }) => value)
          : [],
      disability:
        Array.isArray(model.disability) && model.disability.length > 0
          ? model.disability.map(({ value }) => value)
          : [],
      status: model.status === "true",
      emergency_contact: {
        name: model.emergency_contact_name,
        relationship: model.emergency_contact_relationship,
        phone: model.emergency_contact_phone,
        email: model.emergency_contact_email,
      },
      referred_by: inputs.optional_referred_by ? null : model.referred_by,
      optional_referred_by: inputs.optional_referred_by || "",
      disability_rating: inputs.disability_rating?.value || null,
      housing_status: inputs.housing_status?.value || null,
    });
    await actions.loadClients();
  };
  const duplicateClientModal = (
    <DuplicateClientNameModal
      firstName={inputs.first_name}
      lastName={inputs.last_name}
      duplicateClients={duplicateClients}
      onCancel={() => actions.hideModal("duplicateClientNameModal")}
    />
  );

  return (
    <div className="content-wrapper">
      <Row>
        <BreadCrumbs
          breadcrumbs={[{ label: "Clients" }, { label: "Add Client" }]}
        />
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <ClientForm
            clients={clients}
            codes={codes}
            inputs={inputs}
            contacts={contacts}
            zips={zips}
            onCancel={() => history.push("/app/clients")}
            onMultipleChange={handleMultipleChange}
            onChange={handleChange}
            onSubmit={handleSave}
            handleCheckUniqueName={handleCheckUniqueName}
            onReferredByChange={handleReferredByChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Modal
            id="duplicateClientNameModal"
            title={"Duplicate Client Name"}
            body={duplicateClientModal}
            modal={modal}
            close={actions.hideModal}
          />
        </Col>
      </Row>
    </div>
  );
};

ClientAddPage.propTypes = {
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  codes: PropTypes.array,
  history: PropTypes.object.isRequired,
  clients: PropTypes.array,
  contacts: PropTypes.array,
  modal: PropTypes.object,
  zips: PropTypes.array,
};

const mapStatesToProps = (state) => ({
  codes: state.reducers.codes,
  clients: state.reducers.clients,
  contacts: state.reducers.contacts,
  modal: state.reducers.modal,
  zips: state.reducers.zips.zips,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { ...clientActions, ...contactActions, ...modalActions, ...zipActions },
    dispatch
  ),
});

export default connect(mapStatesToProps, mapDispatchToProps)(ClientAddPage);
