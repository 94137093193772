import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Panel } from "react-bootstrap";
import Loading from "../common/Loading";

import orderBy from "lodash/orderBy";
import find from "lodash/find";
import moment from "moment";

const AccountNoteList = ({ account, users }) => {
  let accountNotesView = <Loading active />;
  if (account && users.length) {
    accountNotesView = (
      <Row>
        {account.notes && account.notes.length ? (
          orderBy(account.notes, "modified_on", "desc").map((note) => {
            let timestamp = moment(note.modified_on).format("MM/DD/YYYY hh:mm");

            let author = find(users, { email: note.created_by });

            return (
              <Panel bsStyle="info" key={note.id} className="note-panel">
                <Panel.Heading>
                  <Row>
                    <Col md={6}>
                      <h5>{author.full_name}</h5>
                    </Col>
                    <Col md={6}>
                      <p>
                        <em>{timestamp}</em>
                      </p>
                    </Col>
                  </Row>
                </Panel.Heading>
                <Panel.Body>
                  <blockquote>
                    <p>{note.note}</p>
                  </blockquote>
                </Panel.Body>
              </Panel>
            );
          })
        ) : (
          <h3 className="vertical-center">No notes available</h3>
        )}
      </Row>
    );
  }
  return <div>{accountNotesView}</div>;
};

AccountNoteList.propTypes = {
  account: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
};

export default AccountNoteList;
