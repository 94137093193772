import React, { Component } from "react";
import PropTypes from "prop-types";

import Formsy from "formsy-react";
import { Input } from "formsy-react-components";
import { Row, Col } from "react-bootstrap";
import DropdownSearch from "../common/DropdownSearch";
import PhoneNumberInput from "../common/PhoneNumberInput";

import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import find from "lodash/find";

class ContactForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { canSubmit: false };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  cancel = () => {
    this.props.onCancel();
  };

  submit = (model) => {
    this.props.onSave(model);
  };

  onChange = (field, selected) => {
    this.setState({ [field]: selected.value });
  };

  generateLocationOptions = () => {
    const locationOptions = [];
    const { accounts, contact, codes } = this.props;

    if (!isEmpty(accounts) && !isEmpty(codes)) {
      let parentAccount = accounts.find(
        (account) => account._id == (this.state.account || contact.account)
      );

      if (parentAccount) {
        parentAccount.locations.map((location) => {
          let state = find(this.props.codes, { _id: location.address.state });
          state = state ? state.value : "";
          locationOptions.push({
            value: location._id,
            label: `${location.name} (${location.address.addr1} - ${location.address.city}, ${state})`,
          });
        });
      }
    }
    return locationOptions;
  };

  generateCodeOptions = (entity, field) => {
    const codeOptions = this.props.codes
      .filter((codes) => codes.entity == entity && codes.field == field)
      .map((code) => ({
        value: code._id,
        label: code.label,
      }));

    return orderBy(codeOptions, "label");
  };

  render() {
    let { contact, editing } = this.props;

    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={4} xs={12}>
            <Input
              required
              name="first_name"
              label="First Name"
              placeholder="First Name"
              value={contact.first_name}
              disabled={!editing}
            />
          </Col>
          <Col md={4} xs={12}>
            <Input
              required
              name="last_name"
              label="Last Name"
              placeholder="Last Name"
              value={contact.last_name}
              disabled={!editing}
            />
          </Col>
          <Col md={4} xs={12}>
            <DropdownSearch
              required
              name="account"
              label="Account"
              onChange={this.onChange}
              value={this.state.account || contact.account}
              disabled={!editing}
              data={[
                ...orderBy(this.props.accounts, "name").map((account) => ({
                  value: account._id,
                  label: account.name,
                })),
              ]}
            />
          </Col>
          </Row>
          <Row>
          <Col md={3} xs={12}>
            <DropdownSearch
              required
              name="location"
              label="Location"
              onChange={this.onChange}
              value={this.state.location || contact.location}
              disabled={!editing}
              data={[...this.generateLocationOptions()]}
            />
          </Col>
          <Col md={3} xs={12}>
            <Input
              name="title"
              label="Title"
              placeholder="Title"
              value={contact.title}
              disabled={!editing}
            />
          </Col>
          <Col md={3} xs={12}>
            <DropdownSearch
              required
              name="role"
              label="Role"
              onChange={this.onChange}
              value={this.state.role || contact.role}
              disabled={!editing}
              data={[...this.generateCodeOptions("Contact", "role")]}
            />
          </Col>
          <Col md={3} xs={12}>
            <Input
              name="email"
              label="Email"
              placeholder="Email"
              value={contact.email}
              disabled={!editing}
            />
          </Col>
          </Row>
          <Row>
          <Col md={3} xs={12}>
            <PhoneNumberInput
              name="mobile_phone"
              label="Mobile Phone"
              placeholder="Mobile Phone"
              value={contact.mobile_phone}
              disabled={!editing}
            />
          </Col>
          <Col md={3} xs={12}>
            <PhoneNumberInput
              name="fax"
              label="Fax"
              placeholder="Fax"
              value={contact.fax}
              disabled={!editing}
            />
          </Col>
          <Col md={3} xs={12}>
            <PhoneNumberInput
              name="business_phone"
              label="Business Phone"
              placeholder="Business Phone"
              value={contact.business_phone}
              disabled={!editing}
            />
          </Col>
          <Col md={1} xs={12}>
            <Input
              name="business_phone_ext"
              label="Ext."
              placeholder="Ext."
              value={contact.business_phone_ext}
              disabled={!editing}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12} className="action-footer">
            <button
              className="btn btn-danger"
              type="button"
              onClick={this.cancel}
            >
              Cancel
            </button>
            <input
              className="btn btn-success"
              type="submit"
              disabled={!this.state.canSubmit}
              value={this.props.saving ? "Saving... " : "Save"}
            />
          </Col>
        </Row>
      </Formsy>
    );
  }
}

ContactForm.propTypes = {
  contact: PropTypes.object.isRequired,
  accounts: PropTypes.array,
  codes: PropTypes.array,
  saving: PropTypes.bool.isRequired,
  editing: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ContactForm;
