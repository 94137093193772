import prodStore from "./configureStore.prod";
import devStore from "./configureStore.dev";

let loadedStore = null;

if (import.meta.env.PROD) {
  loadedStore = prodStore;
} else {
  loadedStore = devStore;
}

export const configureStore = loadedStore;
