import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Zip from "./Zip";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import uniqBy from "lodash/uniqBy";
import CustomLoadable from "../common/Loadable";

const ZipList = ({ zips, onClickEdit, onClickDelete }) => {
  let zipsView = <CustomLoadable />;

  let regionFilterOptions = uniqBy(
    zips.map((zip) => {
      if (zip.region && zip.region.label && zip.region.id) {
        return {
          label: zip.region.label,
          value: zip.region.id,
        };
      }
    }),
    "value"
  ).filter((div) => div); // removes any undefined

  const columns = [
    {
      Header: "Zip Code",
      accessor: "zip",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["zip"] }),
      filterAll: true,
    },
    {
      Header: "City",
      accessor: "city",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["city"] }),
      filterAll: true,
    },
    {
      Header: "County",
      accessor: "county",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["county"] }),
      filterAll: true,
    },
    {
      Header: "Region",
      accessor: "region.label",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else {
          return row[filter.id] == filter.value;
        }
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            {regionFilterOptions.map((option) => {
              return (
                <option key={option.value} value={option.label}>
                  {option.label}
                </option>
              );
            })}
          </select>
        );
      },
    },
    {
      Header: "",
      accessor: "_id",
      filterable: false,
      Cell: (row) => (
        <div className="text-center actions">
          <DropdownButton
            title="Actions"
            id={"dropdown"}
            onClick={(e) => e.stopPropagation()}
          >
            <LinkContainer to={`/app/settings/zips/${row.value}`}>
              <MenuItem>
                <FontAwesomeIcon icon={faFolder} />
                View
              </MenuItem>
            </LinkContainer>
            <MenuItem onClick={() => onClickDelete(row.value)}>
              <FontAwesomeIcon icon={faTrashAlt} className="brand-color" />
              Delete
            </MenuItem>
          </DropdownButton>
        </div>
      ),
    },
  ];

  if (zips.length) {
    zipsView = (
      <ReactTable
        className="-highlight"
        filterable
        data={zips}
        loading={false}
        columns={columns}
        defaultPageSize={10}
        defaultSorted={[
          {
            id: "zip",
            asc: true,
          },
        ]}
      />
    );
  }

  return <div>{zipsView}</div>;
};

ZipList.propTypes = {
  zips: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      county: PropTypes.string.isRequired,
      region: PropTypes.object,
    }).isRequired
  ).isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  filter: PropTypes.object,
  onChange: PropTypes.func,
};

export default ZipList;
