import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";

import { Row, Col, Well } from "react-bootstrap";
import Formsy from "formsy-react";
import { Input } from "formsy-react-components";
import DropdownSearch from "../common/DropdownSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import capitalize from "lodash/capitalize";
import orderBy from "lodash/orderBy";
import isObject from "lodash/isObject";
import find from "lodash/find";
import debounce from "lodash/debounce";
import moment from "moment";

class CallProcedureForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
      rows: [{}],
      refNumberRequired: {},
    };

    autoBind(this);
  }

  componentDidMount() {
    let { entries } = this.props;

    if (entries) {
      let rows = [];
      entries.map((entry) => {
        rows.push(entry);
        this.setState({ rows });
      });
    }
  }

  enableButton() {
    this.setState({ canSubmit: true });
  }

  disableButton() {
    this.setState({ canSubmit: false });
  }

  cancel() {
    this.props.onCancel();
  }

  submit(model) {
    this.props.onSave(this.state.rows);
  }

  addRow() {
    let rows = this.state.rows;
    rows.push({});
    this.setState({ rows }, () => {
      if (this.state.rows.length == 5) {
        this.props.onOverflow();
      }
    });
  }

  deleteRow(idx) {
    let rows = this.state.rows;
    rows.splice(idx, 1);
    this.setState({ rows });
  }

  genProcedureOptions() {
    let procedureOptions = [];
    let prodCat = isObject(this.props.product_category)
      ? this.props.product_category._id
      : this.props.product_category;

    let filteredOptions = this.props.contract.lines.filter((line) => {
      let callStart = moment(this.props.startTime)
        .startOf("day")
        .format("MM/DD/YYYY");
      let lineStart = moment
        .utc(line.start_date)
        .startOf("day")
        .format("MM/DD/YYYY");
      let lineEnd = moment
        .utc(line.end_date)
        .startOf("day")
        .format("MM/DD/YYYY");

      return (
        line.product_category &&
        line.product_category._id === prodCat &&
        moment(callStart).isSameOrBefore(lineEnd) &&
        moment(callStart).isSameOrAfter(lineStart)
      );
    });

    filteredOptions.map((procedure) => {
      let label = procedure.item.description;

      let licensesRequired = procedure.item.licenses_required;
      let user = find(this.props.users, { _id: this.props.assignedTo });
      let userLicenses = user.licenses;

      let reason = "";
      let isDisabled = false;
      let activeLicenses = [];
      let missingLicenses = [];
      let expiredLicenses = [];

      //disable procedure with a false status (not contracted yet)
      if (procedure.status == false) {
        isDisabled = true;
      }

      /* If licenses are required for procedure, check if user has those licenses first, 
       then if their licenses that match are expired */
      if (licensesRequired) {
        licensesRequired.map((license) => {
          let userLicense = find(userLicenses, {
            certificationNameCode_shortName: license.label,
          });
          if (!userLicense) {
            missingLicenses.push(license);
          } else if (moment().isAfter(userLicense.expirationDate)) {
            expiredLicenses.push(license);
          } else {
            activeLicenses.push(license);
          }
        });

        if (!activeLicenses.length && expiredLicenses.length) {
          reason = `${expiredLicenses
            .map((license) => license.label)
            .join(",")} expired`;
          isDisabled = true;
        } else if (!activeLicenses.length && missingLicenses.length) {
          reason = `${missingLicenses
            .map((license) => license.label)
            .join(", ")} required`;
          isDisabled = true;
        }
      }

      procedureOptions.push({
        value: procedure._id,
        label: isDisabled ? `${label} (${reason})` : label,
        isDisabled,
      });
    });

    return orderBy(procedureOptions, "label");
  }

  genChargetypesOptions() {
    let chargetypeOptions = [];

    this.props.chargetypes.map((chargetype) => {
      chargetypeOptions.push({
        value: chargetype._id,
        label: chargetype.type,
      });
    });

    let classification = find(this.props.codes, {
      id: this.props.auth.user.class,
    });

    // If employee is classed 'Per Diem', only show billable and non-billable chargetypes
    if (classification && classification.label == "Per Diem") {
      chargetypeOptions = chargetypeOptions.filter(
        (chargetype) =>
          chargetype.label == "Billable" || chargetype.label == "Non-Billable"
      );
    }

    return chargetypeOptions;
  }

  checkContractReferenceNumber(id) {
    const contract = this.props.contract;
    const client = this.props.clients.find((item) => {
      return item._id === id;
    });
    if (contract?.require_client_reference && !client?.reference_number) {
      this.setState({
        refNumberRequired: { ...this.state.refNumberRequired, [id]: true },
      });
    } else {
      this.setState({
        refNumberRequired: { ...this.state.refNumberRequired, [id]: false },
      });
    }
  }

  onChange(field, selected) {
    let rows = this.state.rows;
    let idx = field.split(" ")[1];
    let row = rows[idx];

    if (field.includes("procedure")) {
      let procedure =
        this.props.contract.lines.find(
          (procedure) => procedure._id == selected.value
        ) ||
        this.props.items.find((procedure) => procedure._id == selected.value);

      let isBillable =
        procedure.charge_type.type == "Personal" ||
        procedure.charge_type.type == "Overhead"
          ? false
          : true;

      let rate = procedure.rate;
      let uom = procedure.uom.value;

      row[`procedure`] = selected.value;
      row[`procedure_type`] = procedure.procedure_type;
      row[`charge_type`] = procedure.charge_type._id;
      row[`isBillable`] = isBillable;
      row[`rate`] = rate;
      row[`uom`] = uom;

      rows[idx] = row;
      if (row[`quantity`] || row[`quantity`] === 0) {
        this.handleCalculateQuantities(rows, idx);
      } else {
        this.setState({ rows });
      }
    } else {
      if (field.includes("client")) {
        this.checkContractReferenceNumber(selected.value);
      }
      field = field.split(" ")[0];
      row[`${field}`] = selected.value || selected;
      this.setState({ rows });
    }
  }

  // when procedure is updated, need to recalculate the quntities incase the procedures have different uom's
  handleCalculateQuantities(rows, idx) {
    let row = rows[idx];
    let uom = row.uom;
    let rowQuantity = row[`quantity`];

    if (uom !== "MILE" && uom !== "STUDENTS") {
      let billing_quantity =
        uom == "MIN"
          ? Math.ceil((parseFloat(rowQuantity) || 0) * 60)
          : uom == "FIXED"
            ? 1
            : rowQuantity;
      row[`billing_quantity`] = billing_quantity;

      rows[idx] = row;
      this.setState({ rows });
    } else {
      row[`quantity`] = 0;
      row[`billing_quantity`] = parseFloat(rowQuantity || 0);

      rows[idx] = row;

      this.setState({ rows });
    }
  }

  onChangeQuantity(field, value) {
    value = value || 0;
    let rows = this.state.rows;
    let idx = field.split(" ")[1];
    let row = rows[idx];
    let uom = row.uom;

    if (uom !== "MILE" && uom !== "STUDENTS") {
      if (
        field == `quantity ${idx}` &&
        parseFloat(value) == Math.ceil((parseFloat(value) || 0) / 0.25) * 0.25
      ) {
        let billing_quantity =
          uom == "MIN"
            ? Math.ceil((parseFloat(value) || 0) * 60)
            : uom == "FIXED"
              ? 1
              : value;
        row[`quantity`] = parseFloat(value);
        row[`billing_quantity`] = billing_quantity;
      }
      if (field == `billing_quantity ${idx}`) {
        row[`billing_quantity`] = value;
      }
      if (
        field == `quantity ${idx}` &&
        parseFloat(value) !== Math.ceil((parseFloat(value) || 0) / 0.25) * 0.25
      ) {
        this.setState({
          canSubmit: false,
          error: "Hours on a call must be in increments of 0.25",
        });
      }

      rows[idx] = row;
      if (
        field !== `quantity ${idx}` ||
        (field == `quantity ${idx}` &&
          parseFloat(value) ==
            Math.ceil((parseFloat(value) || 0) / 0.25) * 0.25)
      ) {
        this.setState({ rows: [...rows] }, () => {
          let totalHrs = this.state.rows.reduce((total, row, i) => {
            return total + parseFloat(row[`quantity`]);
          }, 0);

          if (totalHrs > 24) {
            this.setState({
              canSubmit: false,
              error: "Hours on a call cannot exceed 24 hours",
            });
          } else {
            this.setState({
              canSubmit: true,
              error: false,
            });
          }
        });
      }
    } else {
      row[`quantity`] = 0;
      row[`billing_quantity`] = parseFloat(value);

      rows[idx] = row;

      this.setState({ rows }, () => console.warn(this.state));
    }
  }

  render() {
    let { contract, chargetypes, isMobile } = this.props;

    // If contract is track client, show client field
    let TracksClients =
      contract.track_by && contract.track_by.label == "Client";

    let isBillable = (charge_type) => {
      let isBillable = false;
      let chargetype = chargetypes.find(
        (chargetype) => chargetype._id == charge_type
      );

      if (chargetype) {
        isBillable =
          (chargetype.type !== "Personal" || chargetype.type !== "Overhead") &&
          true;
      }

      return isBillable;
    };

    let isBillableNonPayroll = (charge_type) => {
      let isBillable = false;
      let chargetype = chargetypes.find(
        (chargetype) => chargetype._id == charge_type
      );

      if (chargetype) {
        isBillable = chargetype.type == "Billable Non-Payroll" && true;
      }

      return isBillable;
    };

    return (
      <Formsy
        ref="form"
        className="vertical form time-entry"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        {this.state.rows.map((row, idx) => {
          let Billable = isBillable(row.charge_type);
          let BillableNonPayroll = isBillableNonPayroll(row.charge_type);
          let Fixed = row.uom == "FIXED";
          let TrackBillingQuantity = ["MIN", "MILE", "STUDENTS"].includes(
            row.uom
          );

          return (
            <Row key={idx}>
              <Col
                md={
                  Billable && TrackBillingQuantity && !TracksClients
                    ? 5
                    : (Billable && TrackBillingQuantity && TracksClients) ||
                        (Billable && !TrackBillingQuantity && TracksClients)
                      ? 4
                      : !Billable
                        ? 6
                        : 6
                }
                xs={12}
              >
                <DropdownSearch
                  required
                  name={`procedure ${idx}`}
                  label="Procedure"
                  value={row.procedure}
                  data={[...this.genProcedureOptions()]}
                  onChange={this.onChange}
                />
              </Col>
              <Col
                md={
                  (Billable && TrackBillingQuantity && TracksClients) ||
                  (Billable && TrackBillingQuantity && !TracksClients) ||
                  (Billable && !TrackBillingQuantity && TracksClients)
                    ? 3
                    : !isBillable
                      ? 4
                      : 5
                }
                xs={12}
              >
                <Input
                  id="callProcedureDurationInput"
                  required
                  formNoValidate
                  step={0.25}
                  type="number"
                  name={`quantity ${idx}`}
                  label={`Duration (Payroll Hrs)`}
                  disabled={BillableNonPayroll && !Fixed}
                  value={!BillableNonPayroll || Fixed ? row.quantity : 0}
                  onChange={debounce(this.onChangeQuantity, 500)}
                />
              </Col>
              {TrackBillingQuantity && (
                <Col md={TracksClients ? 3 : 3} xs={12}>
                  <Input
                    formNoValidate
                    type="number"
                    step={0.01}
                    name={`billing_quantity ${idx}`}
                    label={`Billing Quantity (${capitalize(row.uom)})`}
                    value={row.billing_quantity}
                    onChange={this.onChangeQuantity}
                  />
                </Col>
              )}
              {TracksClients && Billable && (
                <Col md={4} xs={12}>
                  <DropdownSearch
                    required={!contract.allow_anonymous}
                    name={`client ${idx}`}
                    label="Client"
                    value={row.client}
                    data={orderBy(
                      [
                        ...this.props.clients
                          .filter((client) => client.status)
                          .map((client) => ({
                            value: client._id,
                            label: `${
                              client.last_name ? `${client.last_name}, ` : ""
                            }${client.first_name ? `${client.first_name}` : ""}
                              ${client.city ? ` - ${client.city}` : ""} ${
                                client.birth_date
                                  ? ` - ${moment
                                      .duration(
                                        moment().diff(client.birth_date)
                                      )
                                      .asYears()
                                      .toFixed(1)}yrs`
                                  : ""
                              } ${
                                !client.birth_date && client.est_birth_date
                                  ? ` - ${moment
                                      .duration(
                                        moment().diff(client.est_birth_date)
                                      )
                                      .asYears()
                                      .toFixed(1)}yrs`
                                  : ""
                              }`,
                          })),
                      ],
                      "label"
                    )}
                    onChange={this.onChange}
                  />
                  {this.state.refNumberRequired[row.client] && (
                    <span className="error-message">
                      This contract requires a client reference number. Please
                      add one in the client section
                    </span>
                  )}
                </Col>
              )}
              <Col md={1} xs={12}>
                <button
                  className="btn btn-delete"
                  type="button"
                  onClick={() => this.deleteRow(idx)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </Col>
            </Row>
          );
        })}
        {this.state.error && (
          <Row>
            <Col md={12} xs={12}>
              <Well> {this.state.error} </Well>
            </Col>
          </Row>
        )}
        <Row className="footer">
          <Col md={3} xs={12} className="">
            {this.state.rows.length <= 10 && (
              <button
                className="btn btn-success"
                type="button"
                onClick={this.addRow}
              >
                Add {isMobile && "Procedure"}
              </button>
            )}
          </Col>
          <Col
            md={6}
            mdOffset={3}
            style={{ textAlign: "right" }}
            className="action-footer"
          >
            <button
              className="btn btn-danger"
              type="button"
              onClick={this.cancel}
            >
              Cancel
            </button>
            <input
              className="btn btn-success"
              type="submit"
              disabled={
                !this.state.canSubmit ||
                this.state.error ||
                this.state.rows.find(
                  (row) => this.state.refNumberRequired[row.client]
                )
              }
              value={this.props.saving ? "Saving... " : "Save"}
            />
          </Col>
        </Row>
      </Formsy>
    );
  }
}

CallProcedureForm.propTypes = {
  saving: PropTypes.bool,
  onSave: PropTypes.func,
  contract: PropTypes.object,
  call: PropTypes.object,
  product_category: PropTypes.object,
  items: PropTypes.array,
  accounts: PropTypes.array,
  divisions: PropTypes.array,
  auth: PropTypes.object,
  users: PropTypes.array,
  contracts: PropTypes.array,
  codes: PropTypes.array,
  chargetypes: PropTypes.array,
  onAdd: PropTypes.func,
  onCancel: PropTypes.func,
  editing: PropTypes.bool,
  adding: PropTypes.bool,
  isModal: PropTypes.bool,
  clients: PropTypes.array,
};

CallProcedureForm.defaultProps = {
  call: {
    start_time: "",
    end_time: "",
  },
};

export default CallProcedureForm;
