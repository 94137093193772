/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Panel } from "react-bootstrap";
import Loading from "../../common/Loading";

import orderBy from "lodash/orderBy";
import find from "lodash/find";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faPencilAlt,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";

const ClientNoteList = ({
  notes,
  client,
  filters,
  users,
  onDelete,
  onEdit,
  showSystemNotes,
  onOpenAttachments,
}) => {
  let displayNotes = notes?.length ? [...notes] : [];

  if (showSystemNotes) {
    if (displayNotes?.length)
      displayNotes = displayNotes.filter((note) => note.system_note);
  } else {
    if (displayNotes?.length && filters) {
      displayNotes = displayNotes.filter(
        (note) => note?.division?.description === filters?.division?.label
      );
    }
  }

  let clientNotesView = <Loading active />;
  if (clientNotesView && users && users.length) {
    clientNotesView = (
      <Row>
        {displayNotes && displayNotes?.length ? (
          orderBy(displayNotes, "modified_on", "desc").map((note) => {
            let timestamp = moment(note?.modified_on).format(
              "MM/DD/YYYY hh:mm"
            );
            let author = find(users, { email: note?.created_by });

            return (
              <Panel bsStyle="info" key={note?._id} className="note-panel">
                <Panel.Heading>
                  <Row>
                    <Col md={11}>
                      <h4>
                        {Array.isArray(note.attachments) &&
                        note.attachments.length ? (
                          <FontAwesomeIcon
                            id="client-note-attachment-icon"
                            icon={faPaperclip}
                            onClick={() => onOpenAttachments(note)}
                          />
                        ) : null}
                        {note?.title}
                      </h4>
                    </Col>
                    {note?.created_by !== "system" && (
                      <Col className="flex" md={1}>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="brand-color contractNoteListBtn"
                          onClick={() => onDelete(note?._id, client.id)}
                        />
                        <FontAwesomeIcon
                          icon={faPencilAlt}
                          className="brand-color contractNoteListBtn"
                          onClick={() => onEdit(note)}
                        />
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col md={6}>
                      <h5>
                        {`${author ? author.full_name : ""} - ${
                          (note?.division && note?.division?.description) || ""
                        } 
                    `}
                      </h5>
                    </Col>
                    <Col md={6}>
                      <p>
                        <em>{timestamp}</em>
                      </p>
                    </Col>
                  </Row>
                </Panel.Heading>
                <Panel.Body>
                  <blockquote>
                    <div dangerouslySetInnerHTML={{ __html: note?.note }} />
                  </blockquote>
                </Panel.Body>
              </Panel>
            );
          })
        ) : (
          <h3 className="vertical-center">No notes available</h3>
        )}
      </Row>
    );
  }

  return <div>{clientNotesView}</div>;
};

ClientNoteList.propTypes = {
  users: PropTypes.array.isRequired,
  client: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  notes: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  showSystemNotes: PropTypes.bool.isRequired,
  onOpenAttachments: PropTypes.func,
};

export default ClientNoteList;
