import React from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faTrashAlt,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import uniq from "lodash/uniq";
import map from "lodash/map";
import CustomLoadable from "../common/Loadable";

const CodeList = ({ codes, onClickDelete }) => {
  let codesView = <CustomLoadable />;

  let group = groupBy(codes, "entity");

  const columns = [
    {
      Header: "Field",
      accessor: "field",
      PivotValue: ({ value }) => {
        value = value.replace(/[_-]/g, " ");
        return <span style={{ textTransform: "capitalize" }}>{value}</span>;
      },
    },
    {
      Header: "Value",
      accessor: "value",
    },
    {
      Header: "Label",
      accessor: "label",
    },
    {
      Header: "",
      accessor: "_id",
      Aggregated: ({ value }) => {
        return <span />;
      },
      Cell: (row) => {
        if (row.original && row.original.read_only == true) {
          return <FontAwesomeIcon className="brand-color" icon={faLock} />;
        } else {
          return (
            <div className="text-center actions">
              <DropdownButton
                title="Actions"
                id={"dropdown"}
                onClick={(e) => e.stopPropagation()}
              >
                <LinkContainer to={`/app/settings/codes/${row.value}`}>
                  <MenuItem>
                    <FontAwesomeIcon icon={faFolder} />
                    View
                  </MenuItem>
                </LinkContainer>
                <MenuItem onClick={() => onClickDelete(row.value)}>
                  <FontAwesomeIcon icon={faTrashAlt} className="brand-color" />
                  Delete
                </MenuItem>
              </DropdownButton>
            </div>
          );
        }
      },
    },
  ];

  if (codes.length > 0) {
    codesView = Object.keys(group)
      .sort()
      .map((entity, i) => {
        return (
          <div key={i}>
            <h2>{entity}</h2>
            <ReactTable
              className="-highlight margin-bottom"
              data={orderBy(group[entity], "label")}
              loading={false}
              showPagination={false}
              columns={columns}
              pageSize={uniq(map(group[entity], "field")).length}
              pivotBy={["field"]}
              defaultSorted={[
                {
                  id: "field",
                  asc: true,
                },
              ]}
            />
          </div>
        );
      });
  }

  return <div>{codesView}</div>;
};

CodeList.propTypes = {
  codes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      entity: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  value: PropTypes.string,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export default CodeList;
