export default function getInitialState() {
  return {
    start_time: "",
    end_time: "",
    assigned_to: "",
    contract: "",
    product_category: "",
    stage: "",
    billing_quantity: "",
    quantity: "",
    entries: [],
    division: "",
    parent_account: "",
    tif_in: "",
    tif_out: "",
    invoiced_by: "",
    notes: "",
  };
}
