import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Row, Col, Alert } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import BreadCrumbs from "../../common/BreadCrumbs";
import ClientNoteForm from "./ClientNoteForm";
import ClientNoteAttachments from "./ClientNoteAttachments";
import * as clientActions from "../../../actions/clientActions";
import * as modalActions from "../../../actions/modalActions";
import * as divisionActions from "../../../actions/divisionActions";
import * as userActions from "../../../actions/userActions";
import ClientNoteList from "./ClientNoteList";
import AddButton from "../../common/AddButton";
import Modal from "../../common/Modal";
import { isEmpty, find, orderBy } from "lodash/";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import FilterComponent from "../../common/FilterComponent";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

export const ClientNotePage = ({
  auth,
  actions,
  codes,
  match,
  client,
  users,
  modal,
  divisions,
}) => {
  const [notes, setNotes] = useState(null);
  const [editNote, setEditNote] = useState(null);
  const [filters, setFilters] = useState({ division: {} });
  const [attachments, setAttachments] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [divisionOptions, setDivisionOptions] = useState({
    default: null,
    options: [],
  });
  const [showSystemNotes, setShowSystemNotes] = useState(false);

  //users division determines if user can view/update information from other divisions or just their own
  const [filterPermissions, setFilterPermissions] = useState(false);

  useEffect(() => {
    if (match.params.id && !client) {
      actions.getClient(match.params.id);
    }
  }, [match, client]);

  useEffect(() => {
    if (!users.length) {
      actions.loadUsers(true);
    }
  }, [users]);

  useEffect(() => {
    if (client && client?.notes) {
      setNotes(client.notes);
    }
  }, [client]);

  useEffect(() => {
    if (isEmpty(divisions)) {
      actions.loadDivisions();
    }

    const usersDivision = find(divisions, {
      _id: auth.user.division,
    });

    const divisionOptions = divisions.map((item) => ({
      value: item._id,
      label: item.description,
    }));

    setFilterPermissions(usersDivision?.display_client_notes);

    setDivisionOptions({
      default: { label: usersDivision?.description, value: usersDivision?._id },
      options: orderBy(divisionOptions, "label"),
    });

    setFilters({
      division: {
        label: usersDivision?.description,
        value: usersDivision?._id,
      },
    });
  }, [divisions]);

  const onClickAdd = () => {
    actions.getClient(client._id, "addClientNoteModal");
  };

  const handleDelete = async (noteId, clientId) => {
    await actions.deleteNote(noteId, clientId);
    await actions.getClient(match.params.id);
  };

  const handleEdit = (note) => {
    setEditNote(note);
    actions.getClient(match.params.id, "editClientNoteModal");
  };

  const handleSave = async (note, client) => {
    await actions.addNote(client, note, true, attachments);
    await actions.getClient(client._id);
    await actions.hideModal();
  };

  const handleSaveEdit = async (note, client, noteId) => {
    let newNote = {
      ...note,
      id: noteId,
    };

    await actions.updateNote(client, newNote);
    await actions.getClient(client._id);
    actions.hideModal();
  };

  const handleEditUploadAttachment = async (client, note, files) => {
    await actions.addNoteAttachment(client, note, files);
    await actions.getClient(client._id);
  };

  const handleAddUploadAttachment = async (file) => {
    const attachment = await actions.addNoteAttachmentToNewNote(file);
    setAttachments((prev) => [
      ...prev,
      {
        path: attachment.fileName,
      },
    ]);
  };

  const handleCancel = () => {
    actions.hideModal();
  };

  const handleChangeFilters = (selectedOptions, field) => {
    setFilters((prev) => ({ ...prev, [field]: selectedOptions }));
    setShowSystemNotes(false);
  };

  const handleToggle = () => {
    setShowSystemNotes(!showSystemNotes);
    resetFilters();
    setFilterPermissions(showSystemNotes);
  };

  const resetFilters = () => {
    setFilters((prev) => ({
      ...prev,
      division: {
        label: divisionOptions.default.label,
        value: divisionOptions.default.value,
      },
    }));
  };

  const handleOpenAttachments = (note) => {
    setEditNote(note);
    actions.showModal("clientNoteAttachmentsModal");
  };

  const handleRemoveAttachmentOnAdding = (index) => {
    setAttachments((prev) => prev.filter((att, ind) => ind !== index));
  };

  const handleRemoveAttachmentOnEditing = async (client, note, index) => {
    const attachment = note.attachments.find((_, i) => i === index);
    await actions.deleteAttachmentNote(client, note, attachment);
    await actions.getClient(client._id);
  };

  let noteForm = (
    <ClientNoteForm
      client={client}
      divisionsDropdown={divisionOptions}
      codes={codes}
      note={{}}
      onSave={handleSave}
      onCancel={handleCancel}
      uploadAddAttachment={handleAddUploadAttachment}
      attachments={attachments}
      onRemoveAttachmentOnAdding={handleRemoveAttachmentOnAdding}
      adding
    />
  );

  let editNoteForm = (
    <ClientNoteForm
      client={client}
      divisionsDropdown={divisionOptions}
      codes={codes}
      note={editNote}
      attachments={editNote?.attachments || []}
      onSave={handleSaveEdit}
      onCancel={handleCancel}
      uploadEditAttachment={handleEditUploadAttachment}
      onRemoveAttachmentOnEditing={handleRemoveAttachmentOnEditing}
    />
  );

  let attachmentsModal = (
    <ClientNoteAttachments
      client={client}
      codes={codes}
      note={editNote}
      onCancel={handleCancel}
      onDownload={actions.downloadNoteAttachment}
    />
  );

  return (
    <div className="content-wrapper">
      <Alert className="alert alert-warning">
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          style={{ color: "#f8d07a" }}
        />
        The disclaimer note
      </Alert>
      <Row>
        <BreadCrumbs
          breadcrumbs={[
            { label: "Client Notes" },
            {
              label: client ? `${client.last_name}, ${client.first_name}` : "",
            },
          ]}
        />
        <Col md={12} className="text-right">
          <button
            className="btn btn-success btn-sm filter"
            type="button"
            onClick={() => setOpenModal(!openModal)}
          >
            <FontAwesomeIcon icon={faFilter} className="icon" />
          </button>
          <AddButton onClickAdd={onClickAdd} auth={auth} />
        </Col>
        <FilterComponent
          auth={auth}
          divisions={divisionOptions?.options}
          users={users}
          entity="Client"
          open={openModal}
          filters={filters}
          onChangeFilters={handleChangeFilters}
          resetFilters={resetFilters}
          noDate
          isClientNote
          checkBox
          onToggle={handleToggle}
          showSystemNotes={showSystemNotes}
          isDisabled={!filterPermissions}
        />
      </Row>
      <Row>
        <Col md={12}>
          <Modal
            id="addClientNoteModal"
            title={"Add Client Note"}
            body={noteForm}
            modal={modal}
            close={actions.hideModal}
          />
        </Col>
        <Col md={12}>
          <Modal
            id="editClientNoteModal"
            title={"Edit Client Note"}
            body={editNoteForm}
            modal={modal}
            close={actions.hideModal}
          />
        </Col>
        <Col md={12}>
          <Modal
            id="clientNoteAttachmentsModal"
            title={"Attachments"}
            body={attachmentsModal}
            modal={modal}
            close={actions.hideModal}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ClientNoteList
            client={client}
            filters={filters}
            notes={notes}
            users={users}
            onDelete={handleDelete}
            onEdit={handleEdit}
            onOpenAttachments={handleOpenAttachments}
            showSystemNotes={showSystemNotes}
          />
        </Col>
      </Row>
    </div>
  );
};

ClientNotePage.propTypes = {
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  codes: PropTypes.array,
  match: PropTypes.object,
  client: PropTypes.object,
  modal: PropTypes.object,
  users: PropTypes.array,
  divisions: PropTypes.array,
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    client: state.reducers.client,
    divisions: state.reducers.divisions,
    users: state.reducers.users,
    codes: state.reducers.codes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...clientActions,
        ...userActions,
        ...modalActions,
        ...divisionActions,
      },
      dispatch
    ),
  };
}
export default connect(mapStatesToProps, mapDispatchToProps)(ClientNotePage);
