import React from "react";
import PropTypes from "prop-types";
import DropModal from "reboron/DropModal";
import autoBind from "react-autobind";

class Modal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { overflow: false };
    autoBind(this);
  }

  componentWillUpdate(nextProps) {
    const { id, modal } = nextProps;

    if (modal !== this.props.modal) {
      if (id === modal.id && modal.show) {
        this.refs.modal.show();
      } else {
        this.refs.modal.hide();
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.overflow !== this.props.overflow) {
      this.setState({ overflow: nextProps.overflow }, () =>
        console.log(this.state.overflow)
      );
    }
  }

  close() {
    const { close, id } = this.props;
    close(id);
  }

  render() {
    const { id, size, title, body, footer } = this.props;
    const { overflow } = this.state;

    let width = size == "lg" ? "70%" : "inherit";
    let modalStyle = {
      width,
    };

    return (
      <div id={id}>
        <DropModal ref="modal" modalStyle={modalStyle} className="view-modal">
          <div className={`modal-${size}`}>
            <div className="modal-header">
              <button type="button" className="close" onClick={this.close}>
                ×
              </button>
              <h2 className="modal-title">{title}</h2>
            </div>
            <div className={`modal-body ${overflow ? "overflow" : ""}`}>
              {body}
            </div>
            <div className="modal-footer">
              {/* <button
                className="btn btn-primary "
                type="button"
                onClick={this.close}
              >
                Close
              </button>
              {footer} */}
            </div>
          </div>
        </DropModal>
      </div>
    );
  }
}

Modal.defaultProps = {
  size: "md",
};

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.element,
  footer: PropTypes.element,
  close: PropTypes.func,
  modal: PropTypes.object,
};

export default Modal;
