import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";

import { Row, Col, Well } from "react-bootstrap";
import Formsy from "formsy-react";

import { find, orderBy, isObject } from "lodash";
import moment from "moment";

import CallProcedureFormItem from "./CallProcedureFormItem";

const INITIAL_STATE = {
  canSubmit: false,
  rows: [{}],
  refNumberRequired: {},
};

const CallProcedureForm = ({
  chargetypes,
  clients,
  contract,
  entries,
  onCancel,
  onOverflow,
  onSave,
  product_category,
  startTime,
  user,
}) => {
  const formRef = useRef();
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    if (entries && entries.length) {
      setState((prev) => ({ ...prev, rows: entries }));
    } else {
      setState((prev) => ({ ...prev, rows: [{}] }));
    }
  }, [entries]);

  const TracksClients = useMemo(
    () => contract.track_by && contract.track_by.label == "Client",
    [contract]
  );

  const generateProcedureOptions = useCallback(() => {
    let prodCat = isObject(product_category)
      ? product_category._id
      : product_category;

    let filteredOptions = contract.lines.filter((line) => {
      let callStart = moment(startTime).startOf("day").format("MM/DD/YYYY");
      let lineStart = moment
        .utc(line.start_date)
        .startOf("day")
        .format("MM/DD/YYYY");
      let lineEnd = moment
        .utc(line.end_date)
        .startOf("day")
        .format("MM/DD/YYYY");

      return (
        line.product_category &&
        line.product_category._id === prodCat &&
        moment(callStart).isSameOrBefore(lineEnd) &&
        moment(callStart).isSameOrAfter(lineStart)
      );
    });
    const procedureOpts = filteredOptions.map((procedure) => {
      let label = procedure.item.description;

      let licensesRequired = procedure.item.licenses_required;
      //   let user = find(users, { _id: assignedTo });
      let userLicenses = user.licenses;

      let reason = "";
      let isDisabled = false;
      let activeLicenses = [];
      let missingLicenses = [];
      let expiredLicenses = [];

      //disable procedure with a false status (not contracted yet)
      if (procedure.status == false) {
        isDisabled = true;
      }

      /* If licenses are required for procedure, check if user has those licenses first, 
       then if their licenses that match are expired */
      if (licensesRequired) {
        licensesRequired.map((license) => {
          let userLicense = find(userLicenses, {
            certificationNameCode_shortName: license.label,
          });
          if (!userLicense) {
            missingLicenses.push(license);
          } else if (moment().isAfter(userLicense.expirationDate)) {
            expiredLicenses.push(license);
          } else {
            activeLicenses.push(license);
          }
        });

        if (!activeLicenses.length && expiredLicenses.length) {
          reason = `${expiredLicenses
            .map((license) => license.label)
            .join(",")} expired`;
          isDisabled = true;
        } else if (!activeLicenses.length && missingLicenses.length) {
          reason = `${missingLicenses
            .map((license) => license.label)
            .join(", ")} required`;
          isDisabled = true;
        }
      }

      return {
        value: procedure._id,
        label: isDisabled ? `${label} (${reason})` : label,
        isDisabled,
      };
    });
    return orderBy(procedureOpts, "label");
  }, [contract]);

  const procedureOptions = useMemo(
    () => [...generateProcedureOptions()],
    [generateProcedureOptions]
  );

  const enableButton = () => {
    setState((prev) => ({ ...prev, canSubmit: true }));
  };

  const disableButton = () => {
    setState((prev) => ({ ...prev, canSubmit: false }));
  };

  const cancel = () => {
    onCancel();
  };

  const submit = () => {
    onSave(state.rows);
  };

  const addRow = () => {
    let rows = state.rows;
    rows.push({});
    setState((prev) => ({ ...prev, rows }));
    if (state.rows.length == 5) {
      onOverflow();
    }
  };

  const deleteRow = (idx) => {
    let rows = state.rows;
    rows.splice(idx, 1);
    setState((prev) => ({ ...prev, rows }));
  };

  const checkContractReferenceNumber = (id) => {
    const c = contract;
    const client = clients.find((item) => {
      return item._id === id;
    });
    if (c?.require_client_reference && !client?.reference_number) {
      setState((prev) => ({
        ...prev,
        refNumberRequired: { ...prev.refNumberRequired, [id]: true },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        refNumberRequired: { ...prev.refNumberRequired, [id]: false },
      }));
    }
  };

  const onChange = (field, selected) => {
    let rows = state.rows;
    let idx = field.split(" ")[1];
    let row = rows[idx];

    if (field.includes("procedure")) {
      let procedure = contract.lines.find(
        (procedure) => procedure._id == selected.value
      );

      let isBillable =
        procedure.charge_type.type == "Personal" ||
        procedure.charge_type.type == "Overhead"
          ? false
          : true;

      let rate = procedure.rate;
      let uom = procedure.uom.value;

      row[`procedure`] = selected.value;
      row[`procedure_type`] = procedure.procedure_type;
      row[`charge_type`] = procedure.charge_type._id;
      row[`isBillable`] = isBillable;
      row[`rate`] = rate;
      row[`uom`] = uom;

      rows[idx] = row;
      if (row[`quantity`] || row[`quantity`] === 0) {
        handleCalculateQuantities(rows, idx);
      } else {
        setState((prev) => ({ ...prev, rows }));
      }
    } else {
      if (field.includes("client")) {
        checkContractReferenceNumber(selected.value);
      }
      field = field.split(" ")[0];
      row[`${field}`] = selected.value || selected;
      setState((prev) => ({ ...prev, rows }));
    }
  };

  // when procedure is updated, need to recalculate the quntities incase the procedures have different uom's
  const handleCalculateQuantities = (rows, idx) => {
    let row = rows[idx];
    let uom = row.uom;
    let rowQuantity = row[`quantity`];

    if (uom !== "MILE" && uom !== "STUDENTS") {
      let billing_quantity =
        uom == "MIN"
          ? Math.ceil((parseFloat(rowQuantity) || 0) * 60)
          : uom == "FIXED"
            ? 1
            : rowQuantity;
      row[`billing_quantity`] = billing_quantity;

      rows[idx] = row;
      setState((prev) => ({ ...prev, rows }));
    } else {
      row[`quantity`] = 0;
      row[`billing_quantity`] = parseFloat(rowQuantity || 0);

      rows[idx] = row;

      setState((prev) => ({ ...prev, rows }));
    }
  };

  const onChangeQuantity = (field, value) => {
    value = value || 0;
    let rows = state.rows;
    let idx = field.split(" ")[1];
    let row = rows[idx];
    let uom = row.uom;

    if (uom !== "MILE" && uom !== "STUDENTS") {
      if (
        field == `quantity ${idx}` &&
        parseFloat(value) == Math.ceil((parseFloat(value) || 0) / 0.25) * 0.25
      ) {
        let billing_quantity =
          uom == "MIN"
            ? Math.ceil((parseFloat(value) || 0) * 60)
            : uom == "FIXED"
              ? 1
              : value;
        row[`quantity`] = parseFloat(value);
        row[`billing_quantity`] = billing_quantity;
      }
      if (field == `billing_quantity ${idx}`) {
        row[`billing_quantity`] = value;
      }
      if (
        field == `quantity ${idx}` &&
        parseFloat(value) !== Math.ceil((parseFloat(value) || 0) / 0.25) * 0.25
      ) {
        setState((prev) => ({
          ...prev,
          canSubmit: false,
          error: "Hours on a call must be in increments of 0.25",
        }));
      }

      rows[idx] = row;
      if (
        field !== `quantity ${idx}` ||
        (field == `quantity ${idx}` &&
          parseFloat(value) ==
            Math.ceil((parseFloat(value) || 0) / 0.25) * 0.25)
      ) {
        let totalHrs = state.rows.reduce((total, row, i) => {
          return total + parseFloat(row[`quantity`]);
        }, 0);

        if (totalHrs > 24) {
          setState((prev) => ({
            ...prev,
            rows,
            canSubmit: false,
            error: "Hours on a call cannot exceed 24 hours",
          }));
        } else {
          setState((prev) => ({
            ...prev,
            rows,
            canSubmit: true,
            error: false,
          }));
        }
      }
    } else {
      row[`quantity`] = 0;
      row[`billing_quantity`] = parseFloat(value);

      rows[idx] = row;

      setState((prev) => ({ ...prev, rows }));
    }
  };

  return (
    <Formsy
      ref={formRef}
      className="vertical form time-entry"
      onValidSubmit={submit}
      onValid={enableButton}
      onInvalid={disableButton}
      style={{ minWidth: "60vw" }}
    >
      {state.rows.map((row, idx) => (
        <CallProcedureFormItem
          key={idx}
          idx={idx}
          row={row}
          chargetypes={chargetypes}
          tracksClients={TracksClients}
          procedureOptions={procedureOptions}
          onChange={onChange}
          onChangeQuantity={onChangeQuantity}
          allowAnonymous={contract.allow_anonymous}
          refNumberRequired={state.refNumberRequired[row.client]}
          deleteRow={deleteRow}
        />
      ))}
      {state.error && (
        <Row>
          <Col md={12} xs={12}>
            <Well> {state.error} </Well>
          </Col>
        </Row>
      )}
      <Row className="footer">
        <Col md={3} xs={12} className="">
          {state.rows.length <= 10 && (
            <button className="btn btn-success" type="button" onClick={addRow}>
              Add
            </button>
          )}
        </Col>
        <Col
          md={6}
          mdOffset={3}
          style={{ textAlign: "right" }}
          className="action-footer"
        >
          <button className="btn btn-danger" type="button" onClick={cancel}>
            Cancel
          </button>
          <input
            className="btn btn-success"
            type="submit"
            disabled={
              !state.canSubmit ||
              state.error ||
              state.rows.find((row) => state.refNumberRequired[row.client]) ||
              (TracksClients &&
                !contract.allow_anonymous &&
                state.rows.find((row) => !row.client))
            }
            value="Save"
          />
        </Col>
      </Row>
    </Formsy>
  );
};

CallProcedureForm.propTypes = {
  onSave: PropTypes.func,
  contract: PropTypes.object,
  product_category: PropTypes.object,
  user: PropTypes.object,
  chargetypes: PropTypes.array,
  onCancel: PropTypes.func,
  clients: PropTypes.array,
  entries: PropTypes.array,
  onOverflow: PropTypes.func,
  startTime: PropTypes.string,
};

CallProcedureForm.defaultProps = {};

export default CallProcedureForm;
