import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as divisionActions from "../../actions/divisionActions";

import * as labelActions from "../../actions/labelActions";
import { Row, Col } from "react-bootstrap";

import DynamicHeader from "../common/DynamicHeader";
import DynamicForm from "../common/DynamicForm";
import find from "lodash/find";

class DepartmentEditPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      editing: false,
    };

    autoBind(this);

    props.actions.getDivision(props.match.params.divId);
  }

  handleSaveDept(department) {
    let division = {
      id: department.division,
    };

    let data = {
      id: this.props.match.params.deptId,
      ...department,
    };

    console.log("division", division);
    console.log("data", data);
    this.props.actions.updateDepartment(division, data);
  }

  handleEdit() {
    this.setState({
      editing: !this.state.editing,
    });
  }

  handleCancel() {
    this.props.history.goBack();
  }

  render() {
    let department = find(this.props.division.departments, {
      _id: this.props.match.params.deptId,
    });
    return (
      <div className="content-wrapper">
        {department && (
          <div>
            <Row>
              <Col md={12}>
                <h1>{department.description}</h1>
              </Col>
              <Col md={9}>
                <h3>
                  <DynamicHeader entity="Department" /> Info
                </h3>
              </Col>
              <Col md={3}>
                <button
                  className="btn btn-warning btn-sm right"
                  onClick={this.handleEdit}
                >
                  EDIT
                </button>
              </Col>
            </Row>
            <Row className="mt">
              <Col md={12}>
                {this.props.division && (
                  <DynamicForm
                    fieldData={find(this.props.labels, {
                      entity: "Department",
                    })}
                    onCancel={this.handleCancel}
                    onSave={this.handleSaveDept}
                    saving={this.props.savingDivision}
                    entity={"Department"}
                    editing={this.state.editing}
                    adding={false}
                  />
                )}
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

DepartmentEditPage.propTypes = {
  actions: PropTypes.object.isRequired,

  savingDivision: PropTypes.bool,
  department: PropTypes.object,
  division: PropTypes.object,
  params: PropTypes.object,
  labels: PropTypes.array,
};

DepartmentEditPage.contextTypes = {
  router: PropTypes.object,
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,

    savingDivision: state.reducers.savingDivision,
    division: state.reducers.division,
    labels: state.reducers.labels.labels,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...divisionActions, ...labelActions },
      dispatch
    ),
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(DepartmentEditPage);
