import { push } from "react-router-redux";
import * as types from "./actionTypes";
import { showModalSuccess, hideModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import contractService from "../services/contractService";

export function loadContractSuccess(contracts) {
  return {
    type: types.LOAD_CONTRACT_SUCCESS,
    contracts,
  };
}

export function getContractSuccess(contract) {
  return {
    type: types.GET_CONTRACT_SUCCESS,
    contract,
  };
}

export function savingContract(status = true) {
  return {
    type: types.SAVING_CONTRACT,
    savingContract: status,
  };
}

export function createContractSuccess(contract) {
  return {
    type: types.CREATE_CONTRACT_SUCCESS,
    contract,
  };
}

export function updateContractSuccess(contract) {
  return {
    type: types.UPDATE_CONTRACT_SUCCESS,
    contract,
  };
}

export function requestContractId(contractId) {
  return (dispatch) => {
    dispatch({
      type: types.REQUEST_CONTRACT_ID,
      contractToDelete: contractId,
    });
    dispatch(showModalSuccess("contractDeleteModal"));
  };
}

export function requestClientId(clientId) {
  return (dispatch) => {
    dispatch({
      type: types.REQUEST_CLIENT_ID,
      clientToDelete: clientId,
    });
    dispatch(showModalSuccess("contractDeleteModal"));
  };
}

export function deleteContractSuccess(contractId) {
  return {
    type: types.DELETE_CONTRACT_SUCCESS,
    contractId,
  };
}

export function loadContracts() {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return contractService
      .loadContracts()
      .then((data) => dispatch(loadContractSuccess(data)))
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function loadDropdownContracts() {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return contractService
      .loadDropdownContracts()
      .then((data) => dispatch(loadContractSuccess(data)))
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function getContract(
  id,
  showCONTRACTDetails = false,
  editCONTRACTDetails = false
) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return contractService
      .getContract(id)
      .then((contract) => {
        dispatch(getContractSuccess(contract));
        if (showCONTRACTDetails) {
          dispatch(showModalSuccess("contractDetailsModal"));
        }
        if (editCONTRACTDetails) {
          dispatch(showModalSuccess("editContractDetailsModal"));
        }
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function createContract(contract) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContract());
    return contractService
      .createContract(contract)
      .then((createdContract) => {
        dispatch(createContractSuccess(createdContract));
        dispatch(savingContract(false));
        dispatch(showAlertSuccess("Contract created successfully", "success"));
        dispatch(push("/app/contracts"));
      })
      .catch((error) => {
        dispatch(savingContract(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateContract(contract) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContract());
    return contractService
      .updateContract(contract)
      .then((updatedContract) => {
        dispatch(updateContractSuccess(updatedContract));
        dispatch(savingContract(false));
        dispatch(showAlertSuccess("Contract updated successfully", "success"));
        dispatch(push("/app/contracts"));
      })
      .catch((error) => {
        dispatch(savingContract(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function addVersion(contract, files) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContract());
    if (files.length == 1) {
      return contractService
        .uploadFile(contract, files[0])
        .then((updatedContract) => {
          dispatch(updateContractSuccess(updatedContract));
          dispatch(savingContract(false));
          dispatch(showAlertSuccess("Version added successfully", "success"));
          dispatch(push(`/app/contracts/${contract._id}`));
        })
        .catch((error) => {
          dispatch(savingContract(false));
          dispatch(showAlertSuccess(error.description, "error"));
        });
    } else {
      dispatch(savingContract(false));
      dispatch(showAlertSuccess("Upload 1 File at a Time", "error"));
    }
  };
}

export function downloadVersion(contract, version) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return contractService
      .downloadVersion(contract, version)
      .then((updatedContract) => {
        dispatch(
          showAlertSuccess("Version downloaded successfully", "success")
        );
      })
      .catch((error) => {
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateVersion(contract, version) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContract());
    return contractService
      .updateLocation(contract, version)
      .then((updatedContract) => {
        dispatch(updateContractSuccess(updatedContract));
        dispatch(savingContract(false));
        dispatch(showAlertSuccess("Version updated successfully", "success"));
        dispatch(push("/app/contracts"));
      })
      .catch((error) => {
        dispatch(savingContract(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteVersion(contract, version) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContract());
    return contractService
      .deleteVersion(contract, version)
      .then((updatedContract) => {
        dispatch(updateContractSuccess(updatedContract));
        dispatch(savingContract(false));
        dispatch(showAlertSuccess("Version deleted successfully", "success"));
        dispatch(push("/app/contracts"));
      })
      .catch((error) => {
        dispatch(savingContract(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function addNote(contract, note) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContract());
    return contractService
      .createNote(contract, note)
      .then((updatedContract) => {
        dispatch(updateContractSuccess(updatedContract));
        dispatch(savingContract(false));
        dispatch(hideModalSuccess("contractNoteModal"));
        dispatch(showAlertSuccess("Note added successfully", "success"));
      })
      .catch((error) => {
        dispatch(savingContract(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateNote(contract, note) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContract());
    return contractService
      .updateNote(contract, note)
      .then((updatedContract) => {
        dispatch(updateContractSuccess(updatedContract));
        dispatch(savingContract(false));
        dispatch(showAlertSuccess("Note updated successfully", "success"));
        dispatch(push(`/app/contracts/${contract._id}/notes`));
      })
      .catch((error) => {
        dispatch(savingContract(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteNote(contract, note) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContract());
    return contractService
      .deleteNote(contract, note)
      .then((updatedContract) => {
        dispatch(updateContractSuccess(updatedContract));
        dispatch(savingContract(false));
        dispatch(showAlertSuccess("Note deleted successfully", "success"));
        dispatch(push(`/app/contracts/${contract._id}/notes`));
      })
      .catch((error) => {
        dispatch(savingContract(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function addLine(contract, line) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContract());
    return contractService
      .createLine(contract, line)
      .then((updatedContract) => {
        dispatch(updateContractSuccess(updatedContract));
        dispatch(savingContract(false));
        dispatch(hideModalSuccess("contractDetailsModal"));
        dispatch(showAlertSuccess("Procedure added successfully", "success"));
        dispatch(push(`/app/contracts/${contract._id}/lines`));
      })
      .catch((error) => {
        dispatch(savingContract(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateLine(contract, line) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContract());
    return contractService
      .updateLine(contract, line)
      .then((updatedContract) => {
        dispatch(updateContractSuccess(updatedContract));
        dispatch(savingContract(false));
        dispatch(showAlertSuccess("Procedure updated successfully", "success"));
        dispatch(push(`/app/contracts/${contract._id}/lines`));
      })
      .catch((error) => {
        dispatch(savingContract(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateMetrics(contract, line, data) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContract());
    return contractService
      .updateLine(contract, line, data)
      .then((updatedContract) => {
        dispatch(updateContractSuccess(updatedContract));
        dispatch(savingContract(false));
        dispatch(showAlertSuccess("Metrics updated successfully", "success"));
      })
      .catch((error) => {
        dispatch(savingContract(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteLine(contract, line) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContract());
    return contractService
      .deleteLine(contract, line)
      .then((updatedContract) => {
        dispatch(updateContractSuccess(updatedContract));
        dispatch(savingContract(false));
        dispatch(showAlertSuccess("Procedure deleted successfully", "success"));
        dispatch(push(`/app/contracts/${contract._id}/lines`));
      })
      .catch((error) => {
        dispatch(savingContract(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteContract(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return contractService
      .deleteContract(id)
      .then(() => {
        dispatch(deleteContractSuccess(id));
        dispatch(showAlertSuccess("Contract deleted successfully", "success"));
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function addClient(contract, client) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContract());
    return contractService
      .createClient(contract, client)
      .then((updatedContract) => {
        dispatch(updateContractSuccess(updatedContract));
        dispatch(savingContract(false));
        dispatch(hideModalSuccess("contractDetailsModal"));
        dispatch(showAlertSuccess("Client added successfully", "success"));
      })
      .catch((error) => {
        dispatch(savingContract(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateContractClient(contract, client) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContract());
    return contractService
      .updateContractClient(contract, client)
      .then((updatedContract) => {
        dispatch(updateContractSuccess(updatedContract));
        dispatch(savingContract(false));
        dispatch(showAlertSuccess("Client updated successfully", "success"));
      })
      .catch((error) => {
        dispatch(savingContract(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteContractClient(contract, client) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContract());
    return contractService
      .deleteContractClient(contract, client)
      .then((updatedContract) => {
        dispatch(updateContractSuccess(updatedContract));
        dispatch(savingContract(false));
        dispatch(showAlertSuccess("Client deleted successfully", "success"));
      })
      .catch((error) => {
        dispatch(savingContract(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}
