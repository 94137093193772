import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import DropdownSearch from "../../../../common/DropdownSearchWithoutFormsy";
import generateUserOptions from "../../utils/generateUserOptions";

import { Col } from "react-bootstrap";
import FieldGroup from "../../../../common/FieldGroup";

export default function InvoicedStageForm({
  editing,
  inputs,
  users,
  onChange,
}) {
  const approvedByOptions = useMemo(() => generateUserOptions(users), [users]);
  return (
    <React.Fragment>
      <Col md={4} xs={12}>
        <DropdownSearch
          name="invoiced_by"
          label="Invoiced By"
          value={inputs.invoiced_by}
          disabled={!editing}
          data={approvedByOptions}
          onChange={(name, { value }) => onChange(name, value)}
        />
      </Col>
      <Col md={4} xs={12}>
        <FieldGroup
          formNoValidate
          type="date"
          name="invoiced_date"
          label="Invoice Date"
          value={moment.utc(inputs.invoiced_date).format("YYYY-MM-DD") || ""}
          disabled={!editing}
          onChange={onChange}
        />
      </Col>
      <Col md={4} xs={12}>
        <FieldGroup
          formNoValidate
          type="text"
          name="invoice_number"
          label="Invoice Number"
          value={inputs.invoice && inputs.invoice.number}
          disabled={!editing}
          onChange={onChange}
        />
      </Col>
    </React.Fragment>
  );
}

InvoicedStageForm.propTypes = {
  editing: PropTypes.bool,
  inputs: PropTypes.object,
  users: PropTypes.array,
  onChange: PropTypes.func,
};

InvoicedStageForm.defaultProps = {
  inputs: {},
  users: [],
};
