import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import autoBind from "react-autobind";

class Division extends Component {
  constructor(props, context) {
    super(props, context);

    autoBind(this);
  }

  onClickEdit(event) {
    event.preventDefault();
    this.props.onClickEdit(this.props.id);
  }

  onClickDelete(event) {
    event.preventDefault();
    this.props.onClickDelete(this.props.id);
  }

  render() {
    return (
      <div className="col-md-4">
        <div className="panel panel-info">
          <div className="panel-heading">
            <a href="" onClick={this.onClickEdit}>
              {this.props.id} - {this.props.name}
            </a>
          </div>
          <div className="panel-body">
            <p>{this.props.name}</p>
          </div>
          <div className="panel-footer">
            <a href="" className="btn btn-danger " onClick={this.onClickDelete}>
              Delete
            </a>
            <Link
              className="btn btn-warning "
              to={`/app/divisions/${this.props.id}`}
            >
              Edit
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Division.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export default Division;
