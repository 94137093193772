import React from "react";
import { Col } from "react-bootstrap";

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <Col md={7} xs={7} className="flex">
      <div className="bread-aisle">
        {breadcrumbs.map((breadcrumb, idx) => {
          let activeClass = idx == breadcrumbs.length - 1 ? "active" : "";

          if (breadcrumb.label.includes("undefined")) {
            breadcrumb.label = "";
          }

          return (
            <div className="loaf" key={idx}>
              <div className="slice">
                <h1 className={`${activeClass} label`}>{breadcrumb.label}</h1>
              </div>
            </div>
          );
        })}
      </div>
    </Col>
  );
};

export default Breadcrumbs;
