import { push } from "react-router-redux";
import * as types from "./actionTypes";
import { showModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import labelService from "../services/labelService";

export function loadLabelsSuccess(labels) {
  return {
    type: types.LOAD_LABEL_SUCCESS,
    labels,
  };
}

export function getLabelSuccess(label) {
  return {
    type: types.GET_LABEL_SUCCESS,
    label,
  };
}

export function savingLabel(status = true) {
  return {
    type: types.SAVING_LABEL,
    savingLabel: status,
  };
}

export function createLabelSuccess(label) {
  return {
    type: types.CREATE_LABEL_SUCCESS,
    label,
  };
}

export function updateLabelSuccess(label) {
  return {
    type: types.UPDATE_LABEL_SUCCESS,
    label,
  };
}

export function requestLabelId(labelId) {
  return (dispatch) => {
    dispatch({
      type: types.REQUEST_LABEL_ID,
      labelToDelete: labelId,
    });
    dispatch(showModalSuccess("labelDeleteModal"));
  };
}

export function deleteLabelSuccess(labelId) {
  return {
    type: types.DELETE_LABEL_SUCCESS,
    labelId,
  };
}

export function loadLabels() {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return labelService
      .loadLabels()
      .then((data) => {
        dispatch(loadLabelsSuccess(data));
      })
      .catch((error) => {
        return dispatch(showAlertSuccess("Invalid User", "error"));
      });
  };
}

export function getDisplayName(entity) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return labelService
      .getDisplayName(entity)
      .then((data) => dispatch(getLabelSuccess(data)))
      .catch((error) => {
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function getLabel(id, showLABELDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return labelService
      .getLabel(id)
      .then((label) => {
        dispatch(getLabelSuccess(label));
        if (showLABELDetails) {
          dispatch(showModalSuccess("labelDetailsModal"));
        }
      })
      .catch((error) => {
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function createLabel(label) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingLabel());
    return labelService
      .createLabel(label)
      .then((createdLabel) => {
        dispatch(createLabelSuccess(createdLabel));
        dispatch(savingLabel(false));
        dispatch(showAlertSuccess("Label created successfully", "success"));
        dispatch(push("/app/labels"));
      })
      .catch((error) => {
        dispatch(savingLabel(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateLabel(label) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingLabel());
    return labelService
      .updateLabel(label)
      .then((updatedLabel) => {
        dispatch(updateLabelSuccess(updatedLabel));
        dispatch(savingLabel(false));
        dispatch(showAlertSuccess("Label updated successfully", "success"));
        dispatch(push("/app/labels"));
      })
      .catch((error) => {
        dispatch(savingLabel(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteLabel(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return labelService
      .deleteLabel(id)
      .then(() => {
        dispatch(deleteLabelSuccess(id));
        dispatch(showAlertSuccess("Label deleted successfully", "success"));
      })
      .catch((error) => {
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}
