import * as endpoints from "./apiEndpoints";
import axios from "axios";

class InvoiceService {
  static loadInvoices(query) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_INVOICES}?${query}`;

    return axios.get(url).then((response) => response.data);
  }


  static loadInvoicesGP(query) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_INVOICES}GP?${query}`;

    return axios.get(url).then((response) => response.data);
  }

  static getInvoice(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_INVOICE}/${id}`;

    return axios.get(url).then((response) => response.data);
  }

  static createInvoice(invoice) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_INVOICE}`;
    const body = {
      invoice: invoice,
    };

    return axios.post(url, body).then((response) => {
      console.log(response.data);
      return response.data;
    });
  }

  static updateInvoice(invoice) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_INVOICE}/${invoice._id}`;
    const body = {
      invoice: invoice,
    };

    return axios.put(url, body).then((response) => response.data);
  }

  static deleteInvoice(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_INVOICE}/${id}`;
    return axios.delete(url).then((response) => response.data);
  }

  static bulkApprove(idArray, user, stageID) {
    const url = `${endpoints.BASE_URL}${endpoints.BULK_INVOICE_APPROVE}`;
    const body = {
      idArray,
      userID: user,
      stageID: stageID,
    };
    return axios.post(url, body).then((response) => response.data);
  }

  static bulkUpdate(idArray, user, stageID) {
    const url = `${endpoints.BASE_URL}${endpoints.BULK_UPDATE_INVOICES}`;
    const body = {
      idArray,
      userID: user,
      stageID: stageID,
    };

    return axios.post(url, body).then((response) => response.data);
  }
}

export default InvoiceService;
