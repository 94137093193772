import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import { LinkContainer } from "react-router-bootstrap";
import { DropdownButton, MenuItem, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { orderBy, groupBy } from "lodash";
import BreadCrumbs from "../../common/BreadCrumbs";
import AddButton from "../../common/AddButton";
import { faTrashAlt, faFolder } from "@fortawesome/free-solid-svg-icons";

import Loading from "../../common/Loading";
import * as clientActions from "../../../actions/clientActions";
import * as modalActions from "../../../actions/modalActions";
import * as divisionActions from "../../../actions/divisionActions";
import * as contractActions from "../../../actions/contractActions";
import ConfirmModal from "../../common/ConfirmModal";
import Modal from "../../common/Modal";
import ClientEpisodeForm from "./ClientEpisodeForm";

const ClientEpisodesPage = ({
  auth,
  actions,
  codes,
  match,
  client,
  modal,
  divisions,
  contracts,
  episodeToDelete,
}) => {
  const [episodes, setEpisodes] = useState(null);
  const [editEpisode, setEditEpisode] = useState(null);

  useEffect(() => {
    if (!divisions.length) {
      actions.loadDivisions();
    }
  }, [divisions]);

  useEffect(() => {
    if (!contracts.length) {
      actions.loadContracts();
    }
  }, [contracts]);

  const onClickDelete = (episodeId) => {
    actions.requestEpisodeId(episodeId);
  };

  const generateProgramLabel = (row) => {
    const foundContract = contracts.find(
      (cont) => cont._id === row.original?.contract?.id
    );
    if (foundContract) {
      const groupedServices = groupBy(foundContract.lines, "service._id");
      return groupedServices[row.value][0]?.service.label;
    }
    return "--";
  };

  const columns = [
    {
      Header: "Contract",
      accessor: "contract",
      Cell: (row) => (row.value ? row.value.name : "--"),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(row) => row.contract && row.contract.name],
        }),
      filterAll: true,
    },
    {
      Header: "Division",
      accessor: "division",
      Cell: (row) => (row.value ? row.value.description : "--"),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(row) => row.division && row.division.description],
        }),
      filterAll: true,
    },
    {
      Header: "Start Date",
      accessor: "start_date",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(item) => moment(item.start_date).format("MM/DD/YYYY")],
        }),
      filterAll: true,
      Cell: (row) => <span>{moment.utc(row.value).format("DD MMM YYYY")}</span>,
    },
    {
      Header: "End Date",
      accessor: "end_date",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(item) => moment(item.end_date).format("MM/DD/YYYY")],
        }),
      filterAll: true,
      Cell: (row) => <span>{moment.utc(row.value).format("DD MMM YYYY")}</span>,
    },
    {
      Header: "Program",
      accessor: "program",
      Cell: (row) => (
        <span>{row.value ? generateProgramLabel(row) : "--"}</span>
      ),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["program"] }),
      filterAll: true,
    },
    {
      Header: "",
      accessor: "actions",
      filterable: false,
      sortable: false,
      Cell: (row) => (
        <div className="text-center actions">
          <DropdownButton
            title="Actions"
            id={"dropdown"}
            onClick={(e) => e.stopPropagation()}
          >
            <MenuItem
              onClick={() => {
                setEditEpisode(row.original);
                actions.getClient(row.original._id, "episodesDetailsModal");
              }}
            >
              <FontAwesomeIcon icon={faFolder} />
              View
            </MenuItem>
            {auth.maxRole == 9000 && (
              <MenuItem onClick={() => onClickDelete(row.original._id)}>
                <FontAwesomeIcon icon={faTrashAlt} className="brand-color" />
                Delete
              </MenuItem>
            )}
          </DropdownButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (match && match.params && match.params.id && !client) {
      actions.getClient(match.params.id);
    }
  }, [match, client]);

  useEffect(() => {
    if (client) {
      setEpisodes(client.episodes);
    }
  }, [client]);

  const props = {
    getTdProps: (state, rowInfo, column) => {
      return {
        onClick: () => {
          if (column.id !== "actions") {
            setEditEpisode(rowInfo.original);
            actions.getClient(rowInfo.original._id, "episodesDetailsModal");
          }
        },
      };
    },
  };

  const handleClickAdd = () => {
    setEditEpisode(null);
    actions.getClient(client._id, "episodesDetailsModal");
  };

  const handleDelete = async () => {
    await actions.deleteEpisode(episodeToDelete);
    await actions.getClient(client._id);
  };

  const handleSave = async (episode, client, episodeId) => {
    if (episodeId) {
      await actions.updateEpisode(client, episode, episodeId);
      await actions.getClient(client._id);
    } else {
      await actions.addEpisode(client, episode);
      await actions.getClient(client._id);
    }
  };

  const episodeForm = (
    <ClientEpisodeForm
      client={client}
      codes={codes}
      contracts={contracts}
      episode={editEpisode}
      divisions={divisions}
      onSave={handleSave}
      onCancel={actions.hideModal}
      editing={true}
      auth={auth}
      adding={true}
    />
  );

  if (!episodes) return <Loading />;

  return (
    <div className="content-wrapper">
      <Row>
        <BreadCrumbs
          breadcrumbs={[
            {
              label: client
                ? `${client.last_name}, ${client.first_name}`
                : "Clients",
            },
            { label: "Episodes" },
          ]}
        />
        <AddButton onClickAdd={handleClickAdd} />
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <ReactTable
            className="-highlight"
            filterable
            data={episodes}
            LoadingComponent={() => (
              <Loading active={!Array.isArray(episodes)} />
            )}
            columns={columns}
            {...props}
            defaultPageSize={10}
            defaultSorted={[
              {
                id: "start_date",
                asc: true,
              },
              {
                id: "division",
                asc: true,
              },
            ]}
          />
        </Col>
        <Modal
          id="episodesDetailsModal"
          title={editEpisode ? "Edit Episode" : "Add Episode"}
          body={episodeForm}
          modal={modal}
          close={actions.hideModal}
        />
        <ConfirmModal
          id="episodeDeleteModal"
          title="Delete Episode"
          body="Are you sure you want to delete this episode?"
          modal={modal}
          close={actions.hideModal}
          confirm={handleDelete}
        />
      </Row>
    </div>
  );
};

ClientEpisodesPage.propTypes = {
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  codes: PropTypes.array,
  match: PropTypes.object,
  client: PropTypes.object,
  modal: PropTypes.object,
  divisions: PropTypes.array,
  contracts: PropTypes.array,
  episodeToDelete: PropTypes.string,
};

const mapStatesToProps = (state) => ({
  codes: state.reducers.codes,
  client: state.reducers.client,
  modal: state.reducers.modal,
  divisions: state.reducers.divisions,
  contracts: state.reducers.contracts,
  episodeToDelete: state.reducers.episodeToDelete,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...clientActions,
      ...modalActions,
      ...divisionActions,
      ...contractActions,
    },
    dispatch
  ),
});

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ClientEpisodesPage);
