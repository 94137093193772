import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as chargetypeActions from "../../actions/chargetypeActions";
import * as modalActions from "../../actions/modalActions";

import ChargetypeList from "./ChargetypeList";
import BreadCrumbs from "../common/BreadCrumbs";
import ConfirmModal from "../common/ConfirmModal";

import { Row, Col, Collapse } from "react-bootstrap";

class ChargeTypePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { add: false };

    autoBind(this);

    props.actions.loadChargetypes();
  }

  onClickDelete(chargetypeId) {
    this.props.actions.requestChargetypeId(chargetypeId);
  }

  handleCancel() {
    this.setState({ add: !this.state.add });
  }

  handleDelete() {
    this.props.actions.deleteChargetype(this.props.chargetypeToDelete);
  }

  handleSave(chargetype) {
    this.props.actions.createChargetype(chargetype);
  }

  render() {
    let { chargetypes } = this.props;
    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: "Settings" }, { label: "Chargetypes" }]}
          />
          <Col md={3}>
            <button
              className="btn btn-success btn-sm right btn-add"
              onClick={() => this.setState({ add: !this.state.add })}
            >
              {this.state.add ? "HIDE" : "ADD"}
            </button>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="settings-add">
            <Collapse in={this.state.add}>
              <div>
                <Row>
                  <h4>Add Chargetype Code</h4>
                  {/* <DynamicForm
                    fieldData={find(this.props.labels, {
                      entity: "Chargetype"
                    })}
                    onCancel={this.handleCancel}
                    onSave={this.handleSave}
                    entity={"Chargetype"}
                    saving={this.props.savingChargetype}
                    editing={true}
                    adding={true}
                  /> */}
                </Row>
              </div>
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ChargetypeList
              chargetypes={chargetypes}
              onClickDelete={this.onClickDelete}
            />
            <ConfirmModal
              id="chargetypeDeleteModal"
              title="Delete Chargetype"
              body="Are you sure you want to delete this chargetype?"
              modal={this.props.modal}
              close={this.props.actions.hideModal}
              confirm={this.handleDelete}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ChargeTypePage.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  savingChargetype: PropTypes.bool,
  labels: PropTypes.array,
  chargetypeToDelete: PropTypes.string,
  chargetypes: PropTypes.array.isRequired,
  chargetype: PropTypes.object.isRequired,
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    savingChargetype: state.reducers.savingChargetype,
    labels: state.reducers.labels.labels,
    chargetypeToDelete: state.reducers.chargetypeToDelete,
    chargetypes: state.reducers.chargetypes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...chargetypeActions, ...modalActions },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(ChargeTypePage);
