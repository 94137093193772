import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { DropdownButton, MenuItem } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

import find from "lodash/find";

import CallService from "../../../services/callService";
import ClientList from "../../client/components/ClientList";
import CustomLoadable from "../../common/Loadable";

const ContractClientList = ({
  contract,
  onClickEdit,
  onClickDelete,
  contractClients,
  loading,
}) => {
  let clientsView = <CustomLoadable />;

  if (contractClients) {
    clientsView = (
      <ClientList
        clients={contractClients}
        onClickView={onClickEdit}
        manual={false}
        loading={loading}
      />
    );
  }

  return <div>{clientsView}</div>;
};

ContractClientList.propTypes = {
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      division: PropTypes.string.isRequired,
    }).isRequired
  ),
  contractId: PropTypes.string,
  trackBy: PropTypes.object,
  contract: PropTypes.object,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  contractClients: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

export default ContractClientList;
