import React from "react";
import PropTypes from "prop-types";

import Loadable from "react-loading-overlay";

export default function CustomLoadable({ size = "auto" }) {
  return (
    <Loadable
      active={true}
      className="vertical-center"
      spinner
      styles={{
        overlay: (base) => ({
          ...base,
          background: "none",
        }),
        spinner: (base) => ({
          ...base,
          width: size,
          "& svg circle": {
            stroke: "#cf4b04",
          },
        }),
      }}
    />
  );
}

CustomLoadable.propTypes = {
  size: PropTypes.string,
};
