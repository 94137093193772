import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { Input, Checkbox, Select } from "formsy-react-components";
import autoBind from "react-autobind";

class DivisionForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
      department: { dept_id: "", description: "" },
    };

    autoBind(this);
  }

  enableButton() {
    this.setState({
      canSubmit: true,
    });
  }

  disableButton() {
    this.setState({
      canSubmit: false,
    });
  }

  submit(model) {
    this.props.onSave(model);
    this.refs.form.reset();
  }

  resetForm() {
    this.refs.form.reset();
  }

  render() {
    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        {this.props.adding && (
          <Col xs={12}>
            <h4>Add Division</h4>
          </Col>
        )}
        <Col md={5}>
          <Input
            formNoValidate
            required
            name="description"
            label="Description"
            placeholder="Description"
            value={this.props.division.description || ""}
          />
        </Col>
        <Col md={5}>
          <Input
            formNoValidate
            required
            name="code"
            label="Code"
            placeholder="Code"
            value={this.props.division.code || ""}
          />
        </Col>
        <Col md={2}>
          <Checkbox
            name="display_client_notes"
            value={this.props.division.display_client_notes || false}
            valueLabel="Display Client Notes"
          />
        </Col>
        {this.props.adding && (
          <>
            <Col xs={12}>
              <h4>Add Department</h4>
            </Col>
            <Col md={6}>
              <Input
                formNoValidate
                required
                name="depDescription"
                label="Description"
                placeholder="Description"
                value={this.state.department.description || ""}
              />
            </Col>
            <Col md={6}>
              <Input
                formNoValidate
                required
                name="dept_id"
                label="Department Id"
                placeholder="Department Id"
                value={this.state.department.dept_id || ""}
              />
            </Col>
          </>
        )}
        <Col md={12}>
          <button
            className="btn btn-danger"
            type="button"
            onClick={this.props.onCancel}
          >
            Cancel
          </button>
          <input
            className="btn btn-success"
            type="submit"
            disabled={!this.state.canSubmit}
            value={this.props.saving ? "Saving... " : "Save"}
          />
        </Col>
      </Formsy>
    );
  }
}

DivisionForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  division: PropTypes.object.isRequired,
  adding: PropTypes.bool,
};
DivisionForm.defaultProps = {
  adding: false,
};

export default DivisionForm;
