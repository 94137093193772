import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const NotFound = (props) => {
  return (
    <div className="content-wrapper">
      <Row>
        <Col md={12}>
          <h2>404 Not Found</h2>
          <p>Sorry, the page you were looking for is not available.</p>
          <p>
            {" "}
            Please try again or go to &nbsp;
            <Link to="/" activeClassName="active">
              <i className="glyphicon glyphicon-home" /> Home
            </Link>
          </p>
        </Col>
      </Row>
    </div>
  );
};

NotFound.propTypes = {
  //
};

export default NotFound;
