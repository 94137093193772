import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import ClientList from "./ClientList";

const DuplicateClientNameModal = ({
  history,
  duplicateClients,
  firstName,
  lastName,
  onCancel,
}) => {
  return (
    <div>
      <Row>
        <h4 style={{ textAlign: "center" }}>{`There ${
          duplicateClients.length > 1 ? "are" : "is"
        } already ${
          duplicateClients.length > 1 ? "clients" : "a client"
        } with the name ${firstName} ${lastName}. Please select an existing client to edit or click continue to continue creating new entry.`}</h4>
        <Col md={12} xs={12}>
          <ClientList
            clients={duplicateClients}
            onClickView={(id) => history.push(`/app/clients/${id}`)}
            defaultPageSize={3}
          />
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button className="btn btn-success" type="button" onClick={onCancel}>
          Continue
        </button>
      </div>
    </div>
  );
};

DuplicateClientNameModal.propTypes = {
  history: PropTypes.object.isRequired,
  duplicateClients: PropTypes.array.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DuplicateClientNameModal;
