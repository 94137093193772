import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Formsy from "formsy-react";
import { Input } from "formsy-react-components";
import { Col, Row } from "react-bootstrap";

class ForgotForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
    };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  submit = (model) => {
    this.props.onSave(model);
  };

  render() {
    return (
      <Formsy
        ref="form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Input
          formNoValidate
          required
          name="email"
          label="Email"
          placeholder="Email"
          value={""}
        />
        <Row className="text-center">
          <Col md={12} className="flex-row">
            <Link className="btn btn-danger" to="/">
              Cancel
            </Link>
            <input
              className="btn"
              type="submit"
              disabled={!this.state.canSubmit}
              value={
                this.props.saving ? "Resetting Password" : "Reset Password"
              }
            />
          </Col>
        </Row>
      </Formsy>
    );
  }
}

ForgotForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  login: PropTypes.object.isRequired,
};

export default ForgotForm;
