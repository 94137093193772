import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Nav, NavItem } from "react-bootstrap";
import { IndexLinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { config } from "../../../config";

class ContactMenu extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    return (
      <Nav className="sidebar sidebar-nav">
        <IndexLinkContainer to="/app/contacts">
          <NavItem eventKey={1}>
            <FontAwesomeIcon icon={faChevronLeft} />
            All Contacts
          </NavItem>
        </IndexLinkContainer>
        <div className="footer-tag">
          <p>
            ECLIPS {config.VERSION}
            <br /> © 2019 GraVoc Associates, Inc.
          </p>
        </div>
      </Nav>
    );
  }
}

ContactMenu.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
};

function mapStatesToProps(state, ownProps) {
  return { state: state, auth: state.reducers.auth };
}

export default connect(mapStatesToProps)(ContactMenu);
