const validation = (totalQuantities) => ({
  isValidQuant: {
    validator: (value) =>
      parseFloat(value) === Math.ceil(parseFloat(value) / 0.25) * 0.25,
    helpText: "Call duration must be in increments of 0.25",
    variant: "error",
  },
  isSumQuant: {
    validator: (value) => parseFloat(value) === totalQuantities,
    helpText: "Call duration must equal total of procedure durations.",
    variant: "error",
  },
});

export default validation;
