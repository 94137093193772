import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as roleActions from "../../actions/roleActions";
import * as modalActions from "../../actions/modalActions";

import * as labelActions from "../../actions/labelActions";
import RoleList from "./RoleList";
import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";

import { Row, Col, Collapse } from "react-bootstrap";
import DynamicForm from "../common/DynamicForm";
import find from "lodash/find";

class RolePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      add: false,
    };

    autoBind(this);

    props.actions.loadRoles();
  }

  componentDidMount = () => {
    this.props.actions.loadLabels();
  };

  onClickEdit(roleId) {
    this.props.actions.getRole(roleId, true);
  }

  onClickDelete(roleId) {
    this.props.actions.requestRoleId(roleId);
  }

  handleDelete() {
    this.props.actions.deleteRole(this.props.roleToDelete);
  }

  handleSave(role) {
    this.props.actions.createRole(role);
  }

  handleCancel() {
    this.setState({ add: false });
  }

  render() {
    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: "Settings" }, { label: "Roles" }]}
          />
          <AddButton
            onClickAdd={() => this.setState({ add: !this.state.add })}
          />
        </Row>
        <Row>
          <Col md={12} className="settings-add">
            <Collapse in={this.state.add}>
              <div>
                <Row>
                  <h4>Add Role</h4>
                  <DynamicForm
                    fieldData={find(this.props.labels, { entity: "Role" })}
                    onCancel={this.handleCancel}
                    onSave={this.handleSave}
                    entity={"Role"}
                    saving={this.props.savingRole}
                    editing={true}
                    adding={true}
                  />
                </Row>
              </div>
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <RoleList
              roles={this.props.roles}
              onClickEdit={this.onClickEdit}
              onClickDelete={this.onClickDelete}
            />
            <Modal
              id="roleDetailsModal"
              title="Role Info"
              body={this.props.role.name}
              modal={this.props.modal}
              close={this.props.actions.hideModal}
            />
            <ConfirmModal
              id="roleDeleteModal"
              title="Delete Role"
              body="Are you sure you want to delete this role?"
              modal={this.props.modal}
              close={this.props.actions.hideModal}
              confirm={this.handleDelete}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

RolePage.propTypes = {
  actions: PropTypes.object,

  modal: PropTypes.object,
  savingRole: PropTypes.bool,
  labels: PropTypes.array,
  roleToDelete: PropTypes.string,
  roles: PropTypes.array.isRequired,
  role: PropTypes.object.isRequired,
};

function mapStatesToProps(state, ownProps) {
  let role = {
    id: 0,
    name: "",
    number: "",
  };

  return {
    state: state.reducers,

    modal: state.reducers.modal,
    savingRole: state.reducers.savingRole,
    labels: state.reducers.labels.labels,
    roleToDelete: state.reducers.roleToDelete,
    roles: state.reducers.roles.roles,
    role: role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...roleActions, ...modalActions, ...labelActions },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(RolePage);
