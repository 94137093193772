import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as codeActions from "../../actions/codeActions";

import { Row, Col } from "react-bootstrap";
import {} from "../../helpers";
import CodeForm from "./CodeForm";

class CodeEditPage extends Component {
  constructor(props, context) {
    super(props, context);

    autoBind(this);
  }

  componentDidMount = () => {
    let { actions } = this.props;
    actions.getCode(this.props.match.params.id);
  };

  handleSave(code) {
    let data = {
      id: this.props.code._id,
      ...code,
    };

    this.props.actions.updateCode(data);
  }

  render() {
    return (
      <div className="content-wrapper">
        <Row>
          <Col md={12}>
            <h1>Edit Code</h1>
            <CodeForm
              onSave={this.handleSave}
              saving={this.props.savingCode}
              code={this.props.code}
              codes={this.props.codes}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

CodeEditPage.propTypes = {
  actions: PropTypes.object.isRequired,

  savingCode: PropTypes.bool,
  code: PropTypes.object,
  codes: PropTypes.array,
  params: PropTypes.object,
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,

    savingCode: state.reducers.savingCode,
    code: state.reducers.code,
    codes: state.reducers.codes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...codeActions }, dispatch),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(CodeEditPage);
