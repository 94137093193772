import * as endpoints from "./apiEndpoints";
import axios from "axios";
import uuidv4 from "uuid/v4";

class ClientService {
  static loadClients() {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CLIENTS}`;

    return axios.get(url).then((response) => response.data);
  }

  static loadPaginatedClients(query) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CLIENTS}/paginated?${query}`;

    return axios.get(url).then((response) => response.data);
  }

  static loadClientsTimeEntry() {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CLIENTS}/timeEntry`;

    return axios.get(url).then((response) => response.data);
  }

  static getClient(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CLIENT}/${id}`;

    return axios.get(url).then((response) => response.data);
  }

  static findClientsByName(firstName, lastName) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CLIENTS}/clientsByName?firstName=${firstName}&lastName=${lastName}`;
    return axios.get(url).then((res) => res.data);
  }

  static createClient(client) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_CLIENT}`;
    const body = {
      client,
    };

    return axios.post(url, body).then((response) => response.data);
  }

  static updateClient(client, updatedFields) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_CLIENT}/${client._id}`;
    const body = {
      client,
      updatedFields,
    };

    return axios.put(url, body).then((response) => response.data);
  }

  static deleteClient(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_CLIENT}/${id}`;
    return axios.delete(url).then((response) => response.data);
  }

  static createNote(client, note, attachments) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_CLIENT}/note/${client._id}`;
    const body = {
      note: note,
      attachments,
    };

    return axios.post(url, body).then((response) => response.data);
  }

  static updateNote(client, note) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_CLIENT}/note/${client._id}`;
    const body = {
      note: note,
    };

    return axios.put(url, body).then((response) => response.data);
  }
  static deleteNote(noteId) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_CLIENT}/notes/${noteId}`;
    return axios.delete(url).then((response) => response.data);
  }

  static createEpisode(client, episode) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_CLIENT}/episode/${client._id}`;
    const body = {
      episode,
    };

    return axios.post(url, body).then((response) => response.data);
  }

  static updateEpisode(client, episode) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_CLIENT}/episode/${client._id}`;
    const body = {
      episode,
    };

    return axios.put(url, body).then((response) => response.data);
  }
  static deleteEpisode(episodeId) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_CLIENT}/episodes/${episodeId}`;
    return axios.delete(url).then((response) => response.data);
  }
  static getContractsByClient(clientId) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CONTRACTS_BY_CLIENT}/${clientId}`;
    return axios.get(url).then((response) => response.data);
  }

  static uploadNoteAttachment(client, note, file) {
    const formData = new FormData();
    // arg for formData.append() must match name in client/index.js upload single
    formData.append("noteAttachmentUpload", file);

    const url = `${endpoints.BASE_URL}${endpoints.POST_NOTE_ATTACHMENT}/${note._id}/upload`;

    return axios
      .post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        return this.createNoteAttachment(client, note, response.data);
      })
      .then((resp) => {
        return resp.data;
      });
  }

  static createNoteAttachment(client, note, attachment) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_NOTE_ATTACHMENT}/${client._id}/${note._id}/attachment`;
    const body = {
      attachment: {
        path: attachment.fileName,
      },
    };

    return axios.post(url, body).then((response) => {
      return response.data;
    });
  }

  static downloadNoteAttachment(note, attachment) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_NOTE_ATTACHMENT}/${
      note._id
    }/attachment/${encodeURIComponent(attachment.path)}`;

    return axios.get(url).then((response) => {
      // simulate DOM click for download
      const file_path = response.data.url;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      return response.data;
    });
  }

  static uploadNoteAttachmentToNewNote(file) {
    const formData = new FormData();
    // arg for formData.append() must match name in client/index.js upload single
    formData.append("noteAttachmentUpload", file);

    const url = `${endpoints.BASE_URL}${
      endpoints.POST_NOTE_ATTACHMENT
    }/${uuidv4()}/upload`;

    return axios
      .post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((resp) => {
        return resp.data;
      });
  }

  static deleteNoteAttachment(client, note, attachment) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_NOTE_ATTACHMENT}/${client._id}/${note._id}/${attachment._id}/attachment`;
    const body = {
      attachment: {
        _id: attachment._id,
      },
    };

    return axios.delete(url, body).then((response) => {
      return response.data;
    });
  }

  static findClientsByQuery(query, skip, limit) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CLIENTS}/query?q=${query}&skip=${skip}&limit=${limit}`;
    return axios.get(url).then((res) => res.data);
  }

  static findClientById(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CLIENTS}/byId/${id}`;
    return axios.get(url).then((res) => res.data);
  }
}
export default ClientService;
