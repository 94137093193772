import { push } from "react-router-redux";
import * as types from "./actionTypes";
import { showModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import roleService from "../services/roleService";

export function loadRoleSuccess(roles) {
  return {
    type: types.LOAD_ROLE_SUCCESS,
    roles,
  };
}

export function getRoleSuccess(role) {
  return {
    type: types.GET_ROLE_SUCCESS,
    role,
  };
}

export function savingRole(status = true) {
  return {
    type: types.SAVING_ROLE,
    savingRole: status,
  };
}

export function createRoleSuccess(role) {
  return {
    type: types.CREATE_ROLE_SUCCESS,
    role,
  };
}

export function updateRoleSuccess(role) {
  return {
    type: types.UPDATE_ROLE_SUCCESS,
    role,
  };
}

export function requestRoleId(roleId) {
  return (dispatch) => {
    dispatch({
      type: types.REQUEST_ROLE_ID,
      roleToDelete: roleId,
    });
    dispatch(showModalSuccess("roleDeleteModal"));
  };
}

export function deleteRoleSuccess(roleId) {
  return {
    type: types.DELETE_ROLE_SUCCESS,
    roleId,
  };
}

export function loadRoles() {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return roleService
      .loadRoles()
      .then((data) => dispatch(loadRoleSuccess(data)))
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function getRole(id, showROLEDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return roleService
      .getRole(id)
      .then((role) => {
        dispatch(getRoleSuccess(role));
        if (showROLEDetails) {
          dispatch(showModalSuccess("roleDetailsModal"));
        }
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function createRole(role) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingRole());
    return roleService
      .createRole(role)
      .then((createdRole) => {
        dispatch(createRoleSuccess(createdRole));
        dispatch(savingRole(false));
        dispatch(showAlertSuccess("Role created successfully", "success"));
        dispatch(push("/app/settings/roles"));
      })
      .catch((error) => {
        dispatch(savingRole(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateRole(role) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingRole());
    return roleService
      .updateRole(role)
      .then((updatedRole) => {
        dispatch(updateRoleSuccess(updatedRole));
        dispatch(savingRole(false));
        dispatch(showAlertSuccess("Role updated successfully", "success"));
        dispatch(push("/app/settings/roles"));
      })
      .catch((error) => {
        dispatch(savingRole(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteRole(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return roleService
      .deleteRole(id)
      .then(() => {
        dispatch(deleteRoleSuccess(id));
        dispatch(showAlertSuccess("Role removed", "success"));
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}
