import queryString from "query-string";

import * as types from "./actionTypes";
import { showModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import callService from "../services/callService";
import contractService from "../services/contractService";

export function loadCallSuccess(calls) {
  return {
    type: types.LOAD_CALL_SUCCESS,
    calls,
    meta: {
      throttle: true,
    },
  };
}

export function loadCallByStageSuccess(calls) {
  return {
    type: types.LOAD_CALL_BY_STAGE_SUCCESS,
    calls,
    meta: {
      throttle: true,
    },
  };
}

export function getCallSuccess(call) {
  return {
    type: types.GET_CALL_SUCCESS,
    call,
  };
}

export function bulkApproveSuccess() {
  return {
    type: types.BULK_APPROVE_SUCCESS,
  };
}

export function savingCall(status = true) {
  return {
    type: types.SAVING_CALL,
    savingCall: status,
  };
}

export function loadingCall(status = true) {
  return {
    type: types.LOADING_CALL,
    loadingCall: status,
  };
}

export function createCallSuccess(call) {
  return {
    type: types.CREATE_CALL_SUCCESS,
    call,
  };
}

export function updateContractSuccess(contract) {
  return {
    type: types.UPDATE_CONTRACT_SUCCESS,
    contract,
  };
}

export function updateCallSuccess(call) {
  return {
    type: types.UPDATE_CALL_SUCCESS,
    call,
    // meta: {
    //   throttle: true
    // }
  };
}

export function updateCallsSuccess(calls) {
  return {
    type: types.UPDATE_CALLS_SUCCESS,
    calls,
  };
}

export function clearApprovedCalls() {
  return {
    type: types.DESELECT_ALL_CALLS,
  };
}

export function toggleCall(id) {
  return {
    type: types.TOGGLE_CALL,
    id: id,
  };
}

export function toggleAll(calls) {
  return {
    type: types.TOGGLE_ALL_CALLS,
    calls: calls,
  };
}

export function toggleCallToInvoice(call) {
  return {
    type: types.TOGGLE_INVOICE_CALL,
    call,
  };
}

export function toggleAllCallsToInvoice(calls) {
  return {
    type: types.TOGGLE_ALL_INVOICE_CALLS,
    calls: calls,
  };
}

export function requestCallId(callId) {
  return (dispatch) => {
    dispatch({
      type: types.REQUEST_CALL_ID,
      callToDelete: callId,
    });
    dispatch(showModalSuccess("callDeleteModal"));
  };
}

export function deleteCallSuccess(callId) {
  return {
    type: types.DELETE_CALL_SUCCESS,
    callId,
  };
}

export function loadCalls(filters = {}) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(loadingCall());
    return callService
      .loadCalls(filters)
      .then((data) => {
        dispatch(loadCallSuccess(data));
        dispatch(loadingCall(false));
      })
      .catch((error) => error);
  };
}

export function loadCallsByQuery(query) {
  return (dispatch) => {
    return callService
      .loadCallsByQuery(query)
      .then((data) => dispatch(loadCallSuccess(data)))
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function loadCallsForApproval(query = {}) {
  return (dispatch) => {
    return callService
      .loadCallsForApproval(
        queryString.stringify(query, {
          arrayFormat: "bracket",
        })
      )
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function getCall(id, showCALLDetails = false) {
  return (dispatch) => {
    dispatch(loadingCall());
    dispatch(hideAlertSuccess());
    return callService
      .getCall(id)
      .then((call) => {
        dispatch(getCallSuccess(call));
        dispatch(loadingCall(false));
        if (showCALLDetails) {
          // dispatch(push(`/app/calls/${call._id}/edit`));
          dispatch(showModalSuccess("callDetailsModal"));
        }
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function createCall(call) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingCall());
    return callService
      .createCall(call)
      .then((createdCall) => {
        dispatch(createCallSuccess(createdCall));
        dispatch(savingCall(false));
        dispatch(showAlertSuccess("Call created successfully", "success"));
      })
      .catch((error) => {
        dispatch(savingCall(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateCall(call) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingCall());
    return callService
      .updateCall(call)
      .then((updatedCall) => {
        dispatch(updateCallSuccess(updatedCall));
        dispatch(savingCall(false));
        dispatch(showAlertSuccess("Call updated successfully", "success"));
      })
      .catch((error) => {
        dispatch(savingCall(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteCall(id) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return callService
      .deleteCall(id)
      .then(() => {
        dispatch(deleteCallSuccess(id));
        dispatch(showAlertSuccess("Call Deleted Successfully", "success"));
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function updateContractStats(contract, line) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return contractService
      .updateContractStats(contract, line)
      .then((updatedContract) => {
        dispatch(updateContractSuccess(updatedContract));
        dispatch(showAlertSuccess("Contract updated successfully", "success"));
      })
      .catch((error) => {
        dispatch(savingCall(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function bulkUpdate(idArray, data) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingCall());
    return callService
      .bulkUpdate(idArray, data)
      .then((updatedCalls) => {
        dispatch(updateCallsSuccess(updatedCalls));
        dispatch(
          showAlertSuccess(
            `${updatedCalls.length} ${
              updatedCalls.length == 1 ? "call" : "calls"
            } updated`,
            "success"
          )
        );
        dispatch(savingCall(false));
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function bulkApprove(idArray, user, stageID) {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    dispatch(savingCall());
    return callService
      .bulkApprove(idArray, user, stageID)
      .then((responseArray) => {
        dispatch(
          showAlertSuccess(
            `${responseArray.modifiedCount} calls approved`,
            "success"
          )
        );
        dispatch(savingCall(false));
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}
