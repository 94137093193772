import { push } from "react-router-redux";
import * as types from "./actionTypes";
import { showModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import itemService from "../services/itemService";

export function loadItemSuccess(items) {
  return {
    type: types.LOAD_ITEM_SUCCESS,
    items,
    meta: {
      throttle: true,
    },
  };
}

export function getItemSuccess(item) {
  return {
    type: types.GET_ITEM_SUCCESS,
    item,
  };
}

export function savingItem(status = true) {
  return {
    type: types.SAVING_ITEM,
    savingItem: status,
  };
}

export function createItemSuccess(item) {
  return {
    type: types.CREATE_ITEM_SUCCESS,
    item,
  };
}

export function updateItemSuccess(item) {
  return {
    type: types.UPDATE_ITEM_SUCCESS,
    item,
  };
}

export function requestItemId(itemId) {
  return (dispatch) => {
    dispatch({
      type: types.REQUEST_ITEM_ID,
      itemToDelete: itemId,
    });
    dispatch(showModalSuccess("itemDeleteModal"));
  };
}

export function deleteItemSuccess(itemId) {
  return {
    type: types.DELETE_ITEM_SUCCESS,
    itemId,
  };
}

export function loadItems() {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return itemService
      .loadItems()
      .then((data) => dispatch(loadItemSuccess(data)))
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function getItem(id, showITEMDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return itemService
      .getItem(id)
      .then((item) => {
        dispatch(getItemSuccess(item));
        if (showITEMDetails) {
          dispatch(showModalSuccess("itemDetailsModal"));
        }
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function createItem(item) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingItem());
    return itemService
      .createItem(item)
      .then((createdItem) => {
        dispatch(createItemSuccess(createdItem));
        dispatch(savingItem(false));
        dispatch(showAlertSuccess("Procedure created successfully", "success"));
        dispatch(push("/app/settings/procedures"));
      })
      .catch((error) => {
        dispatch(savingItem(false));
        //dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateItem(item) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingItem());
    return itemService
      .updateItem(item)
      .then((updatedItem) => {
        dispatch(updateItemSuccess(updatedItem));
        dispatch(savingItem(false));
        dispatch(showAlertSuccess("Procedure updated successfully", "success"));
        dispatch(push("/app/settings/procedures"));
      })
      .catch((error) => {
        dispatch(savingItem(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteItem(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return itemService
      .deleteItem(id)
      .then(() => {
        dispatch(deleteItemSuccess(id));
        dispatch(showAlertSuccess("Procedure deleted successfully", "success"));
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}
