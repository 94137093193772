import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";

import MainMenu from "./menus/MainMenu";
import ContractMenu from "./menus/ContractMenu";
import AccountMenu from "./menus/AccountMenu";
import ContactMenu from "./menus/ContactMenu";
import UserMenu from "./menus/UserMenu";
import CallMenu from "./menus/CallMenu";
import SettingsMenu from "./menus/SettingsMenu";
import ClientMenu from "./menus/ClientMenu";

import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import logoUrl from "../../assets/img/EasterSealsLogo.png";

class Header extends Component {
  constructor(props, context) {
    super(props, context);
  }

  logOut = () => {
    this.props.actions.logoutUser(this.props.auth.username);
  };

  getSidebar = () => {
    let { auth, location, isMobile } = this.props;
    let sidebar;

    let pathname = location.pathname;
    let parsedState = pathname.split("/");

    if (parsedState.length > 3 && parsedState[3].toString() !== "add") {
      let currentState = parsedState[2].toString();
      switch (currentState) {
        case "contracts":
          sidebar = <ContractMenu location={location} />;
          break;
        case "accounts":
          sidebar = <AccountMenu location={location} />;
          break;
        case "contacts":
          sidebar = <ContactMenu location={location} />;
          break;
        case "users":
          sidebar = <UserMenu location={location} />;
          break;
        case "calls":
          sidebar = <CallMenu location={location} />;
          break;
        case "settings":
          sidebar = <SettingsMenu auth={auth} location={location} />;
          break;
        case "clients":
          sidebar = <ClientMenu location={location} auth={auth} />;
          break;
      }
    } else if (
      parsedState.length == 3 &&
      parsedState[2].toString() == "settings"
    ) {
      sidebar = <SettingsMenu auth={auth} location={location} />;
    } else {
      sidebar = (
        <MainMenu auth={auth} location={location} isMobile={isMobile} />
      );
    }
    return sidebar;
  };

  render() {
    let { auth, isMobile } = this.props;

    return (
      <Navbar collapseOnSelect fixedTop={true}>
        <Navbar.Header>
          <Navbar.Brand>
            <LinkContainer to="/app">
              <img src={logoUrl} alt="es logo" />
            </LinkContainer>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          {this.getSidebar()}
          {isMobile ? (
            <Nav>
              <LinkContainer to="/app/settings/account">
                <NavItem>
                  <FontAwesomeIcon icon={faUserCircle} />
                  Account Settings
                </NavItem>
              </LinkContainer>
              <MenuItem onClick={this.logOut}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                Log Out
              </MenuItem>
            </Nav>
          ) : (
            <Nav>
              <NavDropdown
                onClick={(e) => {
                  e.stopPropagation();
                }}
                eventKey={4}
                title={
                  <span>
                    {auth.user.first_name} {auth.user.last_name}
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <LinkContainer to="/app/settings/account">
                  <MenuItem eventKey={5}>Account Settings</MenuItem>
                </LinkContainer>
                {auth.maxRole >= 9000 && (
                  <LinkContainer to="/app/settings/divisions">
                    <MenuItem eventKey={5}>System Settings</MenuItem>
                  </LinkContainer>
                )}
                <MenuItem onClick={this.logOut}>Log Out</MenuItem>
              </NavDropdown>
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

Header.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object,
};

function mapStatesToProps(state) {
  return {
    state: state,
    auth: state.reducers.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...userActions }, dispatch),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(Header);
