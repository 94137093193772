import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import autoBind from "react-autobind";
import { Row, Col, Panel } from "react-bootstrap";
import PhoneNumber from "../common/PhoneNumber";

class Location extends Component {
  constructor(props, context) {
    super(props, context);

    // autoBind(this);
  }

  // onClickEdit(event) {
  //   event.preventDefault();
  //   this.props.location.onClickEdit(this.props.location.id);
  // }

  // onClickDelete(event) {
  //   event.preventDefault();
  //   this.props.location.onClickDelete(this.props.location.id);
  // }

  render() {
    let address = Object.keys(this.props.location.address).map((key, i) => {
      return (
        <span style={{ textTransform: "capitalize" }} key={i}>
          {this.props.location.address[key]}
          <br />
        </span>
      );
    });
    return (
      <Panel bsStyle="info">
        <Panel.Heading style={{ textTransform: "capitalize" }}>
          {this.props.location.name}
        </Panel.Heading>
        <Panel.Body>
          <Col md={5} sm={6}>
            <p>Phone:</p>
          </Col>
          <Col md={7} sm={6}>
            <p>
              <PhoneNumber number={this.props.location.phone} />
            </p>
          </Col>
          <Col md={5} sm={6}>
            <p>Address:</p>
          </Col>
          <Col md={7} sm={6} style={{ textTransform: "capitalize" }}>
            <p>{this.props.location.address.addr1 || ""}</p>
            <p>{this.props.location.address.addr2 || ""}</p>
            <p>
              {this.props.location.address.city || ""},&nbsp;
              {this.props.location.address.state.value || ""}&nbsp;
              {this.props.location.address.zip || ""}
            </p>
            <p>{this.props.location.address.country.label || ""}</p>
          </Col>
          <Col md={5} sm={6}>
            <p>Time Zone:</p>
          </Col>
          <Col md={7} sm={6}>
            {this.props.location.time_zone.value}
          </Col>
        </Panel.Body>
      </Panel>
    );
  }
}

Location.propTypes = {
  location: PropTypes.object.isRequired,
  // onClickEdit: PropTypes.func.isRequired,
  // onClickDelete: PropTypes.func.isRequired,
};

export default Location;
