import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as roleActions from "../../actions/roleActions";

import RoleForm from "./RoleForm";

class RoleAddPage extends Component {
  constructor(props, context) {
    super(props, context);

    autoBind(this);
  }

  handleSave(role) {
    this.props.actions.createRole(role);
  }

  render() {
    return (
      <div className="col-md-4">
        <h1>Add Role</h1>
        <RoleForm
          onSave={this.handleSave}
          saving={this.props.savingRole}
          role={this.props.role}
        />
      </div>
    );
  }
}

RoleAddPage.propTypes = {
  actions: PropTypes.object.isRequired,

  savingRole: PropTypes.bool,
  role: PropTypes.object,
};

function mapStatesToProps(state, ownProps) {
  let role = {
    id: 0,
    name: "",
  };

  return {
    state: state.reducers,

    savingRole: state.reducers.savingRole,
    role: role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...roleActions }, dispatch),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(RoleAddPage);
