import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

// Two Add Buttons Needed custom text in division. Made new comonent instead of messing with original Add Button.

const CustomAddButton = ({ entity, auth, text, onClickAdd }) => {
  let hideButton = auth && auth.maxRole < 6000;

  return (
    !hideButton &&
    (entity ? (
      <Link
        className="btn btn-success btn-sm right btn-add btn-add-custom"
        to={`/app/${entity}/add`}
      >
        <FontAwesomeIcon icon={faPlus} />
        {text ? text : "Add"}
      </Link>
    ) : (
      <button
        className="btn btn-success right btn-add btn-add-custom"
        onClick={onClickAdd}
      >
        <FontAwesomeIcon icon={faPlus} />
        {text ? text : "Add"}
      </button>
    ))
  );
};

export default CustomAddButton;
