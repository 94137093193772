import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as contractActions from "../../../actions/contractActions";
import * as modalActions from "../../../actions/modalActions";
import * as accountActions from "../../../actions/accountActions";
import * as divisionActions from "../../../actions/divisionActions";
import * as itemActions from "../../../actions/itemActions";
import * as glaccountActions from "../../../actions/glaccountActions";
import * as chargetypeActions from "../../../actions/chargetypeActions";
import * as callActions from "../../../actions/callActions";
import * as codeActions from "../../../actions/codeActions";

import ContractLineList from "./ContractLineList";
import ContractLineForm from "./ContractLineForm";
import BreadCrumbs from "../../common/BreadCrumbs";
import { Row, Col } from "react-bootstrap";
import Modal from "../../common/Modal";
import ConfirmModal from "../../common/ConfirmModal";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import AddButton from "../../common/AddButton";

class ContractLinePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      line: {},
      adding: false,
      editing: true,
    };
  }

  componentDidMount = () => {
    let {
      contract,
      accounts,
      divisions,
      items,
      chargetypes,
      glaccounts,
      actions,
    } = this.props;

    if (isEmpty(contract)) actions.getContract(this.props.match.params.id);
    if (isEmpty(accounts)) actions.loadAccounts();
    if (isEmpty(divisions)) actions.loadDivisions();
    if (isEmpty(items)) actions.loadItems();
    if (isEmpty(chargetypes)) actions.loadChargetypes();
    if (isEmpty(glaccounts)) actions.loadGlaccounts();
  };

  onClickAdd = () => {
    this.setState({ line: {}, adding: true });
    this.props.actions.getContract(this.props.contract._id, true);
  };

  onClickEdit = (contractLineId) => {
    let line = find(this.props.contract.lines, { _id: contractLineId });
    this.setState({ line, adding: false, editing: true });
    this.props.actions.getContract(this.props.contract._id, true);
  };

  onClickCopy = (contractLineId) => {
    let line = find(this.props.contract.lines, { _id: contractLineId });
    // Object Destructuring and Property Shorthand
    let newLine = (({
      division,
      department,
      location,
      start_date,
      end_date,
      gl_rev_code,
    }) => ({
      division,
      department,
      location,
      start_date,
      end_date,
      gl_rev_code,
    }))(line);

    this.handleAdd(this.props.contract, newLine);
  };

  onClickDelete = (contractLineId) => {
    this.props.actions.requestContractId(contractLineId);
  };

  handleAdd = async (contract, line) => {
    await this.props.actions.addLine(contract, line);
    await this.props.actions.getContract(this.props.match.params.id);
  };

  handleSave = async (line) => {
    await this.props.actions.updateLine(this.props.contract, line);
    await this.props.actions.getContract(this.props.match.params.id);
    await this.props.actions.hideModal();
  };

  handleDelete = async () => {
    let line = find(this.props.contract.lines, {
      _id: this.props.contractLineToDelete,
    });
    await this.props.actions.deleteLine(this.props.contract, line);
    await this.props.actions.getContract(this.props.match.params.id);
  };

  render = () => {
    let {
      contract,
      accounts,
      divisions,
      items,
      glaccounts,
      chargetypes,
      codes,
      auth,
    } = this.props;

    let contractLineDetails = (
      <ContractLineForm
        contract={contract}
        line={this.state.line}
        accounts={accounts}
        divisions={divisions}
        items={items}
        glaccounts={glaccounts}
        chargetypes={chargetypes}
        codes={codes}
        onAdd={this.handleAdd}
        onSave={this.handleSave}
        onCancel={this.props.actions.hideModal}
        editing={this.state.editing && auth.maxRole >= 9000}
        adding={this.state.adding}
      />
    );

    return (
      <div className="content-wrapper">
        <Row className="row">
          <BreadCrumbs
            breadcrumbs={[
              { label: `${contract.name}` },
              { label: "Procedures" },
            ]}
          />
          <AddButton onClickAdd={this.onClickAdd} auth={auth} />
        </Row>
        <Row>
          <Col md={12}>
            <ContractLineList
              contractId={contract._id}
              lines={contract.lines}
              onClickEdit={this.onClickEdit}
              onClickCopy={this.onClickCopy}
              onClickDelete={this.onClickDelete}
            />
          </Col>
        </Row>
        <Modal
          id="contractDetailsModal"
          title={this.state.adding ? "Add Procedure" : "Edit Procedure"}
          body={contractLineDetails}
          modal={this.props.modal}
          close={this.props.actions.hideModal}
        />
        <ConfirmModal
          id="contractDeleteModal"
          title="Delete Procedure"
          body="Are you sure you want to delete this line?"
          modal={this.props.modal}
          close={this.props.actions.hideModal}
          confirm={this.handleDelete}
        />
      </div>
    );
  };
}

ContractLinePage.propTypes = {
  actions: PropTypes.object.isRequired,
  params: PropTypes.object,
  savingContract: PropTypes.bool,
  contract: PropTypes.object,
  divisions: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  chargetypes: PropTypes.array.isRequired,
  glaccounts: PropTypes.array.isRequired,
  modal: PropTypes.object,
  calls: PropTypes.array.isRequired,
  codes: PropTypes.array.isRequired,
  contractLineToDelete: PropTypes.string,
  adding: PropTypes.bool,
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    savingContract: state.reducers.savingContract,
    accounts: state.reducers.accounts,
    divisions: state.reducers.divisions,
    glaccounts: state.reducers.glaccounts,
    chargetypes: state.reducers.chargetypes,
    items: state.reducers.items,
    calls: state.reducers.calls,
    codes: state.reducers.codes,
    contract: state.reducers.contract,
    contractLineToDelete: state.reducers.contractToDelete,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...contractActions,
        ...accountActions,
        ...divisionActions,
        ...itemActions,
        ...chargetypeActions,
        ...glaccountActions,
        ...callActions,
        ...codeActions,
      },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(ContractLinePage);
