/* eslint-disable react-refresh/only-export-components */
import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col } from "react-bootstrap";

import * as callActions from "../../actions/callActions";
import * as contractActions from "../../actions/contractActions";
import * as modalActions from "../../actions/modalActions";
import * as userActions from "../../actions/userActions";
import * as itemActions from "../../actions/itemActions";
import * as divisionActions from "../../actions/divisionActions";
import * as chargetypeActions from "../../actions/chargetypeActions";
import * as accountActions from "../../actions/accountActions";
import * as clientActions from "../../actions/clientActions";

import CallApprovalList from "./CallApprovalList";
import CallProcedureForm from "./CallProcedureForm";
import FilterComponent from "../common/FilterComponent";
import BreadCrumbs from "../common/BreadCrumbs";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import CallService from "../../services/callService";

class CallApprovalPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      call: false,
      calls: [],
      count: 0,
      loading: false,
      loadingStart: null,
      selected: [],
      open: true,
      overflow: false,
      filters: {
        parent_account: [],
        contract: [],
        assigned_to: [],
        division: [],
        "assigned_to.job_title": [],
        start_time: moment(new Date()).startOf("week"),
        end_time: moment(new Date()).endOf("week"),
        active: "A",
        page: 0,
        pageSize: 10,
        sorted: [
          {
            id: "assigned_to",
            desc: false,
          },
          {
            id: "start_time",
            desc: false,
          },
        ],
      },
      callToDeleteId: null,
      approving: false,
    };
  }

  componentDidMount = () => {
    let {
      accounts,
      contracts,
      divisions,
      items,
      chargetypes,
      actions,
      clients,
    } = this.props;

    actions.loadUsers(true);

    if (localStorage.getItem("approvedFilters")) {
      this.setState(
        {
          filters: { ...JSON.parse(localStorage.getItem("approvedFilters")) },
        },
        () => {
          this.fetchCalls();
        }
      );
    } else {
      this.fetchCalls();
    }

    // if (isEmpty(users)) actions.loadUsers();
    if (isEmpty(contracts)) actions.loadContracts();
    if (isEmpty(accounts)) actions.loadAccounts();
    if (isEmpty(divisions)) actions.loadDivisions();
    if (isEmpty(items)) actions.loadItems();
    if (isEmpty(chargetypes)) actions.loadChargetypes();
    if (isEmpty(clients)) actions.loadClients();
  };

  prepFilters = (onlyIds) => {
    const transformedObject = {
      page: this.state.filters.page,
      pageSize: this.state.filters.pageSize,
      active: this.state.filters.active,
      authUserId: this.props.auth.user._id,
      authMaxRole: this.props.auth.maxRole,
      authPayrollId: this.props.auth.user.payroll_id,
    };

    if (onlyIds) {
      transformedObject.onlyIds = onlyIds;
    }

    const {
      parent_account,
      contract,
      assigned_to,
      division,
      start_time,
      end_time,
      sorted,
    } = this.state.filters;
    const jobTitle = this.state.filters["assigned_to.job_title"];

    if (parent_account?.length) {
      transformedObject.parent_account = parent_account.map((x) => x.label);
    }
    if (contract?.length) {
      transformedObject.contract = contract.map((x) => x.label);
    }
    if (assigned_to?.length) {
      transformedObject.assigned_to = assigned_to.map((x) => x.value);
    }
    if (jobTitle?.length) {
      transformedObject.jobTitle = jobTitle.map((x) => x.value);
    }
    if (division?.length) {
      transformedObject.division = division.map((x) => x.value);
    }
    if (start_time) {
      transformedObject.start_time = start_time;
    }
    if (end_time) {
      transformedObject.end_time = end_time;
    }
    if (sorted?.length) {
      transformedObject.sort = sorted.map((x) => {
        if (x.id === "assigned_to") {
          return `assigned_to.full_name: ${x.desc ? 1 : -1}`;
        } else if (x.id === "contract") {
          return `contract.name: ${x.desc ? 1 : -1}`;
        } else {
          return `${[x.id]}: ${x.desc ? 1 : -1}`;
        }
      });
    }
    return transformedObject;
  };

  fetchCalls = async () => {
    const start = new Date();
    this.setState({ loading: true, loadingStart: start });
    const calls = await this.props.actions.loadCallsForApproval(
      this.prepFilters()
    );
    if(!this.state.loadingStart || (moment(this.state.loadingStart).isSame(moment(start)))){
      this.setState({
        calls: calls.result,
        count: calls.count,
        totalHours: calls.totalHours,
        loading: false,
      });
    }
  };

  onClickDetail = (e, id) => {
    e.stopPropagation();
    this.props.actions.getCall(id, true);
  };

  onClickDelete = (callId) => {
    this.setState({ callToDeleteId: callId }, () => {
      this.props.actions.showModalSuccess("callDeleteModal");
    });
  };

  handleChangeFilters = (selectedOptions, field) => {
    this.setState(
      {
        filters: { ...this.state.filters, page:0, [field]: selectedOptions },
        selected: [],
      },
      () => {
        this.fetchCalls();
        localStorage.setItem(
          "approvedFilters",
          JSON.stringify(this.state.filters)
        );
      }
    );
  };

  handleChangeDate = (startDate, endDate) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          page:0,
          ...(startDate && { start_time: moment(startDate).startOf("day") }),
          ...(endDate && { end_time: moment(endDate).endOf("day") }),
        },
        selected: [],
      },
      () => {
        this.fetchCalls();
        localStorage.setItem(
          "approvedFilters",
          JSON.stringify(this.state.filters)
        );
      }
    );
  };

  resetFilters = () => {
    this.setState(
      {
        filters: {
          contract: [],
          parent_account: [],
          assigned_to: [],
          division: [],
          "assigned_to.job_title": [],
          start_time: moment(new Date()).startOf("week"),
          end_time: moment(new Date()).endOf("week"),
          active: "A",
          page: 0,
          pageSize: 10,
          sorted: [
            {
              id: "assigned_to",
              desc: false,
            },
            {
              id: "start_time",
              desc: false,
            },
          ],
        },
        selected: [],
      },
      () => {
        this.fetchCalls();
      }
    );
    localStorage.removeItem("approvedFilters");
  };

  handleDelete = async () => {
    await this.props.actions.deleteCall(this.state.callToDeleteId);
    this.setState({ callToDeleteId: null });
    CallService.clearCache();
    this.fetchCalls();
  };

  handleToggle = (call) => {
    const { selected } = this.state;

    const nextSelected = [...selected];

    const idx = nextSelected.findIndex((s) => s._id === call._id);
    if (idx > -1) {
      nextSelected.splice(idx, 1);
    } else {
      nextSelected.push(call);
    }

    this.setState({ selected: nextSelected });
  };

  toggleSelectAll = () => {
    const { calls, selected } = this.state;

    const someSelected = calls.some((call) =>
      selected.some((c) => c._id === call._id)
    );

    let nextSelected = [...selected];

    if (someSelected) {
      // if some are selected unselect them all;
      nextSelected = nextSelected.filter((call) =>
        calls.every((c) => c._id !== call._id)
      );
    } else {
      for (const call of calls) {
        nextSelected.push(call);
      }
    }

    this.setState({ selected: nextSelected });
  };

  handleCancel = () => {
    this.props.actions.hideModal();
  };

  bulkApprove = async () => {
    this.setState({ approving: true });
    const calls = this.state.selected.map((x) => x._id);
    const user = this.props.auth.user;
    const stage = find(
      this.props.codes,
      (code) => code.entity == "Call" && code.label == "Approved"
    );

    // await this.resetFilters();
    await this.props.actions.bulkApprove(calls, user._id, stage._id);

    CallService.clearCache();

    this.setState({ selected: [], approving: false, filters:{...this.state.filters, page:0} },
      () => {
        this.fetchCalls();
      });
  };

  saveEntries = async (entries) => {
    // calculate total
    let total = 0;
    entries.map((entry) => (total += parseFloat(entry.quantity)));

    let data = {
      ...this.props.call,
      quantity: total,
      entries,
    };

    await this.props.actions.updateCall(data);
    await this.fetchCalls();
    this.resetFilters();
    await this.props.actions.hideModal();
  };

  cancelEntries = () => {
    this.props.actions.hideModal();
  };

  setOverflow = () => {
    this.setState({ overflow: true });
  };

  genButtonText = () => {
    const { selected, count, approving } = this.state;
    if (!selected.length) return "APPROVE";
    return `${approving ? "APPROVING" : "APPROVE"} ${selected.length} of ${count} Calls`;
  };

  toggleActiveUsers = (_field, value) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          assigned_to: [],
          active: value,
          page:0
        },
        selected: [],
      },
      () => {
        this.fetchCalls();
        localStorage.setItem(
          "approvedFilters",
          JSON.stringify(this.state.filters)
        );
      }
    );
  };

  handlePageSizeChange = (pageSize) => {
    this.setState({ filters: { ...this.state.filters, pageSize } }, () => {
      this.fetchCalls();
      localStorage.setItem(
        "approvedFilters",
        JSON.stringify(this.state.filters)
      );
    });
  };

  handlePageChange = (page) => {
    this.setState({ filters: { ...this.state.filters, page } }, () => {
      this.fetchCalls();
      localStorage.setItem(
        "approvedFilters",
        JSON.stringify(this.state.filters)
      );
    });
  };

  handleSortedChange = (newSort) => {
    this.setState(
      { filters: { ...this.state.filters, sorted: newSort } },
      () => {
        this.fetchCalls();
        localStorage.setItem(
          "approvedFilters",
          JSON.stringify(this.state.filters)
        );
      }
    );
  };

  render() {
    const { auth, items, contracts, chargetypes, codes, users } = this.props;
    const { selected, approving } = this.state;

    let assigned_to = this.state.assigned_to
      ? this.state.assigned_to
      : this.state.call.assigned_to
        ? this.state.call.assigned_to._id || this.state.call.assigned_to
        : this.props.auth.user._id;

    let timeSheet = (
      <CallProcedureForm
        clearApprovedCalls={this.props.actions.clearApprovedCalls}
        call={this.props.call}
        entries={this.props.call.entries}
        items={items}
        codes={codes}
        clients={this.props.clients}
        assignedTo={assigned_to}
        startTime={this.props.call.start_time}
        users={users}
        auth={auth}
        contract={this.props.call.contract}
        product_category={this.props.call.product_category}
        onOverflow={this.setOverflow}
        chargetypes={chargetypes}
        onSave={this.saveEntries}
        onCancel={this.cancelEntries}
      />
    );

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs breadcrumbs={[{ label: "Time Approval" }]} />
          <Col md={5} className="text-right">
            <button
              className="btn btn-success btn-sm filter"
              type="button"
              onClick={() => this.setState({ open: !this.state.open })}
            >
              <FontAwesomeIcon icon={faFilter} className="icon" />
            </button>
            <button
              className="btn btn-success btn-sm btn-add"
              type="button"
              disabled={selected.length <= 0 || approving}
              onClick={this.bulkApprove}
            >
              {this.genButtonText()}
            </button>
          </Col>
        </Row>
        <FilterComponent
          {...this.props}
          users={users}
          open={this.state.open}
          filters={this.state.filters}
          forApproval={true}
          onChangeFilters={this.handleChangeFilters}
          onChangeDate={this.handleChangeDate}
          onChangeDateMobile={this.handleChangeDateMobile}
          focusedInput={this.state.focusedInput}
          resetFilters={this.resetFilters}
          onFocusChange={(focusedInput) => this.setState({ focusedInput })}
          toggleActive={this.toggleActiveUsers}
        />
        <Row>
          <Col md={12}>
            <CallApprovalList
              calls={this.state.calls}
              count={this.state.count}
              selected={this.state.selected}
              totalHours={this.state.totalHours}
              contracts={contracts}
              items={items}
              onClickDetail={this.onClickDetail}
              onClickDelete={this.onClickDelete}
              filters={this.state.filters}
              clients={this.props.clients}
              loading={this.state.loading}
              onPageSizeChange={this.handlePageSizeChange}
              onPageChange={this.handlePageChange}
              onSortedChange={this.handleSortedChange}
              onToggle={this.handleToggle}
              toggleSelectAll={this.toggleSelectAll}
            />
            <Modal
              id="callDetailsModal"
              title="Time Entries"
              body={timeSheet}
              modal={this.props.modal}
              close={this.props.actions.hideModal}
            />
            <ConfirmModal
              id="callDeleteModal"
              title="Delete Call"
              body="Are you sure you want to delete this call?"
              modal={this.props.modal}
              close={this.props.actions.hideModal}
              confirm={this.handleDelete}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

CallApprovalPage.propTypes = {
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object,
  modal: PropTypes.object,
  callToDelete: PropTypes.string,
  call: PropTypes.object.isRequired,
  callsToApprove: PropTypes.array,
  items: PropTypes.array,
  contracts: PropTypes.array,
  codes: PropTypes.array,
  divisions: PropTypes.array,
  chargetypes: PropTypes.array,
  accounts: PropTypes.array,
  users: PropTypes.array,
  clients: PropTypes.array,
  savingCall: PropTypes.bool,
};

function mapStatesToProps(state) {
  return {
    state: state.reducers,
    auth: state.reducers.auth,
    modal: state.reducers.modal,
    callToDelete: state.reducers.callToDelete,
    call: state.reducers.call,
    callsToApprove: state.reducers.callsToApprove,
    savingCall: state.reducers.savingCall,
    contracts: state.reducers.contracts,
    users: state.reducers.users,
    items: state.reducers.items,
    codes: state.reducers.codes,
    divisions: state.reducers.divisions,
    chargetypes: state.reducers.chargetypes,
    accounts: state.reducers.accounts,
    clients: state.reducers.clients,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...callActions,
        ...contractActions,
        ...modalActions,
        ...itemActions,
        ...userActions,
        ...divisionActions,
        ...chargetypeActions,
        ...accountActions,
        ...clientActions,
      },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(CallApprovalPage);
