import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Row, Col, Well } from "react-bootstrap";
import find from "lodash/find";
import moment from "moment";

const CallProcedureList = ({
  entries,
  contract,
  editing,
  clients,
  requireProcedureChange,
}) => {
  const [data, setData] = useState([]);
  const [showClients, setShowClients] = useState(false);
  const [showBillingQuantity, setShowBillingQuantity] = useState(false);

  useEffect(() => {
    let showClientsBool = entries.find((entry) => entry.client);
    setShowClients(!!showClientsBool);

    let showBillingQuantityBool = entries.find((entry) =>
      ["MIN", "MILE", "STUDENTS"].includes(entry.uom)
    );
    setShowBillingQuantity(!!showBillingQuantityBool);
  }, [entries]);

  useEffect(() => {
    if (entries && clients) {
      const d = entries.map((entry) => {
        const newEntry = { ...entry };

        if (contract) {
          // procedure column
          let procedure = find(contract.lines, { _id: entry.procedure });
          if (procedure) {
            newEntry.procedure = procedure.item.description;
          } else {
            newEntry.procedure = "PLEASE SELECT A NEW PROCEDURE";
          }
          // client column
          if (showClients) {
            let client = find(clients, { _id: entry.client });

            if (client) {
              newEntry.client = `${client.last_name}, ${client.first_name} -
            ${client.city ? `${client.city}` : ""} ${
              client.birth_date
                ? `${moment
                    .duration(moment().diff(client.birth_date))
                    .asYears()
                    .toFixed(1)}yrs`
                : ""
            }`;
            } else {
              let originalClient = find(contract.clients, {
                _id: entry.client,
              });
              if (originalClient) {
                newEntry.client = originalClient.name;
              } else {
                newEntry.client = "--";
              }
            }
          }
        }
        return newEntry;
      });
      setData(d);
    }
  }, [entries, clients, showClients, contract]);

  const columns = [
    {
      Header: "Procedure",
      accessor: "procedure",
      sortable: false,
    },
    {
      Header: "Duration",
      accessor: "quantity",
      sortable: false,
    },
    {
      Header: "UOM",
      accessor: "uom",
      sortable: false,
    },
  ];

  if (contract) {
    if (showClients) {
      columns.push({
        Header: "Client",
        accessor: "client",
      });
    }
    if (showBillingQuantity) {
      columns.push({
        Header: "Billing Quantity",
        accessor: "billing_quantity",
      });
    }
  }

  let styles = {
    background: editing ? "#fefefe" : "#f2f2f2",
    margin: "10px 0",
    borderColor: requireProcedureChange ? "red" : "",
  };

  return (
    <Col md={12} xs={12}>
      {requireProcedureChange ? (
        <span style={{ color: "red" }}>
          Procedures must correspond to the selected contract
        </span>
      ) : null}
      <Well style={styles}>
        <ReactTable
          resizable={false}
          style={{ color: editing ? "inherit" : "#aaa" }}
          data={data}
          columns={columns}
          pageSize={entries ? entries.length : 0}
          showPagination={false}
        />
      </Well>
    </Col>
  );
};

CallProcedureList.propTypes = {
  clients: PropTypes.array,
  entries: PropTypes.array,
  contract: PropTypes.object,
  editing: PropTypes.bool,
  requireProcedureChange: PropTypes.bool,
};

export default CallProcedureList;
