import React, { useState } from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { Textarea, Input } from "formsy-react-components";
import DropdownSearch from "../../common/NewDropdownSearch";
import FileUpload from "../../common/FileUpload";

const ClientNoteForm = ({
  note,
  client,
  onCancel,
  onSave,
  saving,
  divisionsDropdown,
  adding,
  uploadEditAttachment,
  uploadAddAttachment,
  attachments,
  onRemoveAttachmentOnAdding,
  onRemoveAttachmentOnEditing,
}) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState(
    adding ? divisionsDropdown?.default : null
  );
  const enableButton = () => {
    setCanSubmit(true);
  };

  const disableButton = () => {
    setCanSubmit(true);
  };

  const cancel = () => {
    onCancel();
  };

  const submit = (inputs) => {
    onSave(inputs, client, note._id);
  };

  const onChange = (field, selected) => {
    setSelectedDropdown(selected);
  };

  return (
    <Formsy
      className="vertical form"
      id="client-note-form"
      onValidSubmit={submit}
      onValid={enableButton}
      onInvalid={disableButton}
    >
      <Row>
        <Col md={6}>
          <Input
            formNoValidate
            required
            name="title"
            label="Title"
            placeholder="Title"
            value={note.title}
          />
        </Col>
        <Col md={6}>
          <DropdownSearch
            required
            name="division"
            label="Divisions"
            value={selectedDropdown?.value || note.division?._id}
            onChange={onChange}
            data={divisionsDropdown?.options || []}
          />
        </Col>
        <Col md={12}>
          <Textarea
            id="client-note-textarea"
            formNoValidate
            required
            name="note"
            label="Note"
            placeholder="Note"
            value={note.note}
          />
        </Col>
        <Row>
          <fieldset>
            <Col md={12} xs={12}>
              <legend>Attachments</legend>
            </Col>
          </fieldset>
        </Row>
        <Col md={6} xs={12}>
          <FileUpload
            onDrop={(files) =>
              adding
                ? uploadAddAttachment(files)
                : uploadEditAttachment(client, note, files)
            }
          />
        </Col>
        <Col md={6} xs={12}>
          <div className="flex file-container">
            {attachments.map((attachment, index) => (
              <div key={attachment.path} className="file-display">
                <span>{attachment.path}</span>
                <input
                  className="btn btn-danger"
                  type="button"
                  value="Delete"
                  onClick={() =>
                    adding
                      ? onRemoveAttachmentOnAdding(index)
                      : onRemoveAttachmentOnEditing(client, note, index)
                  }
                />
              </div>
            ))}
          </div>
        </Col>
        <Col md={12} xs={12} className="action-footer">
          <button className="btn btn-danger" onClick={cancel} type="button">
            Cancel
          </button>
          <input
            className="btn btn-success"
            type="submit"
            disabled={!canSubmit}
            value={saving ? "Saving... " : "Save"}
          />
        </Col>
      </Row>
    </Formsy>
  );
};

ClientNoteForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  client: PropTypes.object,
  note: PropTypes.object.isRequired,
  divisionsDropdown: PropTypes.object.isRequired,
  adding: PropTypes.bool,
  uploadEditAttachment: PropTypes.func,
  uploadAddAttachment: PropTypes.func,
  attachments: PropTypes.array,
  onRemoveAttachmentOnAdding: PropTypes.func,
  onRemoveAttachmentOnEditing: PropTypes.func,
};
ClientNoteForm.defaultProps = {
  adding: false,
  uploadEditAttachment: () => null,
  uploadAddAttachment: () => null,
  attachments: [],
  onRemoveAttachmentOnAdding: () => null,
  onRemoveAttachmentOnEditing: () => null,
};

export default ClientNoteForm;
