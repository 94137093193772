import * as endpoints from "./apiEndpoints";
import axios from "axios";

class CompanyService {
  static loadCompanies() {
    const url = `${endpoints.BASE_URL}${endpoints.GET_COMPANIES}`;

    return axios.get(url).then((response) => response.data);
  }

  static getCompany(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_COMPANY}/${id}`;

    return axios.get(url).then((response) => response.data);
  }

  static createCompany(company) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_COMPANY}`;
    const body = {
      company: company,
    };

    return axios.post(url, body).then((response) => response.data);
  }

  static createLocation(company, location) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_COMPANY}/${company.id}/location`;
    const body = {
      location: location,
    };
    return axios.post(url, body).then((response) => {
      console.log("response.data", response.data);
      return response.data;
    });
  }

  static updateCompany(company) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_COMPANY}/${company.id}`;
    const body = {
      company: company,
    };

    return axios.put(url, body).then((response) => response.data);
  }

  static updateLocation(company, location) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_COMPANY}/${company.id}/location`;
    const body = {
      location: location,
    };

    return axios.put(url, body).then((response) => response.data);
  }

  static deleteLocation(company, location) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_COMPANY}/${company.id}/location`;
    const body = {
      location: location,
    };

    return axios.delete(url, body).then((response) => response.data);
  }

  static deleteCompany(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_COMPANY}/${id}`;
    return axios.delete(url).then((response) => response.data);
  }
}

export default CompanyService;
