import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { find } from "lodash";
import { Row, Col } from "react-bootstrap";
import { useQuery } from "react-query";

import * as callActions from "../../actions/callActions";
import * as modalActions from "../../actions/modalActions";
import * as chargetypeActions from "../../actions/chargetypeActions";

import AccountService from "../../services/accountService";
import ClientService from "../../services/clientService";
import ContractService from "../../services/contractService";
import UserService from "../../services/userService";

import BreadCrumbs from "../common/BreadCrumbs";
import EditButton from "../common/EditButton";
import Loading from "../common/Loading";

import CallForm from "./NewCallForm/CallFormWrapper";

const INITIAL_STATE = {
  editing: false,
  deleting: false,
  readOnly: false,
  call: {},
};

const CallEditPage = ({
  chargetypes,
  actions,
  call,
  auth,
  router,
  history,
  match,
  codes,
  readOnly,
  loadingCall,
  savingCall,
}) => {
  const [state, setState] = useState(INITIAL_STATE);

  const { data: contracts, isLoading: ContractsLoading } = useQuery(
    "contracts",
    () => ContractService.loadContractsTimeEntry(),
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );
  const { data: clients, isLoading: ClientsLoading } = useQuery(
    "clients",
    () => ClientService.loadClientsTimeEntry(),
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );
  const { data: users, isLoading: UsersLoading } = useQuery(
    "users",
    () => UserService.loadUsers(true),
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );
  const { data: accounts, isLoading: AccountsLoading } = useQuery(
    "accounts",
    () => AccountService.loadAccountsTimeEntry(),
    { initialData: [] }
  );

  useEffect(() => {
    actions.getCall(match.params.id);
    actions.loadChargetypes();
  }, [actions, match.params.id]);

  useEffect(() => {
    if (call) {
      setState((prev) => ({
        ...prev,
        readOnly:
          (auth.maxRole == 4000 && call.completed_by) ||
          (auth.maxRole > 4000 && auth.maxRole <= 8000 && call.approved_by) ||
          call.invoiced_by
            ? true
            : false,
      }));
    }
  }, [call, auth]);

  const handleCancel = () => {
    history.push(
      router.previousLocation == "/app/call-approval"
        ? "/app/call-approval"
        : router.previousLocation == "/app/invoices/add"
          ? "/app/invoices/add"
          : `/app/calls`
    );
  };

  const onClickDelete = () => {
    actions.requestCallId(call._id);
    setState((prev) => ({ ...prev, deleting: true }));
  };

  const handleDelete = async () => {
    await actions.deleteCall(call._id);
    await history.push(
      router.previousLocation == "/app/call-approval"
        ? "/app/call-approval"
        : `/app/calls`
    );
  };

  const handleEdit = () => {
    setState((prev) => ({ ...prev, editing: !state.editing }));
  };

  const onConfirmChange = () => {
    actions.showModalSuccess("callConfirmModal");
  };

  const handleEnterTime = () => {
    actions.showModalSuccess("callDetailsModal");
  };

  const handleSave = (model) => {
    const data = {
      _id: call._id,
      ...model,
    };

    if (data.completed_by) {
      data.completed_date = new Date();
    }

    setState((prev) => ({
      ...prev,
      editing: false,
      readOnly:
        (auth.maxRole == 4000 && call.completed_by) ||
        (auth.maxRole > 4000 && auth.maxRole <= 8000 && call.approved_by) ||
        call.invoiced_by
          ? true
          : false,
    }));
    actions.updateCall(data);
  };

  const onClickSave = (model) => {
    const stage = find(codes, {
      _id: call.stage._id ? call.stage._id : call.stage,
    });
    if (stage.label == "Completed") {
      setState((prev) => ({ ...prev, model: call, deleting: false }));
      actions.requestCallId(call._id);
    } else {
      handleSave(model);
    }
  };

  const loading =
    ContractsLoading || UsersLoading || ClientsLoading || AccountsLoading;

  return (
    <div className="content-wrapper">
      <Row>
        <BreadCrumbs
          breadcrumbs={[
            { label: "Time Entries" },
            { label: "Assignment Info" },
          ]}
        />
        <EditButton onClickEdit={handleEdit} disabled={readOnly} />
      </Row>
      <Row>
        <Col md={12} xs={12}>
          {!loadingCall && !loading ? (
            <CallForm
              call={call}
              contracts={contracts}
              users={users}
              auth={auth}
              chargetypes={chargetypes}
              codes={codes}
              onSave={onClickSave}
              onDelete={onClickDelete}
              handleDelete={handleDelete}
              onCancel={handleCancel}
              saving={savingCall}
              editing={state.editing}
              onEnterTime={handleEnterTime}
              onConfirmChange={onConfirmChange}
              readOnly={readOnly}
              clients={clients}
              deleting={state.deleting}
              onSaveCompletedCall={handleSave}
              accounts={accounts}
            />
          ) : (
            <Loading active />
          )}
        </Col>
      </Row>
    </div>
  );
};

CallEditPage.propTypes = {
  actions: PropTypes.object.isRequired,
  savingCall: PropTypes.bool,
  call: PropTypes.object,
  calls: PropTypes.array,
  auth: PropTypes.object,
  codes: PropTypes.array,
  chargetypes: PropTypes.array,
  params: PropTypes.object,
  router: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  readOnly: PropTypes.bool,
  loadingCall: PropTypes.bool,
};

function mapStatesToProps(state) {
  return {
    state: state.reducers,
    savingCall: state.reducers.savingCall,
    loadingCall: state.reducers.loadingCall,
    call: state.reducers.call,
    calls: state.reducers.calls,
    codes: state.reducers.codes,
    auth: state.reducers.auth,
    router: state.reducers.router,
    chargetypes: state.reducers.chargetypes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...callActions,
        ...chargetypeActions,
      },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(CallEditPage);
