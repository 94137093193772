import * as endpoints from "./apiEndpoints";
import axios from "axios";
import moment from "moment";

class UserService {
  static loadUsers(findAll) {
    let url = `${endpoints.BASE_URL}${endpoints.GET_USERS}`;

    if (findAll) url = url.concat("/findAll/true");

    return axios.get(url).then((response) => response.data);
  }

  static getUser(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_USER}/${id}`;

    return axios.get(url).then((response) => response.data);
  }

  static findByAuthToken(token) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_USER_BY_TOKEN}`;
    const body = {
      token,
    };

    return axios.post(url, body).then((response) => response.data);
  }

  static loginUser(user) {
    const url = `${endpoints.BASE_URL}${endpoints.LOGIN}`;
    const body = {
      user: user,
    };

    return axios.post(url, body).then((response) => {
      localStorage.setItem("es_token", response.data.token);
      localStorage.setItem("username", response.data.user.email);
      return response.data;
    });
  }

  static logoutUser() {
    return new Promise((resolve, reject) => {
      localStorage.clear();
      resolve(true);
    });
  }

  static forgotPassword(user) {
    const url = `${endpoints.BASE_URL}${endpoints.FORGOT}`;
    const body = {
      email: user.email,
    };

    return axios.post(url, body).then((response) => response.data);
  }

  static resetPassword(user) {
    const url = `${endpoints.BASE_URL}${endpoints.RESET}`;

    return axios.post(url, user).then((response) => response.data);
  }

  static createUser(user) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_USER}`;
    const body = {
      user: user,
    };
    return axios.post(url, body).then((response) => response.data);
  }

  static updateUser(user) {
    console.log("newUserData", user);
    const url = `${endpoints.BASE_URL}${endpoints.PUT_USER}/${user.id}`;
    const body = {
      user: user,
    };

    return axios.put(url, body).then((response) => response.data);
  }

  static deleteUser(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_USER}/${id}`;

    return axios.delete(url).then((response) => response.data);
  }

  // ---- ADP -------
  static syncAdpWorkerData() {
    const url = `${endpoints.BASE_URL}${endpoints.GET_ADP_WORKERS}`;
    console.log("worker fired");
    return axios.get(url).then((response) => response.data);
  }

  static syncAdpCertData() {
    const url = `${endpoints.BASE_URL}${endpoints.GET_ADP_CERTS}`;
    console.log("cert fired: ", moment().format("hh:mm:ss"));
    return axios.get(url).then((response) => {
      console.log("response fired: ", moment().format("hh:mm:ss"));
      console.log("response: ", response.data);
      response.data;
    });
  }

  static adpBulkUpdate(data) {
    const url = `${endpoints.BASE_URL}${endpoints.BULK_UPDATE_ADP_CERTS}`;

    const body = {
      adpUser: data,
    };
    console.log("adpBulkUpdate fired", body.adpUser);
    return axios.post(url, body).then((response) => response.data);
  }
}

export default UserService;
