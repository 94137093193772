import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as accountActions from "../../actions/accountActions";
import * as contactActions from "../../actions/contactActions";
import * as userActions from "../../actions/userActions";
import * as modalActions from "../../actions/modalActions";

import AccountNoteForm from "./AccountNoteForm";
import AccountNoteList from "./AccountNoteList";
import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import Modal from "../common/Modal";
import { Row, Col } from "react-bootstrap";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";

class AccountNotesPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { note: {} };
  }

  componentDidMount = () => {
    let { account, users, actions } = this.props;
    if (isEmpty(account)) actions.getAccount(this.props.match.params.id);
    actions.loadUsers();
  };

  onClickAdd = () => {
    this.props.actions.getAccount(this.props.account._id, true);
  };

  onClickEdit = (accountNoteId) => {
    let note = find(this.props.account.notes, { _id: accountNoteId });
    this.props.actions.getAccount(this.props.account._id, true);
  };

  handleCancel = () => {
    this.props.actions.hideModal();
  };

  handleSave = async (account, note) => {
    await this.props.actions.createNote(account, note);
    await this.props.actions.getAccount(account.id);
  };

  render() {
    let { account, users, modal, auth } = this.props;

    let noteForm = (
      <AccountNoteForm
        account={account}
        note={this.state.note}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
      />
    );

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: `${account.name}` }, { label: "Notes" }]}
          />
          <AddButton onClickAdd={this.onClickAdd} auth={auth} />
        </Row>
        <Row>
          <Col md={12}>
            <AccountNoteList account={account} users={users} />
          </Col>
        </Row>
        <Modal
          id="accountDetailsModal"
          title={"Add Account Note"}
          body={noteForm}
          modal={modal}
          close={this.props.actions.hideModal}
        />
      </div>
    );
  }
}

AccountNotesPage.propTypes = {
  actions: PropTypes.object.isRequired,
  account: PropTypes.object,
  users: PropTypes.array,
  note: PropTypes.object,
  modal: PropTypes.object,
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    account: state.reducers.account,
    users: state.reducers.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...accountActions,
        ...contactActions,
        ...userActions,
        ...modalActions,
      },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(AccountNotesPage);
