import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const chargetypes = (state = initialState.chargetypes, action) => {
  switch (action.type) {
    case types.LOAD_CHARGETYPE_SUCCESS:
      return action.chargetypes;

    case types.CREATE_CHARGETYPE_SUCCESS:
      return [...state, Object.assign({}, action.chargetypes)];

    case types.UPDATE_CHARGETYPE_SUCCESS:
      return [
        ...state.filter((items) => items.id !== action.chargetype.id),
        Object.assign({}, action.chargetype),
      ];

    case types.DELETE_CHARGETYPE_SUCCESS:
      return [
        ...state.filter((items) => items.id !== action.chargetype.id),
        Object.assign({}, action.chargetype),
      ];

    default:
      return state;
  }
};

export const chargetype = (state = initialState.chargetype, action) => {
  switch (action.type) {
    case types.GET_CHARGETYPE_SUCCESS:
      return action.chargetype;

    default:
      return state;
  }
};

export const savingChargetype = (
  state = initialState.savingChargetype,
  action
) => {
  switch (action.type) {
    case types.SAVING_CHARGETYPE:
      return action.savingChargetype;

    default:
      return state;
  }
};

export const chargetypeToDelete = (
  state = initialState.chargetypeToDelete,
  action
) => {
  switch (action.type) {
    case types.REQUEST_CHARGETYPE_ID:
      return action.chargetypeToDelete;

    default:
      return state;
  }
};
