import React, { useState, useRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { Row, Col, FormControl, Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import DropdownSearch from "../common/DropdownSearchWithoutFormsy";
import CityAndZipDropdown from "../common/CityAndZipDropdown";
import CreatableSelect from "../common/CreatableSelectWithoutFormsy";
import ContactInfoFormSection from "./components/ContactInfoFormSection";
import VeteranInfoFormSection from "./components/VeteranInfoFormSection";

import orderBy from "lodash/orderBy";
import "./ClientForm.scss";

const ClientForm = ({
  inputs,
  adding,
  editing,
  saving,
  onSubmit,
  onCancel,
  onChange,
  codes,
  clients,
  client,
  contacts,
  handleCheckUniqueName,
  zips,
  onMultipleChange,
  onReferredByChange,
}) => {
  const [enabled, setEnabled] = useState(true);
  const [contactInfoExpanded, setContactInfoExpanded] = useState(false);
  const [veteranInfoExpanded, setVeteranInfoExpanded] = useState(false);
  const [youthInfoExpanded, setYouthInfoExpanded] = useState(false);
  const [dbInfoExpanded, setDbInfoExpanded] = useState(false);

  const Ref = useRef();
  const generateCodeOptions = useCallback(
    (entity, field) => {
      const codeOptions = codes
        .filter((code) => code.entity == entity && code.field == field)
        .map((code) => ({
          value: code._id,
          label: code.label,
        }));
      return orderBy(codeOptions, "label");
    },
    [codes]
  );

  if (adding) {
    inputs.address = inputs.address || {};
  }

  const age = inputs.birth_date
    ? moment
        .duration(moment().diff(moment.utc(inputs.birth_date)))
        .asYears()
        .toFixed(1)
    : inputs.est_birth_date
      ? moment
          .duration(moment().diff(moment.utc(inputs.est_birth_date)))
          .asYears()
          .toFixed(1)
      : "";

  const genderCodes = useMemo(
    () => [...generateCodeOptions("Client", "gender")],
    [generateCodeOptions]
  );
  const ethnicityCodes = useMemo(
    () => [...generateCodeOptions("Client", "Ethnicity")],
    [generateCodeOptions]
  );
  const disabilityCodes = useMemo(
    () => [...generateCodeOptions("Client", "Disability")],
    [generateCodeOptions]
  );
  const stateCodes = useMemo(
    () => [...generateCodeOptions("*", "state")],
    [generateCodeOptions]
  );
  const mrcActivityCodes = useMemo(
    () => [...generateCodeOptions("Client", "MRCActivity")],
    [generateCodeOptions]
  );
  const mrcatActivityCodes = useMemo(
    () => [...generateCodeOptions("Client", "MRCAtActivity")],
    [generateCodeOptions]
  );
  const tapActivityCodes = useMemo(
    () => [...generateCodeOptions("Client", "TapActivity")],
    [generateCodeOptions]
  );
  const shirtSizeCodes = useMemo(
    () => [...generateCodeOptions("Client", "ShirtSize")],
    [generateCodeOptions]
  );

  return (
    <div
      ref={Ref}
      id="client-form"
      className="vertical form"
      onValid={() => setEnabled(true)}
      onInvalid={() => setEnabled(false)}
    >
      <Row>
        <fieldset>
          {!adding && (
            <Col md={2} xs={12}>
              <Row className="form-group">
                <Col sm={12} xs={12}>
                  <label
                    className="control-label"
                    htmlFor="number"
                    style={{ textTransform: "uppercase" }}
                  >
                    Client Number
                  </label>
                </Col>
                <Col sm={12} xs={12}>
                  <FormControl
                    required
                    disabled
                    label="Client Number"
                    name="number"
                    value={inputs.number || ""}
                    onChange={({ target: { name, value } }) =>
                      onChange(name, value)
                    }
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col md={adding ? 3 : 2} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="first_name"
                  style={{ textTransform: "uppercase" }}
                >
                  First Name
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  required
                  disabled={!editing}
                  name="first_name"
                  label="First Name"
                  placeholder="First Name"
                  value={inputs.first_name || ""}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={adding ? 3 : 2} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="last_name"
                  style={{ textTransform: "uppercase" }}
                >
                  Last Name
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  required
                  disabled={!editing}
                  name="last_name"
                  label="Last Name"
                  placeholder="Last Name"
                  value={inputs.last_name || ""}
                  onBlur={handleCheckUniqueName}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={12}>
            <DropdownSearch
              data={genderCodes}
              disabled={!editing}
              name="gender"
              label="Sex"
              value={inputs.gender}
              onChange={(name, { value }) => onChange(name, value)}
            />
          </Col>
          <Col md={2} xs={12}>
            <DropdownSearch
              data={ethnicityCodes}
              disabled={!editing}
              name="ethnicity"
              label="Race & Ethnicity"
              value={inputs.ethnicity}
              onChange={(name, { value }) => onChange(name, value)}
            />
          </Col>
          <Col md={2} xs={12}>
            <DropdownSearch
              name="status"
              label="Status"
              onChange={(name, value) =>
                onChange("status", value.value.toString())
              }
              disabled={!editing}
              value={inputs.status.toString()}
              data={[
                { value: "true", label: "Active" },
                { value: "false", label: "Inactive" },
              ]}
            />
          </Col>
        </fieldset>
      </Row>
      <Row>
        <fieldset>
          <Col md={2} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="birth_date"
                  style={{ textTransform: "uppercase" }}
                >
                  Birth Date
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  formNoValidate
                  type="date"
                  required={!inputs.birth_date && !inputs.est_birth_date}
                  disabled={!editing}
                  name="birth_date"
                  label="Birth Date"
                  value={
                    inputs.birth_date
                      ? moment.utc(inputs.birth_date || "").format("YYYY-MM-DD")
                      : ""
                  }
                  validations={{
                    isValidDate: (values, value) =>
                      !value ||
                      (value && value <= moment.utc().format("YYYY-MM-DD")),
                  }}
                  validationErrors={{
                    isValidDate: "Please enter a valid birth date",
                  }}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="est_birth_date"
                  style={{ textTransform: "uppercase" }}
                >
                  Estimated Birth Date
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  formNoValidate
                  type="date"
                  disabled={!editing}
                  value={
                    inputs.est_birth_date
                      ? moment
                          .utc(inputs.est_birth_date || "")
                          .format("YYYY-MM-DD")
                      : ""
                  }
                  validations={{
                    isValidDate: (values, value) =>
                      !value ||
                      (value && value <= moment.utc().format("YYYY-MM-DD")),
                  }}
                  validationErrors={{
                    isValidDate: "Please enter a valid birth date",
                  }}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                  required={!inputs.est_birth_date && !inputs.birth_date}
                  name="est_birth_date"
                  label="Estimated Birth Date"
                />
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="age"
                  style={{ textTransform: "uppercase" }}
                >
                  Age
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  readOnly
                  disabled
                  name="age"
                  label="Age"
                  value={age}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="reference_number"
                  style={{ textTransform: "uppercase" }}
                >
                  Reference Number
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  disabled={!editing}
                  name="reference_number"
                  label="Reference Number"
                  value={inputs.reference_number || ""}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={12}>
            <DropdownSearch
              isMulti
              data={disabilityCodes}
              disabled={!editing}
              label="Disability"
              name="disability"
              value={inputs.disability || ""}
              onChange={(name, value) => onChange(name, value)}
            />
          </Col>
          <Col md={2} xs={12}>
            {inputs.optional_referred_by && !inputs.referred_by ? (
              <Row className="form-group">
                <Col sm={12} xs={12}>
                  <label
                    className="control-label"
                    htmlFor="optional_referred_by"
                    style={{ textTransform: "uppercase" }}
                  >
                    Referred By
                  </label>
                </Col>
                <Col sm={12} xs={12}>
                  <FormControl
                    disabled={!editing}
                    name="optional_referred_by"
                    label="Referred By"
                    placeholder="Referred By"
                    value={inputs.optional_referred_by || ""}
                    onFocus={() =>
                      onMultipleChange({
                        optional_referred_by: null,
                        referred_by: null,
                      })
                    }
                    onChange={({ target: { name, value } }) =>
                      onChange(name, value)
                    }
                  />
                </Col>
              </Row>
            ) : (
              <CreatableSelect
                data={[...generateCodeOptions("Client", "ReferredBy")]}
                disabled={!editing}
                label="Referred By"
                name="referred_by"
                value={inputs.referred_by}
                onChange={(name, { value }, newObject) =>
                  onReferredByChange(name, value, newObject)
                }
              />
            )}
          </Col>
        </fieldset>
      </Row>
      <Row>
        <fieldset>
          <Col md={3} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="street_address"
                  style={{ textTransform: "uppercase" }}
                >
                  Street Address 1
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  disabled={!editing}
                  label="Street Address 1"
                  name="street_address"
                  value={inputs.street_address || ""}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={3} xs={12}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="street_address2"
                  style={{ textTransform: "uppercase" }}
                >
                  Street Address 2
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <FormControl
                  disabled={!editing}
                  label="Street Address 2"
                  name="street_address2"
                  value={inputs.street_address2 || ""}
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={12}>
            <DropdownSearch
              required
              data={stateCodes}
              disabled={!editing}
              label="State"
              name="state"
              value={inputs.state}
              onChange={(name, { value }) => onChange(name, value)}
            />
          </Col>
          <CityAndZipDropdown
            zips={zips}
            city={inputs.city}
            county={inputs.county}
            zip={inputs.zip}
            disabled={!editing}
            onChange={onMultipleChange}
          />
        </fieldset>
      </Row>
      <Row>
        <fieldset>
          <Col md={12} xs={12}>
            <div
              onClick={() => setContactInfoExpanded(!contactInfoExpanded)}
              aria-controls="contact-section"
              aria-expanded={contactInfoExpanded}
            >
              <legend>
                {`Contact Info   `}
                <FontAwesomeIcon
                  icon={contactInfoExpanded ? faCaretUp : faCaretDown}
                />
              </legend>
            </div>
          </Col>
        </fieldset>
      </Row>
      <Collapse in={contactInfoExpanded}>
        <div id="contact-section">
          {contactInfoExpanded && (
            <ContactInfoFormSection
              inputs={inputs}
              editing={editing}
              onChange={onChange}
              codes={codes}
              clients={clients}
              client={client}
            />
          )}
        </div>
      </Collapse>
      <Row>
        <fieldset>
          <Col md={12} xs={12}>
            <div
              onClick={() => setVeteranInfoExpanded(!veteranInfoExpanded)}
              aria-controls="veteran-section"
              aria-expanded={veteranInfoExpanded}
            >
              <legend>
                {`Veteran Info   `}
                <FontAwesomeIcon
                  icon={veteranInfoExpanded ? faCaretUp : faCaretDown}
                />
              </legend>
            </div>
          </Col>
        </fieldset>
      </Row>
      <Collapse in={veteranInfoExpanded}>
        <div id="veteran-section">
          {veteranInfoExpanded && (
            <VeteranInfoFormSection
              inputs={inputs}
              editing={editing}
              onChange={onChange}
              codes={codes}
              contacts={contacts}
            />
          )}
        </div>
      </Collapse>
      <Row>
        <fieldset>
          <Col md={12} xs={12}>
            <div
              onClick={() => setYouthInfoExpanded(!youthInfoExpanded)}
              aria-controls="youth-section"
              aria-expanded={youthInfoExpanded}
            >
              <legend>
                {`Youth Info   `}
                <FontAwesomeIcon
                  icon={youthInfoExpanded ? faCaretUp : faCaretDown}
                />
              </legend>
            </div>
          </Col>
        </fieldset>
      </Row>
      <Collapse in={youthInfoExpanded}>
        <div id="youth-section">
          <Row>
            <fieldset>
              <Col md={3} xs={12}>
                <DropdownSearch
                  data={mrcActivityCodes}
                  disabled={!editing}
                  label="MRC Activity"
                  name="mrc_activity"
                  value={inputs.mrc_activity}
                  onChange={(name, { value }) => onChange(name, value)}
                />
              </Col>
              <Col md={3} xs={12}>
                <DropdownSearch
                  data={mrcatActivityCodes}
                  disabled={!editing}
                  label="MRC-AT Activity"
                  name="mrc_at_activity"
                  value={inputs.mrc_at_activity}
                  onChange={(name, { value }) => onChange(name, value)}
                />
              </Col>
              <Col md={3} xs={12}>
                <DropdownSearch
                  data={tapActivityCodes}
                  disabled={!editing}
                  label="TAP Activity"
                  name="tap_activity"
                  value={inputs.tap_activity}
                  onChange={(name, { value }) => onChange(name, value)}
                />
              </Col>
              <Col md={3} xs={12}>
                <DropdownSearch
                  data={shirtSizeCodes}
                  disabled={!editing}
                  label="Shirt Size"
                  name="shirt_size"
                  value={inputs.shirt_size}
                  onChange={(name, { value }) => onChange(name, value)}
                />
              </Col>
            </fieldset>
          </Row>
        </div>
      </Collapse>
      {!adding && (
        <>
          <Row>
            <fieldset>
              <Col md={12} xs={12}>
                <div
                  onClick={() => setDbInfoExpanded(!dbInfoExpanded)}
                  aria-controls="db-section"
                  aria-expanded={dbInfoExpanded}
                >
                  <legend>
                    {`Created/Modified   `}
                    <FontAwesomeIcon
                      icon={dbInfoExpanded ? faCaretUp : faCaretDown}
                    />
                  </legend>
                </div>
              </Col>
            </fieldset>
          </Row>
          <Collapse in={dbInfoExpanded}>
            <div id="db-section">
              <Row>
                <fieldset>
                  <Col md={3} xs={12}>
                    <Row className="form-group">
                      <Col sm={12} xs={12}>
                        <label
                          className="control-label"
                          htmlFor="created_on"
                          style={{ textTransform: "uppercase" }}
                        >
                          Created On
                        </label>
                      </Col>
                      <Col sm={12} xs={12}>
                        <FormControl
                          readOnly
                          disabled
                          name="created_on"
                          label="Created On"
                          value={
                            inputs.created_on
                              ? moment(inputs.created_on).format("MM/DD/YY")
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={3} xs={12}>
                    <Row className="form-group">
                      <Col sm={12} xs={12}>
                        <label
                          className="control-label"
                          htmlFor="created_by"
                          style={{ textTransform: "uppercase" }}
                        >
                          Created By
                        </label>
                      </Col>
                      <Col sm={12} xs={12}>
                        <FormControl
                          readOnly
                          disabled
                          name="created_by"
                          label="Created By"
                          value={inputs.created_by || ""}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={3} xs={12}>
                    <Row className="form-group">
                      <Col sm={12} xs={12}>
                        <label
                          className="control-label"
                          htmlFor="modified_on"
                          style={{ textTransform: "uppercase" }}
                        >
                          Modified On
                        </label>
                      </Col>
                      <Col sm={12} xs={12}>
                        <FormControl
                          readOnly
                          disabled
                          name="modified_on"
                          label="Modified On"
                          value={
                            inputs.modified_on
                              ? moment(inputs.modified_on).format("MM/DD/YY")
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={3} xs={12}>
                    <Row className="form-group">
                      <Col sm={12} xs={12}>
                        <label
                          className="control-label"
                          htmlFor="modified_by"
                          style={{ textTransform: "uppercase" }}
                        >
                          Modified By
                        </label>
                      </Col>
                      <Col sm={12} xs={12}>
                        <FormControl
                          readOnly
                          disabled
                          name="modified_by"
                          label="Modified By"
                          value={inputs.modified_by || ""}
                        />
                      </Col>
                    </Row>
                  </Col>
                </fieldset>
              </Row>
            </div>
          </Collapse>
        </>
      )}
      <Row>
        <Col md={12} xs={12} className="action-footer">
          <button className="btn btn-danger" type="button" onClick={onCancel}>
            Cancel
          </button>
          <input
            className="btn btn-success"
            type="submit"
            disabled={
              !enabled ||
              !inputs.first_name ||
              !inputs.last_name ||
              (!inputs.birth_date && !inputs.est_birth_date) ||
              !inputs.state
            }
            value={saving ? "Saving... " : "Save"}
            onClick={onSubmit}
          />
        </Col>
      </Row>
    </div>
  );
};

ClientForm.propTypes = {
  codes: PropTypes.array,
  onCancel: PropTypes.func,
  adding: PropTypes.bool,
  editing: PropTypes.bool,
  saving: PropTypes.bool,
  inputs: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  clients: PropTypes.array,
  client: PropTypes.object,
  contacts: PropTypes.array,
  handleCheckUniqueName: PropTypes.func,
  zips: PropTypes.array,
  onMultipleChange: PropTypes.func,
  onReferredByChange: PropTypes.func,
};

ClientForm.defaultProps = {
  codes: [],
  onCancel: () => null,
  adding: true,
  editing: true,
  saving: false,
  onSubmit: () => null,
  clients: [],
  client: null,
  contacts: [],
  handleCheckUniqueName: () => null,
  zips: [],
  onMultipleChange: () => null,
  onReferredByChange: () => null,
};

export default ClientForm;
