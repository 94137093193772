import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import CallService from "../../services/callService";

import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { faFolder, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { LinkContainer } from "react-router-bootstrap";

import moment from "moment";
import isObject from "lodash/isObject";
import find from "lodash/find";

const CallList = ({
  calls,
  filtered,
  users,
  contracts,
  codes,
  pages,
  loading,
  auth,
  onFetchData,
  onClickView,
  onClickDelete,
  accounts,
}) => {
  const [total, setTotal] = useState(0);
  const fetchData = async (state) => {
    const { sorted, filtered, page, pageSize } = state;
    onFetchData({ sorted, filtered, page, pageSize });

    let totalHours = await CallService.getTotalHours({ filtered, auth });
    setTotal(totalHours[0] ? totalHours[0].total_hours : 0);
  };

  useEffect(() => {
    fetchData({
      sorted: [
        {
          id: "start_time",
          desc: true,
        },
      ],
      filtered,
      page: 0,
      pageSize: 10,
    });
  }, [filtered]);

  const columns = [
    {
      Header: "Stage",
      accessor: "stage",
      Cell: ({ value }) => {
        const stage = find(codes, { _id: isObject(value) ? value._id : value });
        return stage ? stage.label : "--";
      },
    },
    {
      Header: "Assigned To",
      accessor: "assigned_to",
      Cell: ({ value }) => {
        const user = find(users, { _id: isObject(value) ? value._id : value });
        return user ? user.full_name : "--";
      },
    },
    {
      Header: "Account",
      accessor: "parent_account",
      Cell: ({ value }) => {
        const account = find(accounts, {
          _id: isObject(value) ? value._id : value,
        });
        return account ? account.name : "--";
      },
    },
    {
      Header: "Contract",
      accessor: "contract",
      Cell: ({ value }) => {
        const contract = find(contracts, {
          _id: isObject(value) ? value._id : value,
        });
        return contract ? contract.name : "--";
      },
    },
    {
      Header: "Discipline",
      accessor: "product_category",
      // maxWidth: 120,
      Cell: ({ value }) => {
        const prodCat = find(codes, {
          _id: isObject(value) ? value._id : value,
        });
        return prodCat ? prodCat.label : "--";
      },
    },
    {
      Header: "Start Time",
      accessor: "start_time",
      filterable: false,
      Cell: (row) =>
        row.value ? moment(row.value).format("MM/DD/YY hh:mm A") : "--",
    },
    {
      Header: "End Time",
      accessor: "end_time",
      filterable: false,
      Cell: (row) =>
        row.value ? moment(row.value).format("MM/DD/YY hh:mm A") : "--",
    },
    {
      Header: "Hours",
      accessor: "quantity",
      maxWidth: 80,
      Cell: (row) => (row.value ? row.value.toFixed(2) : "--"),
      Footer: (
        <span className="footer-totals">
          <p>
            <label>Total Hours: </label>
            {total.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        </span>
      ),
    },
    {
      accessor: "_id",
      filterable: false,
      sortable: false,
      Cell: ({ original, value }) => {
        let stage = find(codes, {
          _id: isObject(original.stage) ? original.stage._id : original.stage,
        });

        stage = stage ? stage.label : null;
        return (
          <div className="text-center actions">
            <DropdownButton
              title="Actions"
              id="dropdown"
              onClick={(e) => e.stopPropagation()}
            >
              <LinkContainer to={`/app/calls/${value}`}>
                <MenuItem>
                  <FontAwesomeIcon icon={faFolder} />
                  View
                </MenuItem>
              </LinkContainer>
              {/* Calls can be deleted if
              - call is not invoiced
              - user is non-admin and call is not yet approved
              - user is employee and call is scheduled
              */}
              {stage !== "Invoiced" &&
                (auth.maxRole == 9000 ||
                  (auth.maxRole > 4000 &&
                    auth.maxRole <= 8000 &&
                    stage !== "Approved") ||
                  (auth.maxRole == 4000 && stage == "Scheduled")) && (
                  <MenuItem onClick={() => onClickDelete(value)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                    Delete
                  </MenuItem>
                )}
            </DropdownButton>
          </div>
        );
      },
    },
  ];

  const props = {
    getTdProps: (state, rowInfo, column) => {
      return {
        onClick: () => {
          if (column.id !== "_id") {
            onClickView(rowInfo.original._id);
          }
        },
      };
    },
  };

  return (
    <ReactTable
      className="-highlight"
      data={calls}
      columns={columns}
      // loading={loading}
      {...props}
      manual
      pages={pages}
      onFetchData={fetchData}
      filtered={filtered}
      pageSize={10}
      defaultPageSize={10}
      defaultSorted={[
        {
          id: "start_time",
          desc: true,
        },
      ]}
    />
  );
};

CallList.propTypes = {
  calls: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onClickEdit: PropTypes.func,
  onClickCopy: PropTypes.func,
  onClickDelete: PropTypes.func,
  filter: PropTypes.object,
  onChange: PropTypes.func,
  auth: PropTypes.object,
};

export default CallList;
