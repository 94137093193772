import React, { Component } from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { Textarea, Input } from "formsy-react-components";

class ContractNoteForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
    };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  cancel = () => {
    this.props.onCancel();
  };

  submit = (model) => {
    this.props.onSave(this.props.contract, model, this.props.note._id);
  };

  render() {
    let { note } = this.props;
    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={12}>
            <Input
              formNoValidate
              required
              name="title"
              label="Title"
              placeholder="Title"
              value={note.title}
            />
          </Col>
          <Col md={12}>
            <Textarea
              formNoValidate
              required
              name="note"
              label="Note"
              placeholder="Note"
              value={note.note}
            />
          </Col>
          <Col md={12} xs={12} className="action-footer">
            <button
              className="btn btn-danger"
              onClick={this.cancel}
              type="button"
            >
              Cancel
            </button>
            <input
              className="btn btn-success"
              type="submit"
              disabled={!this.state.canSubmit}
              value={this.props.saving ? "Saving... " : "Save"}
            />
          </Col>
        </Row>
      </Formsy>
    );
  }
}

ContractNoteForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  contract: PropTypes.object.isRequired,
};

export default ContractNoteForm;
