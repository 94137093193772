import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import { Input } from "formsy-react-components";
import DropdownSearch from "../common/DropdownSearchWithoutFormsy";
import ClientSelect from "../common/ClientSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import capitalize from "lodash/capitalize";
import debounce from "lodash/debounce";

const CallProcedureFormItem = ({
  idx,
  row,
  chargetypes,
  tracksClients,
  procedureOptions,
  onChange,
  onChangeQuantity,
  allowAnonymous,
  refNumberRequired,
  deleteRow,
}) => {
  const [isBillable, setIsBillable] = useState(false);
  const [isBillableNonPayroll, setIsBillableNonPayroll] = useState(false);
  const [isFixed, setIsFixed] = useState();
  const [trackBillingQuantity, setTrackBillingQuantity] = useState();

  useEffect(() => {
    if (row.charge_type) {
      let chargetype = chargetypes.find(
        (chargetype) => chargetype._id == row.charge_type
      );
      if (chargetype) {
        const billable =
          chargetype.type !== "Personal" || chargetype.type !== "Overhead";
        const billableNonPayroll = chargetype.type == "Billable Non-Payroll";
        setIsBillable(billable);
        setIsBillableNonPayroll(billableNonPayroll);
      } else setIsBillable(false);
    }
  }, [row.charge_type]);

  useEffect(() => {
    if (row.uom) {
      setIsFixed(row.uom === "FIXED");
      setTrackBillingQuantity(["MIN", "MILE", "STUDENTS"].includes(row.uom));
    }
  }, [row.uom]);

  return (
    <Row>
      <Col
        md={(isBillable && tracksClients) || trackBillingQuantity ? 4 : 6}
        xs={12}
      >
        <DropdownSearch
          required
          name={`procedure ${idx}`}
          label="Procedure"
          value={row.procedure}
          data={procedureOptions}
          onChange={onChange}
        />
      </Col>
      <Col
        md={(isBillable && tracksClients) || trackBillingQuantity ? 3 : 5}
        xs={12}
      >
        <Input
          id="callProcedureDurationInput"
          required
          formNoValidate
          step={0.25}
          type="number"
          name={`quantity ${idx}`}
          label={`Duration (Payroll Hrs)`}
          disabled={isBillableNonPayroll && !isFixed}
          value={!isBillableNonPayroll || isFixed ? row.quantity : 0}
          onChange={debounce(onChangeQuantity, 500)}
        />
      </Col>
      {trackBillingQuantity && (
        <Col md={4} xs={12}>
          <Input
            formNoValidate
            type="number"
            step={0.01}
            name={`billing_quantity ${idx}`}
            label={`Billing Quantity (${capitalize(row.uom)})`}
            value={row.billing_quantity}
            onChange={onChangeQuantity}
          />
        </Col>
      )}
      {tracksClients && isBillable && (
        <Col md={4} xs={12}>
          <ClientSelect
            required={!allowAnonymous}
            name={`client ${idx}`}
            label="Client"
            value={row.client}
            onChange={onChange}
          />
          {refNumberRequired && (
            <span className="error-message">
              This contract requires a client reference number. Please add one
              in the client section
            </span>
          )}
        </Col>
      )}
      <Col md={1} xs={12}>
        <button
          className="btn btn-delete"
          type="button"
          onClick={() => deleteRow(idx)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Col>
    </Row>
  );
};

CallProcedureFormItem.propTypes = {
  idx: PropTypes.number,
  row: PropTypes.object,
  chargetypes: PropTypes.array,
  tracksClients: PropTypes.bool,
  procedureOptions: PropTypes.array,
  onChange: PropTypes.func,
  onChangeQuantity: PropTypes.func,
  allowAnonymous: PropTypes.bool,
  refNumberRequired: PropTypes.bool,
  deleteRow: PropTypes.func,
};

CallProcedureFormItem.defaultProps = {};

const equals = (a, b) => {
  if (a === b) return true;

  if (a instanceof Date && b instanceof Date)
    return a.getTime() === b.getTime();

  if (!a || !b || (typeof a !== "object" && typeof b !== "object"))
    return a === b;

  if (a.prototype !== b.prototype) return false;

  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) return false;

  return keys.every((k) => equals(a[k], b[k]));
};

export default memo(
  CallProcedureFormItem,
  // improve rendering performance
  (prevProps, nextProps) => {
    return equals(prevProps, nextProps);
  }
);
