import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as invoiceActions from "../../actions/invoiceActions";

import ReactTable from "react-table";
import Loading from "../common/Loading";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faFileExport } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";

class InvoiceList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { invoices, selected, loading } = this.props;

    const allSelected = invoices.every((invoice) =>
      selected.some((i) => i._id === invoice._id)
    );

    const columns = [
      {
        accessor: "_id",
        Header: () => (
          <input
            type="checkbox"
            style={{ margin: "3px auto" }}
            onChange={this.props.toggleSelectAll}
            checked={allSelected}
            defaultChecked={allSelected}
          />
        ),
        Cell: (row) => (
          <input
            type="checkbox"
            onChange={() => this.props.onToggle(row.original)}
            defaultChecked={selected.some(
              (invoice) => invoice.number === row.original.number
            )}
            checked={selected.some(
              (invoice) => invoice.number === row.original.number
            )}
          />
        ),
        sortable: false,
        filterable: false,
        maxWidth: 60,
        style: {
          textAlign: "center",
        },
      },
      {
        Header: "Number",
        accessor: "number",
        id: "number",
        maxWidth: 120,
      },
      {
        Header: "Account",
        accessor: "parent_account.name",
      },
      {
        Header: "Contract",
        accessor: "contract.name",
      },
      {
        Header: "Date",
        accessor: "document_date",
        Cell: (row) => (
          <span>{moment.utc(row.value).format("MM/DD/YYYY")}</span>
        ),
      },
      {
        Header: "Format",
        accessor: "format.label",
      },
      {
        Header: "Stage",
        accessor: "stage.label",
      },
      {
        Header: "",
        accessor: "_id",
        sortable: false,
        filterable: false,
        Cell: (row) => {
          return (
            <div className="text-center actions">
              <DropdownButton
                title="Actions"
                id="dropdown"
                disabled={
                  row.original.stage &&
                  row.original.stage.label === ("Created" && "Void")
                }
                onClick={(e) => e.stopPropagation()}
              >
                <MenuItem
                  onClick={() => this.props.onClickExport(row.original.number)}
                >
                  <FontAwesomeIcon
                    icon={faFileExport}
                    className="brand-color"
                  />
                  Export
                </MenuItem>
                <MenuItem onClick={() => this.props.onClickVoid(row.original)}>
                  <FontAwesomeIcon icon={faBan} className="brand-color" />
                  Void
                </MenuItem>
              </DropdownButton>
            </div>
          );
        },
      },
    ];

    const checkboxProps = {
      getTrGroupProps: (state, row) => {
        if (state && row && row.original) {
          const isSelected = selected.some(
            (inv) => inv._id === row.original._id
          );
          return {
            style: {
              color: isSelected ? "#cf4a03" : "inherit",
              fontWeight: isSelected ? 500 : 400,
            },
          };
        } else {
          return { style: {} };
        }
      },
      getTdProps: (_state, rowInfo, column) => {
        return {
          onClick: () => {
            if (rowInfo) {
              if (column.id !== "_id") {
                this.props.onToggle(rowInfo.original);
              }
            }
          },
        };
      },
    };

    return (
      <ReactTable
        manual
        pageSize={Math.min(invoices?.length, this.props.filters.pageSize)}
        pages={Math.ceil(this.props.count / this.props.filters.pageSize)}
        page={this.props.filters.page}
        sorted={this.props.filters.sorted}
        onPageChange={this.props.onPageChange}
        onPageSizeChange={this.props.onPageSizeChange}
        onSortedChange={this.props.onSortedChange}
        className="-striped -highlight"
        data={invoices || []}
        LoadingComponent={() => <Loading active={loading} />}
        columns={columns}
        defaultPageSize={this.props.filters.pageSize}
        {...checkboxProps}
        showPageJump={false}
      />
    );
  }
}

InvoiceList.propTypes = {
  loading: PropTypes.bool,
  invoices: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  onClickPreview: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  toggleSelectAll: PropTypes.func,
  filters: PropTypes.object,
  auth: PropTypes.object,
  onChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onSortedChange: PropTypes.func,
  invoicesToPrint: PropTypes.array,
  selected: PropTypes.array,
  onClickVoid: PropTypes.func,
  onClickExport: PropTypes.func,
};

function mapStatesToProps(state) {
  return {
    invoicesToPrint: state.reducers.invoicesToPrint,
    loadingInvoice: state.reducers.loadingInvoice,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...invoiceActions }, dispatch),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(InvoiceList);
