import React, { Component } from "react";
import PropTypes from "prop-types";

const PhoneNumber = ({ number }) => {
  let formatted_number;
  if (number) {
    let phoneNum = number
      .toString()
      .replace(/[^0-9]+/g, "")
      .replace(/\s+/, "");

    if (phoneNum.length == 8) {
      formatted_number = phoneNum.replace(
        /([0-9]{2})([0-9]{3})([0-9]{3})/,
        "$1 $2 $3"
      );
    } else if (number.length == 10) {
      formatted_number = phoneNum.replace(
        /([0-9]{3})([0-9]{3})([0-9]{4})/,
        "$1-$2-$3"
      );
    } else if (number.length == 11) {
      formatted_number = phoneNum.replace(
        /([0-9]{1})([0-9]{3})([0-9]{3})([0-9]{4})/,
        "$1-$2-$3-$4"
      );
    } else if (number.indexOf("+") == 0) {
      formatted_number = phoneNum.replace(
        /([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{3})/,
        "$1 $2 $3 $4"
      );
    }
  }

  return <div>{formatted_number}</div>;
};

PhoneNumber.propTypes = {
  name: PropTypes.string,
  number: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholderText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default PhoneNumber;
