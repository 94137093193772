import * as endpoints from "./apiEndpoints";
import axios from "axios";

class RoleService {
  static loadRoles() {
    const url = `${endpoints.BASE_URL}${endpoints.GET_ROLES}`;

    return axios.get(url).then((response) => response.data);
  }

  static getRole(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_ROLE}/${id}`;

    return axios.get(url).then((response) => response.data);
  }

  static createRole(role) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_ROLE}`;
    const body = {
      role: role,
    };

    return axios.post(url, body).then((response) => response.data);
  }

  static updateRole(role) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_ROLE}/${role.id}`;
    const body = {
      role: role,
    };

    return axios.put(url, body).then((response) => response.data);
  }

  static deleteRole(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_ROLE}/${id}`;
    return axios.delete(url).then((response) => response.data);
  }
}

export default RoleService;
