import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Glaccount from "./Glaccount";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import uniqBy from "lodash/uniqBy";
import CustomLoadable from "../common/Loadable";

const GlaccountList = ({ glaccounts, onClickEdit, onClickDelete }) => {
  let glaccountsView = <CustomLoadable />;

  let typeFilterOptions = uniqBy(
    glaccounts.map((account) => {
      if (account.type && account.type.label && account.type.id) {
        return {
          label: account.type.label,
          value: account.type.id,
        };
      }
    }),
    "value"
  ).filter((div) => div); // removes any undefined

  const columns = [
    {
      Header: "GL Account Name",
      accessor: "description",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["description"] }),
      filterAll: true,
    },
    {
      Header: "Revenue Code",
      accessor: "code",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["code"] }),
      filterAll: true,
    },
    {
      Header: "Type",
      accessor: "type.label",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else {
          return row[filter.id] == filter.value;
        }
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            {typeFilterOptions.map((option) => {
              return (
                <option key={option.value} value={option.label}>
                  {option.label}
                </option>
              );
            })}
          </select>
        );
      },
    },
    {
      Header: "",
      accessor: "_id",
      filterable: false,
      Cell: (row) => (
        <div className="text-center actions">
          <DropdownButton
            title="Actions"
            id={"dropdown"}
            onClick={(e) => e.stopPropagation()}
          >
            <LinkContainer to={`/app/settings/glaccounts/${row.value}`}>
              <MenuItem>
                <FontAwesomeIcon icon={faFolder} />
                View
              </MenuItem>
            </LinkContainer>
            <MenuItem onClick={() => onClickDelete(row.value)}>
              <FontAwesomeIcon icon={faTrashAlt} />
              Delete
            </MenuItem>
          </DropdownButton>
        </div>
      ),
    },
  ];

  if (glaccounts.length) {
    glaccountsView = (
      <ReactTable
        className="-highlight"
        data={glaccounts}
        filterable
        loading={false}
        columns={columns}
        defaultPageSize={10}
        defaultSorted={[
          {
            id: "description",
            asc: true,
          },
        ]}
      />
    );
  }

  return <div>{glaccountsView}</div>;
};

GlaccountList.propTypes = {
  glaccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      type: PropTypes.object.isRequired,
    }).isRequired
  ).isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  filter: PropTypes.object,
  onChange: PropTypes.func,
};

export default GlaccountList;
