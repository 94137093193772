import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { orderBy, groupBy, isEmpty } from "lodash";
import { Input } from "formsy-react-components";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import DropdownSearch from "../../common/NewDropdownSearch";
import DateTimePicker from "../../common/DateTimePicker";
import CreatableSelect from "../../common/CreatableSelect";

const INITIAL_STATE = {
  service_location: "",
  status: "true",
  division: "",
  department: "",
  contract: "",
  disability: [],
  program: "",
};

const ClientEpisodeForm = ({
  onSave,
  codes,
  onCancel,
  saving,
  editing,
  adding,
  client,
  episode,
  divisions,
  auth,
  contracts,
}) => {
  const [inputs, setInputs] = useState({
    ...INITIAL_STATE,
    division: auth && auth.user && auth.user.division ? auth.user.division : "",
    department:
      auth && auth.user && auth.user.department ? auth.user.department : "",
  });
  const [canSubmit, setCanSubmit] = useState(false);
  const [contractInfo, setContractInfo] = useState(null);
  const [refNumberRequired, setRefNumberRequired] = useState(false);

  useEffect(() => {
    if (episode) {
      let division =
        episode && episode.division && episode.division.id
          ? episode.division.id
          : null;
      let department = episode.department || null;
      let disability =
        episode && episode.disability && episode.disability.length
          ? episode.disability.map((c) => ({ value: c }))
          : [];
      if (!division) {
        division =
          auth && auth.user && auth.user.division ? auth.user.division : "";
      }
      if (!department) {
        department =
          auth && auth.user && auth.user.department ? auth.user.department : "";
      }
      if (!disability.length) {
        disability =
          client && client.disability && client.disability.length
            ? client.disability.map((c) => ({ value: c }))
            : [];
      }
      setInputs({
        ...episode,
        contract: episode && episode.contract ? episode.contract._id : null,
        division,
        department,
        disability,
        status: episode.status?.toString(),
      });
    } else if (client && client.disability && client.disability.length) {
      setInputs((prev) => ({
        ...prev,
        disability: client.disability.map((c) => ({ value: c })),
      }));
    }
  }, [episode, auth]);

  useEffect(() => {
    if (inputs.contract) {
      const foundContract = contracts.find(
        (contract) => contract._id === inputs.contract
      );
      if (foundContract) {
        setContractInfo({
          classification: foundContract.classification
            ? foundContract.classification.label
            : "",
          start_date: foundContract.start_date,
          end_date: foundContract.end_date,
        });
      }
    } else {
      setContractInfo(null);
    }
  }, [inputs.contract, contracts]);

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = () => {
    onSave(
      {
        ...inputs,
        status: inputs.status === "true",
        disability:
          Array.isArray(inputs.disability) && inputs.disability.length > 0
            ? inputs.disability.map(({ value }) => value)
            : [],
      },
      client,
      episode ? episode._id : false
    );
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "contract") {
      checkContractReferenceNumber(value);
    }
  };

  const checkContractReferenceNumber = (id) => {
    const contract = contracts.find((item) => {
      return item._id === id;
    });
    if (contract?.require_client_reference && !client?.reference_number) {
      setRefNumberRequired(true);
    } else {
      setRefNumberRequired(false);
    }
  };

  const generateCodeOptions = (entity, field) => {
    const codeOptions = codes
      .filter((code) => code.entity == entity && code.field == field)
      .map((code) => ({
        value: code._id,
        label: code.label,
      }));

    return orderBy(codeOptions, "label");
  };

  const generateDivisionOptions = () => {
    if (inputs.contract && contracts) {
      const foundContract = contracts.find(
        (cont) => cont._id === inputs.contract
      );
      const groupedDivisions = groupBy(foundContract.lines, "division._id");
      const divisionOptions = Object.keys(groupedDivisions)
        .map((key) => ({
          ...divisions.find((d) => d._id === key),
        }))
        .map((division) => ({
          value: division._id,
          label: division.description,
        }));
      return orderBy(divisionOptions, "label");
    } else {
      const divisionOptions = divisions.map((division) => ({
        value: division._id,
        label: division.description,
      }));

      return orderBy(divisionOptions, "label");
    }
  };

  const generateDepartmentOptions = () => {
    if (inputs.contract && inputs.division) {
      const foundContract = contracts.find(
        (cont) => cont._id === inputs.contract
      );
      const groupedDivisions = groupBy(foundContract.lines, "division._id");

      const allDepartments = Object.keys(groupedDivisions).map((key) => [
        groupedDivisions[key].map((div) => div.division.departments),
      ]);
      const groupedDepartments = groupBy(allDepartments.flat(3), "_id");
      const departmentOptions = Object.keys(groupedDepartments).map((key) => ({
        value: groupedDepartments[key][0]?._id,
        label: groupedDepartments[key][0]?.description,
      }));
      return departmentOptions;
    } else if (inputs.division) {
      const foundDivision = divisions.find(
        (division) => division.id === inputs.division
      );
      const departmentOptions = foundDivision.departments.map((department) => ({
        value: department._id,
        label: department.description,
      }));

      return orderBy(departmentOptions, "label");
    }
    return [];
  };

  const generateContractOptions = () => {
    const today = new Date();
    const contractOptions = contracts
      .filter(
        (cont) =>
          cont.stage &&
          cont.stage.value === "A" &&
          today < new Date(cont.end_date) &&
          cont.lines.find((li) =>
            li.division._id === inputs.division ? cont : null
          )
      )
      .map((contract) => ({
        value: contract._id,
        label: contract.name,
      }));

    return orderBy(contractOptions, "label");
  };

  const generateProgramOptions = () => {
    if (inputs.contract) {
      const foundContract = contracts.find(
        (cont) => cont._id === inputs.contract
      );
      const groupedServices = groupBy(foundContract.lines, "service._id");
      const programOptions = Object.keys(groupedServices)
        .map((key) => ({
          ...codes.find((d) => d._id === key),
        }))
        .map((program) => ({
          value: program._id,
          label: program.label,
        }));
      return orderBy(programOptions, "label");
    } else {
      return generateCodeOptions("Contract Line", "service");
    }
  };

  const generateLocationOptions = () => {
    const locationOptions = [];
    if (inputs.contract) {
      const parentAccount = contracts.find((item) => {
        return item._id === inputs.contract;
      })?.parent_account;

      if (parentAccount) {
        parentAccount.locations.map((location) => {
          let state = find(codes, { _id: location.address.state });
          state = state ? state.value : "";
          locationOptions.push({
            value: location._id,
            label: `${location.name} (${location.address.addr1} - ${location.address.city}, ${state})`,
          });
        });
      }
    }
    return locationOptions;
  };

  return (
    <Formsy
      className="vertical form"
      onValidSubmit={handleSubmit}
      onValid={() => setCanSubmit(true)}
      onInvalid={() => setCanSubmit(false)}
    >
      <Row>
        <Col md={4} xs={12}>
          <DropdownSearch
            required
            data={[...generateDivisionOptions()]}
            disabled={!editing}
            name="division"
            label="Division"
            value={inputs.division}
            onChange={(name, { value }) => handleChange(name, value)}
          />
        </Col>
        <Col md={4} xs={12}>
          <DropdownSearch
            data={[...generateContractOptions()]}
            disabled={!editing || !inputs.division}
            name="contract"
            label="Contract"
            value={inputs.contract}
            onChange={(name, { value }) => handleChange(name, value)}
          />
          {refNumberRequired && (
            <span className="error-message">
              This contract requires a client reference number. Please add one
              in the client section
            </span>
          )}
        </Col>
        <Col md={4} xs={12}>
          <DropdownSearch
            required
            data={[...generateDepartmentOptions()]}
            disabled={!editing || !inputs.division}
            name="department"
            label="Department"
            value={inputs.department}
            onChange={(name, { value }) => handleChange(name, value)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} xs={12}>
          <CreatableSelect
            required
            data={[...generateLocationOptions()]}
            disabled={!editing}
            label="Service Location"
            name="service_location"
            value={inputs.service_location}
            onChange={(name, { value }) => handleChange(name, value)}
          />
        </Col>
        <Col md={4} xs={12}>
          <div className="form-group row">
            <label
              className="control-label"
              htmlFor="start_date"
              style={{ textTransform: "uppercase" }}
            >
              Episode Start Date *
            </label>
            <DatePicker
              required
              className="form-control"
              disabled={!editing}
              locale="en-us"
              placeholderText="MM/DD/YYYY"
              selected={inputs.start_date ? moment(inputs.start_date) : null}
              onChange={(value) => handleChange("start_date", value)}
            />
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="form-group row">
            <label
              className="control-label"
              htmlFor="end_date"
              style={{ textTransform: "uppercase" }}
            >
              Episode End Date *
            </label>
            <DatePicker
              required
              className="form-control"
              disabled={!editing}
              locale="en-us"
              placeholderText="MM/DD/YYYY"
              selected={inputs.end_date ? moment(inputs.end_date) : null}
              onChange={(value) => handleChange("end_date", value)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4} xs={12}>
          <DropdownSearch
            isMulti
            data={[...generateCodeOptions("Client", "Disability")]}
            disabled={!editing}
            label="Disability"
            name="disability"
            value={inputs.disability}
            onChange={(name, value) => handleChange(name, value)}
          />
        </Col>
        <Col md={4} xs={12}>
          <DropdownSearch
            data={[...generateCodeOptions("Client", "ParticipantType")]}
            disabled={!editing}
            label="Participant Type"
            name="participant_type"
            value={inputs.participant_type}
            onChange={(name, { value }) => handleChange(name, value)}
          />
        </Col>
        <Col md={4} xs={12}>
          <DropdownSearch
            name="status"
            label="Status"
            onChange={(name, value) =>
              handleChange("status", value.value.toString())
            }
            disabled={!editing}
            value={inputs.status.toString()}
            data={[
              { value: "true", label: "Active" },
              { value: "false", label: "Inactive" },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} xs={12}>
          <DropdownSearch
            required
            data={[...generateProgramOptions()]}
            disabled={!editing}
            label="Program"
            name="program"
            value={inputs.program}
            onChange={(name, { value }) => handleChange(name, value)}
          />
        </Col>
        <Col md={4} xs={12}>
          <DropdownSearch
            data={[...generateCodeOptions("Client", "Program")]}
            disabled={!editing}
            label="Program Phase"
            name="program_phase"
            value={inputs.program_phase}
            onChange={(name, { value }) => handleChange(name, value)}
          />
        </Col>
        <Col md={4} xs={12}>
          <DropdownSearch
            name="case_complete"
            label="Case Complete"
            onChange={(name, value) =>
              handleChange("case_complete", value.value.toString())
            }
            disabled={!editing}
            value={inputs.case_complete?.toString()}
            data={[
              { value: "true", label: "True" },
              { value: "false", label: "False" },
            ]}
          />
        </Col>
        <Col md={12} xs={12} className="action-footer">
          <button
            className="btn btn-danger"
            onClick={handleCancel}
            type="button"
          >
            Cancel
          </button>
          <input
            className="btn btn-success"
            type="submit"
            disabled={!canSubmit || refNumberRequired}
            value={saving ? "Saving... " : "Save"}
          />
        </Col>
      </Row>
    </Formsy>
  );
};

ClientEpisodeForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  codes: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  adding: PropTypes.bool.isRequired,
  client: PropTypes.object.isRequired,
  episode: PropTypes.object,
  divisions: PropTypes.array,
  auth: PropTypes.object.isRequired,
  contracts: PropTypes.array,
};

export default ClientEpisodeForm;
