import { push } from "react-router-redux";
import * as types from "./actionTypes";
import { showModalSuccess, hideModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import accountService from "../services/accountService";

export function loadAccountSuccess(accounts) {
  return {
    type: types.LOAD_ACCOUNT_SUCCESS,
    accounts,
    meta: {
      throttle: true,
    },
  };
}

export function getAccountSuccess(account) {
  return { type: types.GET_ACCOUNT_SUCCESS, account };
}

export function savingAccount(status = true) {
  return { type: types.SAVING_ACCOUNT, savingAccount: status };
}

export function createAccountSuccess(account) {
  return { type: types.CREATE_ACCOUNT_SUCCESS, account };
}

export function updateAccountSuccess(account) {
  return { type: types.UPDATE_ACCOUNT_SUCCESS, account };
}

export function requestAccountId(accountId) {
  return (dispatch) => {
    dispatch({ type: types.REQUEST_ACCOUNT_ID, accountToDelete: accountId });
    dispatch(showModalSuccess("accountDeleteModal"));
  };
}

export function requestLocationId(locationId) {
  return (dispatch) => {
    dispatch({
      type: types.REQUEST_LOCATION_ID,
      acctLocationToDelete: locationId,
    });
    dispatch(showModalSuccess("locationDeleteModal"));
  };
}

export function deleteAccountSuccess(accountId) {
  return { type: types.DELETE_ACCOUNT_SUCCESS, accountId };
}

export function deleteLocationSuccess(locationId) {
  return { type: types.DELETE_LOCATION_SUCCESS, locationId };
}

export function loadAccounts() {
  return (dispatch) => {
    dispatch(hideAlertSuccess());
    return accountService
      .loadAccounts()
      .then((data) => dispatch(loadAccountSuccess(data)))
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function getAccount(id, showACCOUNTDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return accountService
      .getAccount(id)
      .then((account) => {
        dispatch(getAccountSuccess(account));
        if (showACCOUNTDetails) {
          dispatch(showModalSuccess("accountDetailsModal"));
        }
      })
      .catch((error) => {
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function createAccount(account) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingAccount());
    return accountService
      .createAccount(account)
      .then((createdAccount) => {
        dispatch(createAccountSuccess(createdAccount));
        dispatch(savingAccount(false));
        dispatch(showAlertSuccess("Account created successfully", "success"));
        dispatch(push("/app/accounts"));
      })
      .catch((error) => {
        dispatch(savingAccount(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateAccount(account) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingAccount());
    return accountService
      .updateAccount(account)
      .then((updatedAccount) => {
        dispatch(updateAccountSuccess(updatedAccount));
        dispatch(savingAccount(false));
        dispatch(showAlertSuccess("Account updated successfully", "success"));
        dispatch(push(`/app/accounts`));
      })
      .catch((error) => {
        dispatch(savingAccount(false));
        dispatch(showAlertSuccess("Unable to update account", "error"));
      });
  };
}

export function deleteAccount(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return accountService
      .deleteAccount(id)
      .then(() => {
        dispatch(deleteAccountSuccess(id));
        dispatch(showAlertSuccess("Account deleted successfully", "success"));
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function createLocation(account, location) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingAccount());
    return accountService
      .createLocation(account, location)
      .then((updatedAccount) => {
        dispatch(updateAccountSuccess(updatedAccount));
        dispatch(savingAccount(false));
        dispatch(hideModalSuccess("accountDetailsModal"));
        dispatch(showAlertSuccess("Location created successfully", "success"));
        dispatch(push(`/app/accounts/${updatedAccount._id}/locations`));
      })
      .catch((error) => {
        dispatch(savingAccount(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateLocation(account, location) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingAccount());
    return accountService
      .updateLocation(account, location)
      .then((updatedAccount) => {
        dispatch(updateAccountSuccess(updatedAccount));
        dispatch(savingAccount(false));
        dispatch(hideModalSuccess("accountDetailsModal"));
        dispatch(showAlertSuccess("Location updated successfully", "success"));
        dispatch(push(`/app/accounts/${updatedAccount._id}/locations`));
      })
      .catch((error) => {
        dispatch(savingAccount(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteLocation(account, location) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingAccount());
    return accountService
      .deleteLocation(account, location)
      .then((updatedAccount) => {
        dispatch(updateAccountSuccess(updatedAccount));
        dispatch(savingAccount(false));
        dispatch(hideModalSuccess("accountDetailsModal"));
        dispatch(showAlertSuccess("Location deleted successfully", "success"));
        dispatch(push(`/app/accounts/${updatedAccount._id}/locations`));
      })
      .catch((error) => {
        dispatch(savingAccount(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function createNote(account, note) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingAccount());
    return accountService
      .createNote(account, note)
      .then((updatedAccount) => {
        dispatch(updateAccountSuccess(updatedAccount));
        dispatch(savingAccount(false));
        dispatch(hideModalSuccess("accountDetailsModal"));
        dispatch(showAlertSuccess("Note created successfully", "success"));
        dispatch(push(`/app/accounts/${updatedAccount._id}/notes`));
      })
      .catch((error) => {
        dispatch(savingAccount(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateNote(account, note) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingAccount());
    return accountService
      .updateNote(account, note)
      .then((updatedAccount) => {
        dispatch(updateAccountSuccess(updatedAccount));
        dispatch(savingAccount(false));
        dispatch(showAlertSuccess("Note updated successfully", "success"));
        dispatch(push(`/app/accounts/${updatedAccount._id}/notes`));
      })
      .catch((error) => {
        dispatch(savingAccount(false));
        dispatch(showAlertSuccess(error, "error"));
      });
  };
}

export function deleteNote(noteId) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingAccount());
    return accountService
      .deleteNote(noteId)
      .then((updatedAccount) => {
        dispatch(updateAccountSuccess(updatedAccount));
        dispatch(savingAccount(false));
        dispatch(hideModalSuccess("accountDetailsModal"));
        dispatch(showAlertSuccess("Note deleted successfully", "success"));
        dispatch(push(`/app/accounts/${noteId}/notes`));
      })
      .catch((error) => {
        dispatch(savingAccount(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}
