import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import autoBind from "react-autobind";

import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { Input } from "formsy-react-components";

class ChangePassForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
    };

    autoBind(this);
  }

  enableButton = () => {
    if (this.state.newPassword === this.state.verifyPassword) {
      this.setState({ canSubmit: true });
    } else {
      this.setState({ canSubmit: false });
    }
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  onChange = (field, value) => {
    this.setState({ [field]: value });
  };

  submit = (model) => {
    this.props.onSave(model.newPassword);
  };

  render() {
    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={12}>
            <Input
              formNoValidate
              required
              type="password"
              name="newPassword"
              label="Password"
              placeholder="New Password"
              onChange={this.onChange}
              value={this.state.newPassword}
            />
          </Col>
          <Col md={12}>
            <Input
              formNoValidate
              required
              type="password"
              name="verifyPassword"
              label="Verify Password"
              placeholder="Verify Password"
              validations="equalsField:newPassword"
              validationError="Passwords do not match."
              onChange={this.onChange}
              value={this.state.verifyPassword}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="action-footer">
            <button
              className="btn btn-danger"
              type="button"
              onClick={this.props.onCancel}
            >
              Cancel
            </button>
            <input
              className="btn btn-success"
              type="submit"
              disabled={!this.state.canSubmit}
              value={this.props.saving ? "Saving... " : "Save"}
            />
          </Col>
        </Row>
      </Formsy>
    );
  }
}

ChangePassForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default ChangePassForm;
