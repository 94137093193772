import React from "react";
import { Link } from "react-router-dom";

import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const AddButton = ({ entity, auth, onClickAdd }) => {
  let hideButton = auth && auth.maxRole < 6000;

  return (
    !hideButton &&
    // <Col md={5} xs={5}>
    (entity ? (
      <Link
        className="btn btn-success btn-sm right btn-add"
        to={`/app/${entity}/add`}
      >
        <FontAwesomeIcon icon={faPlus} />
        Add
      </Link>
    ) : (
      <button className="btn btn-success right btn-add" onClick={onClickAdd}>
        <FontAwesomeIcon icon={faPlus} />
        Add
      </button>
    ))
    // </Col>
  );
};

export default AddButton;
