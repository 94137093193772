import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import AsyncPaginate from "react-select-async-paginate";
import { Row, Col } from "react-bootstrap";
import { useQuery } from "react-query";

import DivisionService from "../../services/divisionService";

const DivisionSelect = ({
  required,
  disabled,
  name,
  label,
  value: initialValue,
  onChange,
}) => {
  const [state, setState] = useState({ value: "" });

  const { data: divisions } = useQuery(
    "divisions",
    () => DivisionService.loadDivisions(),
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!initialValue) {
      setState({ value: "" });
    }
  }, [initialValue]);

  useEffect(() => {
    if (initialValue && initialValue !== state.value) {
      const division = divisions.find(
        (division) => division._id === initialValue
      );
      if (division) {
        setState({
          label: division.description,
          value: initialValue,
        });
      }
    }
  }, [divisions, initialValue, state]);

  const fieldLabel = required ? (label += " *") : label;

  const loadOptions = (search) => {
    return {
      options: divisions
        .filter((division) => division.description.includes(search))
        .map((division) => ({
          label: division.description,
          value: division._id,
        })),
      hasMore: false,
    };
  };

  const handleChange = (value) => {
    const division = divisions.find((v) => v._id === value);
    onChange(name, value, division);
  };

  return (
    <Row className="form-group">
      <Col sm={12} xs={12}>
        <label
          className="control-label"
          htmlFor={name}
          style={{ textTransform: "uppercase" }}
        >
          {fieldLabel}
        </label>
      </Col>
      <Col sm={12} xs={12}>
        <AsyncPaginate
          name={name}
          required={required}
          debounceTimeout={500}
          value={state}
          onChange={({ value }) => handleChange(value)}
          loadOptions={loadOptions}
          isDisabled={disabled}
        />
      </Col>
    </Row>
  );
};

DivisionSelect.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

export default DivisionSelect;
