import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import { Row, Col } from "react-bootstrap";

import * as contractActions from "../../../actions/contractActions";
import * as userActions from "../../../actions/userActions";
import * as modalActions from "../../../actions/modalActions";

import ContractNoteForm from "./ContractNoteForm";
import ContractNoteList from "./ContractNoteList";
import BreadCrumbs from "../../common/BreadCrumbs";
import AddButton from "../../common/AddButton";
import Modal from "../../common/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";

class ContractNotes extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      note: {},
    };

    autoBind(this);
  }

  componentDidMount = () => {
    let { contract, users, actions } = this.props;
    if (isEmpty(contract)) actions.getContract(this.props.match.params.id);
    actions.loadUsers();
  };

  onClickAdd = () => {
    this.props.actions.getContract(this.props.contract._id, true);
  };

  onClickEdit = (contract, note) => {
    this.setState((this.state.note = { note }));
    this.props.actions.getContract(this.props.contract._id, false, true);
  };

  handleSave = async (contract, note) => {
    await this.props.actions.addNote(contract, note);
    await this.props.actions.getContract(contract._id);
  };

  handleSaveEdit = async (contract, note, noteId) => {
    let newNote = {
      ...note,
      id: noteId,
    };
    await this.props.actions.updateNote(contract, newNote);
    await this.props.actions.getContract(contract._id);
    this.props.actions.hideModal();
  };

  handleCancel = () => {
    this.props.actions.hideModal();
  };

  onClickDelete = async (contractId, noteId) => {
    await this.props.actions.deleteNote(noteId);
    this.props.actions.getContract(contractId);
  };

  render() {
    let { contract, auth } = this.props;

    let noteForm = (
      <ContractNoteForm
        contract={contract}
        note={{}}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
      />
    );

    let editNoteForm = (
      <ContractNoteForm
        contract={contract}
        note={this.state.note}
        onSave={this.handleSaveEdit}
        onCancel={this.handleCancel}
      />
    );

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: `${contract.name}` }, { label: "Notes" }]}
          />
          <AddButton onClickAdd={this.onClickAdd} auth={auth} />
          <Col md={12}>
            <Modal
              id="contractDetailsModal"
              title={"Add Contract Note"}
              body={noteForm}
              modal={this.props.modal}
              close={this.props.actions.hideModal}
            />
          </Col>
          <Col md={12}>
            <Modal
              id="editContractDetailsModal"
              title={"Edit Contract Note"}
              body={editNoteForm}
              modal={this.props.modal}
              close={this.props.actions.hideModal}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ContractNoteList
              contract={this.props.contract}
              users={this.props.users}
              onClickDelete={this.onClickDelete}
              onClickEdit={this.onClickEdit}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ContractNotes.propTypes = {
  actions: PropTypes.object.isRequired,
  contract: PropTypes.object,
  users: PropTypes.array,
  note: PropTypes.object,
  modal: PropTypes.object,
  params: PropTypes.object,
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    contract: state.reducers.contract,
    users: state.reducers.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...contractActions,
        ...userActions,
        ...modalActions,
      },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(ContractNotes);
