import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as contactActions from "../../actions/contactActions";
import * as modalActions from "../../actions/modalActions";

import ContactList from "./ContactList";
import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import ConfirmModal from "../common/ConfirmModal";
import { Row, Col } from "react-bootstrap";

class ContactPage extends Component {
  componentDidMount = () => {
    let { actions } = this.props;
    actions.loadContacts();
  };

  onClickDelete = (contactId) => {
    this.props.actions.requestContactId(contactId);
  };

  handleDelete = () => {
    this.props.actions.deleteContact(this.props.contactToDelete);
  };

  render() {
    let { contacts, auth } = this.props;

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs breadcrumbs={[{ label: "Contacts" }]} />
          <Col md={5} className="text-right">
            <AddButton entity="contacts" auth={auth} />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <ContactList
              contacts={contacts}
              auth={auth}
              onClickView={(id) =>
                this.props.history.push(`/app/contacts/${id}`)
              }
              onClickDelete={this.onClickDelete}
            />
          </Col>
        </Row>
        <ConfirmModal
          id="contactDeleteModal"
          title="Delete Contact"
          body="Are you sure you want to delete this contact?"
          modal={this.props.modal}
          close={this.props.actions.hideModal}
          confirm={this.handleDelete}
        />
      </div>
    );
  }
}

ContactPage.propTypes = {
  actions: PropTypes.object,
  contact: PropTypes.object.isRequired,
  contacts: PropTypes.array.isRequired,
  contactToDelete: PropTypes.string,
  modal: PropTypes.object,
};

function mapStatesToProps(state) {
  return {
    modal: state.reducers.modal,
    contact: state.reducers.contact,
    contacts: state.reducers.contacts,
    contactToDelete: state.reducers.contactToDelete,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...contactActions, ...modalActions },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(ContactPage);
