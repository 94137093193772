import { push } from "react-router-redux";
import * as types from "./actionTypes";
import { showModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import userService from "../services/userService";
import emailService from "../services/emailService";

export function loginUserSuccess(user) {
  return { type: types.LOGIN_USER_SUCCESS, user };
}

export function loadUserSuccess(users) {
  return { type: types.LOAD_USER_SUCCESS, users };
}

export function getUserSuccess(user) {
  return { type: types.GET_USER_SUCCESS, user };
}

export function savingUser(status = true) {
  return { type: types.SAVING_USER, savingUser: status };
}

export function loadingUser(status = true) {
  return { type: types.LOADING_USER, loadingUser: status };
}

export function createUserSuccess(user) {
  return { type: types.CREATE_USER_SUCCESS, user };
}

export function updateUserSuccess(user) {
  return { type: types.UPDATE_USER_SUCCESS, user };
}

export function logoutUserSuccess() {
  return { type: types.LOGOUT_USER_SUCCESS };
}

export function checkAuthSuccess(user) {
  return { type: types.CHECK_AUTH_SUCCESS, user };
}

export function resetPassSentSuccess(status = true) {
  return { type: types.RESET_PASS_SENT_SUCCESS, status };
}

export function resetPassSuccess(status = true) {
  return { type: types.RESET_PASS_SUCCESS, status };
}

export function requestUserId(userId) {
  return (dispatch) => {
    dispatch({ type: types.REQUEST_USER_ID, userToDelete: userId });
    dispatch(showModalSuccess("userDeleteModal"));
  };
}

export function deleteUserSuccess(userId) {
  return { type: types.DELETE_USER_SUCCESS, userId };
}

export function syncAdpWorkerDataSuccess() {
  return { type: types.SYNC_ADP_WORKER_OBJECT_SUCCESS };
}

export function syncAdpCertDataSuccess() {
  return { type: types.SYNC_ADP_USER_CERT_SUCCESS };
}

export function adpBulkUpdateSuccess() {
  return { type: types.ADP_BULK_UPDATE_CERT_SUCCESS };
}

export function syncAdpWorker() {
  return (dispatch) => {
    return userService
      .syncAdpWorkerData()
      .then((data) => dispatch(syncAdpWorkerDataSuccess(data)))
      .catch((error) =>
        dispatch(showAlertSuccess("ADP Could not Sync at this time", error))
      );
  };
}

export function syncAdpCertData() {
  return (dispatch) => {
    return userService
      .syncAdpCertData()
      .then((data) => {
        dispatch(syncAdpCertDataSuccess(data));
      })
      .catch((error) =>
        dispatch(showAlertSuccess("ADP Could not Sync at this time", error))
      );
  };
}

export function adpBulkUpdate(data) {
  return (dispatch) => {
    return userService
      .adpBulkUpdate(data)
      .then((responseArray) =>
        dispatch(
          adpBulkUpdateSuccess(
            `${responseArray.modifiedCount} users updated`,
            "success"
          )
        )
      )
      .catch((error) => {
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function checkAuth(token) {
  return (dispatch) => {
    if (token) {
      return userService
        .findByAuthToken(token)
        .then((data) => dispatch(checkAuthSuccess(data)))
        .catch(() => dispatch(logoutUser()));
    } else {
      dispatch(logoutUser());
    }
  };
}

export function loginUser(user) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingUser());
    return userService
      .loginUser(user)
      .then((data) => {
        dispatch(loginUserSuccess(data));
        dispatch(savingUser(false));
        dispatch(
          showAlertSuccess(`Welcome, ${data.user.first_name}`, "success", false)
        );
        dispatch(push("/app"));
      })
      .catch((error) => {
        dispatch(savingUser(false));
        dispatch(showAlertSuccess("Invalid Username or Password", "error"));
      });
  };
}

export function logoutUser() {
  return (dispatch) => {
    return userService.logoutUser().then((data) => {
      dispatch(logoutUserSuccess());
      dispatch(push("/login"));
    });
  };
}

export function forgotPassword(user) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingUser());
    return userService
      .forgotPassword(user)
      .then((data) => {
        dispatch(resetPassSentSuccess());
        dispatch(savingUser(false));
        let reset_link = `${window.location.origin}/reset/${data.reset_password_token}`;

        const email = {
          to: data.email,
          from: "noreply@eastersealsma.org",
          subject: "Reset Password",
          html: `<p><em>This is an automated email</em></p>`,
          templateId: "eab75f71-6d38-4189-8030-5b318a79e56f",
          substitutions: {
            name: data.first_name,
            reset_link,
          },
        };

        dispatch(showAlertSuccess("Password Reset Link Sent", "success"));
        dispatch(push("/login"));
        return emailService.sendEmail(email);
      })
      .then((res) => {
        // console.log("SENDGRID res", res);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(savingUser(false));
        dispatch(showAlertSuccess("Could not reset password", "error"));
      });
  };
}

export function resetPassword(user) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingUser());
    return userService
      .resetPassword(user)
      .then((data) => {
        dispatch(resetPassSuccess());
        dispatch(savingUser(false));
        dispatch(showAlertSuccess("Password Reset Successfully", "success"));
        dispatch(push("/login"));

        const email = {
          to: data.email,
          from: "noreply@eastersealsma.org",
          subject: "Reset Success",
          html: `<p><em>This is an automated email</em></p>`,
          templateId: "3abb3303-c20d-4457-a969-79a512a5e1f5",
          substitutions: {
            name: data.first_name,
          },
        };

        // console.warn("sending", email);
        return emailService.sendEmail(email);
      })
      .then((res) => {
        // console.log("SENDGRID res", res);
      })
      .catch((error) => {
        dispatch(savingUser(false));
        dispatch(showAlertSuccess("Could not reset password", "error"));
      });
  };
}

export function loadUsers(LOAD_ALL = false) {
  return (dispatch) => {
    dispatch(loadingUser());
    dispatch(hideAlertSuccess());
    return userService
      .loadUsers(LOAD_ALL)
      .then((data) => {
        dispatch(loadUserSuccess(data));
        dispatch(loadingUser(false));
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function getUser(id, showUserDetails = false) {
  return (dispatch, getState) => {
    dispatch(loadingUser());
    dispatch(hideAlertSuccess());
    return userService
      .getUser(id)
      .then((user) => {
        dispatch(getUserSuccess(user));
        dispatch(loadingUser(false));
        if (showUserDetails) {
          dispatch(showModalSuccess("userDetailsModal"));
        }
      })
      .catch((error) => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function createUser(user) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingUser());
    return (
      userService
        .createUser(user)
        .then((createdUser) => {
          dispatch(createUserSuccess(createdUser));
          dispatch(savingUser(false));
          dispatch(showAlertSuccess("User created successfully", "success"));
          dispatch(push("/app/users"));

          const email = {
            to: createdUser.email,
            from: "noreply@eastersealsma.org",
            subject: "Easter Seals Project Management",
            text: "lorem ipsum",
            html: "<h1>HEllo World</h1>",
          };

          // return emailService.sendEmail(email);
        })
        // .then(res => {
        //   console.log('SENDGRID res', res);
        // })
        .catch((error) => {
          dispatch(savingUser(false));
          dispatch(showAlertSuccess(error.description, "error"));
        })
    );
  };
}

export function updateUser(user, UPDATE_PASS = false) {
  return (dispatch, getState) => {
    dispatch(savingUser());
    dispatch(hideAlertSuccess());
    return userService
      .updateUser(user)
      .then((updatedUser) => {
        dispatch(updateUserSuccess(updatedUser));
        dispatch(savingUser(false));
        dispatch(
          showAlertSuccess(
            `${UPDATE_PASS ? "Password" : "User"} updated successfully`,
            "success"
          )
        );
      })
      .catch((error) => {
        dispatch(savingUser(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteUser(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return userService
      .deleteUser(id)
      .then(() => {
        dispatch(deleteUserSuccess(id));
        dispatch(showAlertSuccess("User deleted successfully", "success"));
      })
      .catch((error) => console.warn(error));
  };
}
