import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as accountActions from "../../actions/accountActions";

import AccountForm from "./AccountForm";
import BreadCrumbs from "../common/BreadCrumbs";
import { Row, Col } from "react-bootstrap";

class AccountAddPage extends Component {
  handleSave = (account) => {
    this.props.actions.createAccount(account);
  };

  handleCancel = () => {
    this.props.history.push("/app/accounts");
  };

  render() {
    let { codes, saving } = this.props;

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: "Accounts" }, { label: "Add Account" }]}
          />
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <AccountForm
              account={{}}
              codes={codes}
              saving={saving}
              onSave={this.handleSave}
              onCancel={this.handleCancel}
              editing
              adding
            />
          </Col>
        </Row>
      </div>
    );
  }
}

AccountAddPage.propTypes = {
  actions: PropTypes.object.isRequired,
  savingAccount: PropTypes.bool,
  codes: PropTypes.array,
  account: PropTypes.object,
};

function mapStatesToProps(state) {
  return {
    state: state.reducers,
    saving: state.reducers.savingAccount,
    codes: state.reducers.codes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...accountActions }, dispatch),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(AccountAddPage);
