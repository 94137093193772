import React from "react";
import PropTypes from "prop-types";

import Loading from "../common/Loading";
import ReactTable from "react-table";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faFolder,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import matchSorter from "match-sorter";

const ChargeTypeList = ({ chargetypes, onClickDelete }) => {
  const columns = [
    {
      Header: "Type",
      accessor: "type",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["type"] }),
      filterAll: true,
    },
    {
      Header: "Employee Entry",
      accessor: "employee_entry",
      Cell: (row) => (
        <div className="text-center">
          {row.value == true ? <FontAwesomeIcon icon={faCheck} /> : ""}
        </div>
      ),
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "true") {
          return row[filter.id] === true;
        }
        if (filter.value === "false") {
          return row[filter.id] !== true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      ),
    },
    {
      Header: "Overtime",
      accessor: "overtime",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["overtime"] }),
      filterAll: true,
      Cell: (row) => (
        <div className="text-center">
          {row.value == true ? <FontAwesomeIcon icon={faCheck} /> : ""}
        </div>
      ),
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "true") {
          return row[filter.id] === true;
        }
        if (filter.value === "false") {
          return row[filter.id] !== true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      ),
    },
    {
      Header: "",
      accessor: "_id",
      filterable: false,
      Cell: (row) => (
        <div className="text-center actions">
          <DropdownButton
            title="Actions"
            id={"dropdown"}
            onClick={(e) => e.stopPropagation()}
          >
            <LinkContainer to={`/app/settings/chargetypes/${row.value}`}>
              <MenuItem>
                <FontAwesomeIcon icon={faFolder} />
                View
              </MenuItem>
            </LinkContainer>
            <MenuItem onClick={() => onClickDelete(row.value)}>
              <FontAwesomeIcon icon={faTrashAlt} className="brand-color" />
              Delete
            </MenuItem>
          </DropdownButton>
        </div>
      ),
    },
  ];

  const props = {
    getTdProps: (state, rowInfo, column) => {
      return {
        onClick: () => {
          if (column.id !== "_id") {
            onClickView(rowInfo.original._id);
          }
        },
      };
    },
  };

  let isLoading = !chargetypes.length;

  return (
    <ReactTable
      className="-highlight"
      filterable
      data={chargetypes}
      LoadingComponent={() => <Loading active={isLoading} />}
      columns={columns}
      {...props}
      defaultPageSize={10}
      defaultSorted={[
        {
          id: "first_name",
          asc: true,
        },
      ]}
    />
  );
};

ChargeTypeList.propTypes = {
  chargetypes: PropTypes.array,
  onClickView: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  filter: PropTypes.object,
  onChange: PropTypes.func,
};

export default ChargeTypeList;
