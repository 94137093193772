import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";

import ForgotForm from "./ForgotForm";

import { Row, Col } from "react-bootstrap";

class ForgotPage extends Component {
  constructor(props, context) {
    super(props, context);
  }

  handleSave = (user) => {
    this.props.actions.forgotPassword(user);
  };

  render() {
    return (
      <Row className="auth-page">
        <Col md={6} className="center">
          <h1>Forgot Password</h1>
          <ForgotForm onSave={this.handleSave} saving={this.props.savingUser} />
        </Col>
      </Row>
    );
  }
}

ForgotPage.propTypes = {
  actions: PropTypes.object.isRequired,
  savingUser: PropTypes.bool,
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state,
    savingUser: state.reducers.savingUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...userActions }, dispatch),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(ForgotPage);
