import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { find } from "lodash";
import { Row, Col } from "react-bootstrap";
import { useQuery } from "react-query";

import * as callActions from "../../actions/callActions";
import * as modalActions from "../../actions/modalActions";
import * as chargetypeActions from "../../actions/chargetypeActions";
import * as divisionActions from "../../actions/divisionActions";

import AccountService from "../../services/accountService";
import ClientService from "../../services/clientService";
import ContractService from "../../services/contractService";
import UserService from "../../services/userService";

import CallForm from "./NewCallForm/CallFormWrapper";
import BreadCrumbs from "../common/BreadCrumbs";
import ConfirmModal from "../common/ConfirmModal";
import Loading from "../common/Loading";

const CallAddPage = ({
  chargetypes,
  actions,
  router,
  history,
  codes,
  modal,
  auth,
  savingCall,
}) => {
  const [state, setState] = useState({});

  const { data: contracts, isLoading: ContractsLoading } = useQuery(
    "contracts",
    () => ContractService.loadContractsTimeEntry(),
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );
  const { data: clients, isLoading: ClientsLoading } = useQuery(
    "clients",
    () => ClientService.loadClientsTimeEntry(),
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );
  const { data: users, isLoading: UsersLoading } = useQuery(
    "users",
    () => UserService.loadUsers(true),
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );
  const { data: accounts, isLoading: AccountsLoading } = useQuery(
    "accounts",
    () => AccountService.loadAccountsTimeEntry(),
    { initialData: [] }
  );

  useEffect(() => {
    actions.loadChargetypes();
  }, [actions]);

  const handleCancel = () => {
    history.push(
      router.previousLocation == "/app/call-approval"
        ? "/app/call-approval"
        : router.previousLocation == "/app/invoices/add"
          ? "/app/invoices/add"
          : `/app/calls`
    );
  };

  const handleSave = async (call) => {
    call = call || state.call;
    const data = {
      ...call,
    };

    if (call.completed_by) {
      data.completed_date = new Date();
    }

    await actions.createCall(data);

    history.push(`/app/calls`);
  };

  const onClickSave = (call) => {
    const stage = find(codes, { _id: call.stage });
    if (stage.label == "Completed") {
      actions.showModalSuccess("callDeleteModal");
      setState((prev) => ({ ...prev, call }));
    } else {
      handleSave(call);
    }
  };

  const onConfirmChange = () => {
    actions.showModalSuccess("callConfirmModal");
  };

  const handleEnterTime = () => {
    actions.showModal("callDetailsModal");
  };

  const loading =
    ContractsLoading || UsersLoading || ClientsLoading || AccountsLoading;

  return (
    <div className="content-wrapper">
      <Row>
        <BreadCrumbs
          breadcrumbs={[{ label: "Time Entries" }, { label: "Add Time Entry" }]}
        />
      </Row>
      <Row>
        <Col md={12}>
          {!loading ? (
            <CallForm
              call={{}}
              contracts={contracts}
              users={users}
              auth={auth}
              chargetypes={chargetypes}
              codes={codes}
              saving={savingCall}
              onAdd={onClickSave}
              onCancel={handleCancel}
              onEnterTime={handleEnterTime}
              onConfirmChange={onConfirmChange}
              editing
              adding
              clients={clients}
              accounts={accounts}
            />
          ) : (
            <Loading active />
          )}
          <ConfirmModal
            id="callDeleteModal"
            title="Save Call"
            body="Are you sure you want to save this call? Once it is completed you will not be able to make edits."
            modal={modal}
            close={actions.hideModal}
            confirm={handleSave}
          />
        </Col>
      </Row>
    </div>
  );
};

CallAddPage.propTypes = {
  auth: PropTypes.object,
  actions: PropTypes.object.isRequired,
  savingCall: PropTypes.bool,
  call: PropTypes.object,
  contract: PropTypes.object,
  calls: PropTypes.array,
  chargetypes: PropTypes.array,
  codes: PropTypes.array,
  modal: PropTypes.object,
  router: PropTypes.object,
  history: PropTypes.object,
};

function mapStatesToProps(state) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    router: state.reducers.router,
    auth: state.reducers.auth,
    savingCall: state.reducers.savingCall,
    calls: state.reducers.calls,
    codes: state.reducers.codes,
    chargetypes: state.reducers.chargetypes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...callActions,
        ...modalActions,
        ...divisionActions,
        ...chargetypeActions,
      },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(CallAddPage);
