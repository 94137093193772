import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { Input, Checkbox, Textarea } from "formsy-react-components";
import autoBind from "react-autobind";
import DropdownSearch from "../common/DropdownSearch";
import Select from "react-select";

import orderBy from "lodash/orderBy";

class ProcedureForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
      license_required: false,
      licenses_required: [],
    };

    autoBind(this);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.item !== this.props.item) {
      this.setState({
        license_required: nextProps.item.license_required,
        licenses_required: nextProps.item.licenses_required,
      });
    }
  };

  enableButton() {
    this.setState({
      canSubmit: true,
    });
  }

  disableButton() {
    this.setState({
      canSubmit: false,
    });
  }

  onChange(field, selected) {
    this.setState({ [field]: selected.value });
  }

  onAddLicense(selectedOptions, field) {
    this.setState({
      licenses_required: selectedOptions,
    });
  }

  submit = async (model) => {
    model["licenses_required"] = this.state.licenses_required;
    await this.props.onSave(model);
    await this.refs.form.reset();
  };

  resetForm() {
    this.refs.form.reset();
  }

  render() {
    let { item, editing } = this.props;
    return (
      <Col md={12} xs={12}>
        <Formsy
          ref="form"
          className="horizontal form"
          onValidSubmit={this.submit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
        >
          <Row>
            <Col md={6} xs={12}>
              <Input
                formNoValidate
                required
                name="description"
                label="Description"
                placeholder="Description"
                value={item.description || ""}
                disabled={!editing}
              />
            </Col>
            <Col md={6} xs={12}>
              <Input
                formNoValidate
                name="hrs3code"
                label="HRS3 Code"
                placeholder="HRS3 CODE"
                value={item.hrs3code || ""}
                disabled={!editing}
              />
            </Col>
            <Col md={6} xs={12}>
              <DropdownSearch
                required
                name="charge_type"
                label="Charge Type"
                data={[
                  ...this.props.chargetypes.map((chargetype) => {
                    return { value: chargetype._id, label: chargetype.type };
                  }),
                ]}
                onChange={this.onChange}
                value={this.state.charge_type || item.charge_type}
                disabled={!editing}
              />
            </Col>
            <Col md={6} xs={12}>
              <DropdownSearch
                name="product_category"
                label="Product Category"
                data={[
                  ...this.props.codes
                    .filter((code) => code.field == "product_category")
                    .map((prodcat) => {
                      return { value: prodcat._id, label: prodcat.label };
                    }),
                ]}
                onChange={this.onChange}
                value={this.state.product_category || item.product_category}
                disabled={!editing}
              />
            </Col>
            <Col md={3} xs={6}>
              <Checkbox
                formNoValidate
                name="is_global"
                label="Global"
                placeholder="Global"
                value={item.is_global || false}
                disabled={!editing}
              />
            </Col>
            <Col md={3} xs={6}>
              <Checkbox
                formNoValidate
                name="license_required"
                label="License Required"
                placeholder="License Required"
                value={this.state.license_required}
                onChange={() =>
                  this.setState({
                    license_required: !this.state.license_required,
                  })
                }
                disabled={!editing}
              />
            </Col>
            <Col md={3} xs={6}>
              <Checkbox
                formNoValidate
                name="ot_eligible"
                label="OT Eligible"
                placeholder="OT Eligible"
                value={item.ot_eligible || false}
                disabled={!editing}
              />
            </Col>
            <Col md={3} xs={6}>
              <Checkbox
                formNoValidate
                name="ufr_required"
                label="UFR Required"
                placeholder="UFR Required"
                value={item.ufr_required || false}
                disabled={!editing}
              />
            </Col>
            {this.state.license_required && (
              <Col md={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="licenses_required"
                  style={{ textTransform: "uppercase" }}
                >
                  Licenses Required
                </label>
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  name="licenses_required"
                  label="Licenses Required"
                  placeholder="Licenses Required"
                  value={this.state.licenses_required}
                  onChange={this.onAddLicense}
                  options={orderBy(
                    [
                      ...this.props.codes
                        .filter((code) => code.field == "license")
                        .map((code) => ({
                          value: code._id,
                          label: code.label,
                        })),
                    ],
                    "label"
                  )}
                  isDisabled={!editing}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col md={12} xs={12}>
              <Link
                style={item.id ? { display: "inline" } : { display: "none" }}
                className="btn btn-danger"
                to="/app/settings/procedures"
              >
                Cancel
              </Link>
              <input
                className="btn btn-success"
                type="submit"
                disabled={!this.state.canSubmit}
                value={this.props.saving ? "Saving... " : "Save"}
              />
            </Col>
          </Row>
        </Formsy>
      </Col>
    );
  }
}

ProcedureForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  codes: PropTypes.array,
  chargetypes: PropTypes.array.isRequired,
  prodcats: PropTypes.array.isRequired,
};

export default ProcedureForm;
