import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import autoBind from "react-autobind";
import { Row, Col } from "react-bootstrap";

const FileUpload = ({ onDrop }) => (
  <Col md={12} xs={12}>
    <div className="file-upload">
      <Dropzone onDrop={onDrop} multiple>
        <div>
          Try dropping a file here, or click to select a file to upload.
        </div>
      </Dropzone>
    </div>
  </Col>
);

FileUpload.propTypes = {
  onDrop: PropTypes.func,
};

export default FileUpload;
