import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faTrashAlt,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { DropdownButton, MenuItem } from "react-bootstrap";

import find from "lodash/find";
import matchSorter from "match-sorter";
import moment from "moment";
import CallService from "../../../services/callService";

const ContractClientListDropDown = ({
  contract,
  value,
  onClickEdit,
  onClickDelete,
  onClickCopy,
}) => {
  const [hasCalls, setHasCalls] = useState(false);

  useEffect(() => {
    // get hasCalls async and then set it
    const fetchData = async () => {
      try {
        const result = await CallService.getLineHasCalls(contract, value);
        if (result) {
          setHasCalls(result);
        }
      } catch (err) {
        setHasCalls(true);
        console.log(err.message);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="pull-right actions">
      <DropdownButton title="Actions" id={"dropdown"} onClick={(e) => e.stopPropagation()}>
        <MenuItem onClick={() => onClickEdit(value)}>
          <FontAwesomeIcon icon={faFolder} />
          View
        </MenuItem>
        <MenuItem onClick={() => onClickCopy(value)}>
          <FontAwesomeIcon icon={faCopy} />
          Duplicate
        </MenuItem>
        <MenuItem
          data-tip="There are calls associated with this procedure"
          data-for="hasCalls"
          onClick={() => (!hasCalls ? onClickDelete(value) : "")}
          disabled={hasCalls}
        >
          <FontAwesomeIcon icon={faTrashAlt} className="brand-color" />
          Delete
        </MenuItem>
        {hasCalls && (
          <ReactTooltip id="hasCalls" place="left" type="dark" effect="solid" />
        )}
      </DropdownButton>
    </div>
  );
};

ContractClientListDropDown.propTypes = {
  contract: PropTypes.string,
  value: PropTypes.string,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickCopy: PropTypes.func,
};

const ContractLineList = ({
  contractId,
  lines,
  onClickEdit,
  onClickCopy,
  onClickDelete,
}) => {
  const columns = [
    {
      Header: "Division",
      accessor: "division",
      maxWidth: 100,
      Cell: (row) => (row.value ? row.value.code : "--"),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: [(row) => row.division.code] }),
      filterAll: true,
    },
    {
      Header: "Department",
      accessor: "department",
      maxWidth: 300,
      Cell: (row) => {
        let department = find(row.original.division.departments, {
          _id: row.value,
        });
        return <span>{department.description}</span>;
      },
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [
            (row) => {
              let department = find(row.division.departments, {
                _id: row.department,
              });
              return department.description;
            },
          ],
        }),
      filterAll: true,
    },
    {
      Header: "Start Date",
      accessor: "start_date",
      Cell: (row) => <span>{moment.utc(row.value).format("DD MMM YYYY")}</span>,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(item) => moment(item.start_date).format("DD MMM YYYY")],
        }),
      filterAll: true,
    },
    {
      Header: "End Date",
      accessor: "end_date",
      Cell: (row) => <span>{moment.utc(row.value).format("DD MMM YYYY")}</span>,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(item) => moment(item.end_date).format("DD MMM YYYY")],
        }),
      filterAll: true,
    },
    {
      Header: "Item",
      accessor: "item",
      Cell: (row) => (row.value ? row.value.description : "--"),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(row) => row.item.description],
        }),
      filterAll: true,
    },
    {
      Header: "Product Category",
      accessor: "product_category",
      Cell: (row) => (row.value ? row.value.label : "--"),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(row) => row.product_category.label],
        }),
      filterAll: true,
    },
    {
      Header: "Rate",
      accessor: "rate",
      Cell: (row) => (
        <span>{`$${row.value ? row.value.toFixed(2) : (0).toFixed(2)}`}</span>
      ),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["rate"] }),
      filterAll: true,
    },
    {
      Header: "UOM",
      accessor: "uom",
      Cell: (row) => (row.value ? row.value.label : "--"),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["uom.label"] }),
      filterAll: true,
    },
    {
      Header: "",
      accessor: "_id",
      filterable: false,
      sortable: false,
      Cell: (row) => (
        <ContractClientListDropDown
          contract={contractId}
          value={row.value}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          onClickCopy={onClickCopy}
        />
      ),
    },
  ];

  const props = {
    getTdProps: (state, rowInfo, column) => {
      return {
        onClick: () => {
          if (rowInfo && column.id !== "_id") {
            onClickEdit(rowInfo.original._id);
          }
        },
      };
    },
  };

  return (
    <ReactTable
      className="-highlight"
      filterable
      data={lines}
      columns={columns}
      defaultPageSize={10}
      {...props}
      defaultSorted={[
        {
          id: "division",
          asc: true,
        },
      ]}
    />
  );
};

ContractLineList.propTypes = {
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      division: PropTypes.string.isRequired,
    }).isRequired
  ),
  contractId: PropTypes.string,
  onClickCopy: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export default ContractLineList;
