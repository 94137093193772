import PropTypes from "prop-types";

import moment from "moment";
import ReactTable from "react-table";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import matchSorter from "match-sorter";
import { debounce } from "lodash";

import PhoneNumber from "../../common/PhoneNumber";
import Loading from "../../common/Loading";

const ClientList = ({
  clients,
  onClickView,
  pagination,
  loading,
  onFiltersChange,
  onPageChange,
  onPageSizeChange,
  onSortedChange,
  manual,
}) => {
  const columns = [
    {
      Header: "Number",
      accessor: "number",
      Cell: (row) => (row.value ? row.value : "--"),
      maxWidth: 100,
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["last_name"] }),
      filterable: true,
      filterAll: true,
      minWidth: 150,
    },
    {
      Header: "First Name",
      accessor: "first_name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["first_name"] }),
      filterable: true,
      filterAll: true,
    },
    {
      Header: "City",
      accessor: "city",
      Cell: (row) => (row.value ? row.value.toLowerCase() : "--"),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["city"] }),
      filterable: true,
      filterAll: true,
    },
    {
      Header: "State",
      accessor: "state",
      Cell: (row) => (row.value ? row.value.value : "--"),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(row) => row.state && row.state.label],
        }),
      filterAll: true,
      filterable: true,
      maxWidth: 100,
    },
    {
      Header: "Phone",
      accessor: "primary_phone",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["primary_phone"] }),
      filterAll: true,
      filterable: true,
      Cell: (row) =>
        row.value ? (
          <div>
            <PhoneNumber number={row.value} />
          </div>
        ) : (
          "--"
        ),
    },
    {
      Header: "Birth Date",
      accessor: "birth_date",
      Cell: (row) =>
        row.value ? moment.utc(row.value).format("MM/DD/YY") : "--",
    },
    {
      Header: "Age",
      accessor: "age",
      Cell: (row) => (row.value ? row.value.toFixed(1) : "--"),
      maxWidth: 100,
    },
    {
      Header: "",
      accessor: "_id",
      sortable: false,
      Cell: (row) => (
        <div className="text-center actions">
          <DropdownButton
            title="Actions"
            id={"dropdown"}
            onClick={(e) => e.stopPropagation()}
          >
            <MenuItem onClick={() => onClickView(row.value)}>
              <FontAwesomeIcon icon={faFolder} />
              View
            </MenuItem>
          </DropdownButton>
        </div>
      ),
    },
  ];

  const props = {
    getTdProps: (state, rowInfo, column) => {
      return {
        onClick: () => {
          if (column.id !== "_id") {
            onClickView(rowInfo.original._id);
          }
        },
      };
    },
  };

  const handleFilterChange = debounce(onFiltersChange, 500);

  const NoDataConst = () => (
    <div
      style={{
        color: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        top: "-70px",
      }}
    >
      No rows found
    </div>
  );

  const data = Array.isArray(clients) ? clients : clients.result;

  const manualPaginationProps = manual
    ? {
        pageSize: Math.min(clients.result?.length, pagination.pageSize),
        page: pagination.page,
        pages: Math.ceil(clients.count / pagination.pageSize),
      }
    : {};

  return (
    <ReactTable
      {...props}
      {...manualPaginationProps}
      manual={manual}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onSortedChange={onSortedChange}
      onFilteredChange={handleFilterChange}
      className="-highlight"
      NoDataComponent={NoDataConst}
      data={data}
      LoadingComponent={() => <Loading active={loading} />}
      columns={columns}
      defaultPageSize={pagination.pageSize}
      defaultSorted={pagination.sorted}
    />
  );
};

ClientList.propTypes = {
  clients: PropTypes.object.isRequired,
  onClickView: PropTypes.func.isRequired,
  pagination: PropTypes.object,
  loading: PropTypes.bool,
  onFiltersChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onSortedChange: PropTypes.func,
  manual: PropTypes.bool,
};

ClientList.defaultProps = {
  pagination: {
    page: 0,
    sorted: [
      {
        id: "last_name",
        desc: false,
      },
      {
        id: "first_name",
        desc: false,
      },
      {
        id: "city",
        desc: false,
      },
    ],
    pageSize: 10,
  },
  clients: {
    result: [],
    count: 0,
  },
  onFiltersChange: () => null,
  onPageChange: (page) => page,
  onPageSizeChange: (pageSize) => pageSize,
  onSortedChange: () => null,
  manual: true,
};

export default ClientList;
