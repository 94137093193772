import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { Link } from "react-router-dom";
import { Input } from "formsy-react-components";
import autoBind from "react-autobind";
import orderBy from "lodash/orderBy";

import DropdownSearch from "../../common/DropdownSearch";

class ContractClientForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
      rows: [""],
    };

    autoBind(this);
  }

  enableButton() {
    this.setState({ canSubmit: true });
  }

  disableButton() {
    this.setState({ canSubmit: false });
  }

  cancel() {
    this.props.onCancel();
  }

  submit(model) {
    console.log("model", model);
    model.id = this.props.client._id;
    model.isActive = model.isActive === "true";
    this.props.onSave(model);
  }

  onChange(field, selected) {
    selected.value = selected.value.toString();
    console.log("selected.value", selected.value);
    this.setState({
      [field]: selected.value,
    });
  }

  deleteRow(idx) {
    let rows = this.state.rows;
    rows.splice(idx, 1);
    this.setState({ rows });
  }

  resetForm() {
    this.refs.form.reset();
  }

  render() {
    let { client } = this.props;

    return (
      <Col md={12}>
        <Formsy
          ref="form"
          className="vertical form"
          onValidSubmit={this.submit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
        >
          <Row>
            <Col md={5}>
              <Input
                formNoValidate
                name="name"
                label="Name/Initials"
                disabled={!this.props.editing}
                value={client.name}
              />
            </Col>
            <Col md={3}>
              <DropdownSearch
                required
                name="isActive"
                label="Status"
                onChange={this.onChange}
                disabled={!this.props.editing}
                value={this.state.isActive || client.isActive.toString()}
                data={[
                  { value: "true", label: "Active" },
                  { value: "false", label: "Inactive" },
                ]}
              />
            </Col>
            <Col md={4}>
              {client.full_client ? (
                <div style={{ paddingTop: "3rem" }}>
                  <Link to={`/app/clients/${client.full_client}`}>
                    View Linked Full Client
                  </Link>
                </div>
              ) : (
                <DropdownSearch
                  name="full_client"
                  label="Linked Full Client"
                  onChange={this.onChange}
                  disabled={!this.props.editing || client.full_client}
                  value={this.state.full_client || client.full_client}
                  data={orderBy(
                    [
                      ...this.props.fullClients.map((fullClient) => ({
                        value: fullClient._id,
                        label: fullClient.first_name.concat(
                          " ",
                          fullClient.last_name
                        ),
                      })),
                    ],
                    "label"
                  )}
                />
              )}
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <button
                className="btn btn-danger"
                onClick={this.cancel}
                type="button"
              >
                Cancel
              </button>
              <input
                className="btn btn-success"
                type="submit"
                disabled={!this.state.canSubmit}
                value={this.props.saving ? "Saving... " : "Save"}
              />
            </Col>
          </Row>
        </Formsy>
      </Col>
    );
  }
}

ContractClientForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  client: PropTypes.object,
  contract: PropTypes.object.isRequired,
  fullClients: PropTypes.array.isRequired,
};

export default ContractClientForm;
