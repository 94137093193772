import { orderBy } from "lodash";

export default function generateStageOptions(
  codes = [],
  maxRole,
  editing,
  invoicedBy,
  contract
) {
  const stageOpts = codes
    .filter((code) => code.entity === "Call" && code.field === "stage")
    .map((code) => ({
      value: code._id,
      label: code.label,
      order: code.order,
    }));
  let filteredOptions = [];

  // Only allow Scheduled, Completed, Cancelled for employee roles
  if (maxRole === 4000) {
    filteredOptions = stageOpts.filter(
      (stage) =>
        stage.label === "Scheduled" ||
        stage.label === "Completed" ||
        stage.label === "Cancelled" ||
        (!editing && stage.label === "Approved")
    );
  } else if (!invoicedBy) {
    filteredOptions = stageOpts.filter(
      (stage) =>
        stage.label === "Scheduled" ||
        stage.label === "Completed" ||
        stage.label === "Cancelled" ||
        (!editing && stage.label === "Approved")
    );
  }

  if (contract && contract.stage && contract.stage.label === "Draft") {
    filteredOptions = stageOpts.filter(
      (stage) => stage.label === "Scheduled" || stage.label === "Cancelled"
    );
  }

  return orderBy(filteredOptions, "order");
}
