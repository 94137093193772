import { Component } from "react";
import queryString from "query-string";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import * as contractActions from "../../actions/contractActions";
import * as userActions from "../../actions/userActions";
import * as invoiceActions from "../../actions/invoiceActions";
import * as modalActions from "../../actions/modalActions";
import * as accountActions from "../../actions/accountActions";
import * as divisionActions from "../../actions/divisionActions";
import * as itemActions from "../../actions/itemActions";

import InvoiceList from "./InvoiceList";
import InvoicePreview from "./invoiceLines/invoicePreview/InvoicePreview";
import FilterComponent from "../common/FilterComponent";
import BreadCrumbs from "../common/BreadCrumbs";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";

import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPlus } from "@fortawesome/free-solid-svg-icons";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import InvoiceService from "../../services/invoiceService";

const REPORT_PATH = import.meta.env.VITE_REPORT_URL;

class InvoicePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      invoice: {},
      open: false,
      editing: true,
      filters: localStorage.getItem("invoiceFilters")
        ? JSON.parse(localStorage.getItem("invoiceFilters"))
        : {
            parent_account: [],
            contract: [],
            number: [],
            stage: [],
            division: [],
            format: [],
            page: 0,
            pageSize: 10,
            sorted: [],
          },
      invoices: [],
      count: 0,
      selected: [],
      loading: true,
      invoiceToVoid: null,
    };
  }

  handleLoadInvoices = async () => {
    try {
      this.setState({ loading: true });

      const query = this.handlePrepFilters();

      const { result, count } = await InvoiceService.loadInvoices(
        queryString.stringify(query, {
          arrayFormat: "bracket",
        })
      );

      this.setState({
        invoices: result,
        count,
      });
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  };
  componentDidMount = () => {
    let { accounts, items, divisions, actions } = this.props;

    actions.loadUsers();
    actions.loadDropdownContracts();
    // actions.loadInvoices(this.handlePrepFilters());

    this.handleLoadInvoices();

    if (isEmpty(accounts)) actions.loadAccounts();
    if (isEmpty(divisions)) actions.loadDivisions();
    if (isEmpty(items)) actions.loadItems();
  };

  componentWillUnmount = () => {
    this.props.actions.clearInvoices();
  };

  handlePrepFilters = () => {
    const transformedObject = {
      page: this.state.filters.page,
      pageSize: this.state.filters.pageSize,
    };
    const {
      parent_account,
      contract,
      stage,
      division,
      format,
      start_time,
      end_time,
      sorted,
    } = this.state.filters;
    if (parent_account?.length) {
      transformedObject.parent_account = parent_account.map((x) => x.label);
    }
    if (contract?.length) {
      transformedObject.contract = contract.map((x) => x.label);
    }
    if (stage?.length) {
      transformedObject.stage = stage.map((x) => x.value);
    }
    if (division?.length) {
      transformedObject.division = division.map((x) => x.value);
    }
    if (format?.length) {
      transformedObject.format = format.map((x) => x.value);
    }
    if (start_time) {
      transformedObject.start_time = start_time;
    }
    if (end_time) {
      transformedObject.end_time = end_time;
    }
    if (sorted?.length) {
      transformedObject.sort = sorted.map(
        (x) => `${[x.id]}: ${x.desc ? 1 : -1}`
      );
    }
    return transformedObject;
  };

  handleChangeFilters = (selectedOptions, field) => {
    this.setState(
      {
        filters: { ...this.state.filters, [field]: selectedOptions, page: 0 },
      },
      () => {
        // this.props.actions.loadInvoices(this.handlePrepFilters());
        this.handleLoadInvoices();
        localStorage.setItem(
          "invoiceFilters",
          JSON.stringify(this.state.filters)
        );
      }
    );
  };

  handleChangeDate = (startDate, endDate) => {
    let { filters } = this.state;
    if (startDate) {
      filters.start_time = moment.utc(startDate).endOf("day");
      this.setState({ filters: { ...filters, page: 0 } }, () => {
        // this.props.actions.loadInvoices(this.handlePrepFilters());
        this.handleLoadInvoices();
        localStorage.setItem(
          "invoiceFilters",
          JSON.stringify(this.state.filters)
        );
      });
    }
    if (endDate) {
      filters.end_time = moment.utc(endDate).endOf("day");
      this.setState({ filters: { ...filters, page: 0 } }, () => {
        // this.props.actions.loadInvoices(this.handlePrepFilters());
        this.handleLoadInvoices();
        localStorage.setItem(
          "invoiceFilters",
          JSON.stringify(this.state.filters)
        );
      });
    }
  };

  handleToggle = (invoice) => {
    const { selected } = this.state;

    const nextSelected = [...selected];

    const idx = nextSelected.findIndex((s) => s._id === invoice._id);
    if (idx > -1) {
      nextSelected.splice(idx, 1);
    } else {
      nextSelected.push(invoice);
    }

    this.setState({ selected: nextSelected });
  };

  toggleSelectAll = () => {
    const { invoices, selected } = this.state;

    const someSelected = invoices.some((invoice) =>
      selected.some((i) => i._id === invoice._id)
    );

    let nextSelected = [...selected];

    if (someSelected) {
      // if some are selected unselect them all;
      nextSelected = nextSelected.filter((invoice) =>
        invoices.every((i) => i._id !== invoice._id)
      );
    } else {
      for (const invoice of invoices) {
        nextSelected.push(invoice);
      }
    }

    this.setState({ selected: nextSelected });
  };

  resetFilters = () => {
    this.setState(
      {
        filters: {
          parent_account: [],
          contract: [],
          number: [],
          stage: [],
          division: [],
          format: [],
          start_time: null,
          end_time: null,
          page: 0,
          pageSize: 10,
        },
        selected: [],
      },
      () => {
        // this.props.actions.loadInvoices(this.handlePrepFilters());
        this.handleLoadInvoices();
      }
    );
    localStorage.removeItem("invoiceFilters");
  };

  onClickExport = (invoiceNum) => {
    const url = `${REPORT_PATH}&InvoiceNo=${invoiceNum}`;
    window.open(url, "_blank");
  };

  onClickVoid = (invoiceId) => {
    this.setState({ invoiceToVoid: invoiceId });
    this.props.actions.showModalSuccess("invoiceDeleteModal");
  };

  handleVoid = async () => {
    const { invoiceToVoid } = this.state;
    if (!invoiceToVoid) return;

    const voidStage = find(this.props.codes, {
      entity: "Invoice",
      value: "Void",
    });

    invoiceToVoid["stage"] = voidStage._id;

    await this.props.actions.updateInvoice(invoiceToVoid);

    this.setState({ invoiceToVoid: null });

    this.handleLoadInvoices();
  };

  bulkPrint = () => {
    let params = this.state.selected
      .map((invoice) => `&InvoiceNo=${invoice.number}`)
      .join("");
    let url = `${REPORT_PATH}${params}`;
    window.open(url, "_blank");
  };

  genButtonText = () => {
    const { selected, count } = this.state;
    if (!selected.length) return "Print";
    return `Print ${selected.length} of ${count}`;
  };

  handlePageSizeChange = (pageSize) => {
    this.setState({ filters: { ...this.state.filters, pageSize } }, () => {
      // this.props.actions.loadInvoices(this.handlePrepFilters());
      this.handleLoadInvoices();
    });
  };

  handlePageChange = (page) => {
    this.setState({ filters: { ...this.state.filters, page } }, () => {
      // this.props.actions.loadInvoices(this.handlePrepFilters());
      this.handleLoadInvoices();
    });
  };

  handleSortedChange = (newSort) => {
    this.setState(
      { filters: { ...this.state.filters, sorted: newSort } },
      () => {
        // this.props.actions.loadInvoices(this.handlePrepFilters());
        this.handleLoadInvoices();
      }
    );
  };

  render() {
    const { invoice } = this.props;
    const { loading, filters, open, invoices, count, selected } = this.state;

    let invoiceDetails = (
      <InvoicePreview invoice={invoice} invoices={invoices} {...this.props} />
    );

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs breadcrumbs={[{ label: "Invoices" }]} />
          <Col md={5} className="text-right">
            <button
              className="btn btn-success filter"
              type="button"
              onClick={() => this.setState({ open: !open })}
            >
              <FontAwesomeIcon icon={faFilter} className="icon" />
            </button>
            <button
              className="btn btn-success"
              type="button"
              disabled={selected.length <= 0}
              onClick={this.bulkPrint}
            >
              {this.genButtonText()}
            </button>
            <Link className="btn btn-success btn-add" to="/app/invoices/add">
              <FontAwesomeIcon icon={faPlus} />
              ADD
            </Link>
          </Col>
        </Row>
        <FilterComponent
          {...this.props}
          entity="Invoice"
          open={open}
          filters={filters}
          onChangeFilters={this.handleChangeFilters}
          onChangeDate={this.handleChangeDate}
          onChangeDateMobile={this.handleChangeDateMobile}
          focusedInput={this.state.focusedInput}
          resetFilters={this.resetFilters}
          onFocusChange={(focusedInput) => this.setState({ focusedInput })}
        />
        <Row>
          <Col md={12}>
            <InvoiceList
              loading={loading}
              selected={selected}
              invoices={invoices}
              count={count}
              filters={filters}
              onClearInvoices={this.props.actions.clearInvoices}
              onClickExport={this.onClickExport}
              onClickVoid={this.onClickVoid}
              onToggle={this.handleToggle}
              onPageSizeChange={this.handlePageSizeChange}
              onPageChange={this.handlePageChange}
              onSortedChange={this.handleSortedChange}
              toggleSelectAll={this.toggleSelectAll}
            />
          </Col>
        </Row>
        <Modal
          id="invoiceDetailsModal"
          title="Invoice Preview"
          body={invoiceDetails}
          invoice={invoice}
          modal={this.props.modal}
          onClickExport={this.handleInvoiceExport}
          close={this.props.actions.hideModal}
        />
        <ConfirmModal
          id="invoiceDeleteModal"
          title="Void Invoice"
          body="Are you sure you want to void this invoice?"
          modal={this.props.modal}
          close={this.props.actions.hideModal}
          confirm={this.handleVoid}
        />
      </div>
    );
  }
}

InvoicePage.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  accounts: PropTypes.array,
  modal: PropTypes.object,
  invoiceToVoid: PropTypes.string,
  invoiceToPrint: PropTypes.string,
  invoices: PropTypes.array.isRequired,
  invoice: PropTypes.object.isRequired,
  divisions: PropTypes.array,
  codes: PropTypes.array,
  contracts: PropTypes.array,
  users: PropTypes.array,
  items: PropTypes.items,
};

function mapStatesToProps(state) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    invoiceToVoid: state.reducers.invoiceToVoid,
    invoicesToPrint: state.reducers.invoicesToPrint,
    invoices: state.reducers.invoices,
    invoice: state.reducers.invoice,
    contracts: state.reducers.contracts,
    accounts: state.reducers.accounts,
    users: state.reducers.users,
    codes: state.reducers.codes,
    divisions: state.reducers.divisions,
    items: state.reducers.items,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...invoiceActions,
        ...modalActions,
        ...accountActions,
        ...divisionActions,
        ...contractActions,
        ...userActions,
        ...itemActions,
      },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(InvoicePage);
