import React, { Component } from "react";
import PropTypes from "prop-types";
import { withFormsy } from "formsy-react";

import Select from "react-select";
import { Row, Col } from "react-bootstrap";

import find from "lodash/find";

class DropdownSearch extends Component {
  state = { value: "" };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.data !== this.props.data) {
      let obj = find(nextProps.data, { value: this.props.value });
      this.setState({ value: obj });
    }

    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }
  };

  render() {
    let {
      name,
      label,
      data,
      placeholder,
      required,
      validations,
      validationErrors,
      disabled,
      isMulti,
      menuIsOpen,
    } = this.props;
    const { value } = this.state;

    const isFalsy =
      typeof value == "null" || typeof value == "undefined" || value == "";

    let fieldLabel = required ? (label += " *") : label;

    return (
      <Row className="form-group">
        <Col sm={12} xs={12}>
          <label
            className="control-label"
            htmlFor={name}
            style={{ textTransform: "uppercase" }}
          >
            {fieldLabel}
          </label>
        </Col>
        <Col sm={12} xs={12}>
          <Select
            formNoValidate
            required={required}
            name={name}
            autosize={false}
            value={!isFalsy ? value : ""}
            onChange={(value) => this.props.onChange(name, value)}
            options={data}
            placeholder={placeholder ? placeholder : "Please Select..."}
            validations={validations || "isEmptyString"}
            validationErrors={validationErrors}
            isSearchable
            isMulti={isMulti}
            isClearable={false}
            isDisabled={disabled}
            menuIsOpen={menuIsOpen}
          />
        </Col>
      </Row>
    );
  }
}

DropdownSearch.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  data: PropTypes.array,
  required: PropTypes.bool,
  setValue: PropTypes.func,
  getValue: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default withFormsy(DropdownSearch);
