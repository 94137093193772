import logoDataUri from "./assets/logoDataUri";

export default {
  createPDF: function (customerObj) {
    this.signatory = customerObj.signatory;
    this.title = customerObj.title;
    this.esmaContact = customerObj.esmaContact;
    this.esmaSignatory = customerObj.esmaSignatory;
    this.startDate = customerObj.startDate;
    this.endDate = customerObj.endDate;
    this.companyName = customerObj.companyName;
    this.customerName = customerObj.customerName;
    this.customerAddress =
      customerObj.streetAddr +
      ", " +
      customerObj.city +
      ", " +
      customerObj.state +
      " " +
      customerObj.zip;
    this.customerStreetAddr = customerObj.streetAddr;
    this.customerCity = customerObj.city;
    this.customerState = customerObj.state;
    this.customerZip = customerObj.zip;
    this.customerPhone = customerObj.phone;
    this.customerFax = customerObj.fax;
    this.customerEmail = customerObj.email;
    this.numberOfLines = customerObj.numberOfLines;
    this.lines = customerObj.lines;
    this.billingName = customerObj.billingName;
    this.currentDate = customerObj.currentDate;
    this.notes = customerObj.notes;

    let styles = {
      paymentTable: {
        bold: true,
        alignment: "right",
      },
      noticeHeader: {
        bold: true,
        alignment: "center",
      },
      contractHeader: {
        bold: true,
        alignment: "center",
        fontSize: 20,
      },
    };

    let body = [];
    body.push([
      {
        text: "Procedure",
        margin: [8, 5, 20, 5],
        bold: true,
        fillColor: "#CCCCCC",
      },
      {
        text: "Rate",
        margin: [8, 5, 20, 5],
        bold: true,
        fillColor: "#CCCCCC",
      },
      { text: "UOM", margin: [8, 5, 20, 5], bold: true, fillColor: "#CCCCCC" },
    ]);

    for (let i = 0; i < this.lines.length; i++) {
      body.push([
        { text: this.lines[i].item.description, bold: true },
        {
          table: {
            heights: [15],
            body: [
              [
                { text: "$", border: [false, false, false, false], bold: true },
                {
                  text: this.lines[i].rate.toFixed(2),
                  border: [false, false, false, false],
                  fillColor: "#CCCCCC",
                },
              ],
            ],
          },
        },
        { text: this.lines[i].uom.label, bold: true, alignment: "center" },
      ]);
    }

    let notesList = [];
    for (let i = 0; i < this.notes.length; i++) {
      notesList.push([
        {
          ul: [{ text: this.notes[i], margin: [0, 5, 20, 0] }],
        },
      ]);
    }

    let paragraph1 = [
      {
        image: logoDataUri,
        width: 220,
        alignment: "center",
      },
      {
        margin: [10, 0, 10, 0],
        text: [
          "Addendum to the Programs and Services Contract between ",
          { text: `${this.companyName}` },
          " and Easter Seals Massachusetts",
        ],
        style: "contractHeader",
      },
      "\n",
      {
        text: [
          "Effective ",
          { text: `${this.startDate}` },
          " through ",
          { text: `${this.endDate}` },
        ],
        // style: "contractHeader"
        bold: true,
        alignment: "center",
        fontSize: 18,
      },
      "\n",
      "\n",
      {
        text: "Exhibit A:",
        fontSize: 16,
        bold: true,
        decoration: "underline",
      },
      {
        text: "EASTER SEALS MASSACHUSETTS, Inc.",
        fontSize: 18,
        alignment: "center",
      },
      //   "\n",
      {
        text: "Services Fee Schedule ",
        fontSize: 18,
        bold: true,
        alignment: "center",
      },
      "\n",
      {
        table: {
          body: body,
        },
      },
      {
        text: "Notes:",
        margin: [0, 10, 0, 0],
      },
      {
        ul: [notesList],
      },
      {
        listType: "none",
        margin: [20, 10, 0, 8],
        table: {
          widths: [200, "*", -200, "*"],
          body: [
            [
              {
                text: "\n\n\n",
                border: [false, false, false, false],
              },
              { text: "\n\n\n", border: [false, false, false, false] },
            ],
            [
              {
                text: `${this.esmaSignatory}\nEaster Seals of Massachusetts Inc`,
                border: [false, true, false, false],
              },
              { text: "\nDate:", border: [false, true, false, false] },
            ],
            [
              {
                text: "\n\n\n",
                border: [false, false, false, false],
              },
              { text: "\n\n\n", border: [false, false, false, false] },
            ],
            [
              {
                text: `${this.signatory}\n${this.title}\n${this.companyName}`,
                border: [false, true, false, false],
              },
              { text: "\nDate:", border: [false, true, false, false] },
            ],
          ],
        },
      },
    ]; // end paragraph1

    let pdfObj = {
      content: paragraph1,
      styles: styles,
    };

    return pdfObj;
  },
};
