import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const invoices = (state = initialState.invoices, action) => {
  switch (action.type) {
    case types.LOAD_INVOICE_SUCCESS:
      return action.invoices;

    case types.CREATE_INVOICE_SUCCESS:
      return [...state, Object.assign({}, action.invoice)];

    case types.UPDATE_INVOICE_SUCCESS:
      return [
        ...state.filter((invoices) => invoices.id !== action.invoice._id),
        Object.assign({}, action.invoice),
      ];

    case types.DELETE_INVOICE_SUCCESS:
      return [...state.filter((invoice) => invoice._id !== action.invoiceId)];
    default:
      return state;
  }
};

export const invoice = (state = initialState.invoice, action) => {
  switch (action.type) {
    case types.GET_INVOICE_SUCCESS:
      return action.invoice;

    default:
      return state;
  }
};

export const savingInvoice = (state = initialState.savingInvoice, action) => {
  switch (action.type) {
    case types.SAVING_INVOICE:
      return action.savingInvoice;

    default:
      return state;
  }
};

export const loadingInvoice = (state = initialState.loadingInvoice, action) => {
  switch (action.type) {
    case types.LOADING_INVOICE:
      return action.loadingInvoice;

    default:
      return state;
  }
};

export const invoiceToVoid = (state = initialState.invoiceToVoid, action) => {
  switch (action.type) {
    case types.REQUEST_INVOICE_ID:
      return action.invoiceToVoid;

    default:
      return state;
  }
};

export const invoicesToPrint = (
  state = initialState.invoicesToPrint,
  action
) => {
  switch (action.type) {
    case types.TOGGLE_INVOICE_PRINT: {
      // start off with the existing state
      let invoicesToPrint = [...state];
      const keyIndex = invoicesToPrint.indexOf(action.number);
      // check to see if the key exists
      if (keyIndex >= 0) {
        // it does exist so we will remove it using destructing
        invoicesToPrint = [
          ...invoicesToPrint.slice(0, keyIndex),
          ...invoicesToPrint.slice(keyIndex + 1),
        ];
      } else {
        // it does not exist so add it
        invoicesToPrint.push(action.number);
      }

      return [...invoicesToPrint];
    }

    case types.TOGGLE_INVOICES: {
      let invoices = [...state];
      action.invoices.map((invoice) => {
        const keyIndex = invoices.indexOf(invoice);
        // check to see if the key exists
        if (keyIndex >= 0) {
          // it does exist so we will remove it using destructing
          invoices = [
            ...invoices.slice(0, keyIndex),
            ...invoices.slice(keyIndex + 1),
          ];
        } else {
          // it does not exist so add it
          invoices.push(invoice);
        }
      });
      return [...invoices];
    }

    case types.DESELECT_ALL_INVOICES: {
      return [];
    }

    default:
      return state;
  }
};

export const invoicesToApprove = (
  state = initialState.invoicesToApprove,
  action
) => {
  switch (action.type) {
    case types.TOGGLE_INVOICE: {
      // start off with the existing state
      let invoicesToApprove = [...state];
      const keyIndex = invoicesToApprove.indexOf(action.id);
      // check to see if the key exists
      if (keyIndex >= 0) {
        // it does exist so we will remove it using destructing
        invoicesToApprove = [
          ...invoicesToApprove.slice(0, keyIndex),
          ...invoicesToApprove.slice(keyIndex + 1),
        ];
      } else {
        // it does not exist so add it
        invoicesToApprove.push(action.id);
      }

      return [...invoicesToApprove];
    }

    case types.TOGGLE_INVOICES_TO_APPROVE: {
      let invoices = [...state];
      action.invoices.map((invoice) => {
        const keyIndex = invoices.indexOf(invoice);
        // check to see if the key exists
        if (keyIndex >= 0) {
          // it does exist so we will remove it using destructing
          invoices = [
            ...invoices.slice(0, keyIndex),
            ...invoices.slice(keyIndex + 1),
          ];
        } else {
          // it does not exist so add it
          invoices.push(invoice);
        }
      });
      return [...invoices];
    }

    case types.DESELECT_ALL_INVOICES: {
      return [];
    }

    default:
      return state;
  }
};

export const invoicesToComplete = (
  state = initialState.invoicesToComplete,
  action
) => {
  switch (action.type) {
    case types.TOGGLE_GP_INVOICE: {
      // start off with the existing state
      let invoicesToComplete = [...state];
      const keyIndex = invoicesToComplete.indexOf(action.id);
      // check to see if the key exists
      if (keyIndex >= 0) {
        // it does exist so we will remove it using destructing
        invoicesToComplete = [
          ...invoicesToComplete.slice(0, keyIndex),
          ...invoicesToComplete.slice(keyIndex + 1),
        ];
      } else {
        // it does not exist so add it
        invoicesToComplete.push(action.id);
      }

      return [...invoicesToComplete];
    }

    case types.TOGGLE_ALL_GP_INVOICES: {
      // start off with the existing state
      let invoicesToComplete = [...state];
      action.invoices.map((invoice) => {
        const keyIndex = invoicesToComplete.indexOf(invoice);
        // check to see if the key exists
        if (keyIndex >= 0) {
          // if it does exist we will remove it using destructing
          invoicesToComplete = [
            ...invoicesToComplete.slice(0, keyIndex),
            ...invoicesToComplete.slice(keyIndex + 1),
          ];
        } else {
          // if it does not exist we add it
          invoicesToComplete.push(invoice);
        }
      });
      return [...invoicesToComplete];
    }

    case types.DESELECT_ALL_INVOICES: {
      return [];
    }

    default:
      return state;
  }
};
