import { useCallback, useEffect, useState } from "react";

import moment from "moment";
import { isEmpty } from "lodash";

import { getInitialState, getDefaultStage } from "../utils";

export default function useCallChangeHandler(call, auth, codes) {
  const [inputs, setInputs] = useState({
    ...getInitialState(),
    stage: getDefaultStage(codes),
    division: auth.user.division,
    assigned_to: auth.user._id,
    completed_by: auth.user._id,
  });

  useEffect(() => {
    if (!isEmpty(call)) {
      setInputs((prev) => ({
        ...prev,
        assigned_to: call.assigned_to,
        contract: call.contract,
        end_time: call.end_time,
        product_category: call.product_category,
        stage: call.stage,
        start_time: call.start_time,
        billing_quantity: call.billing_quantity,
        quantity: call.quantity,
        entries: call.entries,
        division: call.division,
        parent_account: call.parent_account,
        tif_in: call.tif_in,
        tif_out: call.tif_out,
        invoiced_by: call.invoiced_by,
        invoice: call.invoice,
        approved_by: call.approved_by,
        notes: call.notes,
        completed_by: call.completed_by,
      }));
    }
  }, [call]);

  useEffect(() => {
    if (inputs.start_time && inputs.quantity) {
      setInputs((prev) => ({
        ...prev,
        end_time: moment(inputs.start_time).add(inputs.quantity, "hours"),
      }));
    }
  }, [inputs.quantity, inputs.start_time]);

  useEffect(() => {
    if (inputs.start_time && inputs.contract?.is_tif) {
      setInputs((prev) => ({ ...prev, tif_in: inputs.start_time }));
    }
  }, [inputs.contract?.is_tif, inputs.start_time]);

  useEffect(() => {
    if (inputs.end_time && inputs.contract?.is_tif) {
      setInputs((prev) => ({ ...prev, tif_out: inputs.end_time }));
    }
  }, [inputs.contract?.is_tif, inputs.end_time]);

  const handleChange = useCallback((name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleMultipleChanges = useCallback((data) => {
    setInputs((prev) => ({ ...prev, ...data }));
  }, []);

  return {
    inputs,
    handleChange,
    handleMultipleChanges,
  };
}
