import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { groupBy, orderBy } from "lodash";
import { Row, Col, FormControl } from "react-bootstrap";
import DropdownSearch from "./DropdownSearchWithoutFormsy";

const CityAndZipDropdown = ({
  zips,
  city,
  county,
  zip,
  disabled,
  onChange,
}) => {
  const [cities, setCities] = useState(null);

  useEffect(() => {
    if (zips && zips.length && !Array.isArray(cities)) {
      const groupedByCity = groupBy(zips, "city");
      setCities(
        Object.keys(groupedByCity).map((key) => ({
          zips: groupedByCity[key],
          city: key,
        }))
      );
    }
  }, [zips, cities]);

  const cityOptions = useMemo(() => {
    const cityOptions =
      cities && cities.length
        ? cities.map((c) => ({
            value: c.city,
            label: c.city,
          }))
        : [];

    return orderBy(cityOptions, "label");
  }, [cities]);

  const handleChange = (name, value) => {
    const filteredCity = cities.find((c) => c.city === city);
    if (name === "zip" && !filteredCity) {
      const foundZip = zips.find((z) => z.zip === value);
      onChange({
        [name]: value,
        city: foundZip.city,
        county: foundZip.county,
      });
    } else if (name === "city") {
      const selectedCity = cities.find((c) => c.city === value);
      onChange({
        [name]: value,
        zip: selectedCity.zips.length === 1 ? selectedCity.zips[0].zip : "",
        county:
          selectedCity.zips.length === 1 ? selectedCity.zips[0].county : "",
      });
    } else if (name === "zip") {
      const foundZip = zips.find((z) => z.zip === value);
      onChange({
        [name]: value,
        county: foundZip.county,
      });
    } else {
      onChange({
        [name]: value,
      });
    }
  };

  const zipOptions = useMemo(() => {
    if (city && cities) {
      const filteredCity = cities.find((c) => c.city === city);
      const zipOptions =
        filteredCity && filteredCity.zips && filteredCity.zips.length
          ? filteredCity.zips.map((z) => ({
              value: z.zip,
              label: z.zip,
            }))
          : zips.map((z) => ({
              value: z.zip,
              label: z.zip,
            }));
      return orderBy(zipOptions, "label");
    }
    return zips.map((z) => ({
      value: z.zip,
      label: z.zip,
    }));
  }, [cities, city]);

  return (
    <Row>
      <Col md={2} xs={12}>
        <DropdownSearch
          name="city"
          label="City"
          onChange={(name, value) =>
            handleChange("city", value.value.toString())
          }
          disabled={disabled}
          value={city}
          data={cityOptions}
        />
      </Col>
      <Col md={2} xs={12}>
        <DropdownSearch
          name="zip"
          label="Zip"
          onChange={(name, value) =>
            handleChange("zip", value.value.toString())
          }
          disabled={disabled}
          value={zip}
          data={zipOptions}
        />
      </Col>
      <Col style={{ display: "none" }}>
        <FormControl
          disabled
          label="County"
          name="county"
          value={county}
          onChange={(name, value) => onChange(name, value)}
        />
      </Col>
    </Row>
  );
};
CityAndZipDropdown.propTypes = {
  zips: PropTypes.array,
  city: PropTypes.string,
  zip: PropTypes.string,
  county: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CityAndZipDropdown;
