import React, { Component } from "react";
import { Row, Col, Button, FormGroup, FormControl } from "react-bootstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

class CustomToolbar extends Component {
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.toolbar !== this.props.toolbar) {
      this.props.onViewChange(nextProps.toolbar);
    }
  };

  render() {
    let { toolbar } = this.props;
    let customToolbar;
    if (toolbar) {
      const viewChange = (view) => {
        toolbar.onViewChange(view);
      };

      const goToBack = () => {
        if (toolbar.view === "month") {
          toolbar.date.setMonth(toolbar.date.getMonth() - 1);
        } else if (toolbar.view === "week") {
          toolbar.date.setDate(toolbar.date.getDate() - 7);
        } else if (toolbar.view === "day") {
          toolbar.date.setDate(toolbar.date.getDate() - 1);
        } else if (toolbar.view === "agenda") {
          toolbar.date.setDate(toolbar.date.getDate() - 30);
        }
        toolbar.onNavigate("prev");
      };
      const goToNext = () => {
        if (toolbar.view === "month") {
          toolbar.date.setMonth(toolbar.date.getMonth() + 1);
        } else if (toolbar.view === "week") {
          toolbar.date.setDate(toolbar.date.getDate() + 7);
        } else if (toolbar.view === "day") {
          toolbar.date.setDate(toolbar.date.getDate() + 1);
        } else if (toolbar.view === "agenda") {
          toolbar.date.setDate(toolbar.date.getDate() + 30);
        }
        toolbar.onNavigate("next");
      };

      const goToCurrent = () => {
        const now = new Date();
        if (toolbar.view == "agenda") {
          toolbar.date.setMonth(now.getMonth());
          toolbar.date.setYear(now.getFullYear());
          toolbar.date.setDate(1);
        } else {
          toolbar.date.setMonth(now.getMonth());
          toolbar.date.setDate(now.getDate());
          toolbar.date.setYear(now.getFullYear());
        }
        toolbar.onNavigate("current");
      };

      const renderLabel = () => {
        return (
          <span>
            <strong>{toolbar.label.split(" ")[0]}</strong>
            &nbsp;
            {toolbar.label
              .split(" ")
              .filter((_, i) => i !== 0)
              .join(" ")}
          </span>
        );
      };

      customToolbar = (
        <div className="toolbar-container">
          <Row>
            <Col md={12}>
              <span className="label-date">{renderLabel()}</span>
            </Col>
          </Row>
          <Row>
            <Col md={12} style={{ display: "flex", justifyContent: "center" }}>
              <div
                className="legend-color"
                style={{ backgroundColor: "#A2D9F4", borderRadius: "50%" }}
              />
              <p className="legend-text">Scheduled</p>
              <div
                className="legend-color"
                style={{ backgroundColor: "#B9E3A4", borderRadius: "50%" }}
              />
              <p className="legend-text">Completed</p>
              <div
                className="legend-color"
                style={{ backgroundColor: "#F6DF92", borderRadius: "50%" }}
              />
              <p className="legend-text">Approved</p>
              <div
                className="legend-color"
                style={{ backgroundColor: "#FBA5A5", borderRadius: "50%" }}
              />
              <p className="legend-text">Invoiced</p>
              <div
                className="legend-color"
                style={{ backgroundColor: "#CEB9FF", borderRadius: "50%" }}
              />
              <p className="legend-text">Non-Invoiced</p>
              <div
                className="legend-color"
                style={{ backgroundColor: "#CCCCCC", borderRadius: "50%" }}
              />
              <p className="legend-text">Cancelled</p>
            </Col>
          </Row>
          <Row>
            <Col
              md={3}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Button className="btn-back" onClick={goToBack}>
                <FontAwesomeIcon icon={faChevronLeft} className="icon" />
              </Button>
              <Button className="btn-next" onClick={goToNext}>
                <FontAwesomeIcon icon={faChevronRight} className="icon" />
              </Button>
            </Col>
            <Col
              md={6}
              style={{ display: "flex", justifyContent: "center" }}
              className="views"
            >
              {toolbar.views.map((view, i) => (
                <Button
                  key={i}
                  onClick={() => viewChange(view)}
                  className={toolbar.view == view ? "active" : null}
                >
                  {view}
                </Button>
              ))}
              {/* {renderViews()} */}
            </Col>
            <Col md={3} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={goToCurrent}>Today</Button>
            </Col>
          </Row>
        </div>
      );
    }

    return <div>{customToolbar}</div>;
  }
}

CustomToolbar.propTypes = {
  toolbar: PropTypes.object,
};

export default CustomToolbar;
