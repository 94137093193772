import React, { Component } from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import { Input } from "formsy-react-components";
import Formsy from "formsy-react";
import DropdownSearch from "../common/DropdownSearch";
import PhoneNumberInput from "../common/PhoneNumberInput";

import orderBy from "lodash/orderBy";

class AccountForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
    };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  cancel = () => {
    this.props.onCancel();
  };

  submit = (model) => {
    this.props.onSave(model);
  };

  generateCodeOptions = (entity, field) => {
    const codeOptions = this.props.codes
      .filter((codes) => codes.entity == entity && codes.field == field)
      .map((code) => ({
        value: code._id,
        label: code.label,
      }));

    return orderBy(codeOptions, "label");
  };

  onChange = (field, selected) => {
    this.setState({ [field]: selected.value });
  };

  render() {
    let { account, adding, editing, saving } = this.props;

    if (adding) {
      account.address = account.address || {};
    }

    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={!adding ? 3 : 4}>
            <Input
              required
              name="name"
              label="Name"
              disabled={!editing}
              value={account.name}
            />
          </Col>
          {!adding && (
            <Col md={3}>
              <Input
                required
                type="number"
                name="account_number"
                label="Account Number"
                disabled={!editing}
                value={account.account_number}
              />
            </Col>
          )}
          <Col md={!adding ? 3 : 4}>
            <DropdownSearch
              required
              name="relationship_type"
              label="Relationship Type"
              value={this.state.relationship_type || account.relationship_type}
              onChange={this.onChange}
              disabled={!editing}
              data={[
                ...this.generateCodeOptions("Account", "relationship_type"),
              ]}
            />
          </Col>
          <Col md={!adding ? 3 : 4}>
            <DropdownSearch
              required
              name="revenue_source"
              label="Revenue Source"
              value={this.state.revenue_source || account.revenue_source}
              onChange={this.onChange}
              disabled={!editing}
              data={[...this.generateCodeOptions("Account", "revenue_source")]}
            />
          </Col>
          </Row>
          <Row>
          <Col md={4}>
            <PhoneNumberInput
              name="phone"
              label="Phone"
              placeholder="Phone"
              value={account.phone}
              disabled={!editing}
            />
          </Col>
          <Col md={4}>
            <PhoneNumberInput
              name="fax"
              label="Fax"
              placeholder="Fax"
              value={account.fax}
              disabled={!editing}
            />
          </Col>
          <Col md={4}>
            <Input
              name="website"
              label="URL"
              value={account.website}
              disabled={!editing}
            />
          </Col>
        </Row>
        <Row>
          <fieldset>
            <Col md={12} xs={12}>
              <legend>Primary Address</legend>
            </Col>
            <Col md={4} xs={12}>
              <Input
                required
                name="address.addr1"
                label="Street Address 1"
                value={account.address.addr1}
                disabled={!editing}
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                name="address.addr2"
                label="Street Address 2"
                value={account.address.addr2}
                disabled={!editing}
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                required
                name="address.city"
                label="City"
                value={account.address.city}
                disabled={!editing}
              />
            </Col>
            <Col md={4} xs={12}>
              <DropdownSearch
                required
                name="address.state"
                label="State"
                value={this.state["address.state"] || account.address.state}
                onChange={this.onChange}
                disabled={!editing}
                data={[...this.generateCodeOptions("*", "state")]}
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                required
                name="address.zip"
                label="Zip"
                value={account.address.zip}
                disabled={!editing}
              />
            </Col>
            <Col md={4} xs={12}>
              <DropdownSearch
                required
                name="address.country"
                label="Country"
                value={this.state["address.country"] || account.address.country}
                onChange={this.onChange}
                disabled={!editing}
                data={[...this.generateCodeOptions("*", "country")]}
              />
            </Col>
          </fieldset>
        </Row>
        <Row>
          <Col md={12} xs={12} className="action-footer">
            <button
              className="btn btn-danger"
              type="button"
              onClick={this.cancel}
            >
              Cancel
            </button>
            <input
              className="btn btn-success"
              type="submit"
              disabled={!this.state.canSubmit}
              value={saving ? "Saving... " : "Save"}
            />
          </Col>
        </Row>
      </Formsy>
    );
  }
}

AccountForm.propTypes = {
  codes: PropTypes.array.isRequired,
  contracts: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  adding: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  account: PropTypes.object.isRequired,
};

export default AccountForm;
