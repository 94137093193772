import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DynamicHeader from "../DynamicHeader";

import { Nav, NavItem } from "react-bootstrap";
import { LinkContainer, IndexLinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faUser,
  faBriefcase,
  faMapMarkerAlt,
  faThList,
  faCogs,
  faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";
import { config } from "../../../config";
//
class SettingsMenu extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    return (
      <Nav className="sidebar sidebar-nav">
        <IndexLinkContainer to="/app">
          <NavItem eventKey={1}>
            <FontAwesomeIcon icon={faChevronLeft} />
            Exit Settings
          </NavItem>
        </IndexLinkContainer>
        {this.props.auth.isAuth && this.props.auth.maxRole >= 9000 && (
          <NavItem className="menu-header">Company</NavItem>
        )}
        {this.props.auth.isAuth && this.props.auth.maxRole >= 9000 && (
          <LinkContainer to="/app/settings/divisions">
            <NavItem eventKey={3} className="submenu-item">
              <FontAwesomeIcon icon={faBriefcase} />
              <DynamicHeader entity="Divisions" plural />
            </NavItem>
          </LinkContainer>
        )}
        {this.props.auth.isAuth && this.props.auth.maxRole >= 9000 && (
          <LinkContainer to="/app/settings/roles">
            <NavItem eventKey={3} className="submenu-item">
              <FontAwesomeIcon icon={faUser} />
              <DynamicHeader entity="Roles" plural />
            </NavItem>
          </LinkContainer>
        )}
        {this.props.auth.isAuth && this.props.auth.maxRole >= 9000 && (
          <NavItem className="menu-header">System</NavItem>
        )}
        {this.props.auth.isAuth && this.props.auth.maxRole >= 9000 && (
          <LinkContainer to="/app/settings/glaccounts">
            <NavItem eventKey={3} className="submenu-item">
              <FontAwesomeIcon icon={faBriefcase} />
              <DynamicHeader entity="GL Accounts" plural />
            </NavItem>
          </LinkContainer>
        )}
        {this.props.auth.isAuth && this.props.auth.maxRole >= 9000 && (
          <LinkContainer to="/app/settings/zips">
            <NavItem eventKey={3} className="submenu-item">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <DynamicHeader entity="Zips" plural />
            </NavItem>
          </LinkContainer>
        )}
        {this.props.auth.isAuth && this.props.auth.maxRole >= 9000 && (
          <LinkContainer to="/app/settings/procedures">
            <NavItem eventKey={3} className="submenu-item">
              <FontAwesomeIcon icon={faThList} />
              <DynamicHeader entity="Procedures" plural />
            </NavItem>
          </LinkContainer>
        )}
        {this.props.auth.isAuth && this.props.auth.maxRole >= 9000 && (
          <LinkContainer to="/app/settings/codes">
            <NavItem eventKey={3} className="submenu-item">
              <FontAwesomeIcon icon={faCogs} />
              <DynamicHeader entity="Codes" plural />
            </NavItem>
          </LinkContainer>
        )}
        {this.props.auth.isAuth && this.props.auth.maxRole >= 9000 && (
          <NavItem className="menu-header">Integrations</NavItem>
        )}
        {this.props.auth.isAuth && this.props.auth.maxRole >= 9000 && (
          <LinkContainer to="/app/settings/gp">
            <NavItem eventKey={3} className="submenu-item">
              <FontAwesomeIcon icon={faPuzzlePiece} />
              <DynamicHeader entity="GP" />
            </NavItem>
          </LinkContainer>
        )}
        {this.props.auth.isAuth && this.props.auth.maxRole >= 9000 && (
          <LinkContainer to="/app/settings/adp">
            <NavItem eventKey={3} className="submenu-item">
              <FontAwesomeIcon icon={faPuzzlePiece} />
              <DynamicHeader entity="ADP" />
            </NavItem>
          </LinkContainer>
        )}
        <div className="footer-tag">
          <p>
            ECLIPS {config.VERSION}
            <br /> © 2019 GraVoc Associates, Inc.
          </p>
        </div>
      </Nav>
    );
  }
}

SettingsMenu.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
};

function mapStatesToProps(state, ownProps) {
  return { state: state, auth: state.reducers.auth };
}

export default connect(mapStatesToProps)(SettingsMenu);
