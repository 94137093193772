import { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LinkContainer } from "react-router-bootstrap";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faTrashAlt,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { DropdownButton, MenuItem } from "react-bootstrap";
import find from "lodash/find";
import moment from "moment";

import * as callActions from "../../actions/callActions";

import Loading from "../common/Loading";

class CallApprovalList extends Component {
  render() {
    const { calls, filters, count, selected } = this.props;

    const allSelected = calls.every((call) =>
      selected.some((c) => call._id === c._id)
    );

    const columns = [
      { expander: true, show: false },
      {
        accessor: "_id",
        Header: () => (
          <input
            type="checkbox"
            style={{ margin: "3px auto" }}
            onChange={this.props.toggleSelectAll}
            checked={allSelected}
            defaultChecked={allSelected}
          />
        ),
        Cell: (row) => (
          <input
            type="checkbox"
            onChange={() => this.props.onToggle(row.original)}
            defaultChecked={selected.some(
              (call) => call._id === row.original._id
            )}
            checked={selected.some((call) => call._id === row.original._id)}
          />
        ),
        Footer: (
          <span className="footer-totals">
            <span className="total">
              <label>Total Hours: </label>
              {this.props.totalHours}
            </span>
          </span>
        ),
        sortable: false,
        filterable: false,
        maxWidth: 60,
        style: {
          textAlign: "center",
        },
      },
      {
        Header: "Employee",
        id: "assigned_to",
        accessor: "assigned_to",
        Cell: (row) => (
          <span>
            {row.value.full_name
              ? row.value.full_name
              : `${row.value.last_name}, ${row.value.first_name}`}
          </span>
        ),
      },
      {
        Header: "Date of Service",
        id: "start_time",
        accessor: (d) => d.start_time,
        Cell: (row) => {
          return <span>{moment(row.value).format("MM/DD/YYYY")}</span>;
        },
      },
      {
        Header: "Contract",
        accessor: "contract.name",
        Cell: (row) => {
          return <span>{row.value}</span>;
        },
      },
      {
        Header: "Duration",
        accessor: "quantity",
        Cell: (row) => {
          return (
            <span
              onClick={(e) => this.props.onClickDetail(e, row.original._id)}
              className="table-action"
            >
              {row.value ? row.value.toFixed(2) : "--"}
              <FontAwesomeIcon icon={faSearch} />
            </span>
          );
        },
      },
      {
        Header: "Billing Quantity",
        accessor: "billing_quantity",
        Cell: (row) => <span>{row.value ? row.value.toFixed(2) : "--"}</span>,
      },
      {
        Header: "",
        accessor: "_id",
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <div className="text-center actions">
            <DropdownButton
              title="Actions"
              id={`dropdown`}
              onClick={(e) => e.stopPropagation()}
            >
              <LinkContainer to={`/app/calls/${row.value}`}>
                <MenuItem>
                  <FontAwesomeIcon icon={faFolder} />
                  View
                </MenuItem>
              </LinkContainer>
              <MenuItem onClick={() => this.props.onClickDelete(row.value)}>
                <FontAwesomeIcon icon={faTrashAlt} className="brand-color" />
                Delete
              </MenuItem>
            </DropdownButton>
          </div>
        ),
      },
    ];

    const subColumns = [
      {
        Header: "Procedure",
        accessor: "procedure",
        Cell: (row) => {
          let call = row.original.call;
          if (!call) {
            call = find(this.props.calls, (call) => {
              let entry = find(call.entries, { _id: row.original._id });
              if (entry) return call;
            });
          } else {
            call = find(this.props.calls, { _id: call });
          }
          if (call) {
            let contract = find(this.props.contracts, {
              _id: call.contract._id || call.contract,
            });
            if (contract) {
              let procedure = contract
                ? find(contract.lines, { _id: row.value }) ||
                  find(this.props.items, { id: row.value })
                : "";
              return procedure ? procedure.item.description : "--";
            }
          }
          return "--";
        },
      },
      {
        Header: "Duration",
        accessor: "quantity",
        Cell: (row) => (row.value ? row.value.toFixed(2) : "--"),
      },
      {
        Header: "Quantity",
        accessor: "billing_quantity",
        Cell: (row) => (row.value ? row.value.toFixed(2) : "--"),
      },
      {
        Header: "UOM",
        accessor: "procedure",
        sortable: false,
        Cell: (row) => {
          let call = row.original.call;
          if (!call) {
            call = find(this.props.calls, (call) => {
              let entry = find(call.entries, { _id: row.original._id });
              if (entry) return call;
            });
          } else {
            call = find(this.props.calls, { _id: call });
          }

          let procedure =
            call && call.contract
              ? find(call.contract.lines, { _id: row.value }) ||
                find(this.props.items, { id: row.value })
              : "";
          return procedure ? procedure.uom.value : "--";
        },
      },
      {
        Header: "Client",
        accessor: "client",
        Cell: (row) => {
          let call = row.original.call;
          if (!call) {
            call = find(this.props.calls, (call) => {
              let entry = find(call.entries, { _id: row.original._id });
              if (entry) return call;
            });
          } else {
            call = find(this.props.calls, { _id: call });
          }

          let client = call && find(this.props.clients, { _id: row.value });
          if (client) {
            return `${client.last_name}, ${client.first_name}`;
          } else {
            let originalClient =
              call && find(call.contract.clients, { _id: row.value });
            return originalClient ? originalClient.name : "--";
          }
        },
      },
    ];

    const checkboxProps = {
      selectType: "checkbox",
      getTrGroupProps: (state, row) => {
        if (state && row && row.original) {
          const isSelected = selected.some(
            (inv) => inv._id === row.original._id
          );
          return {
            style: {
              color: isSelected ? "#cf4a03" : "inherit",
              fontWeight: isSelected ? 500 : 400,
            },
          };
        } else {
          return { style: {} };
        }
      },
      getTdProps: (_state, rowInfo, column) => {
        return {
          onClick: () => {
            if (column.id !== "_id") {
              this.props.onToggle(rowInfo.original);
            }
          },
        };
      },
    };

    return (
      <ReactTable
        manual
        pageSize={filters.pageSize}
        pages={Math.ceil(count / filters.pageSize)}
        page={filters.page}
        sorted={filters.sorted}
        onPageChange={this.props.onPageChange}
        onPageSizeChange={this.props.onPageSizeChange}
        onSortedChange={this.props.onSortedChange}
        className="-grouped"
        data={calls}
        columns={columns}
        defaultPageSize={filters.pageSize}
        expanded={calls.map(() => true)}
        defaultSorted={filters.sorted}
        showPageJump={false}
        LoadingComponent={() => <Loading active={this.props.loading} />}
        {...checkboxProps}
        SubComponent={(row) => (
          <div
            style={{
              padding: "0 60px 20px 60px",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <ReactTable
              className="subtable"
              data={row.original.entries}
              columns={subColumns}
              showPagination={false}
              pageSize={row.original.entries.length}
            />
          </div>
        )}
      />
    );
  }
}

CallApprovalList.propTypes = {
  selected: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  calls: PropTypes.array.isRequired,
  items: PropTypes.array,
  contracts: PropTypes.array,
  onToggle: PropTypes.func,
  onClickDetail: PropTypes.func,
  onClickDelete: PropTypes.func,
  filter: PropTypes.object,
  clients: PropTypes.array.isRequired,
  actions: PropTypes.object,
  filters: PropTypes.object,
  onPageSizeChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onSortedChange: PropTypes.func,
  loading: PropTypes.bool,
  totalHours: PropTypes.number,
  toggleSelectAll: PropTypes.func,
};

CallApprovalList.defaultProps = {
  calls: [],
  count: 0,
  totalHours: 0,
};

function mapStatesToProps(state) {
  return {
    loadingCall: state.reducers.loadingCall,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...callActions }, dispatch),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(CallApprovalList);
