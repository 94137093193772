import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import matchSorter from "match-sorter";
import { DropdownButton, MenuItem, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faFolder,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import ReactTable from "react-table";
import ReactTooltip from "react-tooltip";
import { LinkContainer } from "react-router-bootstrap";
import find from "lodash/find";
import pdfMake from "pdfmake/build/pdfmake";

import Modal from "../../common/Modal";
import ConfirmModal from "../../common/ConfirmModal";
import ContractExportForm from "../../contract/contractLines/ContractExportForm";
import Loading from "../../common/Loading";

import * as clientActions from "../../../actions/clientActions";
import * as contractActions from "../../../actions/contractActions";
import BreadCrumbs from "../../common/BreadCrumbs";
import * as modalActions from "../../../actions/modalActions";
import PDF from "../../contract/contractTemplates/pdfTemplate";
import PDFRSO from "../../contract/contractTemplates/pdfTemplateRSO";
import PDFRSSN from "../../contract/contractTemplates/pdfTemplateRSSN";
import PDFADM from "../../contract/contractTemplates/pdfTemplateAddendum";

const ClientContractsPage = ({
  actions,
  auth,
  match,
  modal,
  clientContracts,
  client,
  codes,
  contractToDelete,
  history,
}) => {
  const [contracts, setContracts] = useState(null);
  const [charged, setCharged] = useState(false);
  const [exportState, setExportState] = useState({
    contractId: null,
    line: null,
  });

  const onClickDelete = (contractId) => {
    actions.requestContractId(contractId);
  };

  const handleDelete = async () => {
    await actions.deleteContract(contractToDelete);
    await actions.getClientContracts();
  };

  const onClickExport = (id) => {
    actions.getContract(id, true);
  };

  //Creates PDF Template object
  const createPDFObject = (
    docID,
    esmaSignatory,
    dateRangeStart,
    dateRangeEnd,
    notes
  ) => {
    const contract = find(contracts, { _id: docID });
    const allValidLines = contract.lines.filter((line) => line.status == false);

    if (contract) {
      const parentAccount = contract.parent_account;
      const contractContact = contract.contract_contact;
      const state = find(codes, {
        id: parentAccount.locations[0].address.state,
      });

      const startDate = moment.utc(contract.start_date).format("MMMM D, YYYY");
      const endDate = moment.utc(contract.end_date).format("MMMM D, YYYY");

      const billingName = `${contract.billing_admin.first_name} ${contract.billing_admin.last_name}`;

      const esmaContact = contract.esma_contact;

      const signatoryName = `${contract.signatory.first_name} ${contract.signatory.last_name}`;

      let selectedLines = [];

      const currentDate = moment().format("MMMM D, YYYY");

      //populate selectedLines based on date range selected, or select all lines
      if (!dateRangeStart || !dateRangeEnd) {
        selectedLines = allValidLines;
      } else {
        for (let i = 0; i < allValidLines.length; i++) {
          const lineStart = moment
            .utc(allValidLines[i].start_date)
            .format("MMMM D, YYYY");
          const lineEnd = moment
            .utc(allValidLines[i].end_date)
            .format("MMMM D, YYYY");
          if (
            (moment(dateRangeStart).isBefore(lineEnd, "day") ||
              moment(dateRangeStart).isSame(lineEnd, "day")) &&
            (moment(dateRangeEnd).isAfter(lineStart, "day") ||
              moment(dateRangeEnd).isSame(lineStart, "day"))
          ) {
            selectedLines.push(allValidLines[i]);
          }
        }
      }

      const pdfObject = {
        signatory: signatoryName,
        title: contractContact.title || "",
        customerName: contractContact
          ? `${contractContact.first_name} ${contractContact.last_name}`
          : "",
        companyName: parentAccount.name,
        streetAddr: parentAccount.locations[0].address.addr1,
        city: parentAccount.locations[0].address.city,
        state: state.label,
        zip: parentAccount.locations[0].address.zip,
        startDate: startDate,
        endDate: endDate,
        phone: parentAccount.phone || "",
        fax: parentAccount.fax || "",
        email: contract.signatory.email || "",
        numberOfLines: selectedLines.length,
        lines: selectedLines,
        esmaSignatory: esmaSignatory,
        esmaContact: esmaContact,
        billingName: billingName,
        currentDate: currentDate,
        notes: notes,
      };

      return pdfObject;
    }
  };

  // Builds PDF template
  const createPDFTemplate = (
    contract,
    template,
    esmaSignatory,
    dateRangeStart,
    dateRangeEnd,
    notes
  ) => {
    const pdfObj = createPDFObject(
      contract._id,
      esmaSignatory,
      dateRangeStart,
      dateRangeEnd,
      notes
    );

    switch (template) {
      case "Private Payer":
        return PDF.createPDF(pdfObj);
      case "RS Skilled Nursing Facility":
        return PDFRSSN.createPDF(pdfObj);
      case "Other":
        return PDFRSO.createPDF(pdfObj);
      case "Addendum":
        return PDFADM.createPDF(pdfObj);
    }
  };

  const handleExport = (data) => {
    const contract = find(contracts, { _id: data.id });
    const esmaSignatory = data.esma_signatory;
    const contractType = find(codes, {
      id: data.contract_template,
    });

    const dateRangeStart = data.startDate;
    const dateRangeEnd = data.endDate;

    const notes = data.notes.map((note) => note.content);

    if (contractType) {
      let contractTemplateLabel = contractType.label;
      const pdf = this.createPDFTemplate(
        contract,
        contractTemplateLabel,
        esmaSignatory,
        dateRangeStart,
        dateRangeEnd,
        notes
      );
      pdfMake.createPdf(pdf).open();
    }
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["name"] }),
      filterAll: true,
    },
    {
      Header: "Number",
      accessor: "number",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["number"] }),
      filterAll: true,
    },
    {
      Header: "Account",
      accessor: "parent_account.name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(item) => item["parent_account.name"]],
        }),
      filterAll: true,
    },
    {
      Header: "Start Date",
      accessor: "start_date",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(item) => moment(item.start_date).format("MM/DD/YYYY")],
        }),
      filterAll: true,
      Cell: (row) => <span>{moment.utc(row.value).format("DD MMM YYYY")}</span>,
    },
    {
      Header: "End Date",
      accessor: "end_date",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(item) => moment(item.start_date).format("MM/DD/YYYY")],
        }),
      filterAll: true,
      Cell: (row) => <span>{moment.utc(row.value).format("DD MMM YYYY")}</span>,
    },
    {
      Header: "Status",
      accessor: "stage.label",
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, {
          keys: [(row) => row[`stage.label`]],
        });
      },
      filterAll: true,
      Cell: (row) => {
        let className = "default";
        let endDate = row.original.end_date;
        if (row.value == "Approved") {
          if (moment().add(30, "days").isBefore(moment(endDate))) {
            // Green after 30 days
            className = "success";
          } else if (
            moment().add(30, "days").isAfter(moment(endDate)) &&
            moment().isBefore(moment(endDate))
          ) {
            // Yellow within 30 days
            className = "warning";
          } else if (moment().isAfter(moment(endDate))) {
            // Red after current date
            className = "danger";
          }
        }
        return (
          <div>
            <span className={`status ${className}`} />
            {row.value}
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "_id",
      filterable: false,
      sortable: false,
      Cell: (row) => {
        let expired = false;
        const endDate = row.original.end_date;
        const stage = row.original.stage;

        if (moment().isAfter(moment(endDate)) && stage.label == "Approved") {
          expired = true;
        }

        let hasCalls = row.original.has_calls;

        return (
          <div className="text-center actions">
            <DropdownButton
              title="Actions"
              id={"dropdown"}
              onClick={(e) => e.stopPropagation()}
            >
              <LinkContainer to={`/app/contracts/${row.value}`}>
                <MenuItem>
                  <FontAwesomeIcon icon={faFolder} />
                  View
                </MenuItem>
              </LinkContainer>
              {auth.maxRole == 9000 && (
                <MenuItem
                  data-tip="There are calls associated with this contract."
                  data-for="hasCalls"
                  onClick={() => (!hasCalls ? onClickDelete(row.value) : "")}
                  disabled={hasCalls}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="brand-color" />
                  Delete
                </MenuItem>
              )}
              {hasCalls && (
                <ReactTooltip
                  id="hasCalls"
                  place="left"
                  type="dark"
                  effect="solid"
                />
              )}
              {!expired && (
                <MenuItem onClick={() => onClickExport(row.value)}>
                  <FontAwesomeIcon icon={faDownload} />
                  Export
                </MenuItem>
              )}
            </DropdownButton>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (!charged && match && match.params && match.params.id) {
      setCharged(true);
      actions.getClientContracts(match.params.id);
    }
  }, [clientContracts, client]);

  useEffect(() => {
    if (clientContracts) {
      setContracts(clientContracts);
    }
  }, [clientContracts]);

  const props = {
    getTdProps: (state, rowInfo, column) => {
      return {
        onClick: () => {
          if (column.id !== "_id") {
            history.push(`/app/contracts/${rowInfo.original._id}`);
          }
        },
      };
    },
  };

  const contractExportForm = (
    <ContractExportForm
      contractId={exportState.contractId}
      className="export-modal"
      line={exportState.line}
      editing={true}
      codes={codes}
      onCancel={actions.hideModal}
      onExport={handleExport}
    />
  );

  if (!contracts) return <Loading />;

  return (
    <div className="content-wrapper">
      <Row>
        <BreadCrumbs
          breadcrumbs={[
            {
              label: client
                ? `${client.last_name}, ${client.first_name}`
                : "Clients",
            },
            { label: "Contracts" },
          ]}
        />
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <ReactTable
            className="-highlight"
            filterable
            data={contracts}
            LoadingComponent={() => (
              <Loading active={!Array.isArray(contracts)} />
            )}
            columns={columns}
            {...props}
            defaultPageSize={10}
            defaultSorted={[
              {
                id: "number",
                asc: true,
              },
            ]}
          />
        </Col>
      </Row>
      <Modal
        id="contractDetailsModal"
        title="Export Contract"
        body={contractExportForm}
        modal={modal}
        close={actions.hideModal}
      />
      <ConfirmModal
        id="contractDeleteModal"
        title="Delete Contract"
        body="Are you sure you want to delete this contract?"
        modal={modal}
        close={actions.hideModal}
        confirm={handleDelete}
      />
    </div>
  );
};

ClientContractsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  match: PropTypes.object,
  modal: PropTypes.object,
  client: PropTypes.object,
  clientContracts: PropTypes.array,
  codes: PropTypes.array,
  contractToDelete: PropTypes.string,
  history: PropTypes.object,
};

const mapStatesToProps = (state) => ({
  modal: state.reducers.modal,
  clientContracts: state.reducers.clientContracts,
  client: state.reducers.client,
  codes: state.reducers.codes,
  contractToDelete: state.reducers.contractToDelete,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...clientActions,
      ...contractActions,
      ...modalActions,
    },
    dispatch
  ),
});

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ClientContractsPage);
