import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Prompt } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import BreadCrumbs from "../common/BreadCrumbs";
import ClientForm from "./ClientForm";
import * as clientActions from "../../actions/clientActions";
import * as contactActions from "../../actions/contactActions";
import * as zipActions from "../../actions/zipActions";
import EditButton from "../common/EditButton";
import Loading from "../common/Loading";

const INITIAL_STATE = {
  status: "false",
  first_name: "",
  last_name: "",
  birth_date: "",
  est_birth_date: "",
  ethnicity: null,
  gender: null,
  email: "",
  primary_phone: "",
  primary_phone_type: null,
  secondary_phone: "",
  secondary_phone_type: null,
  organization: "",
  title: "",
  lead_contacts: [],
  street_address: "",
  street_address2: "",
  city: "",
  county: "",
  state: "",
  zip: "",
  veteran_status: null,
  episodes: [],
  mrc_activity: null,
  mrc_at_activity: null,
  emergency_contact_name: "",
  emergency_contact_relationship: "",
  emergency_contact_phone: "",
  emergency_contact_email: "",
  tap_activity: null,
  shirt_size: null,
  preferred_contact_method: null,
  disability: [],
  referred_by: null,
  contact_type: [],
  military_affiliation: [],
  service_branch: [],
  discharge_nature: [],
  disability_rating: null,
  disability_rating_text: "",
  program: [],
  household_size: null,
  monthly_income: null,
  housing_status: null,
  housing_status_text: "",
  current_benefits: [],
  accommodation_needs: "",
  optional_referred_by: "",
  reference_number: null,
};

const ClientEditPage = ({
  auth,
  actions,
  codes,
  match,
  client,
  history,
  clients,
  contacts,
  zips,
}) => {
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [editing, setEditing] = useState(false);
  const [charged, setCharged] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [changedInputs, setChangedInputs] = useState([]);

  const handleChange = (name, value, entity = null) => {
    if (entity) {
      setChangedInputs((prev) =>
        prev.includes(`${entity}.${name}`)
          ? prev
          : [...prev, `${entity}.${name}`]
      );
      setInputs((prev) => ({
        ...prev,
        [entity]: {
          ...prev[entity],
          [name]: value,
        },
      }));
    } else {
      setChangedInputs((prev) =>
        prev.includes(name) ? prev : [...prev, name]
      );
      setInputs((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    setHasChanges(true);
  };

  const handleReferredByChange = (name, value, foundObject) => {
    if (!foundObject) {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
        optional_referred_by: value,
      }));
    } else {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
        optional_referred_by: "",
      }));
    }
    setHasChanges(true);
  };

  const handleMultipleChange = (newValues) => {
    setChangedInputs((prev) => {
      const changed = Object.keys(newValues)
        .map((key) => (!prev.includes(key) ? key : null))
        .filter((n) => n);
      return [...prev, ...changed];
    });
    setInputs((prev) => ({
      ...prev,
      ...newValues,
    }));
  };

  const handleSave = async () => {
    setHasChanges(false);
    const model = { ...inputs };
    delete model.notes;
    await actions.updateClient(
      {
        ...model,
        _id: inputs._id,
        primary_phone: model.primary_phone.replace(/\s/g, ""),
        secondary_phone: model.secondary_phone.replace(/\s/g, ""),
        lead_contacts:
          Array.isArray(model.lead_contacts) && model.lead_contacts.length > 0
            ? model.lead_contacts.map(({ value }) => value)
            : [],
        current_benefits:
          Array.isArray(model.current_benefits) &&
          model.current_benefits.length > 0
            ? model.current_benefits.map(({ value }) => value)
            : [],
        program:
          Array.isArray(model.program) && model.program.length > 0
            ? model.program.map(({ value }) => value)
            : [],
        discharge_nature:
          Array.isArray(model.discharge_nature) &&
          model.discharge_nature.length > 0
            ? model.discharge_nature.map(({ value }) => value)
            : [],
        service_branch:
          Array.isArray(model.service_branch) && model.service_branch.length > 0
            ? model.service_branch.map(({ value }) => value)
            : [],
        military_affiliation:
          Array.isArray(model.military_affiliation) &&
          model.military_affiliation.length > 0
            ? model.military_affiliation.map(({ value }) => value)
            : [],
        contact_type:
          Array.isArray(model.contact_type) && model.contact_type.length > 0
            ? model.contact_type.map(({ value }) => value)
            : [],
        disability:
          Array.isArray(model.disability) && model.disability.length > 0
            ? model.disability.map(({ value }) => value)
            : [],
        status: model.status === "true",
        emergency_contact: {
          name: model.emergency_contact_name,
          relationship: model.emergency_contact_relationship,
          phone: model.emergency_contact_phone,
          email: model.emergency_contact_email,
        },
        referred_by: inputs.optional_referred_by ? null : model.referred_by,
        optional_referred_by: inputs.optional_referred_by || "",
        disability_rating: inputs.disability_rating?.value || null,
        housing_status: inputs.housing_status?.value || null,
      },
      changedInputs
    );
    await actions.getClient(match.params.id);
    await actions.loadClients();
  };

  const handleEdit = async () => {
    setEditing((prev) => !prev);
  };

  useEffect(() => {
    if (match && match.params && match.params.id && !charged) {
      setCharged(true);
      actions.getClient(match.params.id);
    }
  }, [match, client]);

  useEffect(() => {
    if (client) {
      let { disability_rating, housing_status } = client;
      if (disability_rating) {
        disability_rating = codes.find(
          (code) => code._id === disability_rating
        );
      }
      if (housing_status) {
        housing_status = codes.find((code) => code._id === housing_status);
      }
      setInputs(() => ({
        ...INITIAL_STATE,
        ...client,
        lead_contacts:
          client.lead_contacts && client.lead_contacts.length
            ? client.lead_contacts.map((c) => ({ value: c }))
            : [],
        program:
          client.program && client.program.length
            ? client.program.map((c) => ({ value: c }))
            : [],
        discharge_nature:
          client.discharge_nature && client.discharge_nature.length
            ? client.discharge_nature.map((c) => ({ value: c }))
            : [],
        service_branch:
          client.service_branch && client.service_branch.length
            ? client.service_branch.map((c) => ({ value: c }))
            : [],
        military_affiliation:
          client.military_affiliation && client.military_affiliation.length
            ? client.military_affiliation.map((c) => ({ value: c }))
            : [],
        contact_type:
          client.contact_type && client.contact_type.length
            ? client.contact_type.map((c) => ({ value: c }))
            : [],
        status: client.status.toString(),
        disability:
          client.disability && client.disability.length
            ? client.disability.map((c) => ({ value: c }))
            : [],
        current_benefits:
          client.current_benefits && client.current_benefits.length
            ? client.current_benefits.map((c) => ({ value: c }))
            : [],
        emergency_contact_name: client.emergency_contact?.name,
        emergency_contact_relationship: client.emergency_contact?.relationship,
        emergency_contact_phone: client.emergency_contact?.phone,
        emergency_contact_email: client.emergency_contact?.email,
        disability_rating: disability_rating
          ? { value: disability_rating._id, label: disability_rating.label }
          : null,
        housing_status: housing_status
          ? { value: housing_status._id, label: housing_status.label }
          : null,
      }));
    }
  }, [client]);

  useEffect(() => {
    if (!clients || (clients && !clients.length)) {
      actions.loadClients();
    }
  }, [clients]);

  useEffect(() => {
    if (!contacts || (contacts && !contacts.length)) {
      actions.loadContacts();
    }
  }, [contacts]);

  useEffect(() => {
    if (!zips || (zips && !zips.length)) {
      actions.loadZips();
    }
  }, [zips]);

  if (!inputs._id) return <Loading active />;

  return (
    <div className="content-wrapper">
      <Prompt
        when={hasChanges}
        message={() =>
          `Are you sure you want to leave this page? Changes you made will not be saved`
        }
      />
      <Row>
        <BreadCrumbs
          breadcrumbs={[
            { label: "Clients" },
            {
              label: client ? `${client.last_name}, ${client.first_name}` : "",
            },
          ]}
        />
        <EditButton onClickEdit={handleEdit} />
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <ClientForm
            adding={false}
            client={client}
            clients={clients}
            codes={codes}
            editing={editing}
            inputs={inputs}
            contacts={contacts}
            zips={zips}
            onCancel={() => history.push("/app/clients")}
            onChange={handleChange}
            onMultipleChange={handleMultipleChange}
            onSubmit={handleSave}
            onReferredByChange={handleReferredByChange}
          />
        </Col>
      </Row>
    </div>
  );
};

ClientEditPage.propTypes = {
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  codes: PropTypes.array,
  match: PropTypes.object,
  client: PropTypes.object,
  clients: PropTypes.array,
  history: PropTypes.object.isRequired,
  contacts: PropTypes.array,
  zips: PropTypes.array,
};

const mapStatesToProps = (state) => ({
  codes: state.reducers.codes,
  client: state.reducers.client,
  clients: state.reducers.clients,
  contacts: state.reducers.contacts,
  zips: state.reducers.zips.zips,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { ...clientActions, ...contactActions, ...zipActions },
    dispatch
  ),
});

export default connect(mapStatesToProps, mapDispatchToProps)(ClientEditPage);
