import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const PrivateRoute = ({
  component: Component,
  auth,
  isMobile,
  handleLogout,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      auth.isAuth ? (
        <Component
          {...props}
          auth={auth}
          isMobile={isMobile}
          handleLogout={handleLogout}
        />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

function mapStatesToProps(state) {
  return {
    state: state,
  };
}

export default withRouter(connect(mapStatesToProps)(PrivateRoute));
