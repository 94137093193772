import moment from "moment";

const validation = (contract, startTime) => ({
  invalidContractStartDate: {
    validator: (value) =>
      !contract ||
      (value &&
        moment(startTime).isSameOrAfter(
          moment(contract?.start_date).startOf("day")
        ) &&
        moment(startTime).isBefore(
          moment(contract?.end_date).startOf("day").add(24, "hours")
        ))
        ? true
        : false,
    helpText: "Date should be valid for the selected contract",
  },
});

export default validation;
