import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as contractActions from "../../../actions/contractActions";
import * as callActions from "../../../actions/callActions";
import * as modalActions from "../../../actions/modalActions";
import * as clientActions from "../../../actions/clientActions";
import ContractService from "../../../services/contractService";

import AddButton from "../../common/AddButton";
import BreadCrumbs from "../../common/BreadCrumbs";
import Modal from "../../common/Modal";
import ConfirmModal from "../../common/ConfirmModal";
import ContractClientForm from "./ContractClientForm";
import ContractClientList from "./ContractClientList";
import { Row, Col } from "react-bootstrap";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";

class ContractClientPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      client: {},
      editing: true,
      contractClients: [],
      loading: false,
    };
  }

  componentDidMount = () => {
    let { contract, actions } = this.props;
    if (isEmpty(contract)) actions.getContract(this.props.match.params.id);

    this.getAllContractClients();
  };

  getAllContractClients = async () => {
    try {
      this.setState({ loading: true });
      const result = await ContractService.findAllClientsByContract(
        this.props.match.params.id
      );
      this.setState((prev) => ({
        ...prev,
        contractClients: result,
        loading: false,
      }));
    } catch (err) {
      console.log(err.message);
    }
  };

  onClickEdit = (clientId) => {
    let client = find(this.state.contractClients, {
      _id: clientId,
    });
    this.setState({ client, editing: true });
    client.name
      ? this.props.actions.getContract(this.props.contract._id, true)
      : this.props.history.push(`/app/clients/${clientId}`);
  };

  onClickDelete = (clientId) => {
    this.props.actions.requestClientId(clientId);
  };

  handleSave = async (client) => {
    await this.props.actions.updateContractClient(this.props.contract, client);
    await this.props.actions.getContract(this.props.match.params.id);
    const getAllContractClients = async () => {
      try {
        const result = await ContractService.findAllClientsByContract(
          this.props.match.params.id
        );
        this.setState((prev) => ({ ...prev, contractClients: result }));
      } catch (err) {
        console.log(err.message);
      }
    };
    await getAllContractClients();
    await this.props.actions.hideModal();
  };

  handleDelete = async () => {
    await this.props.actions.deleteContractClient(
      this.props.contract,
      this.props.clientToDelete
    );
    await this.props.actions.getContract(this.props.match.params.id);
  };

  handleCancel = () => {
    this.props.actions.hideModal();
  };

  render() {
    let { contract, codes, auth } = this.props;

    let clientForm = (
      <ContractClientForm
        client={this.state.client}
        editing={this.state.editing && auth.maxRole >= 6000}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        fullClients={this.state.contractClients}
      />
    );

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: `${contract.name}` }, { label: "Clients" }]}
          />
        </Row>
        <Row>
          <Col md={12}>
            <ContractClientList
              contract={contract}
              codes={codes}
              onClickEdit={this.onClickEdit}
              onClickDelete={this.onClickDelete}
              contractClients={this.state.contractClients}
              loading={this.state.loading}
            />
          </Col>
        </Row>
        <Modal
          id="contractDetailsModal"
          title="Edit Client"
          body={clientForm}
          modal={this.props.modal}
          close={this.props.actions.hideModal}
        />
        <ConfirmModal
          id="contractDeleteModal"
          title="Delete Client"
          body="Are you sure you want to delete this client?"
          modal={this.props.modal}
          close={this.props.actions.hideModal}
          confirm={this.handleDelete}
        />
      </div>
    );
  }
}

ContractClientPage.propTypes = {
  actions: PropTypes.object.isRequired,
  contract: PropTypes.object,
  clientToDelete: PropTypes.string,
  calls: PropTypes.array,
  codes: PropTypes.array,
  client: PropTypes.object,
  modal: PropTypes.object,
  params: PropTypes.object,
  clients: PropTypes.array,
  history: PropTypes.object.isRequired,
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    contract: state.reducers.contract,
    calls: state.reducers.calls,
    codes: state.reducers.codes,
    clientToDelete: state.reducers.clientToDelete,
    clients: state.reducers.clients,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...contractActions,
        ...callActions,
        ...modalActions,
        ...clientActions,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ContractClientPage);
