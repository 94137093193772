import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Col } from "react-bootstrap";
import moment from "moment";

import DropdownSearch from "../../../../common/DropdownSearchWithoutFormsy";
import generateUserOptions from "../../utils/generateUserOptions";
import FieldGroup from "../../../../common/FieldGroup";

export default function CancelledStageForm({
  editing,
  inputs,
  onChange,
  users,
}) {
  const approvedByOptions = useMemo(() => generateUserOptions(users), [users]);
  return (
    <React.Fragment>
      <Col md={4} xs={12}>
        <DropdownSearch
          name="cancel_by"
          label="Cancelled By"
          value={inputs.cancel_by}
          onChange={(name, { value }) => onChange(name, value)}
          disabled={!editing}
          data={approvedByOptions}
        />
      </Col>
      <Col md={4} xs={12}>
        <FieldGroup
          formNoValidate
          type="date"
          name="cancel_date"
          label="Cancel Date"
          value={moment.utc(inputs.cancel_date).format("YYYY-MM-DD") || ""}
          disabled={!editing}
          onChange={onChange}
        />
      </Col>
      <Col md={4} xs={12}>
        <FieldGroup
          type="text"
          name="cancel_reason"
          label="Cancel Reason"
          value={inputs.cancel_reason || ""}
          disabled={!editing}
          onChange={onChange}
        />
      </Col>
    </React.Fragment>
  );
}

CancelledStageForm.propTypes = {
  editing: PropTypes.bool,
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  users: PropTypes.array,
};

CancelledStageForm.defaultProps = {
  inputs: {},
  state: {},
  users: [],
};
