import { Component } from "react";
import PropTypes from "prop-types";

import InvoiceHeader from "./InvoiceHeader";
import { Row, Col, Button } from "react-bootstrap";

import moment from "moment";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import find from "lodash/find";
import snakeCase from "lodash/snakeCase";

import { getHours, getMinutes } from "../../../../helpers";

class InvoicePreview extends Component {
  constructor(props) {
    super(props);
    this.state = { invoiceIndex: 0 };
  }

  handleClickNext = () => {
    this.setState((prevState) => ({
      invoiceIndex: (prevState.invoiceIndex + 1) % this.props.invoices.length,
    }));
  };

  render() {
    const { invoices, codes, adding } = this.props;
    const { invoiceIndex } = this.state;

    const invoice = invoices[invoiceIndex];

    let invoiceRows = [];
    let combinedRows = []; // Senior Service only
    let groupedRows;
    let columns;
    let columnHeader, rows, footer;

    const formatComma = new Intl.NumberFormat("en-US", {});
    const formatCurrency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    // Invoice By Procedure && Location
    if (invoice.format.label !== "Senior Service") {
      let grandTotal = 0;

      invoice.invoiceLines.map((line) => {
        let invoiceLine = {};

        invoiceLine.dateOfService = moment(line.service_date);
        invoiceLine.location = line.client_name
          ? `${line.location} - ${line.client_name}`
          : line.location;
        invoiceLine.staff = line.staff.name;
        invoiceLine.procedure = line.procedure_description;
        // Units depends on a procedure's UOM
        invoiceLine.units = line.billing_quantity.toFixed(2);
        invoiceLine.charge = (line.billing_quantity * line.price).toFixed(2);

        invoiceRows.push(invoiceLine);
      });

      switch (invoice.format.label) {
        case "Invoice By Procedure":
          groupedRows = groupBy(
            orderBy(invoiceRows, ["dateOfService", "procedure"]),
            "procedure"
          );
          break;
        case "Invoice Total":
          groupedRows = groupBy(
            orderBy(invoiceRows, ["dateOfService", "procedure"]),
            "location"
          );
          break;
        case "One Invoice for Multiple Locations":
        case "Separate Invoice per Location":
          groupedRows = groupBy(invoiceRows, "location");
          break;
      }

      console.log("Grouped Rows", groupedRows);

      // COLUMN HEADER
      columnHeader = (
        <tbody>
          <tr>
            <th>Date of Service</th>
            <th>Location</th>
            <th>Staff</th>
            <th>Procedure</th>
            <th>Units</th>
            <th>Charge</th>
          </tr>
        </tbody>
      );

      // ROW
      rows = Object.keys(groupedRows)
        .sort() // sort by key
        .map((row, idx) => {
          let procedureTotal = groupedRows[row].reduce(
            (total, call) => total + parseFloat(call.charge),
            0
          );
          grandTotal += procedureTotal;

          return (
            <tbody key={idx}>
              {orderBy(groupedRows[row], "dateOfService").map((call, i) => {
                call.charge = formatCurrency.format(call.charge);
                call.dateOfService = moment(call.dateOfService).format(
                  "MM/DD/YYYY"
                );
                return (
                  <tr key={i}>
                    {Object.keys(call).map((data, i) => (
                      <td key={i}>{call[data]}</td>
                    ))}
                  </tr>
                );
              })}

              {/* If multiple line items and multiple groupings, display subtotal */}
              {Object.keys(groupedRows).length > 1 &&
                groupedRows[row].length > 1 && (
                  <tr className="subtotal-row">
                    <td className="invoice-label">{row} Total:</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>{formatCurrency.format(procedureTotal)}</td>
                  </tr>
                )}
            </tbody>
          );
        });

      /* TOTAL FOOTER */
      footer = (
        <tbody>
          <tr className="total">
            <td />
            <td />
            <td />
            <td />
            <td>
              <strong>Grand Total:</strong>
            </td>
            <td>{formatCurrency.format(grandTotal)}</td>
          </tr>
        </tbody>
      );
    } else if (invoice.format.label == "Senior Service") {
      // Senior Service Template
      invoice.invoiceLines.map((line) => {
        let product_category = find(codes, { _id: line.product_category });

        let invoiceItem = {};
        invoiceItem.dateOfService = line.service_date;
        invoiceItem.rate = line.price.toFixed(2);
        invoiceItem.type = line.procedure_type;
        invoiceItem[snakeCase(line.procedure_type)] = line.billing_quantity;
        invoiceItem.product_category = product_category
          ? product_category.label
          : "";
        invoiceRows.push(invoiceItem);
      });

      // Group invoice items by product category and date
      groupedRows = groupBy(invoiceRows, (assignment) => {
        let date = assignment.dateOfService;
        let endOfWeek = moment(date).endOf("week");
        return `${assignment.product_category} (${endOfWeek})`;
      });

      console.log("Grouped Rows By Product Category", groupedRows);

      // Group procedure types [Direct, Indirect, Program Management] into one object
      Object.values(groupedRows).map((assignments) => {
        let prodCatGroup = { ...assignments[0], rate: {} };
        prodCatGroup.direct = assignments
          .filter((assignment) => assignment.direct)
          .reduce((total, assignment) => total + assignment.direct, 0);
        prodCatGroup.indirect = assignments
          .filter((assignment) => assignment.indirect)
          .reduce((total, assignment) => total + assignment.indirect, 0);
        prodCatGroup.program_management = assignments
          .filter((assignment) => assignment.program_management)
          .reduce(
            (total, assignment) => total + assignment.program_management,
            0
          );

        // Cleaning up
        delete prodCatGroup.type;

        // Ensures each procedure type gets assigned its individual rates
        assignments
          .filter((assignment) => assignment.rate)
          .map((assignment) => {
            if (assignment.type == "program_management") {
              console.log("assignment", assignment);
            }
            prodCatGroup.rate[assignment.type] = assignment.rate;
          });

        combinedRows.push(prodCatGroup);
      });

      groupedRows = groupBy(
        combinedRows,
        (assignment) => assignment.product_category
      );

      columns = groupBy(combinedRows, (row) =>
        moment(row.dateOfService).endOf("week").format("YYYY-MM-DD")
      );

      let minCol = Object.keys(columns).length;
      let extendedTotal = 0;
      let totalHours = 0;
      let totalMinutes = 0;

      // COLUMN HEADER - SS
      columnHeader = (
        <tbody>
          <tr>
            <th />
            <th />
            {orderBy(Object.keys(columns), (key) => key).map((date, i) => (
              <th key={i}>{moment(date).format("DD-MMM")}</th>
            ))}
            <th>Total Hours</th>
            <th>Total Minutes</th>
            <th>Rate</th>
            <th>Extended Total</th>
          </tr>
        </tbody>
      );

      // ROW - SS
      rows = Object.keys(groupedRows)
        .sort()
        .map((procedure, idx) => {
          // FOR EACH PRODUCT CATEGORY
          console.log(`GroupedRows[${procedure}]`, groupedRows[procedure]);

          if (groupedRows[procedure].length < minCol) {
            // if no calls on particular date, insert empty object
            Object.keys(columns).map((date, i) => {
              if (
                !groupedRows[procedure].find(
                  (procedure) =>
                    moment(procedure.dateOfService)
                      .endOf("week")
                      .format("YYYY-MM-DD") == date
                )
              ) {
                // Insert new cell
                groupedRows[procedure].splice(i, 0, { dateOfService: date });
              }
            });
          }

          let subTotalHours = 0;
          let subTotalMinutes = 0;

          let directTime = groupedRows[procedure].reduce(
            (total, call) =>
              call.direct ? total + parseFloat(call.direct) : total,
            0
          );
          let indirectTime = groupedRows[procedure].reduce(
            (total, call) =>
              call.indirect ? total + parseFloat(call.indirect) : total,
            0
          );
          let pmTime = groupedRows[procedure].reduce(
            (total, call) =>
              call.program_management
                ? total + parseFloat(call.program_management)
                : total,
            0
          );

          let directHours = getHours(directTime);
          let directMinutes = getMinutes(directTime);
          let indirectHours = getHours(indirectTime);
          let indirectMinutes = getMinutes(indirectTime);
          let pmHours = getHours(pmTime);
          let pmMinutes = getMinutes(pmTime);

          let rate = (type) => {
            let procedureType = groupedRows[procedure].find(
              (prod) => prod.rate && prod.rate[type]
            );

            return procedureType ? procedureType.rate[type] : "0.00";
          };
          let extendedTotalDirect = directTime * rate("Direct");
          let extendedTotalIndirect = indirectTime * rate("Indirect");
          let extendedTotalPM = pmTime * rate("Program Management");

          extendedTotal +=
            extendedTotalDirect + extendedTotalIndirect + extendedTotalPM;
          subTotalHours = directHours + indirectHours + pmHours;
          subTotalMinutes = directMinutes + indirectMinutes + pmMinutes;

          if (subTotalMinutes >= 60) {
            subTotalMinutes = subTotalMinutes - 60;
            subTotalHours = subTotalHours + 1;
          }

          totalHours += subTotalHours;
          totalMinutes += subTotalMinutes;

          if (totalMinutes >= 60) {
            totalMinutes = totalMinutes - 60;
            totalHours = totalHours + 1;
          }

          return (
            <tbody key={idx}>
              <tr className="procedure-name">
                <td>
                  <p>{procedure}</p>
                </td>
              </tr>
              <tr>
                <td>Direct</td>
                <td />
                {orderBy(groupedRows[procedure], "dateOfService").map(
                  (assignment, i) =>
                    assignment.direct ? (
                      <td key={i}>
                        {formatComma.format(parseFloat(assignment.direct))}
                      </td>
                    ) : (
                      <td key={i}>0</td>
                    )
                )}
                <td>{formatComma.format(directHours)}</td>
                <td>{formatComma.format(directMinutes)}</td>
                <td>$ {rate("Direct")}</td>
                <td>{formatCurrency.format(extendedTotalDirect)}</td>
              </tr>
              <tr>
                <td>Indirect</td>
                <td />
                {orderBy(groupedRows[procedure], "dateOfService").map(
                  (assignment, i) =>
                    assignment.indirect ? (
                      <td key={i}>
                        {formatComma.format(parseFloat(assignment.indirect))}
                      </td>
                    ) : (
                      <td key={i}>0</td>
                    )
                )}
                <td>{formatComma.format(indirectHours)}</td>
                <td>{formatComma.format(indirectMinutes)}</td>
                <td>$ {rate("Indirect")}</td>
                <td>{formatCurrency.format(extendedTotalIndirect)}</td>
              </tr>
              {find(
                groupedRows[procedure],
                (assignment) =>
                  assignment.program_management &&
                  assignment.program_management !== 0
              ) && (
                <tr>
                  <td>Program Management</td>
                  <td />
                  {orderBy(groupedRows[procedure], "dateOfService").map(
                    (assignment, i) => {
                      return assignment.program_management ? (
                        <td key={i}>
                          {formatComma.format(
                            parseFloat(assignment.program_management)
                          )}
                        </td>
                      ) : (
                        <td>0</td>
                      );
                    }
                  )}
                  <td>{formatComma.format(pmHours)}</td>
                  <td>{formatComma.format(pmMinutes)}</td>
                  <td>$ {rate("Program Management")}</td>
                  <td>{formatCurrency.format(extendedTotalPM)}</td>
                </tr>
              )}
              {/* If more than one service, display subtotal */}
              {Object.keys(groupedRows).length > 1 && (
                <tr className="subtotal-row">
                  <td className="invoice-label">
                    {/* {procedure} */}
                    Subtotal:
                  </td>
                  <td />
                  {orderBy(groupedRows[procedure], "dateOfService").map(
                    (assignment, i) => (
                      <td key={i}>
                        {formatComma.format(
                          parseFloat(
                            (assignment.direct || 0) +
                              (assignment.indirect || 0) +
                              (assignment.program_management || 0)
                          )
                        )}
                      </td>
                    )
                  )}
                  <td>{formatComma.format(subTotalHours)}</td>
                  <td>{formatComma.format(subTotalMinutes)}</td>
                  <td />
                  <td>
                    {formatCurrency.format(
                      extendedTotalDirect +
                        extendedTotalIndirect +
                        extendedTotalPM
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          );
        });

      footer = (
        <tbody>
          <tr className="total">
            <td>Total</td>
            <td />
            {orderBy(Object.keys(columns), (key) => key).map(
              (assignment, i) => (
                <td key={i}>
                  {formatComma.format(
                    columns[assignment].reduce(
                      (total, entry) =>
                        total +
                        (parseFloat(entry.direct) +
                          parseFloat(entry.indirect) +
                          parseFloat(entry.program_management)),
                      0
                    )
                  )}
                </td>
              )
            )}
            <td>{formatComma.format(totalHours)}</td>
            <td>{formatComma.format(totalMinutes)}</td>
            <td />
            <td>{formatCurrency.format(extendedTotal)}</td>
          </tr>
        </tbody>
      );
    }

    let additionalCells = () => {
      let cells = [];
      let col = Object.keys(columns).length;
      if (col >= 1) {
        for (let i = 0; i < col; i++) {
          cells.push(<td />);
        }
      }
      return cells;
    };

    return (
      <div className="invoice-preview">
        <Row>
          <Col md={12}>
            <InvoiceHeader
              invoice={invoice}
              invoiceNumber={invoice.number}
              additionalCells={additionalCells}
            />
            <table id="invoice">
              <tbody>
                <tr />
              </tbody>
              {columnHeader}
              {rows}
              {footer}
            </table>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {adding && (
              <Row className="pull-right" style={{ display: "flex" }}>
                <Button
                  className="btn-success btn-sm"
                  type="button"
                  onClick={this.props.onClickConfirm}
                  disabled={this.props.loading}
                  style={{ margin: "0 .5em" }}
                >
                  CONFIRM
                </Button>
                {invoices.length > 1 && (
                  <Button
                    className="btn-default btn-sm"
                    type="button"
                    onClick={this.handleClickNext}
                  >
                    NEXT
                  </Button>
                )}
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

InvoicePreview.propTypes = {
  invoices: PropTypes.array,
  adding: PropTypes.bool,
  codes: PropTypes.array,
  users: PropTypes.array,
  loading: PropTypes.bool,
  onClickConfirm: PropTypes.func,
};

export default InvoicePreview;
