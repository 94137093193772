import React, { Component } from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";
import DropdownSearch from "../common/DropdownSearch";

import { Input, RadioGroup, CheckboxGroup } from "formsy-react-components";
import { Row, Col } from "react-bootstrap";

import moment from "moment";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";

class UserForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
    };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  cancel = () => {
    this.props.onCancel();
  };

  submit = (model) => {
    model.full_name = `${model.last_name}, ${model.first_name}`;
    this.props.onSave(model);
  };

  generateDepartmentOptions = () => {
    const { user, divisions } = this.props;

    let departmentOptions = [];
    let parentDivision = divisions.find(
      (division) => division._id == (this.state.division || user.division)
    );

    if (parentDivision) {
      departmentOptions = parentDivision.departments.map((department) => ({
        value: department._id,
        label: department.description,
      }));
    }

    return orderBy(departmentOptions, "label");
  };

  generateReportsToOptions = () => {
    const reportsToOptions = this.props.users
      .filter((user) => user.payroll_id)
      .map((user) => ({
        value: user.payroll_id,
        label: user.full_name || `${user.last_name}, ${user.first_name}`,
      }));

    return orderBy(reportsToOptions, "label");
  };

  generateCodeOptions = (entity, field) => {
    const codeOptions = this.props.codes
      .filter((codes) => codes.entity == entity && codes.field == field)
      .map((code) => ({
        value: code._id,
        label: code.label,
      }));

    return orderBy(codeOptions, "label");
  };

  onChange = (field, selected) => {
    this.setState({ [field]: selected.value });
  };

  render() {
    let { user, users, auth, adding, editing } = this.props;

    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <fieldset className="roles">
            <Col md={12} xs={12}>
              <legend>User Roles</legend>
            </Col>
            <CheckboxGroup
              name="roles"
              disabled={!editing}
              value={user.roles}
              options={orderBy(this.props.roles, "number", "desc").map(
                (role) => ({
                  value: role.id,
                  label: role.name,
                })
              )}
            />
          </fieldset>
        </Row>
        <Row>
          <fieldset>
            <Col md={12} xs={12}>
              <legend>Employee Info</legend>
            </Col>
            <Col md={5} xs={12}>
              <Input
                required
                name="first_name"
                label="First Name"
                disabled={!editing}
                value={user.first_name}
              />
            </Col>
            <Col md={2} xs={12}>
              <Input
                name="middle_initial"
                label="Middle Initial"
                disabled={!editing}
                value={user.middle_initial}
              />
            </Col>
            <Col md={5} xs={12}>
              <Input
                required
                name="last_name"
                label="Last Name"
                disabled={!editing}
                value={user.last_name}
              />
            </Col>
            <Col md={adding ? 6 : 4} xs={12}>
              <Input
                required
                name="email"
                label="Email"
                value={user.email}
                disabled={!editing}
                validations={{
                  isEmail: true,
                  isExists: (values, value) => {
                    let foundUser = find(users, { email: value });
                    let alreadyExists = foundUser && foundUser._id !== user._id;
                    return !alreadyExists;
                  },
                }}
                validationErrors={{
                  isEmail: "This is not a valid email",
                  isExists: "There is already a user with this email.",
                }}
              />
            </Col>
            {adding && (
              <Col md={6} xs={12}>
                <Input
                  required
                  name="password"
                  type="password"
                  label="Password"
                  value={user.password}
                  disabled={!editing}
                />
              </Col>
            )}
            <Col md={adding ? 6 : 4} xs={12}>
              <DropdownSearch
                required
                name="division"
                label="Division"
                onChange={this.onChange}
                value={this.state.division || user.division}
                disabled={!editing}
                data={orderBy(
                  [
                    ...this.props.divisions.map((division) => ({
                      value: division._id,
                      label: division.description,
                    })),
                  ],
                  "label"
                )}
              />
            </Col>
            <Col md={adding ? 6 : 4} xs={12}>
              <DropdownSearch
                required
                name="department"
                label="Department"
                onChange={this.onChange}
                value={this.state.department || user.department}
                disabled={!editing}
                data={[...this.generateDepartmentOptions()]}
              />
            </Col>
            <Col md={6} xs={12}>
              <DropdownSearch
                name="reports_to"
                label="Reports To"
                value={this.state.reports_to || user.reports_to}
                onChange={this.onChange}
                disabled={!editing}
                data={[...this.generateReportsToOptions()]}
              />
            </Col>
            <Col md={6} xs={12}>
              <Input
                required
                name="job_title"
                label="Title"
                value={user.job_title}
                disabled={!editing}
              />
            </Col>
          </fieldset>
        </Row>
        <Row>
          <fieldset>
            <Col md={12} xs={12}>
              <legend>HR Info</legend>
            </Col>
            <Col md={3} xs={12}>
              <Input
                required
                name="payroll_id"
                label="Payroll ID"
                value={user.payroll_id}
                disabled={!editing}
              />
            </Col>
            <Col md={3} xs={12}>
              <Input
                name="associateOID"
                label="Associate ID"
                style={{ backgroundColor: "rgba(255, 210, 117, 0.3)" }}
                value={user.associateOID}
                disabled={!editing}
              />
            </Col>
            <Col md={3} xs={12}>
              <Input
                type="date"
                name="hire_date"
                label="Hire Date"
                style={{ backgroundColor: "rgba(255, 210, 117, 0.3)" }}
                value={
                  user.hire_date
                    ? moment.utc(user.hire_date).format("YYYY-MM-DD")
                    : ""
                }
                disabled={!editing || "null" != this.props.user.aoid}
              />
            </Col>
            <Col md={3} xs={12}>
              <Input
                type="date"
                name="termination_date"
                label="Termination Date"
                style={{ backgroundColor: "rgba(255, 210, 117, 0.3)" }}
                value={
                  user.termination_date
                    ? moment.utc(user.termination_date).format("YYYY-MM-DD")
                    : ""
                }
                disabled={!editing || "null" != this.props.user.aoid}
              />
            </Col>
            <Col md={5} xs={12}>
              <DropdownSearch
                required
                name="gl_expense"
                label="GL Expense Code"
                onChange={this.onChange}
                value={this.state.gl_expense || user.gl_expense}
                disabled={!editing}
                data={orderBy(
                  [
                    ...this.props.glaccounts
                      .filter((glaccount) => glaccount.type.value == "E")
                      .map((glaccount) => ({
                        value: glaccount._id,
                        label: `${glaccount.code} - ${glaccount.description}`,
                      })),
                  ],
                  "label"
                )}
              />
            </Col>
            <Col md={4} xs={12}>
              <DropdownSearch
                name="class"
                label="Class"
                onChange={this.onChange}
                value={this.state.class || user.class}
                disabled={!editing}
                data={[...this.generateCodeOptions("User", "class")]}
              />
            </Col>
            <Col md={3} xs={12}>
              <RadioGroup
                name="flsa_code"
                type="inline"
                label="FLSA"
                disabled={!editing}
                value={user.flsa_code}
                options={[
                  { value: "E", label: "Exempt" },
                  { value: "N", label: "Non-Exempt" },
                ]}
              />
            </Col>
            {(adding || auth.maxRole == 9000) && (
              <Col md={4} xs={12}>
                <Input
                  required
                  step={0.01}
                  type="number"
                  name="reg_pay_rate"
                  label="Pay Rate"
                  value={user.reg_pay_rate}
                  disabled={!editing}
                />
              </Col>
            )}
            <Col md={adding || auth.maxRole == 9000 ? 4 : 6} xs={12}>
              <Input
                type="number"
                name="scheduled_hours"
                label="Scheduled Hours"
                value={user.scheduled_hours || null}
                disabled={!editing}
              />
            </Col>
            <Col md={adding || auth.maxRole == 9000 ? 4 : 6} xs={12}>
              <Input
                required
                type="date"
                name="reg_pay_rate_effective_date"
                label="Pay Rate Effective Date"
                value={
                  user.reg_pay_rate_effective_date
                    ? moment
                        .utc(user.reg_pay_rate_effective_date)
                        .format("YYYY-MM-DD")
                    : ""
                }
                disabled={!editing}
              />
            </Col>
          </fieldset>
        </Row>
        <Row>
          <fieldset>
            <Col md={12} xs={12}>
              <legend>License Info</legend>
            </Col>
            <Col md={4} xs={12}>
              <Input
                type="date"
                name="tbtest_exp_date"
                label="TB Expiration Date"
                style={{ backgroundColor: "rgba(255, 210, 117, 0.3)" }}
                value={
                  user.tbtest_exp_date
                    ? moment.utc(user.tbtest_exp_date).format("YYYY-MM-DD")
                    : ""
                }
                disabled={!editing || "null" != user.aoid}
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                type="date"
                name="cori_exp_date"
                label="CORI Expiration Date"
                style={{ backgroundColor: "rgba(255, 210, 117, 0.3)" }}
                value={
                  user.cori_exp_date
                    ? moment.utc(user.cori_exp_date).format("YYYY-MM-DD")
                    : ""
                }
                disabled={!editing || "null" != user.aoid}
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                type="date"
                name="physical_exp_date"
                label="Physical Expiration Date"
                style={{ backgroundColor: "rgba(255, 210, 117, 0.3)" }}
                value={
                  user.physical_exp_date
                    ? moment.utc(user.physical_exp_date).format("YYYY-MM-DD")
                    : ""
                }
                disabled={!editing || "null" != user.aoid}
              />
            </Col>
            {!isEmpty(user.licenses) &&
              user.licenses.map((license, idx) => {
                return (
                  <span key={idx}>
                    <Col md={4} xs={12}>
                      <Input
                        type="text"
                        name="certificationNameCode_codeValue"
                        label="License ID"
                        style={{
                          backgroundColor: "rgba(255, 210, 117, 0.3)",
                        }}
                        value={
                          license.certificationNameCode_codeValue
                            ? license.certificationNameCode_codeValue
                            : ""
                        }
                        disabled={!editing || "null" != user.aoid}
                      />
                    </Col>
                    <Col md={4} xs={12}>
                      <span />
                      <Input
                        type="text"
                        name="issuingParty_nameCode_shortName"
                        label="License State"
                        style={{
                          backgroundColor: "rgba(255, 210, 117, 0.3)",
                        }}
                        value={
                          license.issuingParty_nameCode_shortName
                            ? license.issuingParty_nameCode_shortName
                            : ""
                        }
                        disabled={!editing || "null" != user.aoid}
                      />
                    </Col>
                    <Col md={4} xs={12}>
                      <Input
                        type="date"
                        name="expirationDate"
                        label="License Expiration Date"
                        style={{
                          backgroundColor: "rgba(255, 210, 117, 0.3)",
                        }}
                        value={
                          license.expirationDate ? license.expirationDate : ""
                        }
                        disabled={!editing || "null" != user.aoid}
                      />
                    </Col>
                  </span>
                );
              })}
          </fieldset>
        </Row>
        <Row>
          <Col md={12} xs={12} className="action-footer">
            <button
              className="btn btn-danger"
              type="button"
              onClick={this.cancel}
            >
              Cancel
            </button>
            <input
              className="btn btn-success"
              type="submit"
              disabled={!this.state.canSubmit}
              value={this.props.saving ? "Saving... " : "Save"}
            />
          </Col>
        </Row>
      </Formsy>
    );
  }
}

UserForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  codes: PropTypes.array.isRequired,
  divisions: PropTypes.array.isRequired,
  auth: PropTypes.object,
  adding: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  glaccounts: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
};

export default UserForm;
