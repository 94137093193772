import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const client = (state = initialState.client, action) => {
  switch (action.type) {
    case types.GET_CLIENT_SUCCESS:
      return action.client;
    default:
      return state;
  }
};

export const clients = (state = initialState.client, action) => {
  switch (action.type) {
    case types.LOAD_CLIENT_SUCCESS:
      return action.clients;
    default:
      return state;
  }
};

export const clientToDelete = (state = initialState.clientToDelete, action) => {
  switch (action.type) {
    case types.REQUEST_CLIENT_ID:
      return action.clientToDelete;

    default:
      return state;
  }
};

export const episodeToDelete = (
  state = initialState.episodeToDelete,
  action
) => {
  switch (action.type) {
    case types.REQUEST_EPISODE_ID:
      return action.episodeToDelete;

    default:
      return state;
  }
};

export const clientContracts = (
  state = initialState.clientContracts,
  action
) => {
  switch (action.type) {
    case types.GET_CONTRACTS_BY_CLIENT_SUCCESS:
      return action.contracts;
    default:
      return state;
  }
};
