import React from "react";

import moment from "moment";
import times from "lodash/times";

const emptyCells = () => times(3, () => <td />);

const InvoiceHeader = ({ invoice, additionalCells }) => {
  let { contract, parent_account } = invoice;

  return (
    <table className="invoice-header">
      <tbody>
        <tr>
          <td>
            <h4>{contract.name}</h4>
          </td>
          {emptyCells()}
          {contract.invoice_format.value == 3 && additionalCells()}
          <td>
            <strong>Account #:</strong>
          </td>
          <td>
            <p>{parent_account.account_number}</p>
          </td>
        </tr>
        <tr>
          <td>
            <strong>Attention: {contract.billing_contact || "--"}</strong>
          </td>
          {emptyCells()}
          {contract.invoice_format.value == 3 && additionalCells()}
          <td>
            <strong>Invoice #:</strong>
          </td>
          <td>
            <p>{invoice.number || "- -"}</p>
          </td>
        </tr>
        <tr>
          <td>
            <strong>
              <p>{`${contract.bill_to_location.addr1}`}</p>
              {contract.bill_to_location.addr2 && (
                <p
                  style={{ marginTop: "10px" }}
                >{`${contract.bill_to_location.addr2}`}</p>
              )}
            </strong>
          </td>
          {emptyCells()}
          {contract.invoice_format.value == 3 && additionalCells()}
          <td>
            <strong>Invoice Date:</strong>
          </td>
          <td>
            <p>{moment.utc(invoice.document_date).format("MM/DD/YYYY")}</p>
          </td>
        </tr>
        <tr>
          <td>
            <strong>
              <p>{`${contract.bill_to_location.city}, ${contract.bill_to_location.state} ${contract.bill_to_location.zip}`}</p>
            </strong>
          </td>
          {emptyCells()}
          {contract.invoice_format.value == 3 && additionalCells()}
          <td>
            <strong>Due Date:</strong>
          </td>
          <td>
            <p>{moment.utc(invoice.due_date).format("MM/DD/YYYY")}</p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default InvoiceHeader;
