import { find, isEmpty, uniqBy } from "lodash";

export default function generateProdCatOptions(contracts, contract, division) {
  let prodCatOptions = [];
  if (!isEmpty(contracts) && !isEmpty(contract)) {
    let parentContract = find(contracts, {
      _id: contract._id,
    });

    if (parentContract) {
      let contractLines = parentContract.lines;

      if (division) {
        contractLines = contractLines.filter(
          (line) => line.division === division
        );
      }

      prodCatOptions = contractLines
        .filter((line) => line.product_category)
        .map((line) => ({
          value: line.product_category._id,
          label: line.product_category.label,
        }));
    }
  }
  return uniqBy(prodCatOptions, "label");
}
