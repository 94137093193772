import toastr from "toastr";

export function alertMessage(alert) {
  if (alert.show) {
    switch (alert.message.type) {
      case "success":
        toastr.success(alert.message.content);
        break;
      case "error":
        toastr.error(alert.message.content);
        break;
      default:
        return false;
    }
  }
  return false;
}

export function utcToYMD(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = d.getDate(),
    year = d.getFullYear();
  // hour = d.getHours(),
  // minute = d.getMinutes();

  if (month.toString().length < 2) month = "0" + month;
  if (day.toString().length < 2) day = "0" + day;

  let formatDate = [year, month, day].join("-");
  // let time = [hour, minute].join(':');
  return formatDate;
}

export function calculateTime(timeOne, timeTwo) {
  let time1 = timeOne.split(":"),
    time2 = timeTwo.split(":");
  let hours1 = parseInt(time1[0], 10),
    hours2 = parseInt(time2[0], 10),
    mins1 = parseInt(time1[1], 10),
    mins2 = parseInt(time2[1], 10);
  let hours = hours2 - hours1,
    mins = 0;

  // get hours
  if (hours < 0) hours = 24 + hours;

  // get minutes
  if (mins2 >= mins1) {
    mins = mins2 - mins1;
  } else {
    mins = mins2 + 60 - mins1;
    hours--;
  }

  // convert to fraction of 60
  mins = mins / 60;

  hours += mins;
  hours = hours.toFixed(2);
  return parseFloat(hours);
}

export function getMinutes(t) {
  let minutes = t % 60;

  return parseInt(minutes);
}

export function getHours(t) {
  let hours = t / 60;

  return parseInt(hours);
}

export function getMonthDateRange(year, month) {
  let moment = require("moment");

  // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
  // array is 'year', 'month', 'day', etc
  let startDate = moment([year, month - 1]);

  // Clone the value before .endOf()
  let endDate = moment(startDate).endOf("month");

  // just for demonstration:
  console.log(startDate.toDate());
  console.log(endDate.toDate());

  // make sure to call toDate() for plain JavaScript date type
  return `${moment(startDate).format("MM/DD/YYYY")} through ${moment(
    endDate
  ).format("MM/DD/YYYY")}`;
}

export function randomString(length, chars) {
  let result = "";
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}
