import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import AsyncPaginate from "react-select-async-paginate";
import { Row, Col } from "react-bootstrap";
import ClientService from "../../../services/clientService";

const round = (n, decimals = 0) =>
  Number(`${Math.round(`${n}e${decimals}`)}e-${decimals}`);

const ClientSelect = ({
  required,
  name,
  label,
  value: initialValue,
  onChange,
}) => {
  const [state, setState] = useState({ value: "" });

  const fieldLabel = required ? (label += " *") : label;

  const getLabel = (item) => {
    return [
      [item.last_name, item.first_name].filter(Boolean).join(", "),
      item.city,
      item.age && `${round(item.age, 1).toFixed(1)}yrs`,
    ]
      .filter(Boolean)
      .join(" - ");
  };
  const loadOptions = async (search, loadedOptions) => {
    const result = await ClientService.findClientsByQuery(
      search,
      loadedOptions.length,
      50
    );

    return {
      options: result.map((result) => ({
        label: getLabel(result),
        value: result._id,
      })),
      hasMore: result.length === 50,
    };
  };

  useEffect(() => {
    (async () => {
      if (initialValue && initialValue !== state.value) {
        const client = await ClientService.findClientById(initialValue);
        setState({
          label: getLabel(client),
          value: initialValue,
        });
      }
    })();
  }, [initialValue, state]);

  return (
    <Row className="form-group">
      <Col sm={12} xs={12}>
        <label
          className="control-label"
          htmlFor={name}
          style={{ textTransform: "uppercase" }}
        >
          {fieldLabel}
        </label>
      </Col>
      <Col sm={12} xs={12}>
        <AsyncPaginate
          name={name}
          required={required}
          debounceTimeout={500}
          value={state}
          onChange={(val) => onChange(name, val)}
          loadOptions={loadOptions}
        />
      </Col>
    </Row>
  );
};

ClientSelect.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  required: PropTypes.bool,
  label: PropTypes.string,
};

export default ClientSelect;
