import React, { useMemo } from "react";
import PropTypes from "prop-types";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { isEmpty, isNil } from "lodash";

const DateTimePicker = ({
  name,
  placeholderText,
  label,
  value,
  interval,
  required,
  disabled,
  valid,
  onChange,
  validations,
}) => {
  const validationState = useMemo(() => {
    if (isEmpty(validations) || isNil(value) || value === "") {
      return { variant: null, helpText: "" };
    }
    return (
      Object.keys(validations)
        .map((key) => {
          if (!validations[key].validator(value)) {
            return validations[key];
          }
        })
        .filter(Boolean)[0] || { variant: null, helpText: "" }
    );
  }, [validations, value]);

  const onDatepickerRef = (el) => {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  };

  const fieldLabel = required ? `${label} *` : label;

  return (
    <div className="form-group row">
      <label
        className="control-label"
        htmlFor={name}
        style={{ textTransform: "uppercase" }}
      >
        {fieldLabel}
      </label>
      <DatePicker
        ref={(el) => onDatepickerRef(el)}
        className="form-control"
        selected={value ? moment(value) : null}
        onChange={(v) => onChange(name, v)}
        placeholderText={placeholderText || "Enter time"}
        showTimeSelect
        fixedHeight
        disabled={disabled}
        locale="en-us"
        timeFormat="hh:mm A"
        timeIntervals={interval || 15}
        dateFormat="LLL"
        timeCaption="Time"
      />
      {!valid && validationState.helpText && (
        <span className="validation-message">{validationState.helpText}</span>
      )}
    </div>
  );
};

DateTimePicker.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholderText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  interval: PropTypes.number,
  valid: PropTypes.bool,
  validations: PropTypes.object,
};

export default DateTimePicker;
