import React, { Fragment } from "react";

import Formsy from "formsy-react";
import Select from "react-select";
import { Row, Col, Collapse, Well } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import { RadioGroup, Checkbox } from "formsy-react-components";

import orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import moment from "moment";

const FilterComponent = ({
  auth,
  filters,
  open,
  onChangeFilters,
  onChangeDate,
  onToggle,
  resetFilters,
  contracts,
  accounts,
  users,
  entity,
  codes,
  divisions,
  isMobile,
  focusedInput,
  onFocusChange,
  forApproval,
  toggleActive,
  state,
  noDate,
  isClientNote,
  checkBox,
  showSystemNotes,
  isDisabled,
}) => {
  const findChildren = (parent, children) => {
    let reportsTo = users.filter(
      (user) => user.reports_to == parent.payroll_id
    );

    if (!reportsTo.length) {
      return children;
    } else {
      reportsTo.map((user) => {
        children.push(user);
        findChildren(user, children);
      });
    }

    return children;
  };

  const genAssignedToOptions = () => {
    let assignedToOptions = [];
    //filter employee optios by active/inactve
    if (filters.active === "A") {
      users = users.filter((user) => user.termination_date == "");
    }
    if (filters.active === "I") {
      users = users.filter((user) => user.termination_date != "");
    }
    if (auth.maxRole <= 4000) {
      /* Employee - show only logged in users calls */
      assignedToOptions = users.filter(
        (user) => user.payroll_id === auth.user.payroll_id
      );
    } else if (auth.maxRole <= 8000) {
      /* Supervisor, Manager, Director, VP - show children, grandchildren employees */
      let children = findChildren(auth.user, []);
      assignedToOptions = [...children];

      if (!forApproval) assignedToOptions.push(auth.user);
    } else if (auth.maxRole <= 9000) {
      assignedToOptions = users;
    }

    // console.log("assignedToOptions", assignedToOptions);
    return uniqBy(
      sortBy(
        assignedToOptions.map((user) => ({
          value: user._id,
          label: `${user.last_name}, ${user.first_name}`,
        })),
        "label"
      ),
      "label"
    );
  };
  return (
    <Row>
      <Col md={12} xs={12} className="settings-add">
        <Collapse in={open}>
          <div>
            <Well bsSize="large">
              <Formsy className="vertical form">
                <Row>
                  {filters.parent_account && (
                    <Col md={3} xs={12}>
                      <Select
                        isMulti
                        label="Parent Account"
                        placeholder="Parent Account"
                        value={filters.parent_account}
                        onChange={(newVal) =>
                          onChangeFilters(newVal, "parent_account")
                        }
                        options={orderBy(
                          [
                            ...accounts.map((account) => ({
                              value: account._id,
                              label: account.name,
                            })),
                          ],
                          "label"
                        )}
                      />
                    </Col>
                  )}
                  {filters.contract && (
                    <Col md={3} xs={12}>
                      <Select
                        isMulti
                        label="Contract"
                        placeholder="Contract"
                        value={filters.contract}
                        onChange={(newVal) =>
                          onChangeFilters(newVal, "contract")
                        }
                        options={orderBy(
                          [
                            ...contracts.map((code) => ({
                              value: code._id,
                              label: code.name,
                            })),
                          ],
                          "label"
                        )}
                      />
                    </Col>
                  )}
                  {filters.stage && !forApproval && (
                    <Col md={3} xs={12}>
                      <Select
                        isMulti
                        label="Stage"
                        placeholder="Stage"
                        value={filters.stage}
                        onChange={(newVal) => onChangeFilters(newVal, "stage")}
                        options={orderBy(
                          [
                            ...codes
                              .filter(
                                (code) =>
                                  code.entity == entity && code.field == "stage"
                              )
                              .map((code) => ({
                                value: code._id,
                                label: code.label,
                                order: code.order,
                              })),
                          ],
                          "order"
                        )}
                      />
                    </Col>
                  )}
                  {!isMobile && filters.start_time ? (
                    <Col md={filters && !forApproval ? 3 : 6} xs={12}>
                      <DateRangePicker
                        startDate={
                          filters.start_time ? moment(filters.start_time) : null
                        }
                        startDateId="startDate"
                        endDate={
                          filters.end_time ? moment(filters.end_time) : null
                        }
                        endDateId="endDate"
                        isOutsideRange={() => false}
                        focusedInput={focusedInput}
                        onFocusChange={onFocusChange}
                        onDatesChange={({ startDate, endDate }) => {
                          onChangeDate(startDate, endDate);
                        }}
                      />
                    </Col>
                  ) : !noDate ? (
                    <Col md={filters.stage ? 3 : 6} xs={12}>
                      <DateRangePicker
                        startDate={
                          filters.start_time ? moment(filters.start_time) : null
                        }
                        startDateId="startDate"
                        endDate={
                          filters.end_time ? moment(filters.end_time) : null
                        }
                        endDateId="endDate"
                        isOutsideRange={() => false}
                        focusedInput={focusedInput}
                        onFocusChange={onFocusChange}
                        onDatesChange={({ startDate, endDate }) => {
                          onChangeDate(startDate, endDate);
                        }}
                        orientation={"vertical"}
                        numberOfMonth={1}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {filters.assigned_to && auth.maxRole > 4000 && (
                    <Col md={3} xs={12}>
                      <Select
                        isMulti
                        label="Employee"
                        placeholder="Employee"
                        value={filters.assigned_to}
                        onChange={(newVal) =>
                          onChangeFilters(newVal, "assigned_to")
                        }
                        options={[...genAssignedToOptions()]}
                      />
                    </Col>
                  )}
                  {filters["assigned_to.job_title"] && auth.maxRole > 4000 && (
                    <Col md={3} xs={12}>
                      <Select
                        isMulti
                        label="Job Title"
                        placeholder="Job Title"
                        value={filters["assigned_to.job_title"]}
                        onChange={(newVal) =>
                          onChangeFilters(newVal, "assigned_to.job_title")
                        }
                        options={orderBy(
                          uniqBy(
                            [
                              ...users.map((user) => ({
                                value: user.job_title,
                                label: user.job_title,
                              })),
                            ],
                            "label"
                          ),
                          "label"
                        )}
                      />
                    </Col>
                  )}
                  {filters.division && auth.maxRole > 4000 && !isClientNote && (
                    <Col md={3} xs={12}>
                      <Select
                        isMulti
                        label="Divisions"
                        placeholder="Divisions"
                        value={filters.division}
                        onChange={(newVal) =>
                          onChangeFilters(newVal, "division")
                        }
                        options={orderBy(
                          [
                            ...divisions.map((division) => ({
                              value: division._id,
                              label: division.description,
                            })),
                          ],
                          "label"
                        )}
                      />
                    </Col>
                  )}
                  {filters.product_category && (
                    <Col md={3} xs={12}>
                      <Select
                        isMulti
                        placeholder="Product Category"
                        value={filters.product_category}
                        onChange={(newVal) =>
                          onChangeFilters(newVal, "product_category")
                        }
                        options={orderBy(
                          [
                            ...codes
                              .filter(
                                (code) => code.field == "product_category"
                              )
                              .map((prodCat) => ({
                                value: prodCat._id,
                                label: prodCat.label,
                              })),
                          ],
                          "label"
                        )}
                      />
                    </Col>
                  )}

                  {filters?.division && isClientNote && (
                    <>
                      <Col md={3} xs={12}>
                        <Select
                          label="Divisions"
                          placeholder="Divisions"
                          value={filters.division}
                          isDisabled={isDisabled}
                          onChange={(newVal) =>
                            onChangeFilters(newVal, "division")
                          }
                          options={orderBy(
                            [
                              ...divisions.map((division) => ({
                                value: division.value,
                                label: division.label,
                              })),
                            ],
                            "label"
                          )}
                        />
                      </Col>
                      <Col md={4} xs={12} className="client-note">
                        {checkBox && (
                          <Checkbox
                            name="client-note"
                            value={showSystemNotes}
                            onChange={() => onToggle()}
                            valueLabel="View System Notes"
                          />
                        )}
                      </Col>
                    </>
                  )}
                  {filters.format && (
                    <Col md={3} xs={12}>
                      <Select
                        isMulti
                        placeholder="Format"
                        value={filters.format}
                        onChange={(newVal) => onChangeFilters(newVal, "format")}
                        options={orderBy(
                          [
                            ...codes
                              .filter((code) => code.field == "invoice_format")
                              .map((format) => ({
                                value: format._id,
                                label: format.label,
                              })),
                          ],
                          "label"
                        )}
                      />
                    </Col>
                  )}
                </Row>
                <Row className="mb-0">
                <Col md={6} xs={12}>
                  {toggleActive && (
                    <div id="filters-radio-group">
                      <RadioGroup
                        name="active"
                        type="inline"
                        label="Employee Type"
                        value={filters.active}
                        onChange={toggleActive}
                        options={[
                          { value: "A", label: "Active" },
                          { value: "I", label: "Inactive" },
                        ]}
                      />
                    </div>
                  )}
                     </Col>
                  <Col md={6} xs={12}>
                    <button
                      className="btn btn-warning"
                      type="button"
                      onClick={resetFilters}
                      style={{ float: "right" }}
                    >
                      Reset
                    </button>
                  </Col>
                </Row>
              </Formsy>
            </Well>
          </div>
        </Collapse>
      </Col>
    </Row>
  );
};

export default FilterComponent;
