import React, { useMemo } from "react";
import PropTypes from "prop-types";

import DropdownSearch from "../../../../common/DropdownSearchWithoutFormsy";
import generateUserOptions from "../../utils/generateUserOptions";

import { Col } from "react-bootstrap";
import FieldGroup from "../../../../common/FieldGroup";

export default function ApprovedStageForm({
  editing,
  inputs,
  users,
  onChange,
}) {
  const approvedByOptions = useMemo(() => generateUserOptions(users), [users]);
  return (
    <React.Fragment>
      <Col md={12} xs={12}>
        <DropdownSearch
          name="approved_by"
          label="Approved By"
          value={inputs.approved_by}
          disabled={!editing}
          data={approvedByOptions}
          onChange={(name, { value }) => onChange(name, value)}
        />
      </Col>
      <Col md={6} style={{ display: "none" }} xs={12}>
        <FieldGroup
          formNoValidate
          type="date"
          name="approved_date"
          label="Approved Date"
          value={inputs.approved_date}
          disabled={!editing}
          onChange={onChange}
        />
      </Col>
    </React.Fragment>
  );
}

ApprovedStageForm.propTypes = {
  editing: PropTypes.bool,
  inputs: PropTypes.object,
  users: PropTypes.array,
  onChange: PropTypes.func,
};

ApprovedStageForm.defaultProps = {
  inputs: {},
  users: [],
};
