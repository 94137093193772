import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const contracts = (state = initialState.contracts, action) => {
  switch (action.type) {
    case types.LOAD_CONTRACT_SUCCESS:
      return action.contracts;

    case types.CREATE_CONTRACT_SUCCESS:
      return [...state, Object.assign({}, action.contract)];

    case types.UPDATE_CONTRACT_SUCCESS:
      return [
        ...state.filter((contracts) => contracts._id !== action.contract._id),
        Object.assign({}, action.contract),
      ];

    case types.DELETE_CONTRACT_SUCCESS:
      return [
        ...state.filter((contract) => contract._id !== action.contractId),
      ];

    default:
      return state;
  }
};

export const contract = (state = initialState.contract, action) => {
  switch (action.type) {
    case types.GET_CONTRACT_SUCCESS:
      return action.contract;

    default:
      return state;
  }
};

export const savingContract = (state = initialState.savingContract, action) => {
  switch (action.type) {
    case types.SAVING_CONTRACT:
      return action.savingContract;

    default:
      return state;
  }
};

export const contractToDelete = (
  state = initialState.contractToDelete,
  action
) => {
  switch (action.type) {
    case types.REQUEST_CONTRACT_ID:
      return action.contractToDelete;

    default:
      return state;
  }
};

export const clientToDelete = (state = initialState.clientToDelete, action) => {
  switch (action.type) {
    case types.REQUEST_CLIENT_ID:
      return action.clientToDelete;

    default:
      return state;
  }
};
