import moment from "moment";

export default function isDateWithin24Hours(startTime, endTime) {
  // Validate that end date is within 24hrs of start date
  if (startTime && endTime) {
    const duration = moment.duration(moment(endTime).diff(startTime));
    const hours = duration && duration.asHours();

    return (
      hours < 24 ||
      moment(endTime).isAfter(startTime) ||
      moment(startTime).isBefore(endTime)
    );
  }
}
