import {
  invoices,
  invoice,
  savingInvoice,
  loadingInvoice,
  invoiceToVoid,
  invoicesToApprove,
  invoicesToComplete,
  invoicesToPrint,
} from "./invoiceReducer";
import {
  calls,
  call,
  callsByStage,
  loadingCall,
  savingCall,
  callToDelete,
  callsToApprove,
  callsToInvoice,
} from "./callReducer";
import {
  contracts,
  contract,
  savingContract,
  contractToDelete,
  clientToDelete,
} from "./contractReducer";
import {
  chargetypes,
  chargetype,
  savingChargetype,
  chargetypeToDelete,
} from "./chargetypeReducer";
import {
  companies,
  company,
  savingCompany,
  companyToDelete,
} from "./companyReducer";
import { codes, code, savingCode, codeToDelete } from "./codeReducer";
import { items, item, savingItem, itemToDelete } from "./itemReducer";
import { zips, zip, savingZip, zipToDelete } from "./zipReducer";
import {
  glaccounts,
  glaccount,
  savingGlaccount,
  glaccountToDelete,
} from "./glaccountReducer";
import { roles, role, savingRole, roleToDelete } from "./roleReducer";
import {
  divisions,
  division,
  savingDivision,
  divisionToDelete,
  departmentToDelete,
} from "./divisionReducer";
import { labels, label, savingLabel, labelToDelete } from "./labelReducer";
import {
  contacts,
  contact,
  savingContact,
  contactToDelete,
} from "./contactReducer";
import {
  accounts,
  account,
  savingAccount,
  accountToDelete,
  acctLocationToDelete,
} from "./accountReducer";
import {
  users,
  user,
  savingUser,
  loadingUser,
  userToDelete,
  auth,
} from "./userReducer";
import {
  client,
  clients,
  clientToDelete as mainClientToDelete,
  episodeToDelete,
  clientContracts,
} from "./clientReducer";
import { combineReducers } from "redux";
import { modal } from "./modalReducer";
import { alert } from "./alertReducer";
import { router } from "./routerReducer";
const rootReducer = combineReducers({
  modal,
  alert,
  router,
  users,
  user,
  savingUser,
  loadingUser,
  userToDelete,
  auth,
  accounts,
  account,
  savingAccount,
  accountToDelete,
  acctLocationToDelete,
  contacts,
  contact,
  savingContact,
  contactToDelete,
  labels,
  label,
  savingLabel,
  labelToDelete,
  divisions,
  division,
  savingDivision,
  divisionToDelete,
  departmentToDelete,
  roles,
  role,
  savingRole,
  roleToDelete,
  glaccounts,
  glaccount,
  savingGlaccount,
  glaccountToDelete,
  zips,
  zip,
  savingZip,
  zipToDelete,
  items,
  item,
  savingItem,
  itemToDelete,
  codes,
  code,
  savingCode,
  codeToDelete,
  companies,
  company,
  savingCompany,
  companyToDelete,
  chargetypes,
  chargetype,
  savingChargetype,
  chargetypeToDelete,
  contracts,
  contract,
  savingContract,
  contractToDelete,
  clientToDelete,
  calls,
  callsByStage,
  call,
  loadingCall,
  savingCall,
  callsToApprove,
  callsToInvoice,
  callToDelete,
  invoices,
  invoice,
  savingInvoice,
  loadingInvoice,
  invoiceToVoid,
  invoicesToApprove,
  invoicesToComplete,
  invoicesToPrint,
  client,
  clients,
  mainClientToDelete,
  episodeToDelete,
  clientContracts,
});

export default rootReducer;
