import * as endpoints from "./apiEndpoints";
import axios from "axios";

class GlaccountService {
  static loadGlaccounts() {
    const url = `${endpoints.BASE_URL}${endpoints.GET_GLACCOUNTS}`;

    return axios.get(url).then((response) => response.data);
  }

  static getGlaccount(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_GLACCOUNT}/${id}`;

    return axios.get(url).then((response) => response.data);
  }

  static createGlaccount(glaccount) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_GLACCOUNT}`;
    const body = {
      glaccount: glaccount,
    };

    return axios.post(url, body).then((response) => response.data);
  }

  static updateGlaccount(glaccount) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_GLACCOUNT}/${glaccount.id}`;
    const body = {
      glaccount: glaccount,
    };

    return axios.put(url, body).then((response) => response.data);
  }

  static deleteGlaccount(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_GLACCOUNT}/${id}`;
    return axios.delete(url).then((response) => response.data);
  }
}

export default GlaccountService;
