import React, { useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { find } from "lodash";
import moment from "moment";

import * as modalActions from "../../../../../actions/modalActions";

import Modal from "../../../../common/Modal";

import CallProcedureForm from "../../../CallProcedureFormUpdated";

function CallFormEnterTime({
  actions,
  chargetypes,
  clients,
  users,
  inputs,
  modal,
  onChange,
}) {
  const [overflow, setOverflow] = useState(false);

  const handleSaveEntries = (entries) => {
    let duration = 0;
    let billing_quantity = 0;

    const newEntries = entries.map((entry) => {
      if (entry.uom !== "MILE" && entry.uom !== "STUDENTS") {
        duration += parseFloat(entry.quantity);
        const chargetype = find(chargetypes, {
          id: entry.charge_type,
        });

        if (entry.uom == "FIXED") {
          entry.billing_quantity = 1;
        }

        if (chargetype.type.includes("Billable")) {
          billing_quantity += parseFloat(entry.billing_quantity);
        }
      } else {
        billing_quantity += parseFloat(entry.billing_quantity);
      }
      return entry;
    });

    const updates = {
      quantity: duration,
      billing_quantity,
      entries: newEntries,
      isValidDate: duration > 24 ? false : true,
    };

    if (inputs.start_time) {
      updates.end_time = moment(inputs.start_time).add(duration, "hours");
    }

    onChange(updates);

    actions.hideModal();
  };

  return (
    <Modal
      id="callDetailsModal"
      title="Enter Time"
      body={
        <CallProcedureForm
          chargetypes={chargetypes}
          clients={clients}
          contract={inputs.contract}
          entries={inputs.entries}
          onCancel={actions.hideModal}
          onOverflow={() => setOverflow(!overflow)}
          onSave={handleSaveEntries}
          product_category={inputs.product_category}
          startTime={inputs.start_time}
          user={find(users, { _id: inputs.assigned_to })}
        />
      }
      overflow={overflow}
      modal={modal}
      close={actions.hideModal}
    />
  );
}

CallFormEnterTime.propTypes = {
  actions: PropTypes.object,
  chargetypes: PropTypes.array,
  clients: PropTypes.array,
  users: PropTypes.array,
  inputs: PropTypes.object,
  modal: PropTypes.object,
  onChange: PropTypes.func,
};

CallFormEnterTime.defaultProps = {
  chargetypes: [],
  clients: [],
  users: [],
  inputs: {},
  modal: {},
};

function mapStatesToProps(state) {
  return {
    modal: state.reducers.modal,
    chargetypes: state.reducers.chargetypes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions }, dispatch),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(CallFormEnterTime);
