import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { Input, Select } from "formsy-react-components";
import autoBind from "react-autobind";

class DepartmentForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
      department: { dept_id: "", description: "" },
    };

    autoBind(this);
  }

  enableButton() {
    this.setState({
      canSubmit: true,
    });
  }

  disableButton() {
    this.setState({
      canSubmit: false,
    });
  }

  submit(model) {
    this.props.onSave(model);
  }

  render() {
    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Col xs={12}>
          <h4>Add Department</h4>{" "}
        </Col>
        <Col md={12}>
          <Select
            formNoValidate
            required
            name="division._id"
            label="Division"
            placeholder="Division"
            options={[
              { value: "", label: "Please select..." },
              ...this.props.divisions.map((division) => {
                return { value: division._id, label: division.description };
              }),
            ]}
            value={this.props.division.departments.description || ""}
          />
        </Col>
        <Col md={6}>
          <Input
            formNoValidate
            required
            name="description"
            label="Description"
            placeholder="Description"
            value={this.state.department.description || ""}
          />
        </Col>
        <Col md={6}>
          <Input
            formNoValidate
            required
            name="dept_id"
            label="Department Id"
            placeholder="Department Id"
            value={this.state.department.dept_id || ""}
          />
        </Col>
        <Col md={12}>
          <Link
            style={
              this.props.division.id != 0
                ? { display: "inline" }
                : { display: "none" }
            }
            className="btn btn-danger"
            to="/app/divisions"
          >
            Cancel
          </Link>
          <button
            className="btn btn-danger"
            type="button"
            onClick={this.props.onCancel}
          >
            Cancel
          </button>
          <input
            className="btn btn-success"
            type="submit"
            disabled={!this.state.canSubmit}
            value={this.props.saving ? "Saving... " : "Save"}
          />
        </Col>
      </Formsy>
    );
  }
}

DepartmentForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  division: PropTypes.object.isRequired,
  divisions: PropTypes.array.isRequired,
};

export default DepartmentForm;
