import { uniqBy } from "lodash";

const findChildren = (users, parent) => {
  const children = [];
  const reportsTo = users.filter(
    (user) => user.reports_to == parent.payroll_id
  );

  if (!reportsTo.length) {
    return children;
  }

  reportsTo.map((user) => {
    children.push(user);
    findChildren(children, user);
  });

  return children;
};

export default function generateAssignedToOptions(users = [], auth = {}) {
  let assignedToOpts = [];
  if (auth.maxRole <= 4000) {
    /* Employee - show only logged in users calls */
    assignedToOpts = users.filter(
      (user) => user.payroll_id === auth.user.payroll_id
    );
  } else if (auth.maxRole <= 8000) {
    /* Supervisor, Manager, Director, VP - show children, grandchildren employees */
    let children = findChildren(users, auth.user);
    assignedToOpts = [...children, auth.user];
  } else if (auth.maxRole <= 9000) {
    assignedToOpts = users;
  }
  return uniqBy(
    assignedToOpts.map((user) => ({
      value: user._id,
      label: user.full_name || `${user.last_name}, ${user.first_name}`,
    })),
    "label"
  );
}
